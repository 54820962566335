.access__wrapper {
  display: flex;
  width: 860px;
  height: 100%;
  flex-direction: column;
}

.access__nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.access__checkbox--fake {
  margin-right: 10px;
}

.access__content {
  margin-top: 20px;
}

.access__toggle {
  margin-left: 10px;
}

.nav__left {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
}

.nav__right {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b85;
  display: flex;
  align-items: center;
}

.right__stop {
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.right__access {
  display: flex;
  align-items: center;
}

.access-block {
  width: 100%;
  height: 100px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.access-block:not(:first-child) {
  margin-top: 20px;
}

.access-block.modules {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.block__name {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b85;
  display: flex;
  align-items: center;
}

.block__modules {
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  align-items: center;
}

.module__item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: #f8f8fa;
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
  border-radius: 100%;
  transition: all 0.02s linear;
  cursor: pointer;
  margin-right: 10px;
}

.platform-access__value {
  color: #636363;
  font-size: 13px;
  line-height: 13px;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  height: 14px;
}

.module__item:last-child {
  margin-right: 0;
}

.module__item:hover {
  border: 2px solid #27ae60;
}

.module__item.active {
  background-color: #27ae60;
  color: white;
}

.module__item.active:hover {
  border: 0;
}

.access-item__btn {
  font-size: 12px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  transition: all 0.2s linear;
  cursor: pointer;
  height: 40px;
  margin-top: 20px;
}

.access-item__btn:hover {
  background-color: transparent;
  color: black;
  border: 1px solid black;
}

.access-item__title {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  width: 100%;
}

.access-item__body {
  margin-top: 15px;
  border-top: 1px solid lightgray;
  padding-top: 15px;
}

.platform-access__copy {
  display: inline-block;
  width: 14px;
  height: 16px;
  top: 0;
  right: 0;
  position: absolute;
}

.platform-access__copy::after {
  mask-image: url("../../img/copy.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  cursor: pointer;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.platform-access__copy:hover::after {
  background-color: black;
}

.platform-access__item {
  margin-top: 25px;
}

.platform-access__item:nth-child(1) {
  margin-top: 0;
}

.platform-access__title {
  font-size: 14px;
  line-height: 14px;
}

.platform-access__name {
  color: #2b2b2b;
  font-size: 13px;
  line-height: 13px;
}

.platform-access__item:last-child .platform-access__value {
  display: flex;
  justify-content: space-between;
  height: 16px;
  position: relative;
  padding-right: 20px;
}

.access-item {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f4ff;
  padding: 20px 15px;
  border-radius: 10px;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0;
}

.access-item.active {
  flex-direction: column;
}

.toggle__access-tab {
  display: inline-block;
  width: 30px;
  height: 15px;
  padding: 3.3px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: #ff284f;
  margin-left: 6px;
}

.thumb__access-tab {
  display: inline-block;
  background: #fff;
  box-shadow: 0 10px 20px rgb(8 15 32 / 20%);
  height: 8.3px;
  width: 8.3px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.toggle__access-tab.active .thumb__access-tab {
  transform: translateX(15px);
}
