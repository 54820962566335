@import "../helpers/variables";

.banner__content {
  display: flex;
  flex-direction: column;
  margin: 30px 0 30px 40px;
  width: 1215px;

  &.settings {
    width: 970px;
  }
}

.banner__file {
  width: 0;
  height: 0;
}

.banner__link,
.banner__name {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #c3c2c9;
  margin-top: 5px;
}

.banner__link {
  margin-right: 20px;
}

.banner__label {
  display: flex;
  width: 100%;
  height: 160px;
  justify-content: flex-end;
  align-items: center;
  border: 2px dashed lightgray;
  border-radius: 10px;
  flex-direction: column;
  font-size: 12px;
  line-height: 12px;
  position: relative;
  padding: 15px;
  cursor: pointer;
  color: lightgray;
  transition: all 0.2s linear;
}

.banner__label:hover {
  border: 2px dashed black;
  color: black;
}

.banner__icon::after {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  mask-image: url("../../img/upload-img.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
}

.banner__label:hover .banner__icon::after {
  background-color: black;
}

.banner__label.active {
  border: 2px dashed black;
  color: black;
}

.banner__label.active .banner__icon::after {
  background-color: black;
}

.banner__icon {
  position: absolute;
  display: flex;
  width: 60px;
  height: 60px;
  margin-bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.banner__files {
  display: flex;
  margin-top: 30px;
}

.banner__nav {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 18px;
  width: 100%;
}

.banner__nav.course {
  margin-right: 40px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.banner__save,
.banner__remove {
  width: 180px;
  height: 40px;
  font-size: 12px;
  line-height: 12px;
  border: 1px solid $black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: all 0.2s linear;
  cursor: pointer;
  background-color: $black;
  color: $white;
}

.banner__save {
  margin-top: 0;
}

.banner__remove {
  margin-right: 15px;
}

.banner__save:hover,
.banner__remove:hover {
  color: $black;
  border-color: $black;
  background-color: transparent;
}

.banner__save.disabled,
.banner__remove.disabled {
  border-color: lightgray;
  color: lightgray;
}

.banner__file-wrapper {
  position: relative;
  width: 320px;
  height: 160px;
}

.banner__file-wrapper:first-child {
  margin-right: 20px;
}

.banner__remove-preloader {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: black;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 100%;
  transition: all 0.2s linear;
  opacity: 1;
}

.banner__file-wrapper:hover .banner__remove-preloader {
  opacity: 1;
}

.banner__img {
  width: 100%;
  height: 200px;
}

.banner__controls {
  display: flex;
}

.banner__info {
  display: flex;
  font-size: 12px;
  line-height: 12px;
}

.banner__remove-preloader::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: inline-block;
  width: 10px;
  height: 10px;
  mask-image: url("../../img/cancel.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  z-index: 2;
}

.banner__form {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid lightgray;
}

.banner__select {
  width: 280px;
  border-radius: 5px;
}

.banner__elem {
  width: 280px;
  height: 120px;
  overflow: hidden;
  position: relative;
  margin-right: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.banner__description {
  position: absolute;
  bottom: -100%;
  left: 0;
  font-size: 12px;
  line-height: 12px;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255 255 255 / 50%);
  color: black;
  transition: all 0.2s ease-in-out;
}

.banner__remove-btn {
  top: -100%;
  right: 0;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
  background-color: rgb(255 255 255 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.banner__elem:hover .banner__description {
  bottom: 0;
  display: flex;
  padding: 0 15px;
}

.banner__elem:hover .banner__remove-btn {
  top: 0;
}

.banner__preview {
  display: flex;
  width: 100%;
  height: 100%;
}

.banner__choice {
  width: 100%;
  height: 99px;
  display: flex;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  border: 4px solid transparent;
  position: relative;
}

.banner__radio:checked ~ .banner__choice {
  border: 4px solid red;
}

.banner__wrapper {
  width: 100%;
  height: 100%;
  padding: 0 0 0 40px;
  display: flex;
  flex-direction: column;
}

.banner__list {
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0;
  align-content: baseline;
  padding-bottom: 20px;
}

.banner__delete {
  display: inline-block;
  width: 13px;
  height: 13px;
  position: relative;
}

.banner__delete::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/cancel.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
}

.banner__search {
  display: flex;
  width: 280px;
  height: 40px;
  font-size: 12px;
  border: 1px solid lightgray;
  padding: 0 20px 0 10px;
  border-radius: 5px;
}

.banner__search-wrapper {
  position: relative;
}

.banner__search-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 9px;
  right: 9px;
  transition: all 0.2s linear;
  mask-image: url("../../img/search.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.banner__managment {
  display: flex;
  flex-direction: column;
  width: 970px;
}

.banner__checklist {
  list-style: none;
  width: 100%;
  border-radius: 8px;
  max-height: 400px;
}

.banner__checklist-item {
  padding: 10px 15px;
  background-color: white;
  border-bottom: 1px solid rgba(232 232 232 / 8%);
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;

  &:nth-child(2) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &--head {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    color: #b4b3bb;
    text-align: left;
  }
}

.banner__add {
  font-size: 12px;
  line-height: 12px;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: black;
  color: white;
  border: 1px solid $black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: transparent;
    color: $black;
  }
}

.checklist-item__img {
  width: 25px;
  height: 20px;
  display: inline-block;
}

.checklist-item__nav {
  display: flex;
  align-items: center;
}

.checklist-item__preview {
  display: inline-block;
}

.checklist-item__preview.mobile {
  margin-right: 17px;
  position: relative;
}

.checklist-item__preview.mobile::after {
  content: "";
  width: 1px;
  height: 15px;
  display: inline-block;
  background-color: lightgray;
  position: absolute;
  top: 3px;
  right: -9px;
}

.checklist-item__remove::after {
  content: "";
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-image: url("../../img/delete-btn.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: background-color 0.2s linear;
}

.checklist-item__remove {
  position: relative;
  display: inline-block;
  width: 13px;
  height: 15px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    &::after {
      background-color: black;
    }
  }
}

.managment__checklist {
  margin-top: 30px;

  &.settings {
    width: 100%;
  }
}

.banner-preview {
  width: 100%;
}

.banner-menu__item {
  border-top: 0;

  &:last-child {
    margin-bottom: 25px;
  }
}
