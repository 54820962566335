.platform__content {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 1215px;

  &.settings {
    width: 970px;
  }
}

.platform__content.overflowed {
  width: calc(100% - 80px);
  overflow-x: auto;
  overflow-y: hidden;
}

.platform__content.monitor {
  padding-top: 20px;
  margin-top: 0;
  border-top: 1px solid #e4e5e8;
}

.link__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &.bills {
    width: 50px;
    background: transparent;
  }
}

.link__wrapper.no-selector::after {
  display: none;
}

// .link__wrapper .platform__copy--table {
//   top: 50%;
//   left: 0;
//   transform: translateY(-50%);
// }

.link__wrapper::after {
  content: "";
  width: 1px;
  height: 20px;
  display: inline-block;
  top: 20px;
  right: 24px;
  position: absolute;
  background-color: #e4e5e8;
  pointer-events: none;
}

.link__wrapper .platform__copy {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
