.validate-error::after {
  position: absolute;
  top: -5px;
  left: 16px;
  content: "";
  width: 12px;
  height: 6px;
  background: url("../../img/error-arrow.png") no-repeat center;
  background-size: cover;
  z-index: 3;
}

.validate-error {
  width: max-content;
  position: absolute;
  left: 16px;
  bottom: -40px;
  font-size: 12px;
  line-height: 16px;
  color: #161718;
  padding: 14px 14px 9px;
  background: white;
  border: 1px solid #c3c2c9;
  border-radius: 3px;
  box-shadow: 0 8px 18px rgb(3 6 29 / 8%);
  z-index: 1000;
  transform: translate3d(0, 0, 1200);
  transition: 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}

.validate-error__wrapper {
  position: relative;
}
