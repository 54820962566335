.tabs_underline .tabs__wrapper {
  border-bottom: 1px solid #cfcfd2;
}

.tabs__list {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 1215px;
}

.tabs__item {
  margin: 0 10px;
  flex: 0 0 auto;
}

.tabs-item {
  color: #9c9ba6;
  padding-bottom: 10px;
  display: block;
  position: relative;
  font-weight: 500;
  transition: 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.tabs-item::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 4px;
  transform: scale(1, 0);
  transform-origin: 0 100%;
  transition: 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #481e8c;
}

.tabs-item.is-open,
.tabs-item:hover {
  color: #481e8c;
}

.tabs-item__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
}

.tabs-item.is-open::before {
  transform: scale(1, 1);
}

.tabs-animated {
  position: relative;
}

.tabs-animated .tabs-panel:not(.is-open) {
  pointer-events: none;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.tabs-animated .tabs-panel-transform {
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.tabs-animated .tabs-panel:not(.is-open) .tabs-panel-transform {
  opacity: 0;
  filter: alpha(opacity=0);
  transition: 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translate(0, 20px) scale(0.98);
}
