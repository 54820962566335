@import "../helpers/variables";

.platform {
  &__nav {
    &--left {
      position: relative;
    }
  }
}

@media (max-width: $mobile) {
  .platform {
    &__wrapper {
      width: 100%;
    }

    &__header {
      width: 100%;
    }

    &__title {
      font-style: 12px;
    }

    &__content {
      width: calc(100% - 40px);
      margin-left: 25px;
    }

    &__nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }
  }
}

@media (max-width: 1000px) {
  .platform {
    &__wrapper {
      height: calc(100vh - 70px);
    }
  }
}
