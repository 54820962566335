@import "../helpers/variables";

.course-lesson {
  margin-left: 40px;
}

.lessons {
  padding-top: 0;
  margin-top: 20px;
  margin-right: 30px;
}

.lessons__title {
  margin-bottom: 0;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.lessons__subtitle {
  margin-top: 12px;
}

.lessons__list {
  margin-top: 30px;
  width: 100%;
  max-width: 1180px;
  margin-bottom: 20px;
  height: fit-content;
}

.lessons__item {
  margin-top: 20px;
  background-color: white;
  padding: 30px 30px 40px;
  border-radius: 10px;
}

.lessons__item:first-child {
  margin-top: 0;
}

.lesson-text__content {
  margin-top: 20px;
  white-space: pre-line;
}

.lessons-document__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lessons-document__item {
  display: flex;
  align-items: center;
}

.lessons-document__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.lesson-document__type_pdf {
  background: #ce1126;
}

.lesson-document__type_psd {
  background: mediumvioletred;
}

.lesson-document__type_xml {
  background: tomato;
}

.lesson-document__type_xls,
.lesson-document__type_xlsx {
  background: #107c41;
}

.lesson-document__type_doc,
.lesson-document__type_docx {
  background: #4466ad;
}

.lesson-document__type_pptx,
.lesson-document__type_ppt {
  background: #f14524;
}

.lesson-document__type_jpg,
.lesson-document__type_jpeg,
.lesson-document__type_png {
  background: #471f8a;
}

.lessons-document__format {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 96px;
  height: 96px;
  color: white;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
  border-radius: 5px;
}

.lessons-document__title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 10px;
}

.lessons-document__btn {
  transition: all 0.2s linear;
}

.lessons-document__btn:hover {
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 20%);
}

.lessons-document__controls {
  display: flex;
  justify-content: flex-end;
  width: 330px;
}

.btn-read {
  background-color: #481e8c;
  color: white;
  width: 160px;
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-download {
  border: 1px solid black;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  border-radius: 5px;
  margin-left: 10px;
}

.lessons-raiting__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lessons-raiting__controls {
  display: flex;
  width: 490px;
  justify-content: flex-end;
}

.lessons-raiting__btn {
  width: 240px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c3c2c9;
  border-radius: 5px;
}

.raiting-btn__for .raiting-btn__icon {
  display: flex;
  width: 23px;
  height: 20px;
  position: relative;
}

.raiting-btn__for .raiting-btn__icon::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/like.svg");
  background-color: #27ae60;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.raiting-btn__against {
  margin-left: 10px;
}

.raiting-btn__against .raiting-btn__icon {
  display: flex;
  width: 23px;
  height: 20px;
  position: relative;
}

.raiting-btn__against .raiting-btn__icon::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/dislike.svg");
  background-color: #ff284f;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.lessons-gallery__content {
  margin-top: 20px;
  width: auto;
  height: 217px;
  display: flex;
}

.lessons-gallery__item {
  width: 217px;
  height: 217px;
}

.lessons-nav {
  margin-top: 20px;
}

.content-nav__item {
  width: calc(50% - 10px);
  padding: 41px 46px;
  margin-left: 0;
  margin-right: 0;
}

.content-nav__item-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  padding-left: 70px;
  padding-right: 25px;
}

.content-nav__item-number {
  margin-left: 32px;
  margin-right: 15px;
  position: absolute;
}

.lesson-video__content {
  display: block;
  width: 100%;
  height: auto;
  max-height: 640px;
  position: relative;
  margin-top: 20px;
  background-color: transparent;
}

.lesson-video__content img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  background-color: transparent;
}

.lesson-video__inner {
  display: none;
}

.content-video {
  position: relative;
  width: 100%;
  display: block;
  height: auto;
  overflow: hidden;
}

.content-video__preview {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.content-video__play-btn {
  width: 120px;
  height: 120px;
  background-color: rgb(0 0 0 / 60%);
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

.content-video__play-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60px;
  width: 50px;
  background-color: #fff;
  mask: url("../../img/play.svg") no-repeat center;
  transform: translate(-35%, -50%);
  mask-size: auto 60px;
  transition: 0.4s;
  z-index: 2;
}

.lesson-image__content {
  display: block;
  width: 100%;
  height: auto;
  max-height: 640px;
  position: relative;
  margin-top: 20px;
  background-color: transparent;
}

.lesson-image__content__title {
  font-size: 18px;
}

.lesson-image__content img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  background-color: transparent;
}

.content-image {
  position: relative;
  width: 100%;
  display: block;
  height: auto;
}

.content-image__preview {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.content-image img {
  width: 100%;
}

.lessons-gallery__wrapper {
  padding-bottom: 0;
  margin-bottom: 0;
}

.lesson-item__content {
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
}

.lesson-item__content p strong {
  font-weight: 800;
}

.lesson-item__content p em {
  font-style: italic;
}

.lesson-item__content p u {
  text-decoration: underline;
}

.comment__title {
  display: flex;
  align-items: center;
}

.comment__author {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  position: relative;
}

.comment__author--lightgray {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 15px;
  color: #737474;
}

.comment__author-answer {
  position: relative;
}

.comment__author-answer::after {
  display: flex;
  width: 1px;
  height: 20px;
  content: "";
  background-color: lightgrey;
  position: absolute;
  right: -7px;
  top: -3px;
}

.comment__avatar {
  margin-right: 15px;
}

.lesson-banner {
  overflow: hidden;
  max-width: 1180px;
  height: auto;
  padding: 0;
}

.lesson-banner__desktop {
  width: 100%;
  display: block;
  height: max-content;

  img {
    max-height: 350px;
  }
}

.lesson-banner__mobile {
  display: none;
}

.lesson-banner__link img {
  width: 100%;
  display: block;
}

.lessons-gallery {
  overflow: hidden;
}

.homework__send {
  padding: 10px 25px;
  background-color: black;
  color: white;
  border-radius: 10px;
}

.lessons-banner__mobile-img {
  width: 100%;
}

.lessons-banner__desktop-img {
  width: 100%;
  opacity: 0;
}

.homework__popup {
  margin-top: 20px;
}

.estimation {
  background-color: white;
  margin-top: 20px;
  padding: 30px;
  border-radius: 10px;
}

.estimation__inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.estimation__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
}

.estimation__item {
  width: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 2px;
  margin-right: 15px;
  position: relative;
}

.estimation__icon {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.1s linear;
  cursor: pointer;
}

.estimation__item:first-child .estimation__icon {
  mask-image: url("../../img/frown.svg");
}

.estimation__item:nth-child(2) .estimation__icon {
  mask-image: url("../../img/frown.svg");
}

.estimation__item:nth-child(3) .estimation__icon {
  mask-image: url("../../img/frown.svg");
}

.estimation__item:nth-child(4) .estimation__icon {
  mask-image: url("../../img/meh.svg");
}

.estimation__item:nth-child(5) .estimation__icon {
  mask-image: url("../../img/meh.svg");
}

.estimation__item:nth-child(6) .estimation__icon {
  mask-image: url("../../img/meh.svg");
}

.estimation__item:nth-child(7) .estimation__icon {
  mask-image: url("../../img/smile.svg");
}

.estimation__item:nth-child(8) .estimation__icon {
  mask-image: url("../../img/smile.svg");
}

.estimation__item:nth-child(9) .estimation__icon {
  mask-image: url("../../img/smile.svg");
}

.estimation__item:last-child {
  margin-right: 0;
}

.estimation__item:last-child .estimation__icon {
  mask-image: url("../../img/smile.svg");
}

.estimation__rate {
  display: none;
}

.estimation__list {
  display: flex;
  padding: 0;
  list-style: none;
  width: auto;
  justify-content: space-between;
}

.estimation__value {
  white-space: nowrap;
  margin-top: 10px;
}

.estimation__inner--client {
  display: flex;
  justify-content: space-between;
}

.estimation__value--client {
  display: flex;
  margin-top: 0;
  align-items: center;
}

.estimation__text--client {
  margin-left: 10px;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  transform: translateY(-1px);
}

.title__modules {
  font-size: 24px;
  line-height: 24px;
}

.subtitle__modules {
  font-size: 16px;
  line-height: 16px;
}

.estimation__icon--client {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.1s linear;
  cursor: pointer;
}

.estimation__title--vote {
  display: flex;
  align-items: center;
}

.homework-comments__item {
  width: max-content;
}

.module-wrapper {
  min-width: 0;
  margin-left: 40px;
}

@media (max-width: 1135px) {
  .content-nav__item_prev {
    padding-right: 0;
  }

  .content-nav__item_next {
    padding-left: 0;
  }

  .content-nav__item {
    margin-left: 0;
    margin-right: 0;
    width: calc(50% - 5px);
  }

  .content-nav__wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 1075px) {
  .content-element__side {
    padding-left: 30px;
  }
}

@media (max-width: 1000px) {
  .module-wrapper {
    margin-left: 0;
  }

  .header__box.block {
    display: none;
  }

  .back {
    margin-top: 20px;
    color: black;
  }

  .lesson-course {
    margin: 0 auto 0 30px;
    max-width: calc(100% - 60px);
  }

  .lessons__headtitle {
    margin-top: 20px;
  }

  .lessons__list:last-child {
    margin-top: 0;
  }

  .course-lesson {
    margin-left: auto;
    width: 100%;
  }

  .lessons-module {
    width: 100%;
    margin-right: 0;
    padding: 0;
  }

  .module__content {
    padding: 0 30px;
  }
}

@media (max-width: 765px) {
  .estimation {
    padding: 20px;
  }

  .estimation__list {
    width: auto;
    justify-content: center;
  }

  .estimation__item {
    max-width: 24px;
    margin-right: 10px;
  }

  .estimation__icon {
    width: 100%;
  }

  .homework-comments__item {
    width: 100%;
  }
}

@media (max-width: 737px) {
  .lessons-document__format {
    width: 60px;
    height: 60px;
    font-size: 12px;
  }

  .lessons-document__controls {
    width: auto;
  }

  .lessons__item {
    padding: 20px 20px 30px;
  }

  .btn-read {
    width: 40px;
  }

  .btn-read__text {
    display: none;
  }

  .btn-read__icon {
    display: flex;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .btn-read__icon::before {
    display: flex;
    width: 70%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    mask-image: url("../../img/read.svg");
    background-color: white;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
  }

  .btn-download {
    width: 40px;
  }

  .btn-download__text {
    display: none;
  }

  .btn-download__icon {
    display: flex;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .btn-download__icon::before {
    display: flex;
    width: 70%;
    height: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    mask-image: url("../../img/download.svg");
    background-color: black;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
  }

  .lessons-raiting__inner {
    display: flex;
    flex-direction: column;
  }

  .lessons-raiting__title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .lessons-raiting__controls {
    display: flex;
    justify-content: flex-end;
    max-width: 294px;
    margin: 20px auto 0;
  }

  .lessons-document__title {
    line-height: 15px;
    font-size: 12px;
  }

  .raiting-btn__for {
    height: 40px;
    width: calc(50% - 5px);
  }

  .raiting-btn__against {
    height: 40px;
    width: calc(50% - 5px);
  }

  .lessons-gallery__content {
    width: auto;
    height: 100px;
    display: flex;
  }

  .lesson-banner {
    padding: 0;
  }
}

@media (max-width: 720px) {
  .lesson-banner__desktop {
    display: none;
  }

  .lesson-banner__mobile {
    display: block;
    height: max-content;

    img {
      width: 100%;
      max-height: 440px;
    }
  }
}

@media (max-width: 691px) {
  .estimation__item {
    max-width: 22px;
    margin-right: 5px;
  }

  .estimation__value {
    margin-top: 5px;
  }
}

@media (max-width: 576px) {
  .comments__board {
    scrollbar-width: 0;
  }

  .comments__board::-webkit-scrollbar {
    display: none;
  }

  .content-nav__item {
    width: calc(50% - 5px);
  }

  .estimation__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  .estimation__item {
    width: 25px;
    margin-right: 10px;
  }

  .estimation__list {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 569px) {
  .upload-btn__wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-left: 0;
  }

  .upload-btn__item:last-child {
    margin-top: 10px;
  }

  .upload-btn__item-label {
    width: 100%;
  }

  .upload-btn__item-label:last-child {
    margin-top: 10px;
  }

  .homework__send {
    width: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 15px 0 0;
    transform: translate(0, 0);
  }

  .content-nav__item-text {
    padding-left: 57px;
    transform: translateY(3px);
  }

  .content-nav__item-number {
    margin-left: 25px;
    margin-right: 15px;
    position: absolute;
    transform: translateY(3px);
  }

  .lessons__headtitle {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0;
    text-align: left;
  }

  .lessons__headsubtitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0;
    text-align: left;
  }

  .lessons__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
  }

  .lessons-text__content {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
  }

  .lessons-document__left .lessons-document-item:last-child {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
  }

  .gallery__wrapper {
    margin: 0;
  }

  .lesson-item__content.gallery__wrapper {
    margin-top: 20px;
  }

  .content-video__play-btn {
    width: 20px;
    height: 23px;
    background-color: transparent;
  }

  .content-video__play-btn::before {
    width: 20px;
    height: 23px;
    mask-size: auto 23px;
  }

  .title__modules {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
  }

  .subtitle__modules {
    font-size: 16px;
    line-height: 16px;
  }
}

@media (max-width: 528px) {
  .lessons-nav {
    display: flex;
    flex-direction: column;
  }

  .content-nav__item {
    width: 100%;
    margin: 0 10px;
    padding: 0;
  }

  .content-nav__item_prev {
    width: 100%;
    padding: 21px 0 21px 46px;
  }

  .content-nav__item_next {
    width: 100%;
    padding: 21px 46px 21px 0;
  }

  .content-name__item_next {
    width: 100%;
  }

  .content-nav__item:nth-child(2) {
    margin-top: 10px;
  }

  .comment__author--lightgray {
    display: none;
  }

  .comment__author-answer::after {
    display: none;
  }

  .module__content {
    padding: 0 20px;
  }

  .lesson-course {
    margin-left: 20px;
    width: calc(100% - 40px);
  }
}

@media (max-width: 420px) {
  .estimation__list {
    justify-content: space-between;
  }

  .estimation__title {
    font-size: 16px;
    line-height: 16px;
  }

  .estimation__item {
    width: 22px;
    margin-right: 5px;
  }
}

@media (max-width: 374px) {
  .lessons-raiting__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .lessons-raiting__controls {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .lessons-about__title {
    padding-right: 5px;
  }

  .estimation__title--vote {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .estimation {
    padding: 20px 0;

    &__title {
      padding-left: 0.6em;
    }

    &__value {
      &--client {
        padding-right: 0.6em;
      }
    }
  }
}

@media (max-width: 353px) {
  .module-info {
    &__lesson {
      margin-right: 80px;

      &::after {
        display: none;
      }
    }
  }
}

@media (max-width: 335px) {
  .estimation__list {
    justify-content: space-between;
  }

  .estimation__item {
    width: 22px;
    margin-right: 3px;
  }
}

@media (max-width: 315px) {
  .lessons-about {
    &__title {
      font-size: 14px;
    }
  }

  .estimation {
    &__item {
      width: 20px;
    }
  }
}
