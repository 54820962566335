.cleaner {
  &__nav {
    margin: 20px 0 0 40px;
    width: 1215px;
  }

  &-nav {
    &__list {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }

  &__label {
    margin-right: 10px;
  }

  &__fake {
    margin-right: 10px;
  }

  &__search {
    &--button {
      padding: 10px 30px;
      border-radius: 5px;
      border: 1px solid black;
      font-size: 12px;
      transition: all 0.2s linear;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }

  &__item {
    width: 100%;
    min-height: 40px;
    position: relative;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &.open {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &__name {
    height: 100%;
    min-height: 40px;
    color: black;
    background-color: white;
  }
}

.cleaner-coincedence {
  &__list {
    display: flex;
    width: 100%;
    list-style: none;
    height: 100%;
    max-height: 0;
    overflow: hidden;
    flex-direction: column;
    margin-left: 20px;
    transition: max-height 0.3s ease-in-out;
  }

  &__item {
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: calc(100% - 20px);

    &:first-child {
      margin-top: 20px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      margin-bottom: 20px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.coincedence {
  &__list {
    width: 970px;
    margin: 20px 0 40px 40px;
    padding-top: 20px;
    border-top: 1px solid rgb(191 192 196 / 40%);
    list-style: none;
  }

  &__header {
    display: flex;
    height: 40px;
    list-style: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #b4b3bb;
    font-size: 12px;
  }

  &__name {
    width: 350px;
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    font-size: 12px;
  }

  &__quanity {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  &__info {
    display: flex;
    min-height: 40px;
    background-color: white;
    box-shadow: 0 10px 10px -8px rgb(0 0 0 / 20%);
  }

  &__item {
    min-height: 40px;
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin-left: 45px;

    &:not(:nth-child(2)) {
      border-top: 1px solid #eaeaeb;
    }

    &:nth-child(2) {
      .coincedence__info {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    &:nth-last-child(2) {
      .coincedence__info {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    &.open {
      .coincedence__info {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    &--header {
      margin-left: 45px;
    }
  }

  &__client {
    &--left {
      display: flex;
      justify-content: space-between;
      height: 40px;
      align-items: center;
      padding-right: 20px;
      width: 100%;
    }
  }

  &-client {
    &__list {
      height: 100%;
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s linear;

      &.open {
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
        overflow: visible;
      }
    }

    &__item {
      min-height: 40px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 45px;

      &.active {
        background-color: black;
        color: white;
      }

      &:not(:first-child) {
        border-top: 1px solid #eaeaeb;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &:nth-last-child(1) {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__remove {
      width: 12px;
      height: 17px;
      display: inline-block;
      margin-right: 40px;
      cursor: pointer;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        display: inline-block;
        mask-image: url("../../img/delete-btn.svg");
        background-color: #b4b3bb;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 100% 100%;
      }
    }

    &__name {
      width: 245px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      font-size: 12px;
      padding-left: 20px;
      white-space: nowrap;
      padding-right: 20px;
    }

    &__email {
      font-size: 12px;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
    }

    &__tel {
      font-size: 12px;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
    }

    &__link {
      font-size: 12px;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
    }

    &__telegram {
      font-size: 12px;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
    }
  }

  &__toggle {
    &-wrapper {
      top: 0;
    }

    &.open {
      background-color: black;

      &::after {
        background-color: white;
        transform: rotate(-180deg) translate(50%, 50%);
      }
    }
  }

  &__join-btn {
    background-color: #27ae60;
    padding: 5px 20px;
    border: 0;
    border-radius: 5px;
    font-size: 12px;
    color: white;
  }
}
