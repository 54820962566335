.analytic {
  width: calc(100vw - 80px);
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 70px;
}

.analytic__select {
  border: 1px solid #bfc0c4;
  color: black;
  border-radius: 5px;
}

.analytic__board {
  display: flex;
  margin-top: 20px;
  overflow: hidden;
  margin-bottom: 40px;
  transform: translateX(-40px);
  margin-left: 40px;
  max-width: 1215px;
}

.analytic__wrapper-board {
  width: max-content;
}

.analytic__top-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.analytic__content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  margin-left: 40px;
}

.analytic__nav {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.analytic__filter-form {
  display: flex;
}

.analytic__wrapper {
  display: flex;
  flex-direction: column;
  min-width: 1260px;
  overflow: visible;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  margin-left: 45px;
  background-color: transparent;
  box-shadow: none;
}

.analytic__column {
  min-width: 117px;
  width: 117px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: black;
  border-right: 1px solid #eaeaeb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
  text-align: center;
}

.manager__row .analytic__column {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
}

.analytic-board__header {
  background-color: white;
  display: flex;
  width: max-content;
  margin-left: 32px;
  height: 40px;
  min-height: 40px;
}

.analytic-board__header.left {
  border-top-left-radius: 15px;
}

.analytic-board__header-values {
  white-space: nowrap;
  background-color: white;
  height: 40px;
  width: max-content;
}

.analytic-board__header-values.right {
  border-top-right-radius: 15px;
}

.analytic-board__name {
  width: 265px;
  line-height: 14px;
  padding: 0 30px 0 15px;
  border-right: 1px solid #eaeaeb;
  height: 100%;
  display: flex;
  align-items: center;
  color: black;
  position: relative;
}

.analytic-board__manager-name {
  width: 220px;
}

.analytic-board__values {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;

  &.overflowX {
    overflow-y: hidden;
    overflow-x: auto;
  }
}

.analytic-board__header-values .analytic__column:last-child {
  border-right: 0;
}

.analytic__column.rating {
  margin: 0;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  border-right: 1px solid #eaeaeb;
}

.analytic__column.br_0 {
  border-right: 0;
}

.analytic__column.main {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0;
  text-align: center;
}

.analytic__column.red {
  background-color: #ff284f;
  color: white;
}

.analytic__column.yellow {
  background-color: #ffa515;
  color: black;
}

.analytic__column.green {
  background-color: #27ae60;
  color: white;
}

.analytic__row {
  width: max-content;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  line-height: 12px;
  font-weight: 800;
  box-shadow: 0 3px 5px rgb(5 4 20 / 8%);
  margin-left: 32px;
  position: relative;
  height: 40px;
  min-height: 40px;
  border: 0;
  margin-right: 0;

  .manager__name {
    border-right: 1px solid #eaeaeb;
  }
}

.analytic__row.main:nth-last-child(2) {
  border-bottom-left-radius: 15px;
}

.analytic__row.green {
  background-color: #d9ead3;
  border-top: 1px solid #b6d7a8;
}

.analytic__row.red {
  background-color: #f4cccc;
  border-top: 1px solid #ea8599;
}

.analytic__row.blue {
  background-color: #cae2f3;
  border-top: 1px solid #a4c2f4;
}

.analytic__row.pink {
  background-color: #ead1dc;
  border-top: 1px solid #ead1dc;
}

.analytic__row.purple {
  background-color: #d9d2e9;
  border-top: 1px solid #d9d2e9;
}

.analytic__row.yellow-column {
  background-color: #fff2cc;
  border-top: 1px solid #fff2cc;
}

.analytic__row.metric {
  margin-left: 0;
  box-shadow: 0 3px 5px rgb(5 4 20 / 8%);

  &.hide {
    display: none;
  }
}

.analytic__row.metric .analytic__column {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0;
  text-align: center;
}

.analytic__row.metric .analytic__column:last-child {
  margin-right: 0;
}

.analytic__row.metric.rating {
  margin-left: 0;
}

.analytic__row.main.rating {
  &.hide {
    display: none;
  }
}

.analytic__row.manager-row {
  min-height: 40px;
  height: 40px;
}

.analytic__row:not(:first-child) {
  border-top: 1px solid #eaeaeb;
}

.analytic__toggle::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 10px;
  height: 6px;
  display: flex;
  mask-image: url("../../img/arrow-down.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
}

.analytic__toggle {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: white;
  position: relative;
  cursor: pointer;
}

.analytic__row.open .analytic__toggle {
  background-color: black;
}

.analytic__row.open .analytic__toggle::after {
  transform: rotate(180deg) translate(50%, 50%);
  background-color: white;
}

.analytic__row--info {
  overflow-x: scroll;
}

.analytic__row.manager-board {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: max-content;
  box-shadow: none;
  background-color: transparent;
  transition: max-height 0.3s ease-in-out;
  height: max-content;
  margin-left: auto;
  max-height: 0;
  border: 0;
  min-height: auto;
}

.analytic__row.manager-board.rating {
  box-shadow: none;
  border: 0;
  background-color: transparent;
}

.analytic__row.manager-board.ml_0 {
  margin-left: 0;
}

.analytic__row.rating {
  padding: 0;
  margin-left: 32px;
  display: flex;
  flex-direction: row;
  border-radius: 0;
  margin-bottom: 0;
}

.analytic__row.rating.board-rating {
  flex-direction: column;
  margin-left: auto;
  width: max-content;
}

.analytic__row.rating.board-rating.ml_0 {
  margin-left: 0;
}

.analytic__toggle-wrapper {
  width: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -32px;
}

.analytic__metric-search {
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 25px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: black;
}

.nav__left {
  display: flex;
  margin-top: 20px;
}

.nav-left {
  display: flex;
}

.nav-left__start {
  margin-right: 50px;
  position: relative;
}

.nav-left__end {
  margin-right: 40px;
  position: relative;
}

.nav-left__select {
  width: 190px;
  height: 40px;
}

.nav-left__filter-input {
  width: 210px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #c3c2c9;
  color: black;
  padding: 0 35px 0 15px;
}

.start-icon::after,
.end-icon::after {
  content: "";
  display: inline-block;
  width: 17px;
  height: 18px;
  mask-image: url("../../img/calendar.svg");
  background-color: #c3c2c9;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
}

.manager__row {
  margin-left: 0;
  display: flex;
  flex-direction: row;
  box-shadow: none;
  width: 100%;
}

.manager__row:first-child {
  margin-top: 20px;
}

.manager__row:last-child {
  margin-bottom: 10px;
}

.manager__row.ml_0 {
  margin-left: 0;
}

.manager__name--analytic {
  width: 220px;
}

.start-icon::before {
  content: "";
  width: 20px;
  height: 14px;
  mask-image: url("../../img/reverse.svg");
  background-color: #161718;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  position: absolute;
  top: 14px;
  right: -36px;
}

.end-icon::before {
  content: "";
  width: 1;
  height: 40px;
  background-color: #bfc0c4;
  display: flex;
  position: absolute;
  top: 0;
  right: -20px;
}

.total {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0;
  text-align: center;
  border-right: 1px solid #eaeaeb;
}

.total-header {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
  text-align: center;
}

.metric-icon::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 16px;
  mask-image: url("../../img/metric.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  position: absolute;
  top: 11px;
  right: 10px;
}

.search-icon::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  mask-image: url("../../img/search.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  position: absolute;
  top: 13px;
  right: 10px;
}

.analytic__wrapper-board .manager-board:last-child .manager__row:last-child {
  margin-bottom: 0;
}

.analytic-board__values .manager-board:last-child .manager__row:last-child {
  margin-bottom: 0;
}

.analytic__row.metric:nth-last-child(2) {
  border-bottom-right-radius: 15px;
}

.audience-wrapper {
  display: flex;
  flex-direction: column;
}

.audience-search {
  width: 100%;
}

.audience-content {
  width: 100%;
}

.audience-funnel__wrapper {
  display: flex;
  flex-direction: column;
}

.audience-column {
  display: flex;
  width: 100%;
  height: 45px;
}

.audience__checkbox {
  max-width: 5%;
  min-width: 5%;
  display: flex;
  justify-content: center;
}

.audience__label {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.audience__name {
  max-width: 19%;
  min-width: 19%;
  display: flex;
  justify-content: flex-start;
}

.audience__link {
  max-width: 19%;
  min-width: 19%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
}

.theme-setting__tab-settings::after {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  mask-image: url("../../img/gear.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100%;
  overflow: visible;
  cursor: pointer;
  transition: background-color 0.2s linear;
  transform: translateY(-2px, 0);
}

.theme-setting__tab-settings:hover::after {
  background-color: black;
}

.theme-setting__tab-settings {
  width: 0;
  height: 0;
  display: inline-block;
  position: relative;
}

.show-settings .theme-setting__tab-settings {
  display: flex;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.platform-tabs__link.top-nav__item_active .theme-setting__tab-settings {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.platform-tabs__link.top-nav__item_active .theme-setting__tab-settings::after {
  width: 18px;
  height: 18px;
}
