@import "../../../helpers/variables";

.action-banner {
  width: 100%;
  margin-top: 20px;
}

.action-banner__wrapper {
  width: 100%;
  height: 340px;
  border-radius: 12px;
  transition: 0.1s;
  position: relative;
}

.action-banner__wrapper:hover {
  box-shadow: 0 2px 24px rgb(26 61 107 / 8%), 0 0 2px rgb(38 54 75 / 8%);
}

.action-banner__indicators {
  top: 0;
  margin: 20px auto 0;
  bottom: unset;
  display: flex;
  justify-content: flex-end;
  padding-right: 265px;
  padding-top: 7px;
}

.action-banner__indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #fff;
  margin-right: 5px;
  margin-left: 5px;
  opacity: 1;
  box-sizing: border-box;
}

.action-banner__indicators li.active {
  background-color: #fff;
}

.action-banner__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 340px;
}

.action-banner__item_dark *:not(.inherit) {
  color: #161718 !important;
  border-color: rgb(22 23 24 / 40%) !important;
}

.action-banner__item_light *:not(.inherit) {
  color: #fff !important;
  border-color: rgb(255 255 255 / 60%) !important;
}

.action-banner__item_dark *.action-caption {
  color: rgb(22 23 24 / 60%) !important;
}

.action-banner__item_light *.action-caption {
  color: rgb(255 255 255 / 60%) !important;
}

.action-banner__info {
  display: flex;
  flex-direction: column;
  padding: 30px;
  min-width: 370px;
  position: relative;
}

.action-banner__info_center {
  justify-content: center;
}

.action-banner__timer {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.action-banner__item_light::after {
  color: rgb(22 23 24 / 40%);
}

.action-banner__item_dark::after {
  color: rgb(22 23 24 / 40%);
}

.action-banner__img {
  width: 420px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 330px;
}

.action-banner__img img {
  width: 100%;
  height: 100%;
}

.action-banner__controls {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 72px;
  right: 30px;
  z-index: 10;
}

.action-banner__control-prev,
.action-banner__control-next {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 6px;
  background: rgb(255 255 255 / 40%);
  box-shadow: 0 2px 10px rgb(7 4 21 / 6%);
  position: relative;
}

.action-banner__control-prev::after,
.action-banner__control-next::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 14px;
  width: 8px;
  transition: 0.4s;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.action-banner__control-prev::after {
  mask-image: url("../../img/a-right.svg");
  background-color: $white;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transform: translate(-50%, -50%) rotate(180deg);
}

.action-banner__control-next::after {
  mask-image: url("../../img/a-right.svg");
  background-color: $white;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.monitor-panel__action-banner {
  min-width: 0;
  margin-top: 20px;
}

.action-banner__item .tile__tooltip {
  position: absolute;
  right: 20px;
  top: 20px;
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  background: #9c9ba6;
  color: white;
  cursor: pointer;
  transition: 0.4s;
  z-index: 100;
}

.carousel-inner {
  height: 340px;
  border-radius: 10px;
}

.carousel-item {
  border-radius: 10px;
  position: absolute;
}

.progress-bar__wrapper {
  color: black;
}
