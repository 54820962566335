.homework__content {
  width: 100%;
  height: auto;
  display: flex;
  border-top: 1px solid rgb(216 216 216 / 50%);
  flex-direction: column;
  margin-top: 20px;
  padding: 30px 0 0;
}

.homework__table {
  min-width: 1215px;
  width: 1215px;
  margin-bottom: 40px;
}

.homework-menu__content {
  padding: 20px 40px;
  height: 100%;
}

.homework__table--wrapper {
  min-width: 1215px;
  width: 1215px;
  display: flex;
  flex-direction: column;
}

.homework-table__row {
  padding: 0 19px 0 26px;
}

.homework-table__row--header {
  padding: 0 19px 0 26px;
}

.homework-comments__item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 0;

  &.client {
    margin-left: 0;
  }

  &.admin {
    margin-left: auto;
  }

  &.textarea {
    width: 100%;
    margin-left: 0;
  }
}

.homework-table__column:first-child {
  min-width: 80px;
  width: 80px;
  max-width: 80px;
}

.homework-table__column:nth-child(2) {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
}

.homework-table__column:nth-child(3) {
  min-width: 155px;
  width: 155px;
  max-width: 155px;
}

.homework-table__column:nth-child(4) {
  min-width: 140px;
  width: 140px;
  max-width: 140px;
}

.homework-table__column:nth-child(5) {
  min-width: 120px;
  width: 120px;
  max-width: 120px;
}

.homework-table__link {
  width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  transition: all 0.2s linear;
  color: black;
  text-align: left;
}

.homework-table__link:hover {
  color: black;
}

.homework-table__column:nth-child(5) .homework-table__link {
  min-width: 120px;
  width: 120px;
  max-width: 120px;
}

.homework-table__column:nth-child(6) {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
}

.homework-table__column:nth-child(7) {
  min-width: 101px;
  width: 101px;
  max-width: 101px;
}

.homework-table__column:nth-child(8) {
  min-width: 89px;
  width: 89px;
  max-width: 89px;
}

.homework-table__column:nth-child(9) {
  min-width: 70px;
  width: 70px;
  max-width: 70px;
}

.homework-table__column:nth-child(10) {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
  text-align: center;
}

.homework-table__column:nth-child(11) {
  min-width: 110px;
  width: 110px;
  max-width: 110px;
  padding-right: 5px;
  padding-left: 5px;
}

.homework-table__course-type {
  width: 130px;
  margin-top: 5px;
  text-align: left;
}

.homework-table__row--header .homework-table__column:nth-child(10) {
  text-align: left;
  min-width: 100px;
  width: 100px;
  max-width: 100px;
  padding-right: 5px;
}

.homework-rate {
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

.homework-rate__check {
  background-color: #ff0;
  color: black;
  transition: all 0.2s linear;
}

.homework-rate__check:hover {
  box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 30%);
}

.homework-rate__view {
  background-color: #27ae60;
  color: white;
  transition: all 0.2s linear;
}

.homework-rate__view:hover {
  box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 30%);
}

.homework-menu {
  width: 820px;
  height: 100%;
}

.homework-menu__comments--client {
  margin-top: 20px;
  padding: 30px;
  border-radius: 15px;
  background-color: white;
  overflow-x: hidden;
}

.homework-menu__comments {
  margin: 0;
}

.homework-menu__response.hidden {
  display: none;
}

.homework-comments__wrapper {
  height: auto;
  overflow-y: auto;
  max-height: 450px;

  &.client {
    overflow-x: hidden;
  }
}

.homework-comments {
  display: flex;
  flex-direction: column;
}

.homework-comments__item:first-child {
  margin-top: 0;
}

.homework-comments__item--admin {
  flex-flow: column;
  align-items: flex-end;
  margin-left: auto;
}

.comments-item__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  color: black;
  border-radius: 5px;
  padding: 20px 30px 35px 20px;
  word-break: break-all;
  background: #f4f4f6;
}

.comments-item__wrapper.admin {
  background: rgb(22 23 24 / 30%);
  color: white;
  word-break: break-word;
}

.homework-comments__gallery {
  display: flex;
  max-width: 100%;
  width: 100%;
  overflow: auto;
}

.homework-comments__warning {
  font-size: 12px;
  line-height: 12px;
  color: #b4b3bb;
  margin-top: 20px;
  transition: all 0.2s linear;
  display: inline-block;
}

.homework-comments__warning.error {
  color: #f00;
}

.homework-comments__gallery.full {
  margin-top: 10px;
}

.comments-gallery {
  display: block;
}

.comments-gallery__file {
  min-width: 100px;
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 5px;
}

.comments-gallery__file:last-child {
  margin-right: 0;
}

.comments-gallery__link {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  padding: 0;
}

.comments-img__delete::after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 17px;
  background-color: #fff;
  position: absolute;
  top: 7px;
  right: 14px;
  transform: rotate(45deg);
  opacity: 0;
  transition: all 0.2s linear;
}

.comments-img__delete::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 17px;
  background-color: #fff;
  position: absolute;
  top: 7px;
  right: 14px;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all 0.2s linear;
}

.comments-img__delete {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #161718;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.comments-gallery__link:hover .comments-img__delete {
  opacity: 1;
}

.comments-gallery__link:hover .comments-img__delete::after {
  opacity: 1;
}

.comments-gallery__link:hover .comments-img__delete::before {
  opacity: 1;
}

.comments-gallery__link.pdf {
  background-color: #f5244a;
  padding: 0 10px;
}

.comments-gallery__link.xls,
.comments-gallery__link.xlsx {
  background-color: #107c41;
  padding: 0 10px;
}

.comments-gallery__link.doc,
.comments-gallery__link.docx {
  background-color: #4466ad;
  padding: 0 10px;
}

.comments-gallery__link.ppt,
.comments-gallery__link.pptx {
  background-color: #f14524;
  padding: 0 10px;
}

.comments-gallery__img {
  width: 100%;
  height: 100%;
}

.comments-gallery__type {
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0;
  margin-top: 35px;
  width: 100%;
}

.comments-gallery__name {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: center;
  display: flex;
  width: 100%;
  overflow: hidden;
  color: white;
  margin-top: 10px;
  white-space: nowrap;
}

.comments-gallery__name.left {
  justify-content: flex-start;
}

.comments-gallery__name.center {
  justify-content: center;
}

.homework-response {
  margin-top: 40px;
  border-top: 1px solid #f1f1f3;
  padding-top: 30px;
}

.homework-response__status {
  display: flex;
  margin-top: 20px;
}

.homework-response__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #161718;
}

.homework-status__title {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  color: #b4b3bb;
}

.homework-estimation {
  display: flex;
  flex-direction: column;
  position: relative;
}

.homework-estimation__check {
  width: 50%;
  height: 40px;
  display: flex;
  margin-top: 20px;
  font-size: 12px;
  line-height: 12px;
}

.homework-estimation__label {
  min-width: 160px;
  width: 160px;
  height: 40px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  border: 1px solid #161718;
  color: #161718;
  transition: all 0.2s linear;
}

.homework-estimation__label--accept {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  min-width: 160px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  cursor: pointer;
}

.homework-estimation__label--accept.accept {
  background-color: #27ae60;
  border: 1px solid #27ae60;
  color: #fff;
}

.homework-estimation__label--decline {
  border-top-right-radius: 5px;
  width: 160px;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-bottom-right-radius: 5px;
  border-left: 0;
  cursor: pointer;
}

.homework-estimation__label--decline.decline {
  background-color: #f00;
  border: 1px solid #f00;
  color: white;
}

.homework-estimation__rate {
  margin-top: 20px;
}

.homework-estimation__block {
  position: relative;
  margin-left: 60px;
  opacity: 1;
  transition: all 0.2s linear;
}

.homework-estimation__block::after {
  content: "";
  position: absolute;
  top: 32px;
  left: -30px;
  width: 1px;
  height: 40px;
  display: inline-block;
  background-color: #f1f1f3;
  pointer-events: none;
}

.homework-estimation__block.hidden {
  opacity: 0;
  pointer-events: none;
}

.homework__estimation--checkbox {
  width: 0;
  height: 0;
  display: inline-block;
  opacity: 0;
  pointer-events: none;
}

.homework__estimation--checkbox:checked + label {
  background-color: #161718;
}

.homework__estimation--label {
  display: inline-block;
  width: 55px;
  height: 55px;
  mask-image: url("../../img/small-star.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
  transition: all 0.2s linear;
}

.homework__estimation--label.active {
  background-color: #161718;
}

.homework-text {
  display: block;
  width: 100%;
  height: 200px;
  position: relative;
  margin-top: 15px;
}

.homework-text::after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 20px;
  position: absolute;
  bottom: 15px;
  right: 17px;
  mask-image: url("../../img/work-icon.svg");
  background-color: #7c7b85;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
}

.homework-text.active::after {
  background-color: #161718;
}

.homework-text__area {
  width: 100%;
  height: 200px;
  resize: none;
  border-radius: 5px;
  border: 1px solid #c3c2c9;
  padding: 25px 25px 55px 20px;
}

.homework-comment {
  margin-top: 35px;
}

.homework-comment__files {
  display: flex;
  margin-top: 20px;
  border-top: 1px solid rgb(18 23 36 / 20%);
  padding-top: 20px;
}

.homework-comment__gallery {
  margin-bottom: 0;
  margin-top: 20px;
}

.homework-comment__gallery--admin {
  margin-top: 0;
}

.homework-comment__gallery--admin.full,
.homework-comment__gallery--client.full {
  margin-top: 20px;
}

.homework-comment__input--file {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.homework-comment__label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row-reverse;
  width: 350px;
  height: 120px;
  cursor: pointer;
  border: 2px dashed #b4b3bb;
  transition: all 0.2s linear;
  color: #b4b3bb;
  border-radius: 5px;
}

.homework-comment__label--image {
  margin-right: 40px;
}

.homework-comment__label--image::after {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #b4b3bb;
  transition: all 0.2s linear;
  mask-image: url("../../img/upload-img.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  margin-right: 10px;
}

.homework-comment__label--image:hover {
  color: #161718;
  border: 2px dashed #161718;
}

.homework-comment__label--image:hover::after {
  background-color: #161718;
}

.homework-comment__label--image.active {
  color: #161718;
  border: 2px dashed #161718;
}

.homework-comment__label--image.active::after {
  background-color: #161718;
}

.homework-comment__label--file::after {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #b4b3bb;
  transition: all 0.2s linear;
  mask-image: url("../../img/upload-file.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  margin-right: 10px;
}

.homework-comment__label--file:hover {
  color: #161718;
  border: 2px dashed #161718;
}

.homework-comment__label--file:hover::after {
  background-color: #161718;
}

.homework-comment__label--file.active {
  color: #161718;
  border: 2px dashed #161718;
}

.homework-comment__label--file.active::after {
  background-color: #161718;
}

.homework__rate {
  display: flex;
  align-items: center;
}

.homework__rate--icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  mask-image: url("../../img/star.svg");
  background-color: #ffd32e;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  margin-right: 3px;
}

.homework__rate--value {
  font-size: 12px;
  line-height: 12px;
  margin-left: 5px;
  top: 1px;
  position: relative;
}

.send-homework__btn {
  margin-top: 25px;
}

.homework-rating {
  display: flex;
  width: 560px;
  font-size: 13px;
  align-items: center;
  transform: translateY(15px);
}

.homework-comments__send {
  width: 100%;
  margin-top: 20px;
  padding: 30px;
  border-radius: 15px;
  background-color: white;
}

.homework-send__title {
  font-size: 16px;
  line-height: 16px;
}

.homework-comments__message {
  font-size: 12px;
  line-height: 12px;
  width: 100%;
  height: 180px;
  border: 1px solid #b4b3bb;
  margin-top: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  resize: none;
}

.homework-comments__buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.homework-comments__title {
  font-size: 16px;
  line-height: 16px;
}

.homework-comment__label.client {
  width: 100%;
  margin: 0;
  font-size: 12px;
  line-height: 12px;
}

.homework-comment__label.client:last-child {
  margin-top: 15px;
}

.homework-comments__warning.client {
  font-size: 13px;
  line-height: 13px;
}

.homework-comments__send--btn {
  color: white;
  background-color: black;
  transition: all 0.2s linear;
  border: 1px solid black;
  width: max-content;
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  margin-top: 25px;
}

.homework-comments__send--btn:hover {
  color: black;
  background-color: white;
  border: 1px solid black;
}

.disabled {
  pointer-events: none;
}

.homework__popup {
  color: black;
  font-size: 12px;
  line-height: 12px;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
}

.homework__popup--text {
  background-color: #f4f4f6;
  padding: 15px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
}

.homework-comment__label--file.client[for="docFile"] {
  margin-top: 20px;
}

.comments-item__rate {
  display: flex;
  width: 100%;
  max-width: 560px;
  margin-top: 12px;
  align-items: center;
}

.rate__item {
  display: inline-block;
  width: 15px;
  height: 15px;
  mask-image: url("../../img/rating.svg");
  background-color: #ffe800;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.rate__item:not(:last-child) {
  margin-right: 6px;
}

.rate__item.active {
  mask-image: url("../../img/estimation.svg");
}

.rate__text {
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
}

@media screen and (min-width: 750px) {
  .homework-comment__label.client {
    font-size: 14px;
    line-height: 14px;
    width: calc(50% - 20px);
  }

  .homework-comments__send--btn {
    font-size: 14px;
    line-height: 14px;
  }

  .homework__popup--text {
    font-size: 18px;
    line-height: 24px;
  }

  .comments-item__wrapper {
    width: 560px;
  }

  .homework-comments__buttons {
    flex-direction: row;
    justify-content: space-between;
  }

  .homework-comment__label--file.client[for="docFile"] {
    margin-top: 0;
  }

  .homework-comment__label.client:last-child {
    margin-top: 0;
    margin-left: 40px;
  }

  .homework__footer {
    padding: 20px 40px;
  }

  .homexork {
    width: 820px;
  }
}

@media screen and (min-width: 750px) {
  .homework-comment__label.client {
    font-size: 14px;
    line-height: 14px;
    width: calc(50% - 20px);
  }

  .homework-comments__send--btn {
    font-size: 14px;
    line-height: 14px;
  }

  .homework__popup--text {
    font-size: 18px;
    line-height: 24px;
  }

  .comments-item__wrapper {
    width: 560px;
  }

  .homework-comments__buttons {
    flex-direction: row;
    justify-content: space-between;
  }

  .homework-comment__label--file.client[for="docFile"] {
    margin-top: 0;
  }

  .homework-comment__label.client:last-child {
    margin-top: 0;
    margin-left: 40px;
  }

  .homework__footer {
    padding: 20px 40px;
  }

  .homework {
    width: 820px;
  }
}

@media (min-width: 1770px) {
  .homework__content {
    width: max-content;
    padding: 30px 0 0;
  }
}

@media (max-width: 380px) {
  .homework-comments__send {
    padding: 18px;
  }
}
