.platform__nav--right {
  display: flex;
  align-items: center;
  color: #b4b3bb;
  font-size: 14px;
  line-height: 14px;
}

.platform-nav__right--counter {
  white-space: nowrap;
  width: max-content;
}

.platform__counter {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #797880;
}
