@import "../../../helpers/variables";

.companies__table {
  box-shadow: 0 0 0 0 rgba($black, 0.2);
}

.company-header__row,
.company-body__row {
  display: grid;
  max-width: 1215px;
  justify-content: space-between;
  width: 970px;
  grid-template-columns: 46px 100px 120px 100px 110px 100px 70px 125px 50px;
  align-items: center;

  &:nth-child(2) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 0;
  }
}

.company-edit {
  height: auto;
  list-style: none;

  &__item {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}
