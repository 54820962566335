.platform-remove__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(70 68 68 / 73%);
  opacity: 0;
  transition: all 0.2s linear;
  z-index: 20;
  font-size: 18px;
  line-height: 32px;
}

.platform-remove__popup {
  position: absolute;
  top: -150%;
  left: 50%;
  right: 50%;
  width: 310px;
  height: auto;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 25px;
  transition: all 0.2s ease-out;
  transform: translateX(-50%);
}

.platform-remove__popup.p_20 {
  padding: 20px;
}

.platform-remove__nav {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  font-size: 18px;
  line-height: 26px;
}

.platform-remove__message {
  font-size: 16px;
  line-height: 22px;
}

.platform-remove__message--link {
  background-color: #f6f6f6;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  padding: 10px;
  margin-top: 15px;
  border-radius: 5px;
  max-height: 112px;
  text-overflow: ellipsis;
  overflow: auto;
}
