@import "../helpers/variables";

.width_100 {
  width: 100%;
  min-width: 100%;
}

.width_0 {
  width: 0;
  min-width: 0;
}

.width_50 {
  width: 50%;
  min-width: 50%;
}

.p_0 {
  padding: 0;
}

.ml_10 {
  margin-left: 10px;
}

.mt_0 {
  margin-top: 0;
}

.ml_0 {
  margin-left: 0;
}

.mt_5 {
  margin-top: 5px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_30 {
  margin-top: 30px;
}

.mr_5 {
  margin-right: 5px;
}

.mr_10 {
  margin-right: 10px;
}

.lh_16 {
  line-height: 16px;
}

.df_jcfe {
  display: flex;
  justify-content: flex-end;
}

.pl_10 {
  padding-left: 10px;
}

.pl_28 {
  padding-left: 28px;
}

.pl_15 {
  padding-left: 15px;
}

.pb_10 {
  padding-bottom: 10px;
}

.mt_20_br_1_pt_20 {
  margin-top: 20px;
  border-top: 1px solid #c4c4c433;
  padding-top: 20px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mt_32 {
  margin-top: 32px;
}

.darkness {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  z-index: 1;
  transition: all 220ms ease 0s;
}

.center-content {
  text-align: center;
}

.black {
  background-color: rgb(1 4 16 / 70%);
}

.platform__no-scroll {
  overflow: hidden;
  height: auto;
  padding-bottom: 0;
}

.disabled {
  color: #c3c2c9;
  pointer-events: none;
}

input:disabled,
textarea:disabled {
  color: #c3c2c9;
  border: 1px solid #c3c2c9;
}

input[s-text] {
  color: black;
}

input[s-date] {
  color: black;
}

div[js-menu-search] {
  z-index: 5;
}

div[js-menu-client-card] {
  z-index: 6;
}

div[js-menu-deal] {
  z-index: 7;
}

div[menu-bills] {
  z-index: 8;
}

div[menu-bills-add] {
  z-index: 8;
}

div[sms-checks] {
  z-index: 9;
}

div[js-menu-rate-point] {
  z-index: 10;
}

div[js-menu-rate-view] {
  z-index: 11;
}

div[js-menu-add-comment] {
  z-index: 12;
}

div[js-menu-open-comment] {
  z-index: 13;
}

div[add-bill-menu] {
  z-index: 17;
}

div[js-pay-menu] {
  z-index: 14;
  display: flex;
  flex-flow: row-reverse;
}

div[js-pay-menu] .platform-modal__wrapper {
  display: flex;
  flex-flow: row-reverse;
}

div[js-menu-update-method],
div[js-menu-create-row],
div[js-menu-create-manager],
div[js-menu-set-projects],
div[js-menu-create-check],
div[js-menu-create-employee],
div[js-menu-create-examiner],
div[js-menu-create-advertiser],
div[js-menu-managers-list] {
  z-index: 10;
}

div[js-menu-set-projects] .menu__list {
  height: 100%;
}

.platform__empty {
  font-size: 12px;
  line-height: 12px;
}

.no-icon {
  &::after {
    display: none;
  }
}

.hide {
  display: none;
}

.transparent {
  opacity: 0;
  pointer-events: none;
}

.off {
  pointer-events: none;
}

.black-border {
  border: 1px solid $black;
}

.b_0 {
  border: 0;
}

.transaction-filter {
  &::before {
    display: none;
  }
}

.ml_5 {
  margin-left: 5px;
}

.bb_0 {
  border-bottom: 0;
}

.mb_35 {
  margin-bottom: 35px;
}

.input-adv-inside {
  background-color: transparent;
}
