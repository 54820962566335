.cfilter {
  transform: translate3d(0, 0, 1200);
  transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  overflow: auto;
  display: flex;
  background-color: rgb(4 7 36 / 60%);
  min-height: 100%;
  z-index: 1200;
  position: fixed;
  top: -9999px;
  left: 0;
}

.cfilter.is-open {
  top: 0;
  transform: translate3d(0, 0, 1200);
  transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}

.cfilter__wrapper {
  position: relative;
  z-index: 1000;
  min-width: 1366px;
  width: 100vw;
  top: 0%;
  bottom: 100%;
  transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transform: translate3d(0, 0, 1200);
  overflow-x: auto;
  height: 100%;
  background-color: #f4f4f6;
}

.filter-head__title {
  font-style: normal;
  font-weight: 500;
  color: #161718;
  font-size: 24px;
  line-height: 28px;
  padding-left: 48px;
  display: flex;
  align-items: center;
}

.filter-non-active .styled-select {
  width: 0;
}

.filter-btn-active {
  background-color: #29006c;
}

.filter-btn-active + span {
  color: #29006c;
}

.filter-non-active {
  width: 0;
  margin: 0;
  opacity: 0;
}

.filter-header {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #161718;
}

.filter-content {
  background: #f4f4f6;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transform: translate3d(0, 0, 1200);
  position: relative;
}

.filter-head {
  background: #fff;
  box-shadow: 0 5px 30px rgb(7 2 14 / 4%);
  min-width: 1366px;
  width: 100%;
  height: 70px;
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.filter-content__body {
  overflow-y: auto;
}

.filter-body {
  padding: 20px 35px 0;
}

.filter-body__wrapper {
  display: flex;
  flex-direction: row;
}

.filter-body__wrapper_start {
  justify-content: flex-start;
}

.filter-column {
  display: inline-flex;
  flex-direction: column;
  margin-right: 20px;
}

.filter-column__title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  min-height: 21px;
  color: #161718;
  padding-left: 15px;
  margin-bottom: 0;
  margin-top: 15px;
}

.filter-column__row {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 420px;
}

.filter-column__row:last-child {
  margin-bottom: 30px;
}

.filter-column__row_bordered {
  padding-bottom: 20px;
}

.filter-column__row_bordered::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #e4e5e8;
}

.button-filter.filtered .content-top__filter {
  background-color: #9c9ba6;
}

.button-filter.filtered .button-filter__label {
  color: #9c9ba6;
}

.filter-content__footer {
  display: flex;
  flex-direction: row;
  margin: 0 35px;
  padding: 20px 0 30px;
  border-top: 1px solid #c7c9cf;
}

.filter-body__inner {
  display: flex;
}

.platform__filter {
  position: fixed;
  top: -150%;
  left: 0;
  right: 100%;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  background-color: transparent;
  z-index: 4;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.platform__filter--btn {
  display: inline-flex;
  flex-flow: row-reverse;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 40px;
  position: relative;
  color: #b4b3bb;
  transition: all 0.2s linear;
}

.platform__filter--btn.no-selector::before {
  display: none;
}

.platform__filter--btn::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  mask-image: url("../../img/filter.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  margin-right: 15px;
  pointer-events: none;
}

.platform__filter--btn::before {
  content: "";
  display: flex;
  width: 1px;
  height: 40px;
  background-color: rgb(191 192 196 / 40%);
  position: absolute;
  right: -20px;
  pointer-events: none;
}

.platform__filter--btn:hover {
  color: #161718;
}

.platform__filter--btn:hover::after {
  background-color: #161718;
}

.platform__filter--btn.active {
  color: #161718;
}

.platform__filter--btn.active::after {
  background-color: #161718;
}

.platform__filter.open {
  top: 0;
}

.platform-filter__layer.darkness {
  z-index: -1;
}

.filter__wrapper {
  position: absolute;
  top: -150%;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  background-color: #f4f4f6;
  margin-top: 0;
  z-index: 2;
  transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transform: translate3d(0, 0, 1200);
}

.filter__head {
  display: flex;
  justify-content: space-between;
  padding: 0 55px 0 40px;
  width: 100%;
  height: 70px;
  align-items: center;
  background-color: white;
}

.filter__title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: #161718;
  margin-bottom: 0;
}

.filter__content {
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  max-height: calc(100vh - 150px);
  height: max-content;
}

.filter__form {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  height: max-content;
}

.filter__column {
  display: inline-flex;
  width: 400px;
  flex-flow: row wrap;
  margin-right: 20px;
  margin-bottom: 0;
  height: max-content;
  justify-content: space-between;
}

.filter__column.empty-fields {
  max-width: 175px;
  width: 100%;
  margin-top: 0;
  flex-flow: row wrap;
}

.filter__column:last-child {
  margin-right: 0;
}

.filter__column--column {
  flex-direction: column;
}

.filter__labels-wrapper {
  display: flex;
  margin: 10px 0 0;
  cursor: pointer;
}

.filter__item {
  display: inline-block;
  width: calc(50% - 10px);
  margin-top: 10px;
}

.filter__name {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  padding-left: 15px;
  color: #7c7b85;
}

.filter__subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #161718;
}

.filter__select-wrapper {
  margin-top: 5px;
}

.filter__select {
  border-radius: 5px;
}

.filter__input {
  display: flex;
  padding: 0 30px 0 15px;
  width: calc(50% - 20px);
  height: 40px;
  border: 1px solid #c3c2c9;
  border-radius: 5px;
}

.filter__input::placeholder {
  color: black;
}

.filter__footer {
  border-top: 1px solid #b4b3bb;
  padding: 20px 40px 20px 0;
  display: flex;
  justify-content: flex-start;
  margin: 0 40px 20px;
  background-color: #f4f4f4;
}

.filter__apply {
  background: #161718;
  color: #fff;
  border: 1px solid #161718;
  border-radius: 5px;
  transition: all 0.2s linear;
  margin-right: 40px;
  position: relative;
  width: 200px;
  height: 40px;
  padding: 15px 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.filter__apply:hover {
  background-color: #fff;
  border: 1px solid #161718;
  color: #161718;
}

.filter__reset {
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  border: 0;
  background-color: transparent;
  align-items: center;
  color: #b4b3bb;
  transition: all 0.2s linear;
  cursor: pointer;
}

.filter__reset--icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.filter__reset--icon::after {
  content: "";
  width: 20px;
  height: 2px;
  background-color: #b4b3bb;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 100%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: all 0.2s linear;
}

.filter__reset--icon::before {
  content: "";
  width: 20px;
  height: 2px;
  background-color: #b4b3bb;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 100%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: all 0.2s linear;
}

.filter__reset:hover {
  color: #ff284f;
}

.filter__reset:hover .filter__reset--icon::after {
  background-color: #ff284f;
}

.filter__reset:hover .filter__reset--icon::before {
  background-color: #ff284f;
}

.filter__stop {
  display: flex;
  align-items: center;
  position: relative;
}

.filter__stop::after {
  content: "";
  width: 1px;
  height: 40px;
  background-color: #b4b3bb;
  position: absolute;
  top: 0;
  left: -20px;
  pointer-events: none;
}

.filter__input-wrapper {
  position: relative;
}

.filter__input-wrapper::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  pointer-events: none;
  content: "";
  width: 15px;
  height: 15px;
  mask-image: url("../../img/reverse.svg");
  background-color: #161718;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transform: translate(-50%, -50%);
}

.filter__input-wrapper.no-icon::after {
  display: none;
}

.custom-filter {
  width: 100%;
  padding: 0 15px;
  position: relative;
}

.custom-filter_active {
  width: 0;
  padding: 0;
}
