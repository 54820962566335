@import "../helpers/variables";

.payment-form {
  display: inline-block;
  width: 310px;
  height: 160px;
  border-radius: 10px;
  padding: 23px 0 0;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s linear;
  position: relative;
  margin-right: 20px;
  margin-top: 0;
}

.payment-form__contract {
  position: relative;
}

.payment-form__text {
  font-size: 12px;
  line-height: 12px;
  margin-left: 10px;
}

.payment-form__pay-form {
  display: flex;
  flex-direction: column;
}

.payment-form__date-wrapper {
  position: relative;
  height: auto;
}

.payment-form__date-wrapper.full {
  margin-top: 20px;
}

.payment-form:nth-child(3n) {
  margin-right: 0;
}

.payment-form:nth-child(n+4) {
  margin-top: 20px;
}

.payment-forms__wrapper {
  width: 1215px;
  display: flex;
  flex-direction: column;
  margin-left: 40px;

  &.settings {
    width: calc(100vw - 420px);
    overflow-x: auto;
  }
}

.payment-forms__panel {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.payment-forms__button {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  border: 1px solid black;
  border-radius: 5px;
  transition: all 0.2s linear;
  padding: 10px 40px;
  background-color: $black;
  color: $white;
  height: 40px;

  &:hover {
    background-color: transparent;
    color: $black;
  }
}

.payment-forms__list {
  margin-top: 20px;
  border-top: 1px solid rgba(#c4c4c4, 0.3);
  padding-top: 20px;
  display: flex;
  flex-flow: row wrap;
  width: 970px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.payment-add__block {
  padding-left: 0;
  padding-right: 0;
  margin-left: 25px;
  margin-right: 25px;
}

.payment-add__menu-title {
  margin: 0;
  padding: 0;
}

.payment-add__selector {
  margin-top: 20px;
  border-top: 1px solid #f1f1f3;
  padding-top: 20px;
}

.payment-add__name {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b85;
}

.payment-add__product-choose {
  position: relative;
  display: flex;
  font-size: 12px;
}

.payment-add__radio {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.payment-add__label {
  color: black;
  width: 100%;
  height: 40px;
  transition: all 0.2s linear;
  border: 1px solid black;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.payment-add__label:nth-child(2) {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.payment-add__label:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 0;
}

.payment-add__radio:checked + .payment-add__label {
  background-color: black;
  color: white;
}

.payment-add__name-input {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  color: black;
  border: 1px solid #c3c2c9;
}

.payment-add__name-input:placeholder {
  color: #7c7b85;
}

.payment-add__select {
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  color: black;
  font-size: 12px;
  line-height: 12px;
}

.payment-add__dates {
  display: flex;
  font-size: 12px;
  line-height: 12px;
}

.payment-add__button {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  padding: 15px 30px;
  transition: all 0.2s linear;
}

.payment-add__button:hover {
  color: white;
  background-color: black;
}

.payment-dates__inner {
  display: flex;
  flex-flow: row wrap;
  margin: 20px 25px;
}

.payment-dates__item {
  display: inline-flex;
  width: 50%;
  position: relative;
  margin-top: 5px;
  align-items: center;
  justify-content: flex-start;
}

.payment-dates__radio {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.payment-dates__radio--fake {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid black;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
}

.payment-dates__radio--fake::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: black;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.payment-dates__radio:checked + .payment-dates__label .payment-dates__radio--fake::after {
  opacity: 1;
}

.payment-dates__label {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: auto;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
}

.payment-dates__nearest {
  width: 100%;
}

.payment-form__delete {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 12px;
  height: 15px;
  display: inline-block;
  mask-image: url("../../img/delete-btn.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  opacity: 0;
  transition: all 0.2s linear;
}

.payment-form:hover .payment-form__delete {
  opacity: 1;
}

.payment-form:hover {
  box-shadow: 2px 4px 9px 0 rgb(0 0 0 / 20%);
}

.payment-form__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.payment-form__menu-wrapper {
  height: 100%;
}

.payment-form__name {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  color: #161718;
  padding: 0 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.payment-form__product {
  color: #7d7c85;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-top: 17px;
  pointer-events: none;
  padding: 0 23px;
}

.payment-form__bottom {
  border-top: 1px solid #f1f1f4;
  margin-top: auto;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 23px;
}

.payment-form__date {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #7d7c85;

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.payment-form__cut {
  display: inline-block;
  width: 14px;
  height: 14px;
  position: relative;
}

.payment-form__cut::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  mask-image: url("../../img/foreign.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.payment-form__copy {
  display: inline-block;
  width: 14px;
  height: 14px;
  position: relative;
  margin-left: 23px;
}

.payment-form__copy::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  mask-image: url("../../img/copy.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.select {
  font-size: 12px;
  line-height: 12px;
}

.select__head--tariff {
  font-size: 12px;
  line-height: 14px;
}

.tariff-content__input-value {
  width: 0;
  height: 0;
}

.payment-add__warning {
  margin-top: 15px;
  color: red;
}

.adv-input__search {
  margin-top: 20px;
  position: relative;
}

.adv-input__search::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  mask-image: url("../../img/search.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: all 0.2s linear;
}

.adv-input__search:focus.adv-input__search::after {
  background-color: black;
}

.adv-items__wrapper {
  margin-top: 5px;
}

.payment-add__block-date {
  width: 50%;
  height: 40px;
  display: inline-block;
  position: relative;
  border: 1px solid black;
}

.payment-add__block-date:nth-child(1) {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.payment-add__block-date:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.payment-dates-mode__radio {
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.payment-dates-mode__label {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  border: 0;
  cursor: pointer;
  transition: all 0.2s linear;
}

.payment-add__dates-interval {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
}

.payment-dates-mode__radio:checked + .payment-dates-mode__label {
  background-color: black;
  color: white;
}

.payment-add__select-dates {
  margin-top: 0;
}

.payment-dates-mode__lavel {
  width: 100%;
  height: 38px;
}

.payment-add__delete-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgb(0 0 0 / 80%);
  display: flex;
  justify-content: center;
  transition: opacity 0.2s linear;
  opacity: 0;
}

.payment-delete__block {
  position: relative;
  width: 320px;
  height: 180px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 25px;
  transition: all 0.2s ease-out;
  transform: translateY(-150%);
}

.payment-delete__message {
  font-size: 18px;
  line-height: 26px;
}

.payment-delete__controls {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.payment-delete__accept {
  border: 1px solid black;
  padding: 5px 30px;
  border-radius: 10px;
  transition: all 0.2s linear;
  cursor: pointer;
  margin-right: 30px;
}

.payment-delete__accept:hover {
  color: white;
  background-color: black;
}

.payment-delete__decline {
  padding: 5px 30px;
  background: black;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: all 0.2s linear;
  border: 1px solid black;
}

.payment-delete__decline:hover {
  color: black;
  background-color: transparent;
  border: 1px solid black;
}

.payment-form__item {
  padding: 20px 0 0;
  margin: 0 25px;
  list-style: none;
}

.payment-form__title {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b85;
}

.payment-form__labels {
  width: 100%;
  height: 40px;
  display: flex;
}

.payment-form__label {
  width: 50%;
  height: 100%;
}

.bt_1 {
  margin-top: 20px;
  border-top: 1px solid #f1f1f3;
}

.form__label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  border: 1px solid black;
  transition: all 0.2s linear;
  cursor: pointer;
}

.form__label:nth-child(2) {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.form__label:nth-child(4) {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.form__label--type {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.form__angle::after {
  top: 22px;
}

.form__checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.form__checkbox:checked + .form__label {
  background-color: black;
  color: white;
}

.form__checkbox:not(:checked) + .form__label {
  background-color: white;
  color: black;
}

.form__checkbox:checked + .form__label--type .platform__checkbox--fake {
  background-color: black;
}

.form__checkbox:checked + .form__label--type .platform__checkbox--fake::after {
  opacity: 1;
}

.form__checkbox:not(:checked) + .form__label--type .platform__checkbox--fake {
  background-color: white;
}

.form__checkbox:not(:checked) + .form__label--type .platform__checkbox--fake::after {
  opacity: 0;
}

.form__dates {
  height: auto;
  min-height: 60px;
  display: flex;
  flex-direction: column;
}

.form__dates-wrapper {
  display: flex;
  height: 40px;
}

.date__text {
  font-size: 12px;
  line-height: 12px;
  margin-left: 10px;
  color: black;
}

.payment-form__links-wrapper {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 20px;
  border: 1px solid #c3c2c9;
  border-radius: 5px;
}

.link__item {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.05s linear;
}

.link__item:hover {
  background-color: #b4b3bb;
  color: black;
}

.link__item:not(:first-child) {
  border-top: 1px solid #c3c2c9;
}

.link__item.active {
  background-color: black;
  color: white;
}

.course__img {
  min-width: 140px;
  height: 140px;
  width: 140px;
  min-height: 140px;
}

.form__course {
  display: flex;
  margin-top: 30px;
  box-shadow: 0 10px 20px rgb(8 15 32 / 4%);
  border-radius: 5px;
  height: 140px;
  overflow: hidden;
}

.info__warning {
  font-size: 12px;
  line-height: 15px;
  color: #7c7b85;
}

.client__info {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #f1f1f3;
}

.client__info.no-selector {
  border-top: 0;
}

.client__info.mt_15 {
  margin-top: 15px;
}

.client__info.buy-menu {
  margin-top: 15px;
  padding-top: 0;
  border-top: 1px solid #f1f1f3;
}

.client__info.no-product {
  margin-top: 15px;
}

.client__info--button {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  background-color: #161718;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  border: 1px solid black;
  transition: all 0.2s linear;
}

.client__info--button:hover {
  background-color: white;
  color: black;
}

.client__nav {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #f1f1f3;
}

.client__nav.no-selector {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}

.form-item__name {
  color: #7c7b85;
  font-size: 12px;
  line-height: 12px;
  padding-left: 15px;
}

.form-item__name.type-client {
  padding: 0;
}

.form-item__input {
  width: 100%;
  height: 40px;
  border: 1px solid #c3c2c9;
  vertical-align: 5px;
  padding: 0 15px;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 12px;
}

.form__content {
  margin-top: 15px;
}

.form__content.no-product {
  margin-top: 20px;
}

.form__item {
  position: relative;
}

.form__item:not(:first-child) {
  margin-top: 20px;

  &.have-login {
    margin-top: 0;

    &.mt_20 {
      margin-top: 20px;
    }

    &.mt_0 {
      margin-top: 0;
    }
  }
}

.prop__tariff {
  display: flex;
  font-size: 12px;
  line-height: unset;
  width: max-content;
  flex-flow: column wrap;
}

.prop__tariff.overflowed {
  overflow: hidden;
  width: 100%;
}

.tariff__name--value {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
  max-width: 100%;
  width: max-content;
  pointer-events: all;
}

.prop__tariff.one-date {
  max-width: 50%;
  width: 50%;
}

.prop__tariff.one-date .tariff__name--value {
  max-width: calc(100% - 45px);
  margin-left: 5px;
}

.public {
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
  display: flex;
}

.public__form {
  height: max-content;
  width: 550px;
  padding: 50px;
  display: inline-flex;
  flex-direction: column;
  margin: 40px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(8 15 32 / 4%);
}

.form__title {
  font-size: 28px;
  line-height: 28px;
}

.course__info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 140px);
}

.course__name {
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course__type {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  margin-top: 5px;
  color: #7c7b85;
}

.course__prop {
  font-size: 14px;
  line-height: 16px;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}

.course__prop.without-tariff {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tariff__date-start {
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-flow: column-reverse;
}

.tariff__name {
  width: 100%;
  max-width: 100%;
}

.tariff__name.no-selector::after {
  display: none;
}

.tariff__name::after {
  display: none;
}

.tariff__cost {
  font-weight: 700;
  margin-top: 2px;
}

.date-start__name {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 2px;
}

.form__select {
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  color: #7c7b85;
}

.date-start {
  font-weight: 700;
}

.date-start__value {
  font-weight: 400;
  margin-left: 5px;
}

.after-registration__text {
  font-size: 12px;
  line-height: 12px;
}

.payment-form__list {
  padding-bottom: 20px;
}

@media (max-width: 480px) {
  .public__form {
    width: calc(100% - 60px);
    padding: 25px 20px;
  }

  .form__title {
    font-size: 18px;
    line-height: 18px;
    margin: 0;
  }

  .form__course {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    height: auto;
    box-shadow: none;
    overflow: visible;
  }

  .form__item:not(:first-child) {
    margin-top: 10px;
  }

  .course__img {
    width: 100%;
    height: auto;
  }

  .course__info {
    width: 100%;
    margin-top: 15px;
    box-shadow: none;
    padding: 0;
  }

  .course__name {
    font-size: 16px;
    line-height: 20px;
    white-space: normal;
  }

  .course__type {
    font-size: 10px;
    line-height: 10px;
    margin-top: 5px;
  }

  .course__prop {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }

  .tariff__date-start {
    margin-top: 10px;
    font-size: 12px;
    line-height: 12px;
    flex-direction: row;
  }

  .tariff__name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    min-width: 100%;
  }

  .tariff__name::after {
    display: flex;
  }

  .tariff__name--value {
    font-weight: 400;
    white-space: normal;
  }

  .tariff__cost {
    margin-top: 0;
  }

  .date-start {
    font-weight: 700;
    margin-top: 0;
  }

  .date-start__value {
    font-weight: 400;
  }

  .prop__tariff {
    display: flex;
    flex-direction: row;
  }

  .prop__tariff.one-date {
    max-width: 100%;
    white-space: normal;
  }

  .tariff__coast {
    margin-top: 0;
  }

  .date-start__name {
    margin-top: 0;
  }

  .client__info {
    margin-top: 15px;
    padding-top: 15px;
  }
}

@media (min-width: 350px) and (max-width: 480px) {
  .form__title {
    font-size: 24px;
    line-height: 24px;
    margin: 0;
  }

  .course__name {
    font-size: 24px;
    line-height: 24px;
  }

  .course__type {
    font-size: 12px;
    line-height: 12px;
  }
}

@media (min-width: 451px) and (max-width: 461px) {
  .public__form {
    width: calc(100% - 60px);
    padding: 25px 20px;
  }

  .prop__tariff {
    flex-flow: wrap;
  }
}

@media (min-width: 481px) and (max-width: 610px) {
  .public__form {
    width: calc(100% - 60px);
    padding: 25px 20px;
  }

  .course__info {
    padding: 20px;
    width: calc(100% - 140px);
  }

  .course__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .course__type {
    margin-top: 5px;
  }

  .course__prop {
    display: flex;
    flex-direction: column;
  }

  .prop__tariff {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .prop__tariff.one-date {
    max-width: 100%;
  }

  .prop__tariff.one-date .tariff__name--value {
    margin-left: 5px;
  }

  .tariff__date-start {
    flex-direction: row;
    margin-top: 10px;
  }

  .tariff__cost {
    margin-top: 0;
  }

  .date-start {
    display: flex;
    margin-top: 0;
  }

  .date-start__name {
    margin-top: 0;
  }

  .client__info {
    margin-top: 15px;
    padding-top: 15px;
  }

  .form__item:not(:first-child) {
    margin-top: 10px;
  }
}

.private {
  position: relative;
}

.private__checkbox {
  min-width: 18px;
  min-height: 18px;
}

.private__link {
  text-decoration: underline;
}

.client__info--button.registration {
  margin-bottom: 15px;
}

.form-dates__item {
  position: relative;
}
