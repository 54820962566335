.info-blocks__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -15px;
  margin-right: -15px;
}

.info-block {
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
}

.info-block_icon {
  padding-left: 0;
  position: relative;
}

.info-block_icon_play::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 18px;
  width: 18px;
  mask: url("../../img/play-icon.svg") no-repeat center;
  mask-size: auto 18px;
  background-color: #481e8c;
  transition: 0.4s;
  transform: translateY(-50%);
}

.info-block_icon_video::before {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 19px;
  height: 12px;
  content: "";
  mask-image: url("../../img/video-icon.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.info-block_icon_module::before {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 15px;
  height: 16px;
  content: "";
  mask-image: url("../../img/module-icon.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.info-block_icon_work::before {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  content: "";
  mask-image: url("../../img/work-icon.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.info-block_icon_doc::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 16px;
  mask: url("../../img/document-icon.svg") no-repeat center;
  mask-size: auto 20px;
  background-color: black;
  transition: 0.4s;
  transform: translateY(-50%);
}

.info-block_icon_clock::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 20px;
  mask: url("../../img/clock-icon.svg") no-repeat center;
  mask-size: auto 20px;
  background-color: #481e8c;
  transition: 0.4s;
  transform: translateY(-50%);
}

.info-block__text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  margin-left: 10px;
}

.info-block__text_big {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
}

.info-block::after {
  content: "";
  display: block;
  width: 1px;
  height: 20px;
  position: absolute;
  top: 9px;
  right: -15px;
  transform: translateY(-56%);
  background-color: #cccdcf;
}

.info-date__item:nth-last-child(2)::after {
  display: none;
}

.info-content__item:last-child::after {
  display: none;
}

.info-date__logo {
  position: absolute;
  display: block;
  top: 20px;
  right: 30px;
  width: 81px;
  height: 56px;
}

.info-block__modules {
  margin-left: 0;
}

@media (max-width: 850px) {
  .info-content {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 600px) {
  .info-block {
    margin-bottom: 15px;
  }

  .info-content {
    margin-bottom: 0;
  }

  .info-content__item {
    margin-bottom: 0;
  }
}

@media (max-width: 570px) {
  .info-date__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
  }
}

@media (max-width: 562px) {
  .info-block__lessons {
    margin-right: 139px;

    &::after {
      display: none;
    }
  }

  .info-block__homework {
    margin-left: 0;
  }
}

@media (max-width: 460px) {
  .info-date__item {
    margin-bottom: 0;
  }

  .info-date__item:last-child {
    margin-top: 15px;
  }

  .info-date__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
  }

  .info-date__logo {
    display: none;
  }

  .info-content {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
  }

  .info-content__item {
    margin: 0 21px 0 0;
    display: inline-flex;
    align-items: center;
    width: auto;
  }

  .info-content__item:last-child {
    margin-left: 0;
  }

  .info-content__item::after {
    right: -9px;
  }

  .info-content__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
  }
}

@media (max-width: 450px) {
  .info-block {
    &__modules {
      margin-right: 30px;

      &::after {
        display: none;
      }
    }

    &__lessons {
      margin-left: 0;
      margin-right: 180px;
    }
  }
}

@media (max-width: 400px) {
  .info-block {
    &__lessons {
      margin-right: 180px;

      &::after {
        display: none;
      }
    }
  }
}

@media (max-width: 383px) {
  .info-block {
    &:last-child {
      margin-left: 0;
      margin-bottom: 0;
      margin-right: 15px;
    }
  }

  .modules-info__item {
    &:nth-child(2) {
      &::after {
        display: none;
      }
    }

    &:last-child {
      margin-bottom: 15px;
      margin-right: 21px;
    }
  }
}

@media (max-width: 375px) {
  .block {
    padding: 0 20px;
  }

  .header__box.block {
    padding: 20px;
  }

  .info-content {
    flex-wrap: wrap;
  }
}

@media (max-width: 358px) {
  .info-content__item:last-child {
    margin-top: 20px;
  }
}

@media (max-width: 349px) {
  .info-block {
    margin-right: 10px;
  }
}

@media (max-width: 335px) {
  .info-date {
    flex-wrap: wrap;
  }

  .info-date__item:nth-last-child(2) {
    margin-top: 15px;
  }

  .modules-info__item {
    &:last-child {
      margin-top: 0;
    }
  }
}

@media (max-width: 288px) {
  .info-block_icon {
    padding-left: 0;
  }
}
