.report {
  background-color: #f4f4f6;
  width: 100vw;
  height: max-content;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.report__wrapper {
  width: 270px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.report__project-logo {
  margin-top: 30px;
}

.report__logo {
  display: inline-block;
  width: 190px;
  height: 190px;
  min-height: 190px;
}

.report__logo.success {
  background-image: url("../../img/payment-succes.svg");
}

.report__logo.error {
  background-image: url("../../img/payment-failed.svg");
}

.report__title {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0;
  text-align: center;
  margin-top: 30px;
}

.report__description {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  margin-top: 10px;
  color: rgb(22 23 24 / 50%);
}

.report__nav {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.report__login {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  display: flex;
  width: 240px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: 5px;
  border: 1px solid black;
  transition: all 0.2s linear;
}

.report__back {
  width: 240px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  display: flex;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  transition: all 0.2s linear;
  border-radius: 5px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  color: black;
}

.report__work-link {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  color: rgb(22 23 24 / 50%);
  padding: 20px 0;
  margin-top: auto;
}

.report__link {
  color: black;
  text-decoration: underline;
}

.report__link.manager {
  margin-top: 25%;
  font-size: 12px;
  background: transparent;
  box-shadow: none;
  border: 0;
}

.report__link--bill {
  margin-top: 15px;
  padding: 10px 15px;
  border-radius: 7px;
  font-size: 14px;
  width: calc(100% - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
}

@media (max-width: 720px) {
  .report {
    &__logo {
      width: 150px;
      height: 150px;
      min-height: 150px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    &__title {
      margin-top: 15px;
    }

    &__info {
      transform: translateY(calc(-50% - 26px));
    }

    &__work-link {
      padding: 10px 0;
    }
  }
}
