.platform__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100vw - 85px);
  min-height: 110px;
  background-color: white;
  padding: 0 40px;

  &.settings {
    width: 100%;
  }
}

.platform__header.monitor {
  background-color: transparent;
}

.platform__header .platform__tabs {
  margin-top: 9px;
  transform: translateY(20px);
  width: 100%;
  display: flex;
}

.platform__title {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
  color: black;
  margin-bottom: 0;
  display: flex;
}
