input,
button,
select,
input:focus,
button:focus,
select:focus,
input:active,
button:active,
select:active {
  outline: none;
}

label {
  margin-bottom: 0;
}

.bl {
  margin-bottom: 50px;
}

.cell-120 {
  width: 120px;
}

.cell-180 {
  width: 180px;
}

.cell-40 {
  width: 40px;
}

.cell-500 {
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
}

.btn-large {
  width: 100%;
  font-size: 12px;
}

.btn_110 {
  min-width: 110px;
}

.table-form {
  margin-bottom: 0;
}

.input-sm {
  padding: 0.2rem 0.5rem;
  font-size: 12px;
  line-height: 12px;
  min-width: 100%;
}

.input-large {
  width: 100%;
}

.input_110 {
  min-width: 110px;
}

.input_mr-10 {
  margin-right: 10px;
}

.input-medium {
  width: 100px;
  text-align: center;
}

.yellow {
  background-color: yellow !important;
}

select.filter__element {
  appearance: none;
  width: 100%;
  height: auto;
  border: 0;
  background: transparent;
  cursor: pointer;
}

select.filter__element option {
  padding: 0.2rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* header */
.header__wrapper {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.header__logo {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.2;
}

.header__nav-link {
  padding: 0.25rem 0;
  font-weight: 700;
  color: rgb(0 0 0 / 50%);
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
}

.header__nav-link:hover {
  color: rgb(0 0 0 / 50%);
  border-bottom-color: rgb(0 0 0 / 50%);
  text-decoration: none;
}

.header__nav-link_active {
  color: #000;
  border-bottom-color: #000;
}

.table {
  overflow-x: auto;
  width: unset;
}

.table_fixed {
  overflow-x: unset;
}

.table-header__row {
  display: box;
  display: flexbox;
  display: flex;
}

.table-header__cell {
  display: inline-block;
  min-width: 120px;
  text-align: center;
  padding: 0.1rem 0.15em;
  font-weight: 700;
  color: rgb(0 0 0 / 70%);
  background-color: transparent;
  border: 1px solid rgb(0 0 0 / 30%);
  font-size: 11px;
}

.table-header__cell_caption {
  display: inline-block;
  min-width: 290px;
  border: 1px solid rgb(0 0 0 / 30%);
}

.table-body__row {
  display: box;
  display: flexbox;
  display: flex;
  position: relative;
}

.table-body__accordion {
  position: absolute;
  top: 3px;
  left: -24px;
  width: 17px;
  height: 17px;
  background-color: #454d55;
  color: #fff;
  border: none;
  line-height: 17px;
  padding: 0;
  font-size: 19px;
}

.table-body__cell {
  display: inline-block;
  min-width: 120px;
  text-align: center;
  padding: 0.1rem 0.15em;
  font-size: 11px;
  font-weight: 400;
  color: black;
  min-height: 23px;
}

.table-body__cell_caption {
  display: inline-block;
  min-width: 290px;
  text-align: left;
  padding: 0.1rem 0.8rem;
  font-size: 11px;
  font-weight: 700;
  color: black;
}

.table-body__cell_caption2 {
  display: inline-block;
  min-width: 290px;
  text-align: left;
  padding: 0.1rem 0.8rem;
  font-size: 12px;
  font-weight: 400;
  color: black;
}

.row_green div {
  background-color: #d9ead3;
  border: 1px solid #b6d7a8;
}

.row_pink div {
  background-color: #f4cccc;
  border: 1px solid #ea8599;
}

.row_blue div {
  background-color: #cae2f3;
  border: 1px solid #a4c2f4;
}

.row_violet div {
  background-color: #ead1dc;
  border: 1px solid #c3adb6;
}

.row_light-gray div {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
}

.row_gray div {
  background-color: #f3f3f3;
  border: 1px solid #d9d9d9;
}

.row_black div {
  background-color: black;
  border: 1px solid black;
  color: white;
}

.row_purple div {
  background-color: #d9d2e9;
  border: 1px solid #b4a7d6;
}

.row_gold div {
  background-color: #fff2cc;
  border: 1px solid #c9bea0;
}

.custom-lg-2,
.custom-lg-10 {
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 30px;
}

.custom-lg-10 {
  padding-left: 24px;
}

.filter__btn {
  display: block;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 7px;
  left: 7px;
  background: url("../../svg/f.svg");
  background-size: 30px 30px;
  cursor: pointer;
  z-index: 30;
}

@media (max-width: 991px) {
  .filter__wrapper {
    overflow-x: auto;
    display: flex;
  }

  .filter__element {
    margin-right: 15px;
    margin-left: 15px;
  }

  .filter__element:first-child {
    margin-left: 0;
  }

  .table-response {
    overflow-x: auto;
  }

  .table {
    overflow-x: unset;
  }

  .filter__btn {
    display: none;
  }
}

@media (min-width: 992px) {
  .custom-lg-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .custom-lg-2_big {
    -webkit-box-flex: 0;
    flex: 0 0 320px;
    max-width: 320px;
  }

  .custom-lg-2_big form {
    margin-right: 35px;
  }

  .custom-lg-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .custom-lg-10_big {
    flex: 0 0 calc(100% - 350px);
    max-width: calc(100% - 350px);
  }

  .filter__wrapper_without {
    margin-top: 0;
  }

  .filter__wrapper_none {
    display: none !important;
  }

  .custom-lg-2_active {
    max-width: 0;
  }

  .custom-lg-2_big.custom-lg-2_active {
    padding: 0;
  }

  .custom-lg-10_active {
    max-width: calc(100% - 30px);
  }

  .custom-lg-10_big.custom-lg-10_active {
    max-width: calc(100% - 30px);
    min-width: calc(100% - 30px);
  }

  .filter__btn_active {
    left: 15px;
  }
}

.searchClient {
  display: flex;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 7px;
}

.searchClient::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/search.svg");
  background-color: gray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.table-plan {
  margin-top: 20px;
}

.table-plan td,
.table-plan th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0;
  font-size: 12px;
}

.table-plan th {
  border-bottom: 0;
}

.table-plan thead th {
  border: 0;
  text-align: center;
  color: #b4b3bb;
  font-weight: 400;
}

.table-plan tbody {
  background-color: white;
}

.plans-container {
  margin-top: 20px;
}

.filter__element.mb-2.input-sm {
  margin-bottom: 0 !important;
}

.plans-table__center {
  text-align: center;
}

.container-table__title {
  font-size: 14px;
}
