@import "../helpers/variables";

.client-deals {
  display: flex;
  flex-flow: row wrap;
  min-width: 880px;
}

.client-card {
  cursor: pointer;
  background: #f4f4f6;
  border-radius: 3px;
  border: 1px solid #f4f4f6;
  transition: 0.4s;
  margin-bottom: 10px;
}

.client-card:hover {
  border: 1px solid black;
  box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
  border-radius: 3px;
}

.client-card__title {
  padding: 19px 17px 15px 15px;
  font-size: 12px;
  line-height: 16px;
  color: #161718;
  position: relative;
}

.client-card__title::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  height: 16px;
  width: 16px;
  mask: url("../../img/client-card-icon.svg") no-repeat center;
  mask-size: auto 14px;
  background-color: #bfc0c4;
  transition: 0.4s;
  transform: translateY(-50%);
}

.client-card__info {
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
}

.client-card:hover .client-card__title::after {
  background-color: black;
}

.client-card__title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #f2f2f4;
}

.access-item {
  flex-direction: column;
  background-color: #f2f2f4ff;
  padding: 15px 10px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 14px;
}

.platform-access__title {
  font-size: 14px;
  line-height: 14px;
}

.message__title {
  font-size: 16px;
  line-height: 18px;
  color: #c3c2c9;
}

.update-client {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-card__tabs {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 33px;
  border-bottom: 1px solid #c4c4c433;
  width: 864px;
}

.client-tab__item {
  margin-right: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;
  color: #9c9ba6;
}

.client-tab__item::after {
  content: "";
  display: inline-flex;
  width: 100%;
  height: 3px;
  background-color: black;
  opacity: 0;
  transition: all 0.1s linear;
  position: relative;
  top: -9px;
}

.client-tab__item.active {
  color: black;
}

.client-tab__item.active::after {
  opacity: 1;
}

.client-tab__item:hover {
  color: black;
}

.client-tab__item:hover::after {
  opacity: 1;
}

.client-card__content {
  display: none;
  margin-top: 30px;
  flex-flow: row wrap;
  width: 864px;
  list-style: none;
}

.name-input {
  width: 200px;
  margin-right: 20px;
  padding: 0 15px;
}

.name-input.new-client {
  width: 235px;
}

.client-menu {
  &__name {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 15px;
  }
}

.client-password {
  &__btn {
    display: inline-block;
    cursor: pointer;
    color: $client-acces-text;

    &:hover {
      text-decoration: underline;
    }
  }

  &__item {
    width: 100%;

    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }

  &__input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid $border-input;
    padding: 0 15px;
  }

  &__generate {
    position: absolute;
    top: 30px;
    right: 34px;
    display: inline-block;
    cursor: pointer;
    width: 28px;
    height: 28px;

    svg {
      &:hover {
        path {
          &:nth-child(1) {
            display: inline-block;
            border: 2px solid black;
          }
        }
      }
    }
  }
}

.gear1,
.gear2 {
  transition: all 0.2s linear;
}

.client-password__generate-icon {
  &:active {
    .gear1 {
      animation-name: rotate-gear;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    .gear2 {
      animation-name: rotate-gear-revers;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &:hover {
    .gear1,
    .gear2 {
      fill: black;
    }
  }
}

@keyframes rotate-gear {
  from {
    transform: rotate(0);
    transform-origin: center;
    transform-box: fill-box;
  }

  to {
    transform: rotate(360deg);
    transform-origin: center;
    transform-box: fill-box;
  }
}

@keyframes rotate-gear-revers {
  from {
    transform: rotate(360deg);
    transform-origin: center;
    transform-box: fill-box;
  }

  to {
    transform: rotate(0deg);
    transform-origin: center;
    transform-box: fill-box;
  }
}
