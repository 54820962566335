.employee {
  width: 140px;
  min-width: 140px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;

  &__img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 100%;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &-name {
    white-space: nowrap;
  }
}

.date-debate {
  width: 70px;
}

.platform-table__column.pad__comment {
  min-width: 100px;
  width: 100px;
  margin: 0;
  padding: 0 25px;

  &.pad__comment {
    width: 150px;
  }
}

.pad__header {
  padding-right: 15px;
  padding-left: 15px;
}

.comment .platform-form__access-btn {
  margin-top: 0;
  width: 100%;
}

.list-rate {
  min-width: 148px;
  width: 148px;
}

.point-rate {
  min-width: 110px;
  width: 110px;
  overflow: hidden;
}

.rate {
  min-width: 100px;
  width: 100px;
  background-color: transparent;
  border: 0;
  cursor: default;
}

.rate:hover {
  background-color: transparent;
  border: 0;
}

.comment-item {
  width: 100%;
  margin-top: 10px;
}

.comment__create-data {
  font-size: 12px;
  line-height: 12px;
}

.comment__text {
  font-size: 14px;
  line-height: 14px;
}

.platform-table__row--header .rate {
  color: #b4b3bb;
}

.pad-comment__btn {
  min-width: 100px;
}
