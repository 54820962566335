.available-course {
  display: flex;
  max-width: 1181px;
  border-radius: 10px;
  margin-top: 20px;
  height: auto;
  opacity: 1;
  box-shadow: 0 10px 20px rgb(8 15 32 / 8%);
  overflow: hidden;
  transition: all 0.2s linear;
}

.available-course.dark .available-course__button::after {
  content: "";
  background-color: black;
}

.available-course:first-child {
  margin-top: 0;
}

.available-course:hover {
  box-shadow: 0 10px 20px rgb(8 15 32 / 16%);
}

.available-course__inner {
  display: flex;
  width: 100%;
  padding: 25px 30px 40px;
  position: relative;
}

.available-course__left {
  display: flex;
  width: 550px;
  margin-right: 20px;
  flex-direction: column;
}

.available-course__title {
  margin-top: 25px;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 43px;
  letter-spacing: 0;
  text-align: left;
  display: block;
  color: black;
  max-width: 485px;
  word-break: break-word;
}

.dark .available-course__title {
  margin-top: 25px;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 43px;
  letter-spacing: 0;
  text-align: left;
  display: block;
  color: black;
}

.light .available-course__title {
  margin-top: 25px;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 43px;
  letter-spacing: 0;
  text-align: left;
  display: block;
  color: white;
}

.course-content__item {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 15px;
  position: relative;
}

.light .course-content__item {
  color: white;
}

.available-course__type {
  padding: 5px 12px;
  border: 1px solid black;
  color: black;
  display: inline-block;
  text-decoration: none;
  border-radius: 5px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-top: 15px;
  width: 103px;
}

.light .available-course__type {
  border: 1px solid white;
  color: white;
}

.course-date__item {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 15px;
}

.light .course-date__item {
  color: white;
}

.course-date__item:first-child .course-date__icon::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/play.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.available-course__subtitle {
  margin-top: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  display: block;
}

.available-course__progress {
  margin-top: auto;
  max-width: 400px;
}

.available-course__about {
  display: flex;
  margin-top: 20px;
}

.available-course__img {
  position: relative;
  display: block;
  max-width: 100%;
  padding: 0 20px;
  transform: translateY(40px);
  transform: translateY(40px);
  transform: translateY(40px);
}

.available-course.dark {
  color: black;
}

.course-content__item:first-child .course-content__icon::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/module-icon.svg");
  background-color: #481e8c;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.course-content__item:last-child .course-content__icon::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/work-icon.svg");
  background-color: #481e8c;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.available-course.dark .course-date__item:first-child .course-date__icon::before {
  background-color: black;
}

.available-course.dark .available-course__type {
  color: black;
  border: 1px solid black;
}

.course-content__item:nth-child(2) .course-content__icon::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/video-icon.svg");
  background-color: #481e8c;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.available-course.dark .course-content__item:first-child .course-content__icon::before {
  background-color: black;
}

.available-course.dark .course-content__item:nth-child(2) .course-content__icon::before {
  background-color: black;
}

.available-course.dark .course-content__item:last-child .course-content__icon::before {
  background-color: black;
}

.available-course.light .course-date__item:first-child .course-date__icon::before {
  background-color: white;
}

.available-course.light .available-course__type {
  color: white;
  border: 1px solid white;
}

.available-course.light .course-content__item:first-child .course-content__icon::before {
  background-color: white;
}

.available-course.light .course-content__item:nth-child(2) .course-content__icon::before {
  background-color: white;
}

.available-course.light .course-content__item:last-child .course-content__icon::before {
  background-color: white;
}

.course-date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.course-date__item:last-child {
  margin-left: 15px;
  margin-right: 0;
}

.course-date__item::after {
  content: "";
  position: absolute;
  right: -8px;
  top: 5px;
  display: flex;
  width: 1px;
  height: 12px;
  background-color: rgb(0 0 0 / 20%);
}

.course-date__item:last-child::after {
  display: block;
  left: -7px;
}

.course-date__item:first-child .course-date__icon {
  display: flex;
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 10px;
}

.dark .course-date__item::after {
  content: "";
  position: absolute;
  top: 5px;
  right: -8px;
  display: flex;
  width: 1px;
  height: 12px;
  background-color: black;
}

.course-date__item:nth-last-child(2) {
  margin-right: 0;
}

.course-date__item:nth-last-child(2)::after {
  display: none;
}

.light .course-date__item::after {
  background-color: white;
}

.course-date__img {
  position: absolute;
  top: 25px;
  right: 30px;
  width: 81px;
  height: 56px;
}

.course-content__item::after {
  content: "";
  position: absolute;
  right: -8px;
  top: 1px;
  display: flex;
  width: 1px;
  height: 18px;
  background-color: black;
}

.light .course-content__item::after {
  content: "";
  position: absolute;
  right: -8px;
  top: 1px;
  display: flex;
  width: 1px;
  height: 18px;
  background-color: white;
}

.course-content__item:first-child .course-content__icon {
  display: flex;
  width: 16px;
  min-width: 16px;
  height: 17px;
  position: relative;
  margin-right: 6px;
}

.course-content__item:nth-child(2) .course-content__icon {
  display: flex;
  width: 22px;
  height: 14px;
  position: relative;
  margin-right: 8px;
}

.course-content__item:last-child {
  margin-right: 0;
}

.course-content__item:last-child .course-content__icon {
  display: flex;
  min-width: 16px;
  width: 16px;
  height: 18px;
  position: relative;
  margin-right: 7px;
}

.course-content__item:last-child::after {
  display: none;
}

.available-course__image {
  display: flex;
  align-items: flex-end;
  border-radius: 100%;
  overflow: hidden;
  width: 300px;
  height: 300px;
}

.course-image__img {
  display: block;
  width: 100%;
  height: 100%;
}

.available-course__button {
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  background-color: white;
  color: black;
  border-radius: 5px;
  z-index: 0;
  transition: all 0.2s linear;
  box-shadow: 0 2px 9px 0 rgb(0 0 0 / 50%);
}

.dark .available-course__button {
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  background-color: black;
  color: white;
  border-radius: 5px;
  transition: all 0.2s linear;
}

.available-course__arrow::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  mask-image: url("../../img/arrow-right.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  margin-left: 20px;
}

.dark .available-course__arrow::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  mask-image: url("../../img/arrow-right.svg");
  background-color: white;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  margin-left: 20px;
}

.tabs-panel {
  margin-top: 20px;
}

.tabs-panel:first-child {
  margin-top: 0;
}

.available-courses {
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.available-courses__wrapper {
  margin-left: 40px;
}

@media (max-width: 1165px) {
  .available-course__title {
    max-width: 400px;
  }
}

@media (max-width: 1080px) {
  .course-image {
    display: none;
  }

  .available-course__left {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .header {
    display: none;
  }

  .available-courses__wrapper {
    margin-left: 0;
  }

  .available-courses {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 790px) {
  .available-course {
    height: auto;
  }

  .available-course__title {
    min-width: 100%;
  }

  .available-course__inner {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .available-course__image {
    transform: translate(0, 0);
    display: block;
    margin: 0 auto;
  }

  .available-course__progress {
    margin-top: 25px;
  }

  .available-course__button {
    position: relative;
    bottom: 0;
    right: -50%;
    margin-top: 40px;
    transform: translateX(-50%);
  }

  .dark .available-course__button {
    position: relative;
    bottom: 0;
    right: -50%;
    margin-top: 40px;
    transform: translateX(-50%);
  }

  .available-course__button::after {
    display: block;
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    height: 1px;
    background-color: white;
    transform: translateX(calc(-50% + 100px));
    width: calc(100vw - 120px);
  }

  .dark .available-course__button::after {
    background-color: black;
  }

  .course-image {
    display: flex;
    margin: 30px auto;
  }

  .course-image__img {
    max-width: 100%;
    margin-top: 5px;
  }

  .course-date__img {
    width: 35px;
    height: 24px;
    top: 16px;
    right: 21px;
  }
}

@media (max-width: 528px) {
  .course-date__img {
    width: 35px;
    height: 24px;
    top: 16px;
    right: 21px;
  }
}

@media (max-width: 528px) {
  .available-courses {
    padding: 20px;
  }

  .available-course__inner {
    padding: 20px 20px 0;
  }

  .available-course__button {
    position: relative;
    bottom: 0;
    right: -50%;
    margin-top: 40px;
  }

  .available-course__button::after {
    display: block;
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    width: calc(100vw - 80px);
    height: 1px;
    background-color: white;
  }
}

@media (max-width: 470px) {
  .course-modules {
    &__text {
      width: max-content;
      max-width: 77px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .course-content {
    &__homework {
      width: 100%;
      overflow: hidden;
    }
  }

  .lesson-text {
    max-width: none;
  }
}

@media (max-width: 441px) {
  .course-date__item {
    font-size: 12px;
  }

  .course-date__item::after {
    top: 6px;
  }
}

@media (max-width: 415px) {
  .course-date__img {
    display: none;
  }

  .course-date__item {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
  }

  .available-course__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-align: left;
    margin-top: 10px;
    word-break: break-all;
  }

  .available-course__type {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
  }

  .available-course__subtitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    margin-top: 34px;
  }

  .available-course__progress {
    margin-top: 20px;
  }

  .course-content__item {
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
  }

  .dark .course-date__item::after {
    top: 3px;
  }

  .light .course-date__item::after {
    top: 3px;
  }
}

@media (max-width: 387px) {
  .lesson-text {
    max-width: 70px;
  }
}

@media (max-width: 375px) {
  .available-courses__main {
    margin-top: 20px;
  }

  .available-courses__list {
    padding-bottom: 20px;
  }

  .available-course__button {
    width: 100%;
    min-height: 40px;
  }

  .course-image {
    display: flex;
    margin: 20px auto;
    width: 90%;
    height: 90%;
    aspect-ratio: 1/1;
  }

  .available-course__about {
    display: flex;
    flex-wrap: wrap;
  }

  .course-content__item {
    &:nth-child(2) {
      &::after {
        display: none;
      }
    }

    &:last-child {
      margin-top: 15px;
    }
  }

  .course-content__lesson {
    margin-right: 17px;
  }

  .lesson-text {
    max-width: 150px;
  }
}

@media (max-width: 361px) {
  .dark .available-course__title {
    margin-top: 15px;
    font-size: 20px;
    line-height: 24px;
  }

  .light .available-course__title {
    margin-top: 15px;
    font-size: 20px;
    line-height: 24px;
  }

  .course-content__item {
    &:last-child {
      margin-top: 10px;
    }

    &:nth-child(2) {
      &::after {
        display: none;
      }
    }
  }
}

@media (max-width: 320px) {
  .course-date__item {
    &.countdown {
      display: none;
    }
  }
}

@media (max-width: 297px) {
  .course-content {
    &__lesson {
      margin-top: 10px;
    }

    &__modules {
      margin-right: 37px;

      &::after {
        display: none;
      }
    }
  }
}
