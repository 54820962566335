.tile__control {
  height: auto;
  padding: 20px 30px 40px 20px;
}

.tile__text--control {
  margin-top: auto;
}

.tiles {
  width: 100%;
  margin-top: 30px;
}

.tiles__wrapper {
  display: flex;
  flex-flow: row wrap;
  max-width: 1260px;
  justify-content: space-between;
  width: 100%;
}

.tile {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 30px 30px;
  color: #161718;
  position: relative;
  background-color: white;
  border-radius: 10px;
  margin: 0 30px 0 0;
}

.tile:nth-child(5n) {
  margin-right: 0;
}

.tile:nth-child(n+6) {
  margin-top: 25px;
}

.tile__bg {
  background: #fff;
  border: 1px solid #f1f1f4;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.2s;
}

.tile_red .tile__bg {
  background: #ff284f;
  border-color: #ff284f;
}

.tile_black .tile__bg {
  background: #07020e;
  border-color: #07020e;
}

.tile_green .tile__bg {
  background: #27ae60;
  border-color: #27ae60;
}

.tile_red,
.tile__description {
  color: white;
}

.tile:hover .tile__bg {
  box-shadow: 0 2px 24px rgb(26 61 107 / 8%), 0 0 2px rgb(38 54 75 / 8%);
}

.tile_small {
  width: 219px;
  height: 219px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.big-tile {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border: 1px solid #f1f1f4;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  transition: 0.1s;
  padding-bottom: 20px;
}

.tile_green {
  color: #fff;
  background: #27ae61;
  border-color: #27ae61;
}

.tile_green:hover .tile__bg {
  box-shadow: 0 10px 30px rgb(39 174 96 / 30%);
}

.tile_green .tile__tooltip {
  background: rgb(255 255 255 / 40%);
  color: white;
}

.tile_red:hover .tile__bg {
  box-shadow: 0 10px 30px rgb(255 40 79 / 30%);
}

.tile_red .tile__tooltip {
  background: rgb(255 255 255 / 40%);
  color: #ff284f;
}

.tile__text-description {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 1;
  color: black;
  align-items: center;
}

.tile__description {
  font-size: 14px;
  line-height: 14px;
  color: black;
  margin-top: auto;
  display: flex;
}

.tile_green,
.tile__description {
  color: white;
}

.tile_black,
.tile_black .tile__description,
.tile_black .tile__text-description {
  color: white;
}

.tile__deviation-percent {
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 5px;
  color: black;
  font-size: 18px;
  line-height: 1;
  display: flex;
  align-items: center;
  height: 26px;
  padding: 0 8px;
  margin-left: 10px;
}

.tile_black .tile__deviation-percent {
  color: #30e37c;
  border-color: #30e37c;
}

.tile_black:hover .tile__bg {
  box-shadow: 0 10px 30px rgb(7 2 14 / 30%);
}

.tile_black .tile__tooltip {
  background: rgb(255 255 255 / 40%);
  color: #07020e;
}

.tile__tooltip:hover {
  background: black;
  color: #fff;
}

.tile__title {
  font-size: 16px;
  line-height: 21px;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 25px;
  width: calc(100% - 25px);
  padding-left: 40px;
}

.tile__title_margin {
  width: auto;
  margin: 20px 20px 20px 30px;
}

.tile__title_mb35 {
  margin-bottom: 35px;
}

.tile__title::before {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: 0.4s;
}

.tile__title_horn::before {
  background: url("../../img/i-horn.png") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_handshake::before {
  background: url("../../img/handshake.svg") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_estimation::before {
  background: url("../../img/estimation.svg") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_dispute::before {
  background: url("../../img/dispute.svg") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_change::before {
  background: url("../../img/change.svg") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_cart::before {
  background: url("../../img/i-cart.png") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_flash::before {
  background: url("../../img/i-flash.png") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_cup::before {
  background: url("../../img/i-cup.png") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_cash::before {
  background: url("../../img/i-cash.png") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_money::before {
  background: url("../../img/i-money.png") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_chart::before {
  background: url("../../img/i-chart.png") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_rating::before {
  background: url("../../img/rating-monitor.svg") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_deal::before {
  background: url("../../img/deal.svg") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_average-check::before {
  background: url("../../img/average-check.svg") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_reject::before {
  background: url("../../img/reject.svg") no-repeat center;
  background-size: 30px 30px;
}

.tile__title_transfer::before {
  background: url("../../img/transfer-icon.svg") no-repeat center;
  background-size: 30px 30px;
}

.tile__icons {
  display: flex;
}

.tile__title_average::before {
  display: inline-block;
  width: 15px;
  height: 23px;
  content: "";
  background: url("../../img/average.svg") no-repeat center;
  background-size: 15px 23px;
  margin-right: 6px;
}

.tile__title_estimation-average::before {
  display: inline-block;
  width: 30px;
  height: 30px;
  content: "";
  background: url("../../img/estimation.svg") no-repeat center;
  background-size: 30px 30px;
  margin-right: 15px;
}

.tile__icon {
  display: flex;
  align-items: center;
}

.tile__text {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

.tile-table {
  width: 100%;
}

.tile-table__header {
  border-bottom: 1px solid #e4e5e8;
}

.tile-table__header-col {
  font-size: 16px;
  line-height: 18px;
  color: #b4b3bb;
  padding-left: 35px;
}

.tile-table__header-col_p30 {
  padding-left: 30px;
}

.tile-table__header-col_small {
  width: 50px;
}

.tile-table__header-col_medium {
  width: 70px;
}

.tile-table__body-row {
  height: 52px;
  position: relative;
  border-bottom: 1px solid #e4e5e8;
}

.tile-table__body-col {
  padding-left: 35px;
  padding-right: 35px;
  position: relative;
}

.tile-table__index {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  font-size: 18px;
  line-height: 1;
  color: #bfc0c4;
}

.tile-table__body-col_p30 {
  padding-left: 30px;
  padding-right: 30px;
}

.tile-table__body-col_pr0 {
  padding-right: 0;
}

.tile-table__body-col_pr10 {
  padding-right: 10px;
}

.tile-table__body-col_pr30 {
  padding-right: 30px;
}

.tile-table__header-col_pl10,
.tile-table__body-col_pl10 {
  padding-left: 10px;
}

.tile-table__header-col_pl30,
.tile-table__body-col_pl30 {
  padding-left: 30px;
}

.tile-table__body-col_small {
  width: 50px;
}

.tile-table__body-col_medium {
  width: 70px;
}

.tile-table__body-col_large {
  width: 200px;
  min-width: 200px;
}

.tile-table__body-col_bordered {
  border-right: 1px solid #e4e5e8;
}

.tile-bonus {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  width: 40px;
  cursor: pointer;
  color: #9c9ba6;
  margin: 0 auto;
}

.tile-table___value {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.monitor-panel__widgets {
  border-top: 0;
  padding-top: 0;
  min-width: auto;
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
}

.tile_red {
  background-color: #ff284f;
}

.tile__title.average {
  padding-left: 0;
}

.tile_red .tile__title {
  color: white;
}

.modal__form--update-bg {
  margin-left: auto;
}

.tile__conversion {
  background-image: url("../../img/conversion-bg-green.svg");
}

.tile_green .progress-bar__description {
  color: white;
}

.tile_green .progress-bar__description span {
  color: white;
}

.tile_green .tile__description {
  color: white;
}

.tile_black .tile__deviation-percent,
.tile_red .tile__deviation-percent,
.tile_green .tile__deviation-percent {
  color: white;
  border-color: white;
}

.tile_black .tile__text-description,
.tile_red .tile__text-description,
.tile_green .tile__text-description {
  color: white;
}

.tile_black .progress-bar__wrapper .progress-bar__title,
.tile_green .progress-bar__wrapper .progress-bar__title {
  color: black;
}

.tile_black .progress-bar__description {
  color: white;
}

.tile_black .progress-bar__description span {
  color: white;
}

.tile_small.empty {
  color: black;
  background-color: white;
  font-size: 20px;
  line-height: 20px;
  padding-top: 25px;
}

.tile .tile__tooltip,
.big-tile .tile__tooltip {
  position: absolute;
  right: 20px;
  top: 20px;
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  background: #9c9ba6;
  color: white;
  cursor: pointer;
  transition: 0.4s;
}

.tile__tooltip--index {
  position: relative;
  z-index: 1;
}

.tile_red .tile_text {
  color: white;
}

.tile_black {
  background-color: black;
}

.tile_black .tile__tooltip:hover {
  background-color: lightgray;
  color: white;
}

.tile_red .tile__text {
  color: white;
}

.tile_red .progress-bar__description {
  color: white;
}

.tile_red .progress-bar__description span {
  color: white;
}

.tile_red .tile__description {
  color: white;
}
