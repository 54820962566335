@import "../helpers/variables";

.platform-table {
  width: 1215px;
  margin-bottom: 30px;

  &.performance-assessment {
    margin-top: 30px;
  }
}

.platform-table__column.link {
  min-width: 75px;
  width: 75px;
  border-right: 1px solid #e4e5e8;
  padding-right: 0;
  margin-right: 10px;
  height: 60px;

  .icon-fb,
  .icon-vk,
  .icon-tg {
    margin-left: auto;
    display: block;
  }

  &.icons {
    display: block;
    text-align: center;

    .icon-vk,
    .icon-fb {
      margin-right: 3px;
    }

    .icon-vk,
    .icon-tg,
    .icon-fb {
      display: inline-block;
      margin-left: 0;
    }

    .icon-tg {
      margin-right: 0;
    }
  }
}

.platform-table__column.link__icon {
  position: relative;
  height: 100%;
}

.platform-table__column.column-manager {
  width: 70px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  height: max-content;
  text-align: left;
}

.platform-table__row--link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  cursor: pointer;
  color: #2a6eea;

  &.course {
    width: 100%;
  }

  &.name {
    max-width: 180px;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 180px;

    &.removed {
      color: lightgray;
    }
  }

  &.client-name {
    width: 100%;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
  }
}

.platform-table__column.course {
  width: 145px;
  min-width: 70px;
  margin-right: 10px;
  cursor: pointer;
  text-align: left;

  &:hover {
    .platform-table__row--link {
      color: #81a7ed;
    }
  }
}

.platform-table__column.course-product {
  width: 113px;
  min-width: 113px;
}

.platform-table__column.type {
  width: 45px;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.platform-table__column.price {
  width: 125px;
  margin-right: 28px;
  display: flex;
  align-items: center;
  height: max-content;

  &.ta-l {
    text-align: left;
    transform: translateX(-5px);
  }
}

.platform-table__column.stream {
  width: 50px;
  margin-right: 25px;
}

.platform-table__column.tag {
  width: 75px;
  margin-right: 20px;
}

.platform-table__column.coupling {
  width: 45px;
  background-size: auto;
  margin-right: 20px;
}

.platform-table__column.status {
  width: 167px;
  margin-right: 30px;
  display: flex;
  justify-content: flex-start;

  &.ta-l {
    text-align: left;
    transform: translateX(-10px);
  }
}

.platform-table__column.status-estimation {
  width: 120px;
  margin-right: 30px;
  display: flex;
  justify-content: flex-start;
}

.platform-table__column.reminder {
  width: 115px;
  position: relative;
  height: auto;
  cursor: pointer;
  display: flex;
  margin-left: 0;
  margin-right: 15px;
  align-items: center;
  text-align: left;

  &.ta-l {
    transform: translateX(-10px);
  }
}

.platform-table__column.reminder .column-reminder {
  width: 100%;
}

.platform-table__column.reminder:hover .tooltip {
  opacity: 1;
}

.platform-table__column.column-hide {
  width: 13px;
}

.platform-table__column.action {
  min-width: 60px;
  width: 60px;
  margin-right: 10px;
  text-align: left;
}

.platform-table__column.estimation-column {
  min-width: 50px;
  width: 50px;
  overflow: visible;
  position: relative;
}

.platform-table__column.bills__settings {
  min-width: 50px;
}

.platform-table__column.bills__exposed {
  min-width: 90px;
}

.platform-table__column .do-rate {
  width: 100px;
}

.platform__table {
  display: flex;
  flex-direction: column;
  width: 1100px;
  height: auto;
}

.platform-table__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  background-color: #fff;
  color: #161718;
  border-bottom: 1px solid #e4e5e8;
  padding: 0 15px;
  transition: all 0.2s linear;

  &:nth-child(2) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom: 0;
  }
}

.platform-table__row--header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: transparent;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #b4b3bb;
  padding: 0 0 0 15px;

  &.link {
    border-right: 0;
    display: flex;
  }
}

.platform-table__column.header-link {
  border-right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 75px;
  min-width: 75px;
}

.platform-table__row--link.platform__go {
  top: 22px;
  right: -1px;
}

.platform-table-column__nav {
  padding-left: 5px;
}

.platform-table__tag {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.platform-table__column {
  font-size: 12px;
  line-height: 12px;
}

.platform-table__column.appeal {
  width: 70px;
  margin-right: 20px;
}

.platform-table__column.social {
  width: 30px;
  margin-right: 10px;
  text-align: center;
}

.platform-table__column.date {
  width: 50px;
  margin-right: 10px;
}

.platform-table__column.date__rating {
  width: 70px;
  margin-right: 10px;
}

.link__wrapper--crm {
  width: 50px;
  height: 60px;
}

.client-phone {
  overflow: hidden;
  display: inline-flex;
  text-overflow: ellipsis;
  margin-top: 5px;
  color: $black;
  line-height: 14px;

  &__icon {
    min-width: 14px;
    width: 14px;
    min-height: 14px;
    height: 14px;
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      margin-top: 0;
      right: 0;
      top: 0;
      display: block;
      height: 14px;
      width: 14px;
      background-color: #b4b3bb;
      mask: url("../../img/copy.svg") no-repeat center;
      mask-size: auto 14px;
    }
  }

  &__value {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
  }
}

.client-column {
  width: 190px;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  position: relative;

  &.ta-l {
    text-align: left;
  }

  &__circle {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 45px);
  }

  &__name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max-content;
  }

  &-estimation {
    text-align: left;
    width: 110px;
  }
}

.link__circle {
  min-width: 25px;
  min-height: 25px;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  &.icon-vk {
    width: 25px;
    height: 25px;

    &::after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      margin-right: 3px;
      background-image: url("../../img/vk-circle.svg");
      background-size: 100% 100%;
    }
  }

  &.icon-tg {
    width: 25px;
    height: 25px;

    &::after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      background-image: url("../../img/bothelp.svg");
      background-size: 100% 100%;
    }
  }

  &.icon-fb {
    width: 25px;
    height: 25px;

    &::after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      background-image: url("../../img/facebook.svg");
      background-size: 100% 100%;
    }
  }

  &.icon-fb,
  &.icon-vk,
  &.icon-tg {
    margin-right: 10px;
  }
}

.course-row {
  display: flex;
  flex-direction: column;

  &__text {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.course-crm {
  display: inline-flex;
  align-items: center;

  &--icon {
    position: relative;
    margin-right: 5px;
    transform: translateY(-1px);

    &::after {
      content: "";
      display: inline-block;
      width: 14px;
      height: 15px;
      mask-image: url("../../img/calendar.svg");
      background-color: #b4b3bb;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
      cursor: pointer;
    }
  }
}

.c-tooltip {
  &__estimation {
    left: -50%;
    position: absolute;
    transform: translate(50%, -100%);
    top: -100%;
  }
}

.custom-table__body-col.link-row-adv-name {
  width: 365px;
  display: flex;
  height: 60px;
  align-items: center;
  margin-right: 0;

  .editable {
    width: 240px;
  }

  .custom-table__body-col_15per {
    width: 125px;
    max-width: 125px;
  }
}

.link-row-adv-inside {
  display: grid;
  grid-template-columns: 370px 415px 310px 40px;
  padding: 0 15px;
  align-items: center;

  .custom-table__body-col_35per {
    width: 100%;
    max-width: 100%;
  }
}

.deals-header,
.deals-row {
  display: grid;
  grid-template-columns: 50px 190px 75px 70px 145px 135px 170px 115px 13px;
  justify-items: start;
  padding: 0;
  justify-content: space-around;
}

.rating-header,
.rating-row {
  display: grid;
  grid-template-columns: 70px 60px 30px 100px 75px 70px 145px 135px 170px 50px 75px;
  justify-items: start;
  padding: 0;
  justify-content: space-around;
}
