@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-Regular.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-Medium.woff2");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-Bold.woff2");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: FontAwesome;
  src: url("../../fonts/FontAwesome.otf");
}

*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: Inter, sans-serif;
  cursor: default;
  background-color: #f4f4f6;
}

select,
textarea,
input {
  font-family: inherit;
  font-size: 12px;
  line-height: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: 500;
}

p {
  margin: 0;
  padding: 0;
}

input[type="file"] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

select {
  font-family: "Segoe UI", Ubuntu, "Open Sans", "Helvetica Neue", sans-serif;
}

a:hover {
  text-decoration: none;
}

::selection {
  background: #c5c5c5;
}

.page {
  overflow: hidden;

  &__content {
    display: flex;
    max-height: 100vh;
  }
}

@media (max-width: 1000px) {
  .page {
    &__content {
      flex-direction: column;
    }
  }
}
