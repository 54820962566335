.platform__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;

  &--black {
    background-color: black;
    color: white;
    border: 1px solid black;

    &:hover {
      background-color: transparent;
      color: black;
    }
  }

  &--transparent {
    border: 1px solid black;
    background-color: transparent;
    color: black;

    &:hover {
      background-color: black;
      color: white;
    }
  }
}

.platform__close--btn {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
}

.platform__close--btn::after {
  content: "";
  width: 24px;
  height: 3px;
  background-color: #b4b3bb;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 100%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: all 0.2s linear;
  border-radius: 3px;
}

.platform__close--btn::before {
  content: "";
  width: 24px;
  height: 3px;
  background-color: #b4b3bb;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 100%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: all 0.2s linear;
  border-radius: 3px;
}

.platform__close--btn:hover::after {
  background-color: #161718;
}

.platform__close--btn:hover::before {
  background-color: #161718;
}

.platform__close--btn-modal::before {
  content: "";
  width: 24px;
  height: 3px;
  background-color: #b4b3bb;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 100%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: all 0.2s linear;
}

.platform__close--btn-modal {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 25px;
  width: 24px;
  height: 24px;
  z-index: 2;
}

.platform__close--btn-modal::after {
  content: "";
  width: 24px;
  height: 3px;
  background-color: #b4b3bb;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 100%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: all 0.2s linear;
}

.platform__close--btn-modal:hover::after {
  background-color: #161718;
}

.platform__close--btn-modal:hover::before {
  background-color: #161718;
}

.platform-form__edit-btn {
  background-color: #161718;
  border: 1px solid #161718;
  color: #fff;
  transition: all 0.2s linear;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  padding: 13px 15px;
}

.platform-form__edit-btn:hover {
  background-color: #fff;
  color: #161718;
  border-radius: 5px;
}

.platform-form__edit-btn:disabled {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  color: #b9b9b9;
  cursor: default;
}

.platform-form__edit-btn.cancel {
  width: max-content;
  padding: 0 20px;
  margin-left: 0;
  height: 35px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.platform-form__edit-btn.cancel:hover {
  background-color: #fff;
  color: #161718;
  border-radius: 5px;
}

.platform__copy {
  display: inline-block;
  position: absolute;
  top: 29px;
  right: 12px;
  width: 15px;
  height: 15px;

  &.client-menu {
    top: 35px;
  }

  &.left {
    top: 37px;
    right: 42px;
  }

  &.link,
  &.telegram {
    top: 37px;
    right: 43px;
  }

  &.email,
  &.tel {
    top: 37px;
  }
}

.platform__copy::after {
  content: "";
  transition: all 0.2s linear;
  mask-image: url("../../img/copy.svg");
  background-color: #7c7b85;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.platform__copy:hover::after {
  background-color: black;
}

.platform__copy--table {
  position: absolute;
  top: 22px;
  left: 0;
  width: 15px;
  height: 15px;
}

.platform__copy--table::after {
  content: "";
  mask-image: url("../../img/copy.svg");
  background-color: #7c7b85;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.platform__copy--table:hover::after {
  background-color: black;
}

.platform__copy--access {
  position: absolute;
  top: 20px;
  right: 15px;
  mask-image: url("../../img/copy.svg");
}

.platform__download--btn {
  color: white;
  background-color: black;
  border-radius: 7px;
  font-size: 12px;
  line-height: 12px;
  margin-left: 15px;
  padding: 14px 15px;
  border: 1px solid black;
  transition: all 0.2s linear;
}

.platform__download--btn.search {
  cursor: pointer;
}

.platform__download--btn:hover {
  border: 1px solid black;
  color: black;
  border-radius: 5px;
  background-color: transparent;
}

.button {
  font-size: 12px;
  line-height: 12px;
  padding: 13px 36px;
  border-radius: 5px;
  background: black;
  color: #fff;
  cursor: pointer;
  border: 1px solid black;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  background-color: transparent;
  color: black;
  border: 1px solid black;
}

.button_white {
  background: #fff;
  color: black;
}
