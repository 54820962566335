a {
  color: inherit;
}

.content-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0 28px 40px;
  background-color: white;
  height: auto;
  max-height: 134px;
}

.content-top.manager {
  background-color: transparent;
  box-shadow: none;
  border-bottom: 0;
}

.content-top__title {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0;
  text-align: left;
}

.content-top__filter {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-left: 0;
  margin-right: 15px;
  mask: url("../../img/filter.svg") no-repeat center;
  mask-size: auto 20px;
  background-color: #b4b3bb;
  transition: all 0.2s linear;
}

.content-top__filter_small {
  height: 20px;
}

.content-top__filter:active {
  background-color: #29006c;
}

.content-top__filter_active {
  background-color: #29006c;
}

.content-top__delimiter {
  margin-left: 25px;
  margin-right: 10px;
  height: 26px;
  width: 1px;
  background-color: #b4b3bb;
}

.content-top__nav {
  display: flex;
}

.content-top--transparent {
  background-color: transparent;
}

.content-top__add-button {
  margin-left: 0;
  font-weight: 400;
  display: inline-flex;
  text-align: center;
  width: max-content;
}

.add-project-btn {
  display: flex;
  margin-left: auto;
}

.content-top__add-button span {
  position: relative;
  top: 30%;
}

.content-top__settings--main {
  transform: translateY(7px);
}

.content-top__settings--funnel {
  transform: translateY(2px);
}

.content-top__settings--salary {
  transform: translateY(4px);
}

.top-nav__list-items {
  display: flex;
  flex-direction: row;
  margin-top: 0;
  height: 100%;
}

.top-nav__item {
  margin-right: 22px;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: normal;
  color: #b4b3bb;
  position: relative;
  white-space: nowrap;
  display: flex;
}

.top-nav__item:last-child {
  margin-right: 0;
}

.top-nav__item:hover .top-nav__item::after {
  opacity: 1;
}

.content-monitor__top {
  height: auto;
}

.content-monitor__main {
  margin-top: 20px;
  max-width: 1216px;
}

.content-monitor__settings {
  margin-left: 15px;
  margin-top: 0;
  min-width: 22px;
  min-height: 22px;
  width: 22px;
  height: 22px;
  display: inline-block;
  position: relative;
  top: -1px;
  cursor: pointer;
}
