.platform__search {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
}

.platform__search--start {
  position: absolute;
  top: 13px;
  right: 16px;
  display: inline-block;
  width: 15px;
  height: 15px;

  &.active {
    &::after {
      background-color: rgb(255 0 0 / 50%);
      mask-image: url("../../img/cancel.svg");
    }

    &:hover {
      &::after {
        background-color: #f00;
      }
    }
  }
}

.platform__search--start::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
  background-color: rgb(121 120 128 / 50%);
  mask-image: url("../../img/search.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
}

.platform__search--start:hover::after {
  background-color: black;
}

.platform__input--search {
  background-color: #fff;
  border: 1px solid #c3c2c9;
  border-radius: 3px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 40px 0 15px;
  width: 340px;
  height: 40px;
}

.platform__input--search::placeholder {
  color: #797880;
}
