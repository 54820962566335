@import "../helpers/variables";

.column_mwidth-update {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  transform: translateX(-10px);

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    background-color: #b4b3bb;
    mask: url("../../img/control.svg") no-repeat center;
    mask-size: 100% 100%;
    transition: 0.4s;
    transform: translate(-50%, -50%);
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    width: 1px;
    height: 23px;
    content: "";
    display: block;
    position: absolute;
    background-color: #e4e5e8;
    transform: translateY(-50%);
    top: 50%;
    right: -11px;
  }

  &:hover {
    &::after {
      background-color: black;
    }
  }
}

.column_pmethod__yookassa {
  background: url("../../img/yooukassa.svg") no-repeat center;
}

.column_pmethod_yandex {
  background: url("../../img/yooukassa.svg") no-repeat center;
}

.column_pmethod-select.column_pmethod-tr.column_pmethod_yandex {
  background: url("../../img/yooukassa.svg") no-repeat;
}

.pmethod_yandex {
  background: url("../../img/yooukassa.svg") no-repeat;
}

.methods__select.paymentmethods.yandex {
  background: url("../../img/yooukassa.svg") no-repeat;
}

.payment-methods {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &:nth-child(2) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &__table {
    display: flex;
    flex-direction: column;
  }

  &__header-row {
    display: flex;
    justify-content: space-around;
  }

  &__id,
  &__icon {
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
  }

  &__name {
    min-width: 425px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: max-content;
  }

  &__settings {
    display: flex;
    align-items: center;
    margin-left: 100px;
    height: max-content;
  }

  &__status {
    display: flex;
    width: max-content;
    align-items: center;
    height: max-content;
  }

  &__nolink {
    min-width: 24px;
    margin-left: 50px;
    margin-right: 10px;
  }

  &__link {
    margin-left: 50px;
    height: max-content;
    margin-right: 10px;

    &::after {
      content: "";
      width: 15px;
      height: 16px;
      display: inline-block;
      mask-image: url("../../img/copy.svg");
      background-color: $color-table-nav-items;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
      cursor: pointer;
      transition: all 0.2s linear;
    }

    &:hover {
      &::after {
        background-color: $black;
      }
    }
  }
}

.add-payment-method {
  margin-left: auto;
}
