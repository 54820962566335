.tabs__blocks {
  position: relative;
}

.tabs__list {
  position: relative;
  margin-bottom: 40px;
  width: 1220px;
}

.tabs__list::after {
  content: "";
  position: absolute;
  background-color: rgba(196 196 196 / 20%);
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  transition: 0.4s;
}

.tabs__list-wrapper {
  display: flex;
  flex-direction: row;
  height: 38px;
}

.tab {
  margin-right: 15px;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  color: #9c9ba6;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 400;
}

.tab.active,
.tab:hover {
  color: black;
  transition: 0.4s;
}

.tab:hover::after,
.tab:active::after,
.tab.active::after {
  content: "";
  position: absolute;
  background-color: black;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: -18px;
  transition: 0.4s;
}

.courses-tab {
  margin-top: 21px;
}

.platform__tabs {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  transform: translateY(20px);
  width: 100%;
  display: flex;
  margin-top: 0;
  border-bottom: 1px solid rgba(196 196 196 / 20%);
  padding-bottom: 10px;
  max-width: 1215px;
}

.platform-tabs__link {
  position: relative;
  margin-right: 15px;
  color: rgb(22 23 24 / 50%);
  display: flex;
  white-space: nowrap;
  cursor: pointer;
}

.platform-tabs__link.top-nav__item_active::after {
  content: "";
  position: absolute;
  background-color: black;
  width: 100%;
  height: 4px;
  left: 0;
  display: block;
  transition: all 0.2s linear;
  opacity: 1;
  bottom: -10px;
}

.platform-tabs__link::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 4px;
  bottom: -10px;
  left: 0;
  opacity: 0;
  transition: all 0.1s linear;
  background-color: black;
}

.platform-tabs__link:last-child {
  margin-right: 0;
}

.platform-tabs__link:hover {
  color: #161718;
}

.platform-tabs__link:hover::after {
  opacity: 1;
}

.platform-tabs__link.active {
  color: #161718;
}

.platform-tabs__link.active::after {
  opacity: 1;
}
