.select {
  width: 100%;
  height: 40px;
  position: relative;
}

.select-tariff__body {
  display: none;
  flex-direction: column;
  background-color: white;
  border: 1px solid #c3c2c9;
  z-index: 1002;
  width: 100%;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%);
  top: 38px;
  left: 0;
  max-height: 320px;
  overflow-y: auto;
}

.select__option--tariff {
  width: 100%;
  height: auto;
  display: flex;
  cursor: pointer;
  position: relative;
  color: black;
  border-bottom: 0;
  justify-content: flex-start;
  padding: 5px 0 5px 10px;

  &::after {
    content: "";
    width: 1px;
    height: 7px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: lightgrey;
  }
}

.select__option:last-child {
  border-bottom: 0;
}

.select__option--tariff:hover {
  background-color: #1e90ff;
  color: white;
}

.select__head--tariff {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  pointer-events: none;
  justify-content: flex-start;
  overflow: hidden;
  color: black;
}

.tariff-content {
  flex-direction: column;
}

.update-deal__select-wrapper.select--tariff {
  margin-top: 0;
  display: flex;
  align-items: center;
}

.tariff__name {
  margin-right: 0;
}

.tariff__name::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 10.5px;
  position: absolute;
  background-color: lightgray;
  top: 1px;
  right: -8px;
}

.block__name {
  margin-left: 10px;
}

.block__name.ml_0 {
  margin-left: 0;
}

.block__checkbox {
  margin-top: 10px;
}

.select-head {
  &__name {
    position: relative;

    &::after {
      content: "";
      width: 1px;
      height: 15px;
      background-color: #c3c2c9;
      position: absolute;
      top: -1px;
      right: -10px;
    }
  }

  &__price {
    margin-left: 20px;
  }
}
