.platform__select {
  appearance: none;
  border: 1px solid #c3c2c9;
  cursor: pointer;
  padding: 0 30px 0 15px;
  width: 100%;
  height: 40px;
  background-color: white;
  border-radius: 5px;

  &.disable {
    color: #c3c2c9;
    border: 1px solid #c3c2c9;
    pointer-events: none;
  }
}

.platform-select__wrapper::after {
  content: "";
  position: absolute;
  top: 13px;
  right: 16px;
  width: 9px;
  height: 15px;
  mask-image: url("../../img/a-down.svg");
  background-color: #797880;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transform: rotate(90deg);
  pointer-events: none;
}

.platform-select__wrapper {
  position: relative;
  border: 0;

  &.disable {
    .platform-form__calendar {
      color: #c3c2c9;
      background-color: #c3c2c9;
    }
  }
}

.platform-select__wrapper.tariff {
  margin-top: 5px;
}

.platform-select__wrapper.no-icon::after {
  display: none;
}

.platform__option {
  color: black;
}

.platform__option .platform-form__item.client {
  background-color: #e5e5e5;
}

.platform__option .platform__toggle {
  width: 40px;
  height: 20px;
  padding: 3.3px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: #ff284f;
}

.platform__option .platform__toggle.active {
  background: #27ae60;
}

.platform__option .platform__thumb {
  background: #fff;
  box-shadow: 0 10px 20px rgb(8 15 32 / 20%);
  height: 13.3px;
  width: 13.3px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.platform__option .platform__toggle.active .platform__thumb {
  transform: translateX(20px);
}

.platform__option .platform__checkbox {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.platform__option .platform__checkbox--fake {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #c3c2c9;
  border-radius: 3px;
  transition: all 0.2s linear;
  position: relative;
  background-color: white;
}

.platform__option .platform__checkbox--fake::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.2s linear;
  color: white;
  font-size: 12px;
  line-height: 12px;
}

.platform__option .platform-checkbox__label--button {
  width: 33.3333%;
  height: 40px;
  background-color: transparent;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  transition: all 0.2s linear;
  border: 1px solid black;
  cursor: pointer;
}

.platform__option .platform-checkbox__label--button:nth-child(2) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
}

.platform__option .platform-checkbox__label--button:nth-child(6) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0;
}

.platform__option .platform__checkbox:checked ~ .platform-checkbox__label .platform__checkbox--fake {
  background-color: black;
}

.platform__option .platform__checkbox:checked ~ .platform-checkbox__label .platform__checkbox--fake::after {
  opacity: 1;
}

.platform__option .platform__checkbox:checked + .platform-checkbox__label--button {
  background-color: black;
  color: white;
}

.platform__option .platform-checkbox__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.platform__option .platform-checkbox__label--value {
  margin-left: 10px;
  font-size: 12px;
  line-height: 12px;
}

.platform__option .platform-remove__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(70 68 68 / 73%);
  opacity: 0;
  transition: all 0.2s linear;
  z-index: 20;
  font-size: 18px;
  line-height: 32px;
}

.platform__option .platform-remove__popup {
  position: absolute;
  top: -150%;
  left: 50%;
  right: 50%;
  width: 320px;
  height: 180px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  transition: all 0.2s ease-out;
  transform: translateX(-50%);
}

.platform__option .platform-remove__message {
  font-size: 16px;
  line-height: 26px;
}

.platform__option .platform-remove__nav {
  display: flex;
  justify-content: center;
  margin-top: auto;
  font-size: 18px;
  line-height: 26px;
}

.platform__option .platform__no-scroll {
  overflow: hidden;
  height: auto;
  padding-bottom: 0;
}

.platform__option .platform__tabs {
  margin-top: 9px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
}

.platform__option .platform-tabs__link {
  position: relative;
  margin-right: 15px;
  color: rgb(22 23 24 / 50%);
  display: flex;
}

.platform__option .platform-tabs__link:last-child {
  margin-right: 0;
}

.platform__option .platform-tabs__link:hover {
  color: #161718;
}

.platform__option .platform-tabs__link::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 4px;
  bottom: -10px;
  left: 0;
  opacity: 0;
  transition: all 0.1s linear;
  background-color: black;
}

.platform__option .platform-tabs__link:hover::after {
  opacity: 1;
}

.platform__option .platform-tabs__link.active {
  color: #161718;
}

.platform__option .platform-tabs__link.active::after {
  opacity: 1;
}

.platform__option .tooltip {
  position: absolute;
  background-color: #161718;
  font-size: 11px;
  border-radius: 5px;
  color: white;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s linear;
  width: 210px;
  display: inline-block;
  padding: 10px 10px 5px;
  height: max-content;
}

.platform__option .tooltip__table {
  min-width: 100%;
  width: 100%;
}

.platform__option .tooltip__crm {
  top: -23px;
  left: -180px;
}

.platform__option .tooltip__card {
  top: -31px;
  right: 27px;
}

.platform__option .tooltip__date {
  display: inline-block;
  width: 30%;
  min-width: 30%;
}

.platform__option .tooltip__message {
  display: inline-block;
  width: 70%;
  min-width: 70%;
}

.platform__option .tooltip__row--head {
  color: #fffc;
  font-size: 11px;
  line-height: 11px;
  display: inline-flex;
  width: 100%;
  min-width: 100%;
}

.platform__option .tooltip__row {
  margin-top: 10px;
  min-width: 100%;
  display: inline-flex;
  font-size: 11px;
  line-height: 11px;
}

.platform__option .platform__download--btn {
  color: white;
  background-color: black;
  border-radius: 7px;
  font-size: 12px;
  line-height: 12px;
  margin-left: 15px;
  padding: 14px 15px;
  border: 1px solid black;
  transition: all 0.2s linear;
}

.platform__option .platform__download--btn.search {
  cursor: pointer;
}

.platform__option .platform__download--btn:hover {
  border: 1px solid black;
  color: black;
  border-radius: 5px;
  background-color: transparent;
}

.platform__option .transition-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background: rgb(4 7 36 / 40%);
  display: none;
  transition: display 0.4s;
}

.platform__option .transition-loader.show {
  display: block;
}

.platform__option .transition-loader-inner {
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  width: 100%;
  color: black;
  padding: 0 100px;
  text-align: center;
  user-select: none;
  pointer-events: none;
}

.platform__option .transition-loader-inner label {
  font-size: 1em;
  padding: 1%;
  opacity: 0;
  display: inline-block;
  user-select: none;
}

.platform__option .transition-loader-inner label::after {
  font-family: FontAwesome, sans-serif;
  content: "\f111";
}

.platform__option .transition-loader-inner label:nth-child(1) {
  animation: loader 3s 600ms infinite ease-in-out;
}

.platform__option .transition-loader-inner label:nth-child(2) {
  animation: loader 3s 500ms infinite ease-in-out;
}

.platform__option .transition-loader-inner label:nth-child(3) {
  animation: loader 3s 400ms infinite ease-in-out;
}

.platform__option .transition-loader-inner label:nth-child(4) {
  animation: loader 3s 300ms infinite ease-in-out;
}

.platform__option .transition-loader-inner label:nth-child(5) {
  animation: loader 3s 200ms infinite ease-in-out;
}

.platform__option .transition-loader-inner label:nth-child(6) {
  animation: loader 3s 100ms infinite ease-in-out;
}

.platform__option div[js-menu-search] {
  z-index: 2;
}

.platform__option div[js-menu-client-card] {
  z-index: 3;
}

.platform__option div[js-menu-deal] {
  z-index: 4;
}

.platform__option div[menu-bills-add] {
  z-index: 5;
}

.platform__option div[sms-checks] {
  z-index: 6;
}

.platform__option div[js-menu-rate] {
  z-index: 7;
}

.platform__option div[js-menu-add-comment] {
  z-index: 8;
}

.platform__option div[js-menu-open-comment] {
  z-index: 9;
}

.select {
  position: relative;
  width: 170px;
  min-width: 170px;
  height: 40px;
  cursor: pointer;
}

.select::after {
  content: "";
  width: 1px;
  height: 40px;
  display: inline-block;
  background-color: white;
  position: absolute;
  top: 0;
  right: 35px;
}

.select::before {
  content: "";
  display: inline-block;
  width: 11px;
  height: 6px;
  mask-image: url("../../img/arrow-down.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  background-color: white;
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%) rotate(0deg);
}

.select.open::before {
  transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.select__option--status::after {
  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  border-radius: 100%;
  top: 18px;
  left: 10px;
}

.select--tariff {
  border: 1px solid lightgray;
  width: 100%;
}

.select__status {
  width: 170px;
  height: 40px;
}

.select__head {
  width: calc(100% + 45px);
  height: 100%;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  display: inline-flex;
  align-items: center;
  color: inherit;
  justify-content: flex-start;
  padding: 0 30px 0 15px;
  transform: translateX(-15px);

  &--status {
    max-width: 100%;
    padding-left: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(0);
  }
}

.select__body {
  position: absolute;
  display: none;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e1e1e1;
  z-index: 3000;
  width: 100%;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%);
  max-height: 250px;
  left: 0;
  overflow: auto;

  &.open {
    display: flex;
  }
}

.select__status--body {
  position: fixed;
  width: 170px;
  height: 360px;
}

.select__body.custom-scroll {
  max-height: 355px;
}

.select__option {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 7px 0;
  border-bottom: 1px solid lightgrey;
  color: black;
  font-size: 12px;
  line-height: 12px;
  padding-left: 15px;
  padding-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  min-height: 26px;
  position: relative;
}

.select__option:last-child {
  border-bottom: 0;
}

.select__option:hover {
  background-color: rgb(100 100 100 / 10%);
}

.select-tariff__body {
  top: 38px;
  left: 0;
  border: 1px solid #c3c2c9;
  max-height: 320px;
  overflow-y: auto;
  width: 100%;
}

.select__option--tariff {
  color: black;
  border-bottom: 0;
  justify-content: flex-start;
  padding: 3px 0 3px 10px;
}

.select__option--tariff:hover {
  background-color: #1e90ff;
  color: white;
}

.select__head--tariff {
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  pointer-events: none;
  justify-content: flex-start;
  overflow: hidden;
  color: black;
  font-size: 12px;
  line-height: 12px;
}

.select__option::after {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  content: "";
  position: absolute;
  top: 17px;
  left: 7px;
}

.select__option[data-code="important"]::after {
  background-color: #d9d9d9;
}

.select__option[data-code="successfully"]::after {
  background-color: #24c869;
}

.select__option[data-code="reservation"]::after {
  background-color: #4a86e8;
}

.select__option[data-code="closed"]::after {
  background-color: #ff284f;
}

.select__option[data-code="unknown"]::after {
  background-color: #ff852f;
}

.select__option[data-code="installment"]::after {
  background-color: #bb6bd9;
}

.select__option[data-code="prepaid"]::after {
  background-color: #f9ed5d;
}

.select__option[data-code="full"]::after {
  background-color: #318f5d;
}

.select__option--status.deal-status__form_successfully::after {
  background-color: #24c869;
}

.select__option--status.deal-status__form_reservation::after {
  background-color: #4a86e8;
}

.select__option--status.deal-status__form_closed::after {
  background-color: #ff284f;
}

.select__option--status.deal-status__form_prepaid::after {
  background-color: #f9ed5d;
}

.select__option--status.deal-status__form_installment::after {
  background-color: #bb6bd9;
}

.select__option--status.deal-status__form_unknown::after {
  background-color: #ff852f;
}

.select__option--status.deal-status__form_important::after {
  background-color: #d9d9d9;
}

.select.deal-status__form_successfully {
  border: 0;
  background-color: #24c869;
  color: white;
  border-radius: 3px;
}

.select.deal-status__form_reservation {
  border: 0;
  background-color: #4a86e8;
  color: white;
  border-radius: 3px;
}

.select.deal-status__form_closed {
  border: 0;
  background-color: #ff284f;
  color: white;
  border-radius: 3px;
}

.select.deal-status__form_prepaid {
  border: 0;
  background-color: #f9ed5d;
  color: black;
  border-radius: 3px;
}

.select.deal-status__form_prepaid::before {
  background-color: #908500;
}

.select.deal-status__form_prepaid::after {
  background-color: #908500;
}

.select.deal-status__form_installment {
  background-color: #bb6bd9;
  color: white;
  border-radius: 3px;
  border: 0;
}

.select.deal-status__form_unknown {
  background-color: #ff852f;
  color: white;
  border-radius: 3px;
  border: 0;
}

.platform__option[code="successfully"] {
  color: #24c869;
}

.platform__option[code="reservation"] {
  color: #4a86e8;
}

.platform__option[code="closed"] {
  color: #ff284f;
}

.platform__option[code="prepaid"] {
  color: #f9ed5d;
}

.platform__option[code="installment"] {
  color: #bb6bd9;
}

.platform__option[code="unknown"] {
  color: #ff852f;
}

.platform__option[code="important"] {
  color: #d9d9d9;
}

.select.deal-status__form_full {
  background-color: #318f5d;
  color: white;
  border-radius: 3px;
  border: 0;
}

.select.deal-status__form_important {
  background-color: #d9d9d9;
  color: white;
  border-radius: 3px;
  border: 0;
  max-width: 170px;
}

.select-deal__status .select__head {
  display: flex;
  justify-content: flex-start;
}

.select-deal__status .select__body {
  left: 0;
}

.select__option--status {
  position: relative;
  min-height: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
}

.select__option--status:hover {
  background-color: rgb(100 100 100 / 10%);
  color: black;
}

.select.tariff {
  width: 100%;
}

.select__option--status.no-tariff {
  pointer-events: all;
}

.select.tariff::after {
  display: none;
}

.select__rating {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select__rating::after {
  display: none;
}

.select__rating::before {
  display: none;
}

.select__head[data-code="prepaid"] {
  font-size: 12px;
  line-height: 12px;
  color: #908500;
}

.tariff__price {
  display: inline-block;
  margin-left: 15px;
  pointer-events: none;
}

.tariff .select__head--tariff .tariff__name::after {
  top: 1px;
}

.tariff__name {
  display: inline-block;
  position: relative;
  pointer-events: none;
}

.tariff__name.no-selector::after {
  display: none;
}

.select.disabled {
  pointer-events: none;
  color: #c3c2c9;
  border: 1px solid #c3c2c9;
}

.disabled .tariff__price {
  color: #c3c2c9;
}

.disabled .tariff__name {
  color: #c3c2c9;
}

.disabled .select__head--tariff {
  color: #c3c2c9;
}

.select:hover .tooltip {
  opacity: 1;
}

.select-head {
  &__placeholder {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.select-deal-card {
  &::after {
    content: "";
    right: 23px;
  }

  &__head {
    padding: 15px 0 15px 5px;
    width: calc(100% + 35px);
  }
}
