@import "../helpers/variables";

.menu__tasks {
  height: auto;
  width: 100%;
  background-color: #f4f4f6;
}

.menu__tasks.full {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid lightgray;
}

.menu-tasks__calendar::after {
  content: "";
  display: inline-block;
  width: 13px;
  height: 14px;
  mask-image: url("../../img/calendar.svg");
  background-color: #797880;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  pointer-events: none;
  position: absolute;
  top: 35px;
  right: 35px;
}

.menu-tasks__text {
  font-size: 12px;
  line-height: 12px;
  width: 100%;
  height: 35px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  margin-right: 17px;
  border-radius: 5px;
  border: 1px solid #c3c2c9;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.menu-tasks__datepicker {
  font-size: 12px;
  line-height: 12px;
  width: 150px;
  min-height: 35px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid #c3c2c9;
  margin-top: 11px;
  padding: 0 16px;
}

.deal-task__taskchecker {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 0 17px 0 rgb(0 0 0 / 50%);
  cursor: pointer;
}

.reminder__now::before {
  background-color: #ffc000;
  cursor: pointer;
}

.reminder__on::before {
  background-color: lightgray;
  cursor: pointer;
  display: none;
}

.reminder__off::before {
  background-color: #ff284f;
  cursor: pointer;
  display: none;
}

.reminder__date,
.reminder__message,
.tooltip__date,
.tooltip__message {
  font-size: 10px;
  line-height: 12px;
  color: #fff;
}

.reminder__message {
  word-break: break-word;
}

.reminder__selector::after {
  content: "";
  width: 1px;
  height: 10px;
  background-color: #7d7c85;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: -8px;
}

.reminder__past {
  color: #ff2e2e;
}

.reminder {
  min-width: 14px;
  width: 14px;
  margin-left: 15px;
}

.reminder__past .reminder {
  color: #ff2e2e;
}

.reminder__past .reminder::after {
  background-color: #ff2e2e;
}

.reminder-d {
  min-width: 50px;
  width: 50px;
  margin-left: 16px;
}

.reminder__past .reminder-d {
  color: #ff2e2e;
}

.tooltip-card__tasks {
  right: 0;
  position: absolute;
  transform: translateY(10px);
  top: -65px;
}

.reminder__done {
  background-color: #27ae60;
  position: relative;
}

.reminder__done::after {
  content: "✓";
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-through {
  text-decoration: line-through;
}

.menu-tasks__inner {
  width: 790px;
}

.menu-tasks__wrapper {
  display: flex;
  flex-direction: column;
}

.menu-tasks__form {
  display: flex;
  width: 100%;
}

.menu-tasks__add {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-radius: 10px;
  height: 90px;
  background-color: white;
  margin-top: 0;
  padding: 15px;
  position: relative;
}

.menu-tasks__selector {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: lightgray;
  margin-top: 20px;
}

.menu-tasks__title {
  color: #7c7b85;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;
}

.menu-tasks__calendar {
  display: flex;
  flex-direction: column;
  position: relative;
}

.menu-tasks__control {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
}

.menu-tasks__btn {
  width: 180px;
  height: 35px;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  background-color: black;
  border-radius: 5px;
  border: 0;
  transition: all 0.2s linear;
  cursor: pointer;
}

.menu-tasks__btn:hover {
  color: black;
  background-color: transparent;
  border: 1px solid black;
}

.menu-tasks__head-title {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-align: left;
}

.menu-tasks__error {
  color: red;
  margin-top: 20px;
}

.task {
  display: flex;
  background-color: white;
  height: 70px;
  padding: 15px 20px;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-radius: 5px;
  border: 1px solid #e4e4e4;
}

.task:not(:first-child) {
  margin-top: 10px;
}

.task__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.task__status {
  display: flex;
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
  margin-right: 15px;
  border-radius: 100%;
}

.reminder__off {
  position: relative;
  display: block;
  min-height: 25px;
  height: 25px;
  min-width: 25px;
  width: 25px;
  background-color: #ff284f;
  border-radius: 100%;
}

.reminder__off::after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  height: 25px;
  width: 25px;
  background-color: #fff;
  mask: url("../../img/reminder.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: auto 13px;
  transition: 0.4s;
}

.reminder__on {
  position: relative;
  display: block;
  min-height: 25px;
  height: 25px;
  min-width: 25px;
  width: 25px;
  background-color: lightgray;
  border-radius: 100%;
}

.reminder__on::after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  height: 25px;
  width: 25px;
  background-color: #fff;
  mask: url("../../img/reminder.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: auto 13px;
  transition: 0.4s;
}

.reminder__now {
  position: relative;
  display: block;
  min-height: 25px;
  height: 25px;
  min-width: 25px;
  width: 25px;
  background-color: #ffc000;
  border-radius: 100%;
}

.reminder__now::after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  height: 25px;
  width: 25px;
  background-color: #fff;
  mask: url("../../img/reminder.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: auto 13px;
  transition: 0.4s;
}

.task__date {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 35px;
}

.deal-reminder_yellow::before {
  background-color: #ffc000;
}

.task__message {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 40px;
  min-width: 370px;
  width: 370px;
  overflow: hidden;
}

.task__through {
  text-decoration: line-through;
}

.task__complete--btn {
  min-width: 100px;
  max-width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  border-radius: 5px;
  border: 1px solid black;
  margin-right: 20px;
  cursor: pointer;
  transition: all 0.2s linear;
}

.task__complete--btn:hover {
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 20%);
}

.task__delete,
.task__remove {
  display: inline-flex;
  min-width: 15px;
  max-width: 20px;
  height: 20px;
  mask-image: url("../../img/delete.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
}

.reminder-count {
  position: relative;
}

.reminder-message {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-left: 5px;
}

.deal__reminder-title {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #7c7b85;
  display: flex;
  align-items: center;
  padding: 0;
}

.deal-task__taskchecker:hover .tooltip {
  opacity: 1;
}

.task__item {
  width: 100%;
  display: flex;
  min-height: 70px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-radius: 10px;
  padding: 20px 20px 15px;
  align-items: center;
  height: auto;
  justify-content: space-between;
}

.task__item.text-through .info__when--day {
  text-decoration: line-through;
}

.create__date,
.message__text,
.deal__name {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  color: black;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}

.action__name {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  white-space: normal;
}

.task__item.text-through .action__name {
  text-decoration: line-through;
}

.task__item:not(:first-child) {
  margin-top: 10px;
}

.task__status.off {
  background-color: #ff284f;
}

.task__status.on {
  background-color: lightgray;
}

.task__status.today {
  background-color: #ffc000;
}

.task__status.done {
  background-color: #27ae60;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 8px;
    mask-image: url("../../img/bills_check.svg");
    background-color: $white;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
  }
}

.task__create {
  margin-right: 45px;
}

.task__deal {
  min-width: 237px;
  width: 237px;
  margin-right: 20px;
}

.create__title,
.message__title,
.deal__title,
.action__title {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #acacb2;
}

.task__empty {
  font-size: 14px;
  line-height: 14px;
}

.task__create-info {
  margin-right: 45px;
}

.task__action {
  width: 285px;
  margin-right: 170px;
}

.task__nav {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  width: 137px;
  justify-content: flex-end;
}

.task__complete {
  display: flex;
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  color: #161718;
  border: 1px solid #161718;
  border-radius: 5px;
}

.task__remove {
  display: flex;
  width: 14px;
  height: 17px;
  mask-image: url("../../img/delete-btn.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
}

.task__remove:hover {
  background-color: black;
}

.tooltip__deal-card {
  position: absolute;
  bottom: 0;
  left: -220px;
  height: auto;
}

.deal-task:hover .tooltip__deal-card {
  opacity: 1;
}

.task__left {
  display: flex;
  align-items: center;
}
