@import "../helpers/variables";

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
  appearance: number-input;
}

select option {
  font-family: Tahoma, sans-serif;
  font-size: 12px;
}

h2 {
  margin: 0;
  padding: 0;
}

.motivation__choose {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.motivation__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  pointer-events: none;
}

.motivation__panel {
  width: 1215px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px;
}

.motivation__menu {
  width: 520px;
}

.motivation__toggle::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: white;
  transition: all 0.2s;
  box-shadow: 0 10px 20px rgb(8 15 32 / 20%);
}

.motivation__toggle {
  position: relative;
  min-width: 60px;
  height: 30px;
  border-radius: 30px;
  cursor: pointer;
}

.motivation:checked ~ .motivation__toggle {
  background-color: #27ae60;
}

.motivation:checked ~ .motivation__toggle::after {
  top: 5px;
  left: calc(100% - 25px);
}

.motivation:not(:checked) ~ .motivation__toggle {
  background-color: #ff284f;
}

.motivation:not(:checked) ~ .motivation__toggle::after {
  top: 5px;
  left: 5px;
}

.motivation__selects {
  display: flex;
}

.motivation-add__button,
.motivation-modify__button {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  padding: 15px 40px;
  background-color: $black;
  border-radius: 10px;
  color: $white;
  border: 1px solid rgb(0 0 0 / 50%);
  transition: all 0.2s linear;
}

.motivation-add__button:disabled,
.motivation-modify__button:disabled {
  color: #f1f1f1;
  background-color: #a1a1a1;
  border: 1px solid #f1f1f1;
}

.motivation-add__button:focus,
.motivation-modify__button:focus {
  border: 1px solid black;
}

.motivation-menu__top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 1201;
  box-shadow: 0 5px 25px rgb(7 2 14 / 4%);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
}

.motivation-menu__title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
}

.motivation-menu__close {
  right: 0;
}

.motivation-menu__form {
  margin-top: 0;
  padding: 0 25px 25px;
  height: calc(100vh - 140px);
}

.motivation-menu__name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b85;
  margin-left: 15px;
  margin-top: 20px;
}

.motivation-menu__input,
.motivation-menu__select {
  margin-top: 4px;
  color: black;
}

.motivation-menu__input::placeholder,
.motivation-menu__select::placeholder {
  color: black;
}

.motivation-menu__select {
  margin-top: 4px;
}

.motivation-menu__select--year::after {
  top: calc(50% + 2px);
}

.motivation-menu__control {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1201;
  height: 80px;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  box-shadow: 0 -5px 25px rgb(7 2 14 / 4%);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
}

.motivation-menu__save {
  padding: 15px 55px;
  background-color: black;
  color: white;
  border-radius: 10px;
  transition: all 0.2s linear;
  cursor: pointer;
}

.motivation-menu__save:hover {
  box-shadow: 4px 4px 10px 1px rgb(0 0 0 / 20%);
}

.motivation-menu__delete,
.motivation-menu__delete--from-main {
  position: relative;
  color: #ff284f;
  border: 1px solid #ff284f;
  border-radius: 10px;
  padding: 15px 45px 15px 70px;
  cursor: pointer;
}

.motivation-menu__delete::after,
.motivation-menu__delete--from-main::after {
  position: absolute;
  top: 15px;
  left: 47px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 16px;
  mask-image: url("../../img/delete.svg");
  background-color: #ff284f;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.motivation-menu__templates {
  z-index: 1500;
}

.motivation-menu__template-modify {
  z-index: 1600;
}

.motivation-period {
  padding-top: 20px;
  border-top: 1px solid #f1f1f3;
  margin-left: 0;
}

.motivation-period__title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: black;
}

.motivation-months__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #f1f1f3;
}

.motivation-months__inner {
  display: flex;
  flex-flow: row wrap;
  margin-top: 12px;
  border: 1px solid #dbdadf;
  box-shadow: 0 5px 10px rgb(5 4 33 / 5%);
  border-radius: 10px;
  padding: 25px 15px 40px;
  min-width: 472px;
  max-width: 473px;
}

.motivation-months__item {
  width: calc(50% - 10px);
  height: 37px;
  position: relative;
  margin-top: 10px;
}

.motivation-wage__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: #481e8c;
  margin-top: 20px;
  margin-left: 15px;
}

.motivation-months__item:first-child {
  margin-top: 0;
}

.motivation-months__item:nth-child(2) {
  margin-top: 0;
}

.motivation-months__item:nth-child(even) {
  margin-left: 20px;
}

.motivation-months__checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.motivation-months__label {
  border-radius: 36px;
  background-color: #f4f4f6;
  color: black;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  transition: all 0.2s linear;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.motivation-months__label:hover {
  box-shadow: 2px 2px 1px 1px rgb(0 0 0 / 50%);
}

.motivation-months__checkbox:checked ~ .motivation-months__label {
  background-color: black;
  color: white;
}

.motivation-months__checkbox:disabled ~ .motivation-months__label {
  background-color: #d7d7de;
  cursor: default;
  color: black;
}

.motivation-months__checkbox:disabled ~ .motivation-months__label:hover {
  box-shadow: none;
}

.motivation__blocks {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  align-self: flex-start;
  width: 100%;
  max-width: 915px;
}

.motivation__block {
  width: 290px;
  height: 240px;
  display: flex;
  border-radius: 10px;
  background: linear-gradient(0deg, #fff, #fff);
  border: 1px solid #f1f1f4;
  box-sizing: border-box;
  box-shadow: 0 8px 18px rgb(5 4 20 / 8%);
  overflow: hidden;
  cursor: pointer;
  flex-direction: column;
  margin-right: 20px;
}

.motivation__block:nth-child(n+4) {
  margin-top: 20px;
}

.motivation__block:nth-child(3n) {
  margin-right: 0;
}

.motivation__info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: auto;
  height: 90px;
}

.motivation__checkbox {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.motivation__checkbox:checked ~ .motivation__toggle::after {
  left: calc(100% - 25px);
}

.motivation__checkbox:checked ~ .motivation__toggle {
  background-color: #27ae60;
}

.motivation__checkbox:not(:checked) ~ .motivation__toggle::after {
  left: 5px;
}

.motivation__checkbox:not(:checked) ~ .motivation__toggle {
  background-color: #ff284f;
}

.motivation-template {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 75px;
  align-content: flex-start;
}

.motivation-template__list {
  width: 1240px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}

.motivation-template__content {
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
}

.motivation-template__modify {
  width: 520px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.motivation-modify-template__form {
  height: calc(100vh - 70px - 80px);
  width: 520px;
  padding: 0 25px;
}

.motivation-modify-template__close {
  right: 0;
}

.motivation-wage__title:first-child {
  margin-top: 0;
}

.motivation-now__block {
  display: flex;
  flex-direction: column;
  height: 160px;
  padding: 20px 25px;
  margin-right: 20px;
  margin-top: 0;
  width: 288px;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.motivation-now__block:nth-child(n+5) {
  margin-top: 20px;
}

.motivation-now__block:nth-child(4n) {
  margin-right: 0;
}

.motivation-now__control {
  justify-content: flex-end;
  display: flex;
  margin-top: 5px;
  pointer-events: none;
}

.motivation-now__switch {
  display: inline-block;
}

.motivation-add__info {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  padding: 0 20px;
}

.months__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b85;
  margin-left: 15px;
}

.motivation-department,
.motivation-position {
  background-color: white;
  width: 255px;
}

.motivation-position {
  margin-left: 20px;
}

.block-add,
.block-modify {
  margin-left: 20px;
}

.block-add:first-child,
.block-modify:first-child {
  margin-left: 0;
}

.block-add:nth-child(2),
.block-modify:nth-child(2) {
  margin-top: 0;
}

.block-add:nth-child(3),
.block-modify:nth-child(3) {
  margin-top: 0;
}

.block-add:nth-child(4),
.block-modify:nth-child(4) {
  margin-top: 0;
}

.block-add:nth-child(5n),
.block-modify:nth-child(5n) {
  margin-left: 0;
}

.motivation__block.block-arbitrary {
  background: transparent;
}

.menu-modify-template__top {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  height: 70px;
  box-shadow: 0 5px 25px rgb(7 2 14 / 4%);
  display: flex;
  align-items: center;
  padding: 0 40px;
}

.menu-modify-template__control {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -5px 25px rgb(7 2 14 / 4%);
  margin-top: auto;
  padding: 0 25px;
}

.menu-modify-template__save {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 50px;
  background-color: black;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.menu-modify-template__cancel {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 50px;
  color: black;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
}

.menu-wage__input {
  margin-top: 4px;
}

.levels__item {
  display: flex;
}

.additional,
.traffic {
  display: flex;
  margin-top: 20px;
  border-bottom: 1px solid rgb(164 170 178 / 40%);
  padding-bottom: 20px;
}

.level {
  display: flex;
  margin-top: 20px;
}

.levels__item:first-child .level {
  margin-top: 0;
}

.motivation-coeff__title {
  display: inline-block;
  margin-top: 20px !important;
}

.levels {
  margin-top: 20px;
}

.fixed-item {
  position: relative;
}

.fixed-item__icon {
  position: absolute;
  top: 34px;
  right: 15px;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
}

.levels-additional,
.levels-traffic {
  border: 1px solid rgb(180 179 187 / 40%);
  box-shadow: 0 8px 18px rgb(5 4 20 / 8%);
  border-radius: 10px;
  padding: 20px 15px;
  margin-top: 20px;
  min-width: 470px;
  max-width: 470px;
  margin-bottom: 20px;
}

.levels-additional__top,
.levels-traffic__top {
  display: flex;
  justify-content: space-between;
}

.levels-additional__title,
.levels-traffic__title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
}

.levels-additional__delete,
.levels-traffic__delete {
  width: 13px;
  height: 14px;
  display: inline-block;
  mask-image: url("../../img/delete.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.ladder__add {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: #481e8c;
  border: 1px solid #481e8c;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.ladder__create {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(180 179 187 / 40%);
  box-shadow: 0 8px 18px rgb(5 4 20 / 8%);
  border-radius: 10px;
  padding: 20px 15px 25px;
  margin-top: 20px;
  position: relative;
}

.ladder-create__button {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: #481e8c;
  border: 1px solid #481e8c;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.level__id {
  width: 44px;
  display: inline-block;
}

.level-id__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b85;
}

.level-id__count {
  margin-top: 13px;
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 100%;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.level-id__count::after {
  content: "";
  width: 1px;
  height: 40px;
  position: absolute;
  top: -8px;
  right: -14px;
  background-color: #91929933;
}

.level__value-from {
  margin-left: 14px;
  position: relative;
}

.level__value-to {
  margin-left: 35px;
  position: relative;
}

.level__value-to::after {
  content: "";
  width: 1px;
  height: 40px;
  position: absolute;
  top: 22px;
  right: -16px;
  background-color: #91929933;
}

.level__reward {
  margin-left: 30px;
  position: relative;
}

.level__delete {
  width: 13px;
  height: 14px;
  display: inline-block;
  mask-image: url("../../img/delete.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  position: relative;
  top: 36px;
  cursor: pointer;
  margin-left: 10px;
}

.level__delete--modify {
  width: 13px;
  height: 14px;
  display: inline-block;
  mask-image: url("../../img/delete.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  position: relative;
  top: 36px;
  cursor: pointer;
  margin-left: 10px;
}

.value-from__title,
.value-to__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: #7c7b85;
}

.value-from__value,
.value-to__value {
  width: 100px;
  height: 40px;
  border: 1px solid #c3c2c9;
  border-radius: 5px;
  margin-top: 7px;
  padding: 0 24px 0 5px;
}

.value-reward__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: #7c7b85;
}

.value-reward__value {
  width: 80px;
  height: 40px;
  border: 1px solid #c3c2c9;
  border-radius: 5px;
  margin-top: 7px;
  padding: 0 24px 0 5px;
}

.level-input__icon {
  display: inline-block;
  position: absolute;
  top: 34px;
  right: 15px;
}

.levels-interval__icon {
  width: 13px;
  height: 12px;
  display: inline-block;
  mask-image: url("../../img/reverse.svg");
  background-color: #161718;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  position: relative;
  top: 36px;
  left: 18px;
}

.level-additional__button--add {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.level-additional__button {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.level-additional__button--modify {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.level-traffic__button--add {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.level-traffic__button--modify {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.level-traffic__button {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.levels-bottom__btn--add,
.levels-bottom__ad-btn--add {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.levels-bottom__btn--modify {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.motivation-modify-template__title {
  color: black !important;
}

.motivation-now__list {
  display: inline-flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #c4c4c433;
  width: 100%;
  flex-flow: row wrap;
  align-content: flex-start;
  margin-bottom: 40px;
}

.motivation-now__name {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: #161718;
  width: calc(100% - 10px);
  white-space: word-wrap;
  display: inline-block;
  pointer-events: none;
}

.motivation-now__department {
  display: inline-block;
  padding: 5px 10px;
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #161718;
  border: 1px solid #161718;
  border-radius: 5px;
  width: max-content;
  pointer-events: none;
}

.motivation-now__position {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #16171899;
  margin-top: 10px;
  pointer-events: none;
}

.motivation-now__head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  pointer-events: none;
}

.motivation-now__icon {
  display: inline-block;
  width: 13px;
  height: 14px;
  mask-image: url("../../img/copy.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.block-arbitrary {
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #481e8c;
  width: 290px;
  height: 240px;
  border-radius: 10px;
  cursor: pointer;
}

.block-arbitrary__title {
  width: 134px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.motivation-wage__circle {
  min-width: 40px;
  min-height: 40px;
  border-radius: 100%;
  border: 1px solid #481e8c;
  position: relative;
  display: inline-block;
}

.motivation-wage__circle::after {
  content: "";
  width: 15px;
  height: 2px;
  background-color: #481e8c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.motivation-wage__circle::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  content: "";
  width: 15px;
  height: 2px;
  background-color: #481e8c;
}

.fixed-homework {
  margin-top: 20px;
}

.fixed-deal {
  margin-top: 20px;
}

.motivation-year {
  margin-left: 20px;
  background-color: white;
}

.motivation-add__year {
  width: 145px;
  height: 40px;
}

.motivation-department__wrapper {
  width: 100%;
}

.motivation-position__wrapper {
  width: 100%;
  margin-left: 0;
}

.ladder__separator {
  width: 100%;
  height: 1px;
  display: flex;
  margin-bottom: 20px;
  background: rgb(164 170 178 / 40%);
}

.levels-rate {
  margin-top: 20px;
}

.motivation-modify__block,
.motivation-add__block,
.motivation-new__block {
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  width: 290px;
  height: 240px;
}

.motivation-new__info {
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 90px;
  align-items: center;
}

.motivation-menu__cancel {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 50px;
  color: black;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
}

.motivation-FixedWageRate-0__image {
  height: 150px;
  width: 100%;
  background: linear-gradient(180deg, #df77b9 0%, #b69bc4 100%);
  position: relative;
}

.motivation-FixedWageRate-0__image::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55px;
  height: 68px;
  mask-image: url("../../img/ruble.svg");
  background-color: rgb(255 255 255 / 70%);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  display: inline-block;
}

.motivation-OneValueWageRate-7__image {
  height: 150px;
  width: 100%;
  background: linear-gradient(180deg, #df77b9 0%, #b69bc4 100%);
  position: relative;
}

.motivation-OneValueWageRate-7__image::after {
  content: "1.15";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  font-size: 90px;
  font-weight: 800;
  letter-spacing: 3.5px;
  color: rgb(255 255 255 / 70%);
  display: inline-block;
}

.motivation-OneValueWageRate-4__image {
  height: 150px;
  width: 100%;
  background: linear-gradient(180deg, #ee7b7a 0%, #dc68b1 100%);
  position: relative;
}

.motivation-OneValueWageRate-4__image::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  width: 62px;
  height: 70px;
  font-size: 90px;
  font-weight: 800;
  letter-spacing: 3.5px;
  mask-image: url("../../img/control.svg");
  background-color: rgb(255 255 255 / 70%);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  color: rgb(255 255 255 / 70%);
  display: inline-block;
}

.motivation-OneValueWageRate-3__image {
  height: 150px;
  width: 100%;
  background: linear-gradient(180deg, #ee7b7a 0%, #dc68b1 100%);
  position: relative;
}

.motivation-OneValueWageRate-3__image::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  width: 62px;
  height: 70px;
  font-size: 90px;
  font-weight: 800;
  letter-spacing: 3.5px;
  mask-image: url("../../img/control.svg");
  background-color: rgb(255 255 255 / 70%);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  color: rgb(255 255 255 / 70%);
  display: inline-block;
}

.motivation-LeveledWageRate-1__image {
  height: 150px;
  width: 100%;
  background: linear-gradient(180deg, #8f95c2 0%, #a789b9 100%);
  position: relative;
}

.motivation-LeveledWageRate-1__image::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 62px;
  height: 70px;
  font-size: 90px;
  font-weight: 800;
  letter-spacing: 3.5px;
  mask-image: url("../../img/rating.svg");
  background-color: rgb(255 255 255 / 70%);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  color: rgb(255 255 255 / 70%);
  display: inline-block;
}

.motivation-LeveledWageRate-2__image {
  height: 150px;
  width: 100%;
  background: linear-gradient(180deg, #8f95c2 0%, #a789b9 100%);
  position: relative;
}

.motivation-LeveledWageRate-2__image::after {
  content: "%";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  font-size: 76px;
  font-weight: 800;
  letter-spacing: 3.5px;
  color: rgb(255 255 255 / 70%);
  display: inline-block;
}

.motivation-TypedLeveledWageRate-5__image {
  height: 150px;
  width: 100%;
  background: linear-gradient(180deg, #69c0d6 0%, #7eabd0 100%);
  position: relative;
}

.motivation-TypedLeveledWageRate-5__image::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  font-size: 76px;
  font-weight: 800;
  letter-spacing: 3.5px;
  color: rgb(255 255 255 / 70%);
  display: inline-block;
  background-image: url("../../img/percent.svg");
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
}

.motivation-TypedLeveledWageRate-6__image {
  height: 150px;
  width: 100%;
  background: linear-gradient(180deg, #69c0d6 0%, #7eabd0 100%);
  position: relative;
}

.motivation-TypedLeveledWageRate-6__image::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  font-size: 76px;
  font-weight: 800;
  letter-spacing: 3.5px;
  color: rgb(255 255 255 / 70%);
  display: inline-block;
  background-image: url("../../img/percent.svg");
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
}

.motivation__image {
  pointer-events: none;
}

.motivation-menu__value {
  color: black;
}

.input-number {
  transition: all 0.2s linear;
}

.input-number:focus {
  border: 1px solid black;
}

.m_blocks {
  width: 910px;
  display: flex;
  flex-flow: row wrap;
}

.motivation-tab__content {
  display: none;
}

.motivation__control {
  position: relative;
}

.levels-additional__list,
.levels-traffic__list {
  margin-top: 15px;
}

.motivation__wrapper {
  padding-top: 70px;
}

.motivation-TypedLeveledWageRate-8__image {
  height: 150px;
  width: 100%;
  background: linear-gradient(180deg, #8fc29a 0%, #89b6b9 100%);
  position: relative;
}

.motivation-TypedLeveledWageRate-8__image::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  font-size: 76px;
  font-weight: 800;
  letter-spacing: 3.5px;
  color: rgb(255 255 255 / 70%);
  display: inline-block;
  background-image: url("../../img/plan.svg");
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
}

@media (min-width: 1300px) {
  .motivation__block:nth-child(3n) {
    margin-top: 20px;
    margin-right: 20px;
  }

  .motivation__block:nth-child(even) {
    margin-right: 0;
  }

  .motivation__block:nth-child(odd) {
    margin-right: 20px;
  }

  .motivation__block:nth-child(n+3) {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1529px) {
  .motivation__block:nth-child(2n) {
    margin-right: 0;
  }

  .motivation__block:nth-child(3n) {
    margin-right: 20px;
    margin-top: 20px;
  }

  .motivation__block:nth-child(n+2) {
    margin-top: 20px;
  }

  .motivation__block:nth-child(2) {
    margin-top: 0;
  }
}

@media screen and (min-width: 1550px) {
  .motivation__block:nth-child(2n) {
    margin-right: 20px;
  }

  .motivation__block:nth-child(3n) {
    margin-right: 0;
  }

  .motivation__block:nth-child(4n) {
    margin-right: 20px;
    margin-top: 20px;
  }

  .motivation__block:nth-child(n+3) {
    margin-top: 20px;
  }

  .motivation__block:nth-child(3) {
    margin-top: 0;
  }
}
