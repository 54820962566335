@import "../helpers/variables";

.bills-menu {
  z-index: 4;
}

.bills-menu__wrapper {
  flex-flow: row-reverse;
  background-color: transparent;
}

.bills-menu__form {
  width: 1020px;
}

.bills__table {
  width: auto;
}

.bills-list {
  height: calc(100vh - 150px);
  margin: 0;
}

.bills-list__item {
  display: flex;
  margin: 20px;
  padding: 0;
  height: 30px;
  min-height: 30px;
  align-items: center;
}

.bills-list__item--header {
  margin: 20px;
  padding: 0;
  height: 30px;
  border-bottom: 1px solid lightgray;
  align-items: center;
  display: flex;
}

.bills-list__add {
  height: calc(100vh - 515px);
}

.bills-form__wrapper {
  background-color: #f4f4f6;
}

.bills-form__list {
  width: 260px;
  padding: 20px;
  margin: 0;
  list-style: none;
}

.bills-form__item {
  min-width: 50%;
  width: 50%;

  &.width_100 {
    min-width: 100%;
    width: 100%;
  }

  &.mt_20 {
    margin-top: 20px;
  }
}

.bills-form__item--information {
  font-size: 12px;
  margin-top: 15px;
  color: #b4b4b4;
  font-style: italic;
  line-height: 14px;
}

.bills__status--header {
  min-width: 20px;
  width: 20px;
}

.bills__manager {
  display: flex;
  align-items: center;
  min-width: 80px;
  width: 80px;
  margin-left: 15px;
}

.bills__manager a {
  min-width: 100%;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bills__form,
.bill__form {
  display: flex;
  align-items: center;
  min-height: 30px;
  height: 30px;
  width: 800px;
  justify-content: space-between;
}

.bills__date,
.bills__paymentDay {
  min-width: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: white;
}

.bills__summ {
  min-width: 55px;
  width: 55px;
  border: 0;
  background-color: transparent;
}

.bills__select--payment {
  min-width: 30px;
  width: 30px;
  min-height: 30px;
  height: 30px;
  border: 0;
  color: transparent;
}

.bills__select--payment.pmethod_other {
  background: url("../../img/m_other.svg") no-repeat;
}

.bills__select--payment.pmethod_sber {
  background: url("../../img/m_sber.svg") no-repeat;
}

.bills__select--payment.pmethod_yandex {
  background: url("../../img/yooukassa.svg") no-repeat;
}

.bills__select--payment.pmethod_paypal {
  background: url("../../img/m_paypal.svg") no-repeat;
}

.bills__select--payment.pmethod_bill {
  background: url("../../img/m_bill.svg") no-repeat;
}

.bills__select--payment.pmethod_westernunion {
  background: url("../../img/m_westernunion.svg") no-repeat;
}

.bills__select--payment.pmethod_null {
  background: white;
}

.bills__select--payment.pmethod_qiwi {
  background: url("../../img/m_qiwi.svg") no-repeat;
}

.bills__link {
  position: relative;
  display: flex;
  min-width: 45px;
  width: 45px;
  height: 30px;
  justify-content: space-between;
  align-items: center;
}

.bills__copy {
  position: relative;
  width: 14px;
  height: 16px;
  top: 0;
  left: 0;
  cursor: pointer;
}

.bills__copy::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 7px;
  height: 14px;
  width: 14px;
  background-color: #b4b3bb;
  mask: url("../../img/copy.svg") no-repeat center;
  mask-size: auto 14px;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 0.2s linear;
}

.bills__copy:hover::after {
  background-color: #161718;
}

.bills__copy::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 14px;
  background-color: #b4b3bb;
  position: absolute;
  top: 1px;
  right: 22px;
  pointer-events: none;
}

.bills__open {
  position: relative;
  width: 14px;
  height: 16px;
  top: 0;
  right: 0;
}

.bills__open::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 14px;
  width: 14px;
  background-color: #b4b3bb;
  mask: url("../../img/foreign.svg") no-repeat center;
  mask-size: auto 14px;
  transition: all 0.2s linear;
}

.bills__open:hover::after {
  background-color: #161718;
}

.bills__upload-file {
  margin-left: 5px;
}

.bills__upload-file--icon {
  position: absolute;
  top: 0;
  left: 0;
}

.bills__upload-file--icon::after {
  content: "";
  display: block;
  width: 20px;
  height: 15px;
  mask: url("../../img/upload-bill.svg") no-repeat center;
  mask-size: 100% 100%;
  background-color: #b4b3bb;
  transition: 0.4s;
  cursor: pointer;
  position: relative;
  left: 0;
  margin-left: 5px;
}

.bills__upload-file--icon:hover::after {
  background-color: #161718;
}

.bills__account--number {
  border: 0;
  background-color: transparent;
  min-width: 155px;
  width: 155px;
}

.bills__nav {
  display: none;
  position: relative;
}

.bills__nav::after {
  content: "";
  width: 1px;
  height: 13px;
  display: inline-block;
  position: absolute;
  right: 22px;
  top: 3px;
  pointer-events: none;
  background: #b4b3bb;
}

.bills__nav--edit {
  justify-content: space-between;
  position: relative;
  width: 50px;
}

.bills__nav--edit::after {
  content: "";
  width: 1px;
  height: 12px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 0;
  pointer-events: none;
  background-color: #b4b3bb;
}

.bills__nav.opened {
  display: flex;
  width: 50px;
  justify-content: space-between;
}

.bills-nav__item {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
}

.bills-nav__item.edit {
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #b4b3bb;
  mask: url("../../img/nav-setting.svg") no-repeat center;
  mask-size: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.bills-nav__item.edit:hover {
  background-color: #161718;
}

.bills-nav__item.delete {
  display: block;
  position: relative;
  height: 18px;
  width: 16px;
  background-color: #b4b3bb;
  mask: url("../../img/delete.svg") no-repeat center;
  mask-size: 100% 100%;
  transition: 0.4s;
  cursor: pointer;
}

.bills-nav__item.delete:hover {
  background-color: #161718;
}

.bills-nav__item.accept {
  display: block;
  position: relative;
  top: -1px;
  height: 13px;
  width: 13px;
  background-color: #b4b3bb;
  mask: url("../../img/accept.svg") no-repeat center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.bills-nav__item.accept:hover {
  background-color: #161718;
}

.bills-nav__item.decline {
  display: block;
  position: relative;
  top: -1px;
  height: 13px;
  width: 13px;
  background-color: #b4b3bb;
  mask: url("../../img/cancel.svg") no-repeat center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  cursor: pointer;
}

.bills-nav__item.decline:hover {
  background-color: #161718;
}

.bills__sms {
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  mask-image: url("../../img/check-list.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  position: absolute;
  top: 0;
  right: 0;
}

.bills__sms:hover {
  background-color: #161718;
}

.bills__sms.active {
  background-color: rgb(39 174 96 / 70%);
}

.bills__sms.active:hover {
  background-color: #27ae60;
}

.bills__payment {
  min-width: 55px;
  width: 55px;
  appearance: none;
  color: transparent;
}

.bills__payment--option {
  color: black;
}

.bills__exposed {
  min-width: 90px;
  width: 90px;
  margin-right: 15px;
}

.bills__type {
  min-width: 65px;
  width: 65px;
}

.bills__check {
  min-width: 50px;
  width: 50px;
}

.bills__for {
  background-color: lightgray;
  margin: 20px;
  height: auto;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 12px;
}

.bills__for--client {
  font-size: 20px;
  line-height: 20px;
}

.bills__footer {
  padding: 0 30px;
  justify-content: space-between;
}

.bills__close {
  width: max-content;
  margin: 0;
  padding: 0 15px;
}

.bills__image {
  width: 25px;
  height: 20px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.bills__image img {
  vertical-align: middle;
  border-style: none;
  width: 100%;
  height: auto;
  position: relative;
  top: -2px;
}

.bills__add .column-links {
  position: relative;
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 2px;
}

.bills__add .column-links.no-image {
  top: -8px;
}

.bills__add .column-links::after {
  content: "";
  width: 1px;
  height: 12px;
  display: inline-block;
  position: absolute;
  left: 34px;
  top: 4px;
  pointer-events: none;
  background: #b4b3bb;
}

.bills__add .column-links.no-image::after {
  top: 2px;
}

.bills__add .column-links.image-here::after {
  top: 2px;
}

.platform-table__column .check__wrapper.image-here::after {
  left: 35px;
  top: 2px;
}

.platform-table__column .check__wrapper.no-image::after {
  left: 34px;
  top: 2px;
}

.platform-table__column .check__wrapper--bills::after {
  left: 33px;
  top: 3px;
}

.editable {
  height: 30px;
  border: 1px solid black;
  padding: 0 5px;
}

.platform-table__column {
  font-size: 12px;
  line-height: 12px;
}

.platform-table__column.date {
  width: 50px;
  margin-right: 20px;
}

.platform-table__column.column-client {
  max-width: 150px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  text-align: left;
  padding-right: 15px;
}

.platform-table__column.column-client-pa {
  max-width: 100px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 100%;
  text-align: left;
}

.platform-table__column.link {
  min-width: 75px;
  width: 75px;
  border-right: 1px solid #e4e5e8;
  padding-right: 25px;
  margin-right: 20px;
  display: flex;
  height: 60px;
}

.platform-table__row--header .platform-table__column.link {
  border-right: 0;
  height: max-content;
}

.platform-table__column.link__icon {
  position: relative;
}

.platform-table__column.column-manager {
  width: 115px;
  margin-right: 20px;
}

.platform-table__column.column-manager .column-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.platform-table__column.course {
  width: 105px;
  margin-right: 20px;
}

.platform-table__column.course .column-course {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}

.platform-table__column.bill-status--header {
  background-color: transparent;
  min-width: 20px;
  width: 20px;
}

.platform-table__column.bill-status--header::after {
  display: none;
}

.platform-table__column.bills__payment {
  min-width: 55px;
  width: 55px;
  margin-right: 15px;
  background-color: white;
}

.platform-table__column.bills__payment--option {
  color: black;
}

.platform-table__column.bills__account--number {
  border: 0;
  background-color: transparent;
  min-width: 115px;
  width: 115px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: left;
}

.platform-table__column.bills__add {
  min-width: 60px;
  width: 60px;
  height: 20px;
  margin-right: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.platform-table__column.bills__add .column-links {
  position: relative;
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.platform-table__column.bills__add .column-links::after {
  content: "";
  width: 1px;
  height: 12px;
  display: inline-block;
  position: absolute;
  left: 23px;
  top: 4px;
  pointer-events: none;
  background: #b4b3bb;
}

.platform-table__column.bills__summ {
  min-width: 55px;
  width: 55px;
  border: 0;
  background-color: transparent;
  margin-right: 15px;
}

.platform-table__column.bills__paymentDay {
  min-width: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  margin-right: 15px;
  background-color: transparent;
}

.platform-table__column.bills__exposed {
  min-width: 65px;
  width: 65px;
  margin-right: 15px;
  text-align: left;
}

.exposed {
  padding: 15px 30px;
  background: lightgray;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.set {
  background-color: #27ae60;
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.platform-form__access-btn.bills__save {
  width: max-content;
  padding: 0 15px;
  margin: 0;
}

.platform-form__edit-btn.bills__close {
  height: 40px;
  display: flex;
  align-items: center;
}

.bills__summ:disabled,
.bills__account--number:disabled,
.bills__paymentDay:disabled {
  color: black;
  border: 0;
}

.bills__preloader {
  font-size: 13px;
  line-height: 13px;
  padding: 0 25px;
}

.bill__item {
  width: 100%;
  height: 70px;
  display: flex;
  border-radius: 10px;
  padding: 20px 20px 15px 25px;
  background: linear-gradient(0deg, #fff, #fff), rgb(225 225 234 / 90%);
  position: relative;
  cursor: pointer;
  transition: all 0.2s linear;
  box-shadow: 0 1px 15px 0 rgb(5 4 20 / 5%);

  &.payd {
    cursor: default;
  }
}

.bill__remove {
  position: absolute;
  top: 50%;
  right: 15px;
  mask-image: url("../../img/delete-btn.svg");
  background-color: $bill-text;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
  transition: opacity 0.2s linear, background-color 0.2s linear;
  display: inline-block;
  width: 15px;
  height: 17px;
  opacity: 0;
  transform: translateY(-50%);

  &:hover {
    background-color: $black;
  }
}

.bill__item:hover .bill__remove {
  opacity: 1;
}

.bill__item:not(:first-child) {
  margin-top: 10px;
}

.bill-status {
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #e5e5e5;
  position: relative;
  margin-right: 15px;
  color: #9c9ba0;
  cursor: pointer;
  display: flex;

  &::after {
    content: "";
    width: 8px;
    height: 9px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    mask-image: url("../../img/bills_check.svg");
    background-color: lightgray;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
  }

  &.checked {
    background-color: #27ae60;

    &::after {
      background-color: white;
    }
  }
}

.bill__tooltip {
  width: max-content;
  height: auto;
  padding: 10px 15px;
  background-color: black;
  color: white;
  font-size: 12px;
  line-height: 12px;
  opacity: 0;
  position: absolute;
  top: calc(-100% - 8px);
  left: 50%;
  transition: all 0.2s linear;
  display: flex;
  border-radius: 5px;
  transform: translateX(-50%);
}

.bill__create-info {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90px;
  min-width: 90px;

  &:hover {
    .bill__tooltip {
      opacity: 1;
    }
  }
}

.bill__methods {
  width: 145px;
  height: 30px;
  display: flex;
  flex-direction: column;
  min-width: 90px;
  justify-content: center;
  margin-right: 35px;
}

.methods__unselected {
  width: 100px;
  height: 30px;
  margin-right: 40px;
  min-width: 100px;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
}

.unselected__text {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bill__manager {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  width: 80px;
  min-width: 80px;
  margin-right: 44px;
}

.bill__manager.icon {
  margin-right: 0;
}

.bill__price {
  position: relative;
  min-width: 80px;
  width: 80px;
}

.bill__price::after {
  content: "";
  width: 1px;
  height: 27px;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: -12px;
  background-color: #dfdfe3;
}

.bill__notify {
  font-size: 14px;
}

.bill__deal {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  width: 155px;
  margin-right: 70px;
}

.info__title {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #acacb2;
}

.info__input,
.price__input {
  width: 75px;
  height: 20px;
}

.info__when {
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  color: black;
  position: relative;
}

.info--day {
  font-size: inherit;
  line-height: inherit;
}

.methods__select {
  width: 100%;
  height: 100%;
  appearance: none;
  border: 0;
  padding-left: 45px;
  display: flex;
  align-items: center;
}

.methods__select--value {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  width: 100px;
  font-size: 12px;
  line-height: 12px;
}

.methods__select.yandex {
  background: url("../../img/yooukassa.svg");
  background-repeat: no-repeat;
}

.methods__select.other {
  background: url("../../img/m_other.svg");
  background-repeat: no-repeat;
}

.methods__select.westernunion {
  background: url("../../img/m_westernunion.svg");
  background-repeat: no-repeat;
}

.methods__select.qiwi {
  background: url("../../img/m_qiwi.svg");
  background-repeat: no-repeat;
}

.methods__select.sber {
  background: url("../../img/m_sber.svg");
  background-repeat: no-repeat;
}

.methods__select.bill {
  background: url("../../img/m_bill.svg");
  background-repeat: no-repeat;
}

.methods__select.paypal {
  background: url("../../img/m_paypal.svg");
  background-repeat: no-repeat;
}

.manager__title {
  color: #acacb2;
}

.manager__name {
  color: black;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  appearance: none;
  border: 0;
}

.deal__title {
  color: #acacb2;
}

.deal__name {
  color: black;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price__title {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b8599;
}

.price__value {
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bill__empty {
  font-size: 14px;
  line-height: 14px;
}

.bill__link {
  min-width: 180px;
  width: 180px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  top: -2px;
  margin-right: 20px;

  &.disabled {
    cursor: default;
    pointer-events: all;
  }
}

.bill__link.icon {
  min-width: 0;
  width: 225px;
}

.bill__copy {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  background-color: white;
  mask-image: url("../../img/copy.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  margin-left: 10px;
  transition: all 0.2s linear;
  pointer-events: none;
}

.bill__check {
  display: inline-block;
  width: 22px;
  height: 24px;
  mask-image: url("../../img/work-icon.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
  transition: all 0.2s;
  margin-right: auto;
  margin-left: auto;
}

.link__btn {
  width: 180px;
  height: 40px;
  border-radius: 5px;
  background-color: black;
  border: 1px solid black;
  transition: all 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #f4f4f6;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: black;
    border: 1px solid black;
    background-color: #fff;

    .bill__copy {
      background-color: #000;
    }

    .bill__check {
      background-color: #000;
    }

    &.bill__redemption::after {
      background-color: #000;
    }
  }

  &.disabled {
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
    color: #b9b9b9;
    cursor: default;

    .bill__copy {
      background-color: #b9b9b9;
    }

    &.bill__redemption {
      &::after {
        background-color: #b9b9b9;
      }
    }
  }

  &.payoff {
    min-width: 180px;
    width: 180px;
    max-width: 180px;
  }
}

.link__payoff {
  display: flex;
  justify-content: center;
  width: 180px;
  height: 40px;
  align-items: center;
}

.link__image {
  display: inline-block;
  width: 20px;
  height: 20px;
  mask-image: url("../../img/block-image.svg");
  background-color: rgb(172 172 178 / 30%);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.link__check {
  display: inline-block;
  width: 20px;
  height: 20px;
  mask-image: url("../../img/check-list.svg");
  background-color: rgb(172 172 178 / 30%);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  margin: 0 15px;
}

.link__comment {
  display: inline-block;
  width: 20px;
  height: 20px;
  mask-image: url("../../img/work-icon.svg");
  background-color: rgb(172 172 178 / 30%);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.bill-image__label-wrapper {
  border-radius: 5px;
  width: 100px;
  height: 100px;
  display: flex;
  border: 2px dashed #b4b3bb;
  transition: all 0.2s linear;
  margin-top: 10px;
  position: relative;
}

.bill-image__label-wrapper:hover {
  border: 2px dashed black;
}

.bill-image__label {
  display: flex;
  width: 100px;
  height: 100px;
  background-image: url("../../img/picture.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 32px;
  transition: all 0.2s linear;
  cursor: pointer;
  border: 2px dashed #b4b3bb;
  border-radius: 5px;
  margin-top: 15px;
}

.bill-image__label-wrapper:hover .bill-image__label {
  background-color: black;
}

.bill-image__label-wrapper.active {
  border: 2px dashed black;
}

.bill-image__label-wrapper.active .bill-image__label {
  background-color: black;
}

.pay__item {
  margin-left: 40px;
  margin-right: 40px;
  position: relative;
}

.pay__item:last-child {
  margin-bottom: 50px;
}

.pay__name {
  padding-left: 0;
}

.bill-comment {
  border-top: 1px solid #f1f1f3;
  margin-top: 20px;
  padding-top: 15px;
}

.bill-comment__textarea {
  height: 90px;
  width: 100%;
  display: flex;
  padding: 15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: black;
  margin-top: 10px;
  border: 1px solid #c3c2c9;
  border-radius: 5px;
  resize: none;
}

.bills__items {
  height: calc(100vh - 150px);
  padding: 25px;
}

.pay__save {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  margin-top: 0;

  &.disable {
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
    color: #b9b9b9;
    cursor: default;
    pointer-events: none;
  }
}

.pay__close {
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  background-color: #fff;
  color: black;
  border: 1px solid black;
}

.pay__close:hover {
  background-color: black;
  color: #fff;
}

.pay__footer {
  justify-content: space-between;
}

.pay-menu__form {
  width: 520px;
  margin-left: auto;
}

.payoff {
  cursor: pointer;
  margin-right: 30px;
}

.platform-form__input-full {
  position: relative;
  background-color: #b4b3bb;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  display: flex;
  top: 10px;
  left: calc(100% - 30px);
  opacity: 0;
  transition: opacity 0.2s linear;
}

.platform-form__input-full::after,
.platform-form__input-full::before {
  content: "";
  width: 2px;
  height: 10px;
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.platform-form__input-full::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.platform-form__input-full.active {
  position: absolute;
  opacity: 1;
}

.update-menu {
  position: fixed;
  transition: all 0.4s ease-out;
  bottom: -100%;
  width: calc(100% - 70px);
  height: 80px;
  background-color: rgb(0 0 0 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  transform: translateX(70px);
}

.update-menu.active {
  bottom: 0;
}

.update-menu__text {
  color: white;
  font-size: 12px;
  line-height: 12px;
  margin-right: 25px;
}

.update-menu__ok {
  background-color: white;
  color: black;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  padding: 8px 15px;
  margin-right: 15px;
  cursor: pointer;
}

.update-menu__cancel {
  background-color: white;
  color: black;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
}

.bill__create {
  border: 2px dashed #c3c2c9;
  border-radius: 10px;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #c3c2c9;
  background: transparent;
  display: flex;
  align-items: center;
  flex-flow: row-reverse;
  transition: all 0.2s linear;
  cursor: pointer;
}

.bill__create:hover {
  border-color: black;
  color: black;
}

.bill__create--icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  margin-right: 20px;
  border: 2px solid #c3c2c9;
  border-radius: 100%;
  transition: all 0.2s linear;
}

.bill__create--icon::after,
.bill__create--icon::before {
  content: "";
  width: 16px;
  height: 2px;
  background-color: #c3c2c9;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: all 0.2s linear;
}

.bill__create--icon::before {
  transform: translate(-50%, -50%) rotate(90deg);
}

.bill__create:hover .bill__create--icon {
  border: 2px solid black;
}

.bill__create:hover .bill__create--icon::after,
.bill__create:hover .bill__create--icon::before {
  background-color: black;
}

.add-bill__wrapper {
  display: flex;
  flex-flow: row-reverse;
}

.add-bill__item-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.add-bill__item {
  display: flex;
  flex-direction: column;
  width: calc(50% - 15px);
}

.add-bill__item:nth-child(2) {
  margin-right: 15px;
}

.add-bill__form {
  width: 520px;
}

.add-bill__width_100 {
  width: 100%;
}

.br_5 {
  border-radius: 5px;
}

.bill__rouble--icon {
  margin-right: 20px;
}

.bill__rouble--icon::after {
  content: "₽";
  position: absolute;
  top: 34px;
  left: calc(50% - 48px);
  font-size: 12px;
}

.bill__rouble--icon::before {
  content: "";
  position: absolute;
  top: 23px;
  left: calc(50% - 16px);
  font-size: 12px;
  display: inline-block;
  width: 1px;
  height: 40px;
  background-color: #e8e8ec;
}

.add-bill__remove-level {
  display: inline-block;
  width: 17px;
  height: 18px;
  mask-image: url("../../img/delete-btn.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
  margin-top: 18px;
  transition: all 0.2s linear;
}

.add-bill__remove-level:hover {
  background-color: black;
}

.add-bill__nav {
  margin-left: 15px;
}

.add-bill__btn {
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
  transition: all 0.2s linear;
}

.add-bill__btn:hover {
  background-color: black;
  color: white;
}

.bill-remains {
  background: rgb(244 244 246 / 70%);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 20px 15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b85;
}

.bill-remains__value {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: black;
}

.bill__save {
  width: auto;
  padding: 20px 30px;
  margin-top: 0;
}

.bill__close {
  background-color: white;
  color: black;
  border-radius: 1px solid black;
  padding: 13px 30px;
}

.bill__close:hover {
  background-color: black;
  color: white;
}

.bills__items--checks {
  padding: 0;
}

.bill-image__wrapper-link {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin-top: 15px;
  display: inline-block;
  position: relative;
}

.bill-image__image {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-top: 15px;
}

.bill-image__image.mt_0 {
  margin-top: 0;
}

.check__wrapper {
  width: 20px;
  height: 16px;
  display: inline-block;

  &::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    mask-image: url("../../img/money-check-alt-solid.svg");
    background-color: #b4b3bb;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
    cursor: pointer;
    transition: all 0.2s linear;
  }
}

.check__wrapper:hover .check__wrapper::after {
  background-color: black;
}

.check__wrapper.active {
  &::after {
    background-color: black;
  }
}

.bill__info {
  display: flex;
  align-items: center;
  margin-right: 25px;
  width: 95px;
}

.bill-image__image img {
  width: 100%;
  height: 100%;
  display: flex;
}

.preview_update {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  background-color: #f4f4f6;
}

.preview_update::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  mask-image: url("../../img/cancel.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.bills-item__inner {
  list-style: none;
}

.bills-item__item {
  &:not(:first-child) {
    list-style: none;
    margin-top: 15px;
  }
}

.bill-error {
  color: #ff284f;
}

.bill-error__border {
  border: 1px solid #ff284f;
}

.bills-item__pay-info {
  display: flex;
  list-style: none;
  justify-content: space-between;

  &.edit-mode {
    flex-direction: column;
  }
}

.pay-info__item {
  width: calc(50% - 10px);
}

.add-layer {
  min-width: 100%;
  width: 100%;

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
  }
}

.bills-layers {
  width: 100%;
  min-width: 100%;

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  &__add-btn {
    margin-top: 20px;
    font-size: 12px;
    line-height: 12px;
    transition: all 0.2s linear;
  }
}

.bill-layer {
  display: flex;
  justify-content: space-between;
  height: auto;
  align-items: center;

  &:not(:first-child) {
    margin-top: 15px;
  }

  &__item {
    width: calc(50% - 18px);

    &:first-child {
      margin-right: 20px;
    }
  }

  &__name {
    font-size: 12px;
    line-height: 12px;
  }

  &__remove {
    display: inline-block;
    width: 17px;
    height: 18px;
    margin-left: 15px;
    top: 7px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      mask-image: url("../../img/delete.svg");
      background-color: #b4b3bb;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
      transition: all 0.2s linear;
      cursor: pointer;
    }

    &:hover {
      &::after {
        background-color: black;
      }
    }
  }
}

.bills-remains {
  background: rgb(244 244 246 / 70%);
  border-radius: 5px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  align-items: center;

  &__value {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: black;
  }
}

.pay-info__select {
  .platform__select {
    padding-left: 36px;
  }

  &.yandex {
    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("../../img/yooukassa.svg");
      background-size: 100%;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  &.other {
    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("../../img/m_other.svg");
      background-size: 100%;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  &.sber {
    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("../../img/m_sber.svg");
      background-size: 100%;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  &.western-union {
    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("../../img/m_westernunion.svg");
      background-size: 100%;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  &.paypal {
    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("../../img/m_paypal.svg");
      background-size: 100%;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  &.qiwi {
    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("../../img/m_qiwi.svg");
      background-size: 100%;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  &.bill {
    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("../../img/m_bill.svg");
      background-size: 100%;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}

.bill__payd {
  font-size: 12px;
  line-height: 12px;
}

.bill__exhibited-date {
  margin-left: 5px;
}

.bill__status {
  min-width: 30px;
  width: 30px;
  min-height: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: rgb(172 172 178 / 30%);
  position: relative;
  margin-right: 15px;

  &::after {
    content: "✓";
    position: absolute;
    width: max-content;
    height: max-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.active {
    background-color: #27ae60;

    &::after {
      color: white;
    }
  }
}

.bill__redemption {
  &::after {
    content: "";
    width: 16px;
    height: 17px;
    display: inline-block;
    margin-left: 10px;
    mask-image: url("../../img/work-icon.svg");
    background-color: white;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
    transition: all 0.2s linear;
  }
}
