$black: #161718;
$white: #fff;
$content-selector: rgb(196 196 196 / 40%);
$red: #ff284f;
$green: #27ae60;
$no-active-item: #9c9ba6;
$transparent: transparent;
$payment: #00a884;
$border-input: #c3c2c9;
$bill-text: #acacb2;
$client-acces-text: #636363;
$color-table-nav-items: #b4b3bb;
