.managers-monitor {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding-right: 40px;
  margin-left: 15px;
}

.managers-monitor__element {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: auto;
}
