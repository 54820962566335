@charset "UTF-8";
@import "../helpers/variables";

.funnels-funnel__wrapper {
  background-color: white;
  width: 520px;
  height: 100vh;
  flex-direction: row-reverse;
  flex: none;
}

.funnels-funnel__content {
  background-color: white;
  width: 520px;
  display: flex;
  flex-direction: column;
}

.funnels__wrapper {
  position: relative;
  background-color: rgb(0 0 0 / 50%);
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.funnels-funnel__item {
  width: 288px;
  height: 120px;
  background-color: white;
  margin-right: 20px;
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 4%);
  padding: 25px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s linear;
}

.funnels-funnel__delete {
  opacity: 0;
  pointer-events: none;
  width: 14px;
  height: 16px;
  mask-image: url("../../img/delete-btn.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
}

.funnels-funnel__item:hover {
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 20%);
}

.funnels-funnel__item:hover .funnels-funnel__delete {
  opacity: 1;
  pointer-events: all;
}

.funnels-funnel__item:nth-child(4n) {
  margin-right: 0;
}

.funnels-funnel__item:nth-child(n+5) {
  margin-top: 20px;
}

.funnels-funnel__select-type {
  width: 140px;
  background-color: white;
}

.funnels-panel__add {
  padding: 15px 20px;
  border: 1px solid black;
  border-radius: 8px;
  color: $white;
  background-color: $black;
  font-size: 12px;
  line-height: 12px;
  transition: all 0.2s linear;
}

.funnels-panel__add:hover {
  color: $black;
  background-color: transparent;
}

.funnels-funnel__title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.funnels-funnel__type {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-top: 10px;
  color: #b4b3bb;
  margin-bottom: 0;
}

.funnels-funnel__type::after {
  transform: translateY(-25%);
}

.funnels__panel {
  width: 1215px;
  margin-top: 20px;
  margin-left: 40px;
}

.funnels-add__content {
  display: flex;
  flex-direction: column;
}

.funnels__add-title {
  margin-top: 20px;
  margin-bottom: 0;
}

.funnels__add-title:first-child {
  margin-top: 0;
}

.funnels__add-input {
  margin-top: 10px;
}

.funnels__menu {
  padding: 0 15px;
}

.funnels-add__menu {
  flex-direction: column;
  overflow: auto;
}

.funnels-add__top {
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  box-shadow: 0 5px 25px rgb(7 2 14 / 4%);
}

.funnels-add__top h2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
}

.funnels-add__delete-border {
  position: fixed;
  bottom: -100%;
}

.funnels-add__save-border {
  position: fixed;
  bottom: -100%;
}

.funnels-add__nav {
  display: flex;
  justify-content: space-between;
  width: 1220px;
}

.funnels-add__funnel {
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 10px;
  background-color: transparent;
}

.funnels-add__funnel.add-funnel {
  margin-left: 15px;
  background-color: black;
  color: white;
}

.funnels-now__list {
  display: flex;
  flex-flow: row wrap;
  width: 1215px;
  margin-top: 20px;
  border-top: 1px solid #c4c4c433;
  padding-top: 20px;
  margin-bottom: 40px;
  margin-left: 40px;
}

.funnels-now__item {
  display: inline-flex;
  width: 290px;
  height: 240px;
  background-color: white;
  margin-right: 20px;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
  padding: 25px 25px 30px;
  box-shadow: 0 10px 20px rgb(8 15 32 / 4%);
  transition: all 0.2s linear;
}

.funnels-now__item:nth-child(4n) {
  margin-right: 0;
}

.funnels-now__item:nth-child(n+5) {
  margin-top: 20px;
}

.funnels-now__item:hover {
  box-shadow: 0 10px 20px rgb(8 15 32 / 20%);
}

.funnels-now__title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;
}

.funnels-plan__list {
  margin-top: 20px;
}

.funnels-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

.funnels-list__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.funnels-list__item:not(:first-child) {
  margin-top: 15px;
}

.funnels-item__title {
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
}

.funnels-item__value {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
}

.funnels__tabs {
  display: inline-flex;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 43px;
  border-bottom: 1px solid #9c9ba64d;
}

.funnels-tabs__item {
  color: #9c9ba6;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 15px;
  position: relative;
}

.funnels-tabs__item:first-child {
  margin-left: 0;
}

.funnels-tabs__item.active {
  color: black;
}

.funnels-tabs__item.active::after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: black;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.funnels-funnel {
  width: 1220px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.funnels-funnel__top {
  height: 70px;
  box-shadow: 0 5px 25px rgb(7 2 14 / 4%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

.funnels-funnel__head-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
}

.funnels-funnel__form {
  padding: 20px 25px;
}

.funnels-funnel__name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;
}

.funnels-funnel__name:nth-child(3) {
  margin-top: 20px;
}

.funnels-funnel__input {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  margin-top: 7px;
}

.funnels-funnel__close {
  top: 24px;
}

.funnels-control__btn {
  padding: 10px 30px;
  border-radius: 10px;
  color: black;
  border: 1px solid black;
  font-size: 12px;
  cursor: pointer;
}

.funnels-manager__checkbox {
  width: 0;
  height: 0;
  display: none;
  opacity: 0;
}

.funnels-menu__manager label {
  width: 100%;
  height: 40px;
  background-color: #9c9ba6;
  color: black;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.funnels-manager__checkbox:checked ~ label {
  background-color: black;
  color: white;
}

.funnel-settings {
  margin-right: 15px;
}

.funnel-settings:last-child {
  margin-right: 0;
}

.funnel-settings__wrapper {
  display: none;
  flex-direction: column;
  position: absolute;
  right: -135px;
}

.funnel-settings__button {
  display: inline-block;
  position: absolute;
  transform: translateX(15px);
  cursor: pointer;
}

.funnel-settings__remove {
  cursor: pointer;
}

.funnel-settings__edit {
  cursor: pointer;
}

.funnel-settings__rollup {
  cursor: pointer;
}

.funnel-manager__nav {
  display: flex;
  justify-content: space-between;
}

.funnel-manager__table {
  margin-top: 15px;
  margin-bottom: auto;
}

.menu-input__select-year {
  width: 190px;
  height: 40px;
  background-color: white;
}

.funnels-funnel__managers-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 10px;
}

.funnels-menu__manager {
  display: flex;
  width: calc(50% - 5px);
}

.funnels-menu__manager:nth-child(n+3) {
  margin-top: 10px;
}

.funnels-funnel__control {
  height: 80px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -5px 25px rgb(7 2 14 / 4%);
  padding: 0 40px;
  margin-top: auto;
  transform: translateX(-15px);
  margin-left: 15px;
}

.funnels-funnel__save {
  color: white;
  background-color: black;
  transition: all 0.2s linear;
}

.funnels-funnel__save:hover {
  box-shadow: 0 2px 5px 2px rgb(0 0 0 / 20%);
}

.funnels-funnel__cancel {
  transition: all 0.2s linear;
}

.funnels-funnel__cancel:hover {
  box-shadow: 0 2px 5px 2px rgb(0 0 0 / 20%);
}

.funnel-manager__title {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-align: left;
}

.funnels-funnel__head-row {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #b4b3bb;
  height: 30px;
}

.funnels-funnel__head-row td {
  text-align: center;
  align-items: center;
}

.manager-row {
  height: 65px;
  background-color: white;
  border-bottom: 1px solid #c4c4c4;
}

.funnel-manager__name {
  width: 220px;
  padding-left: 20px;
}

.funnels-edit__managers-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 10px;
}

.funnel-manager__head-row {
  background-color: transparent;
  height: 40px;
}

.funnel-manager__head-row td {
  text-align: center;
  color: #b4b3bb;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
}

.funnel__input {
  width: 120px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  height: 30px;
}

.manager-row td {
  width: 220px;
  text-align: center;
}

.funnel-manager__inner {
  display: flex;
  align-items: center;
}

.funnel-manager__settings {
  display: inline-block;
}

.funnel-manager__adv {
  position: relative;
}

.funnel-manager__adv::after {
  content: "";
  position: absolute;
  top: 27px;
  right: 56px;
  display: inline-block;
  width: 10px;
  height: 12px;
  mask-image: url("../../img/ruble.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.funnel-manager__adv input {
  padding: 0 18px 0 10px;
}

.funnel-manager__sales {
  position: relative;
}

.funnel-manager__sales::after {
  content: "шт";
  position: absolute;
  top: 22px;
  right: 57px;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.funnel-manager__sales input {
  padding: 0 30px 0 10px;
}

.funnel-manager__head-row td::after {
  display: none;
}

.funnel-manager__revenue {
  position: relative;
}

.funnel-manager__revenue::after {
  content: "";
  position: absolute;
  top: 27px;
  right: 56px;
  display: inline-block;
  width: 10px;
  height: 12px;
  mask-image: url("../../img/ruble.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.funnel-manager__revenue input {
  padding: 0 18px 0 10px;
}

.funnel-manager__tbody {
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 40%);
}

.manager-item {
  width: 880px;
  min-width: 880px;
  max-width: 880px;
  position: relative;
}

.manager-item:not(:first-child) {
  margin-top: 30px;
  border-top: 1px solid #c4c4c433;
  padding-top: 30px;
}

.funnels-add__inner {
  height: 100vh;
}

.additional-inner {
  width: 660px;
  min-width: 660px;
  max-width: 660px;
}

.manager-item__additional {
  width: 660px;
  min-width: 660px;
  max-width: 660px;
}

.additional-table {
  min-width: 660px;
}

.funnel-settings__button::after {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  mask-image: url("../../img/settings.svg");
  background-color: #9c9ba6;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
}

.funnel-settings__button:hover::after {
  background-color: black;
}

.funnel-settings__remove::after {
  content: "";
  display: inline-block;
  width: 17px;
  height: 20px;
  mask-image: url("../../img/delete-btn.svg");
  background-color: #9c9ba6;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
}

.funnel-settings__remove:hover::after {
  background-color: black;
}

.funnel-settings__edit::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  mask-image: url("../../img/edit.svg");
  background-color: #9c9ba6;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  margin-top: 5px;
}

.funnel-settings__edit:hover::after {
  background-color: black;
}

.funnel-settings__rollup::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  mask-image: url("../../img/undo.svg");
  background-color: #9c9ba6;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  margin-top: 5px;
}

.funnel-settings__rollup:hover::after {
  background-color: black;
}

.funnels-add__save-border,
.funnels-add__delete-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 50px);
  height: 80px;
  background-color: black;
  color: white;
  transform: translateX(-25px);
  transition: all 0.4s ease-out;
}

.funnels-edit__name {
  margin-bottom: 0;
}

.funnels-edit__name:nth-child(3) {
  margin-top: 15px;
}

.funnels-add__save-button,
.funnels-add__cancel-button,
.funnels-add__delete-button {
  display: inline-block;
  padding: 5px 15px;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  margin-left: 15px;
}

.funnels-add__delete-button,
.funnels-add__cancel-button {
  display: inline-block;
  padding: 5px 15px;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  margin-left: 15px;
  cursor: pointer;
}

.funnels-add__save-button:hover,
.funnels-add__cancel-button:hover,
.funnels-add__delete-button:hover {
  color: white;
}

.funnels-year {
  margin-left: 40px;
}

.funnels__delete-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 50px);
  height: 80px;
  background-color: black;
  color: white;
  transform: translateX(-25px);
  transition: all 0.4s ease-out;
  position: fixed;
  bottom: -100%;
}

.tunnel__form--content {
  width: 100%;
}

.tunnel-form__name {
  margin-bottom: 0;
}

.outside-links__wrapper {
  padding: 0 40px 20px;
}

.outside-links__content {
  padding: 20px 40px;
  background-color: transparent;
  min-height: 80px;
}

.outside-link {
  display: flex;
  background-color: #fff;
  height: 50px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.outside-link:not(:first-child) {
  margin-top: 5px;
}

.outside-link__link {
  display: flex;
  width: 33.3333%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.outside-link__name {
  width: 20%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.outside-link__icons {
  display: flex;
}

.outside-link__nav {
  display: flex;
}

.outside-link__action {
  display: flex;
  margin-right: 35px;
  align-items: center;
}

.outside-link__edit {
  display: inline-block;
  width: 19px;
  height: 19px;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
}

.outside-link__edit::after {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  mask-image: url("../../img/gear.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  content: "";
}

.outside-link__edit::before {
  content: "";
  display: inline-flex;
  width: 1px;
  height: 23px;
  background-color: #e4e5e8;
  position: absolute;
  top: -1px;
  right: -10px;
}

.outside-link__edit:hover::after {
  background-color: black;
}

.outside-link__remove {
  display: inline-block;
  width: 16px;
  height: 17px;
  position: relative;
  cursor: pointer;
}

.outside-link__remove::after {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  mask-image: url("../../img/delete-btn.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  content: "";
}

.outside-link__remove:hover::after {
  background-color: black;
}

.outside-link__toggle {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: black;
  cursor: pointer;
}

.outside-link__toggle::after {
  content: "";
  display: inline-flex;
  width: 10px;
  height: 5px;
  mask-image: url("../../img/arrow-down.svg");
  background-color: white;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.add-outside__content {
  height: 100%;
}

.outside-select {
  font-size: 12px;
  line-height: 12px;
}
