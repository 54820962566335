.tooltip-item {
  width: 24px;
  height: 24px;
  display: flex;
  background-color: #f4f4f6;
  color: #0a1b2c66;
  position: absolute;
  top: 20px;
  right: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2s linear;

  &:hover {
    background-color: black;
    color: white;
  }
}

.tooltip {
  position: fixed;
  background-color: #161718;
  font-size: 11px;
  border-radius: 5px;
  color: rgb(255 255 255 / 40%);
  z-index: 10;
  pointer-events: none;
  width: 210px;
  display: inline-block;
  padding: 10px;
  height: max-content;
  opacity: 0;
  transition: opacity 0.1s linear;

  &__copy {
    background-color: #27ae60;
    color: white;
    width: max-content;
    height: 30px;
    padding: 15px 50px 15px 30px;
    position: fixed;
    z-index: 1000;
    font-size: 12px;
    line-height: 12px;
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 30%);
    display: flex;
    align-items: center;
    top: 35px;
    right: -100%;

    &--icon {
      position: relative;
      right: -13px;

      &::after {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        mask-image: url("../../img/bills_check.svg");
        background-color: white;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 100% 100%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.tooltip-course__start {
    position: absolute;
    top: -100%;
    right: 50%;
    width: max-content;
    transform: translate(50%, -25px);
  }
}

.tooltip-content {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 30px;
  width: max-content;
  max-width: 230px;
  background-color: rgb(0 0 0 / 80%);
  height: max-content;
  padding: 10px 15px;
  border-radius: 8px;
  transition: opacity 0.2s linear;
}

.tooltip__table {
  min-width: 100%;
  width: 100%;
}

.tooltip__crm {
  height: auto;
}

.tooltip__card {
  position: absolute;
  top: -31px;
  right: 27px;
}

.tooltip__date {
  display: inline-block;
  width: 30%;
  min-width: 30%;
}

.tooltip__message {
  display: inline-block;
  width: 70%;
  min-width: 70%;
}

.tooltip__row--head {
  color: #fffc;
  font-size: 11px;
  line-height: 11px;
  display: inline-flex;
  width: 100%;
  min-width: 100%;
}

.tooltip__row {
  margin-top: 10px;
  min-width: 100%;
  display: inline-flex;
  font-size: 11px;
  line-height: 11px;
}

.tooltip__bill-date {
  opacity: 0;
  top: 26px;
  height: 43px;
}

.tooltip__task-date {
  opacity: 0;
  top: 66px;
  height: 43px;
}

.tooltip-item:hover .tooltip-content {
  opacity: 1;
}

.tooltip-estimation .c-tooltip__text {
  font-size: 10px;
  line-height: 12px;
  text-align: left;
}
