@import "../helpers/variables";

/* Страница со списком уроков */
.lessons-main {
  margin-top: 60px;
}

.lessons-item {
  background-color: white;
  padding: 30px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  color: inherit;
  box-shadow: 0 10px 20px rgb(8 15 32 / 4%);
  transition: all 0.2s linear;
}

.lessons-item__left {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.lessons-item__inner {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
}

.lessons-item__element:first-child {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  margin-right: 17px;
  display: flex;
  align-items: center;
}

.lessons-item__element:nth-child(2) {
  display: flex;
  flex-direction: column;
}

.lessons-item__btn {
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid black;
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  transition: all 0.2s linear;
}

.lessons-item__btn:hover {
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 20%);
}

.lessons-about__title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
}

.lessons-about__content {
  display: flex;
  margin-top: 15px;
}

.lessons-about__item {
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 30px;
  position: relative;
  align-items: center;
}

.lessons-about__item:first-child .lessons-about__icon {
  display: flex;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  position: relative;
}

.lessons-about__item:first-child .lessons-about__icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/on-checked.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.lessons-about__item:first-child::after {
  display: flex;
  width: 1px;
  height: 20px;
  content: "";
  background-color: rgb(191 192 196 / 30%);
  position: absolute;
  right: -15px;
}

.lessons-about__item:last-child {
  margin-right: 0;
}

.lessons-about__item:last-child .lessons-about__icon {
  width: 12px;
  height: 16px;
  margin-right: 12px;
  display: flex;
  position: relative;
}

.lessons-about__item:last-child .lessons-about__icon::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/document-icon.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.lessons-about__text {
  display: flex;
  align-items: center;
}

.lessons-element__btn {
  width: 200px;
  height: 40px;
  border: 1px solid #481e8c;
  color: #481e8c;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
}

.lessons-element__btn:hover {
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 20%);
}

.back {
  margin-top: 20px;
}

.block-header {
  margin-top: 20px;

  &__warning {
    background-color: rgb(231 231 231);
    color: black;
    padding: 13px 25px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  &__selector {
    width: 100%;
    height: 1px;
    background-color: $content-selector;
    margin: 30px 0;
  }
}

.warning__smile {
  font-size: 16px;
  margin-left: 5px;
}

.lessons-header {
  margin-bottom: 20px;
}

.module__lessons-closed {
  max-width: 320px;
  width: 100%;
  color: black;
  background: #ececee;
  padding: 15px 33px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: 0;
  border-radius: 5px;
}

.lessons-left__inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 200px);
}

.lessons__inner {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.lessons-element__text {
  padding: 10px 33px;
  border: 1px solid black;
  border-radius: 5px;
  color: black;
  font-size: 12px;
}

.lessons-item:hover {
  box-shadow: 0 10px 20px rgb(8 15 32 / 10%);
}

.title__modules {
  font-size: 24px;
  line-height: 24px;
}

.subtitle__modules {
  font-size: 16px;
  line-height: 16px;
  margin-top: 15px;
}

.lesson-video {
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
}

.lesson-video__inner {
  width: 100%;
  height: auto;
}

.lessons-text {
  a {
    word-break: break-all;
  }
}

@media (max-width: 1000px) {
  .body__header {
    display: none;
  }
}

@media (max-width: 916px) {
  .lessons-item__inner {
    flex-direction: column;
  }

  .module__lessons-closed {
    margin: 20px 0 0;
  }
}

@media (max-width: 861px) {
  .module__lessons-closed {
    display: inline-block;
    width: auto;
    padding: 10px 15px;
    min-width: 250px;
    max-width: 250px;
  }

  .lessons-item__left {
    width: 100%;
  }
}

@media (max-width: 673px) {
  .lessons-item__inner {
    display: flex;
    flex-direction: column;
  }

  .module__lessons-closed {
    width: 100%;
    margin-right: auto;
    margin-top: 27px;
    margin-left: auto;
  }

  .lessons-item__left {
    margin-right: auto;
  }

  .module__lessons-closed:nth-child(2) {
    margin-top: 20px;
  }
}

@media (max-width: 669px) {
  .modules-left {
    justify-content: flex-start;
  }
}

@media (max-width: 640px) {
  .lessons-element__text {
    display: none;
  }

  .lessons-left__inner {
    width: calc(100% - 40px);
  }

  .lessons-element__icon {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
  }

  .lessons-element__icon::before {
    position: relative;
    display: flex;
    content: "";
    width: 20px;
    height: 20px;
    mask-image: url("../../img/arrow-right.svg");
    background-color: black;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
  }

  .lessons-header {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0;
    text-align: left;
  }
}

@media (max-width: 525px) {
  .lessons-item {
    padding: 15px 13px 15px 15px;
  }

  .lessons-item__element:first-child {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-align: center;
  }

  .lessons-about__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    width: calc(100% - 5px);
  }

  .lessons-about__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
  }

  .lessons-about__content {
    margin-top: 8px;
  }

  .lessons-about__item {
    margin-right: 20px;
  }

  .lessons-about__item:first-child .lessons-about__icon {
    width: 16px;
    height: 16px;
  }

  .lessons-about__item:first-child::after {
    right: -10px;
  }

  .lessons-about__item:last-child {
    margin-right: 0;
  }

  .lessons-about__item:last-child .lessons-about__icon {
    width: 13px;
    height: 16px;
  }
}

@media (max-width: 385px) {
  .lessons-about__item {
    margin-right: 5px;
  }

  .lessons-about__item::after {
    display: none;
  }

  .lessons-about__text {
    font-size: 10px;
  }

  .lessons-about__icon {
    margin-right: 5px;
  }

  .lessons-about__item:last-child .lessons-about__icon {
    margin-right: 3px;
  }
}

@media (max-width: 349px) {
  .lessons-about__content {
    display: flex;
    flex-flow: wrap;
  }

  .lessons-item__element:first-child {
    margin-right: 9;
  }

  .lessons-about__item {
    margin-right: 10px;
  }

  .lessons-about__item:first-child .lessons-about__icon {
    margin-right: 5px;
  }

  .lessons-about__item:last-child .lessons-about___icon {
    margin-right: 5px;
  }

  .lessons-about__item:first-child::after {
    display: none;
  }
}

@media (max-width: 319px) {
  .lessons-about__content {
    display: flex;
    flex-direction: column;
  }

  .lessons-about__item:nth-child(2) {
    margin-top: 5px;
  }

  .lessons-about__item:first-child::after {
    display: none;
  }
}

/* Страница урока */
.content-element__header-wrapper {
  margin-left: 0;
  margin-right: 0;
}

.content-element__header-element {
  margin-left: 0;
}

@media (max-width: 1075px) {
  .content-element__side {
    padding-left: 30px;
  }

  .content-element__header-btn {
    margin-right: 0;
  }

  .content-element__header-element {
    margin-left: 0;
  }
}

@media (max-width: 825px) {
  .lesson-part__inner {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 491px) {
  .lesson-part__download {
    display: none;
  }

  .lesson-part__download-icon {
    width: 20px;
    height: 20px;
    position: relative;
    border: 1px solid;
  }

  .lesson-part__doc-btn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn__content_medium {
    min-width: 0;
    max-width: 40px;
    height: 40px;
  }
}

@media (max-width: 342px) {
  .module__lessons-closed {
    min-width: 0;
    max-width: none;
    width: 100%;
  }

  .lessons-left__inner {
    width: 100%;
  }
}
