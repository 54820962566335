@import "../../helpers/variables";

.auth {
  width: 100%;
  min-height: 100vh;
  min-width: min-content;
  position: relative;
  color: #fff;
  z-index: 1;
}

.auth-background {
  /* stylelint-disable-next-line */
  background-image: -webkit-linear-gradient(180deg, rgb(143 149 194 / 100%) 0%, rgb(167 137 185 / 100%) 100%);
  background-image: linear-gradient(180deg, rgb(143 149 194 / 100%) 0%, rgb(167 137 185 / 100%) 100%);
  display: flex;
  width: 100%;
  height: auto;
}

.auth::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  z-index: -1;
}

.auth-wrapper {
  width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  padding: 60px 0;
}

.auth-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 auto;
}

.auth-header__logo {
  display: flex;
  flex-direction: row;
  height: 40px;
}

.auth-logo {
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 156px;
  height: 40px;
  flex-shrink: 0;
}

.auth-logo_credu {
  background-image: url("../../img/credu-logo.svg");
}

.auth-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 0 auto;
}

.side-title {
  position: absolute;
  z-index: 1000;
  height: 100%;
  max-height: 73%;
  display: flex;
  align-items: center;
}

.side-title span {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: rgb(255 255 255 / 40%);
  white-space: nowrap;
  padding-left: 12px;
  transform: rotate(-90deg);
}

.side-title_left {
  left: 0;
  top: 10%;
  justify-content: flex-end;
  width: 160px;
}

.side-title_right {
  right: 0;
  top: 10%;
  justify-content: flex-start;
  width: 200px;
}

.auth-content__main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

.auth-title {
  font-size: 72px;
  line-height: 70px;
  margin-bottom: 33px;
  max-width: 550px;
}

.auth-subtitle {
  font-size: 24px;
  line-height: 34px;
  color: rgb(255 255 255 / 80%);
  max-width: 390px;
  padding-left: 20px;
}

.auth-img {
  position: relative;
  display: flex;
  flex-grow: 1;
}

.img-credu {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-35%);
}

.img-phone {
  transform: translateX(-20px);
}

.auth-img__wrapper {
  margin-top: 50px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.auth-content__side {
  display: flex;
  flex-direction: column;
  flex-basis: 470px;
  position: relative;
  padding-left: 90px;
}

.auth-content__form-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.auth-form {
  position: relative;
  width: 400px;
  background: #fff;
  border: 1px solid #f1f1f4;
  box-sizing: border-box;
  box-shadow: 0 8px 18px rgb(5 4 20 / 8%);
  border-radius: 12px;
  padding: 37px 40px 33px;
}

.auth-form__error {
  width: 100%;
  min-height: 16px;
}

.auth-form__error-msg {
  font-size: 13px;
  line-height: 16px;
  color: black;

  /* text-align: center; */
}

.auth-form__title {
  font-size: 30px;
  line-height: 34px;

  /* padding-left: 21px; */
  margin-bottom: 30px;
}

.auth-form__title_violet {
  color: #29006c;
}

.auth-form__title_black {
  color: black;
}

.auth-form__element {
  display: flex;
  flex-direction: column;
}

.auth-form__element_submit {
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 16px;
}

.auth-element__label {
  font-size: 16px;
  line-height: 18px;
  padding-left: 21px;
  margin-bottom: 9px;
  color: #7c7b85;
}

.auth-element__input {
  font-size: 12px;
  line-height: 16px;
  color: rgb(101 92 117 / 80%);
  padding: 19px 40px 14px 19px;
  margin-bottom: 20px;
  width: 100%;
  background: #fff;
  border: 1px solid #c3c2c9;
  box-sizing: border-box;
  border-radius: 3px;
  transition: border-color 0.4s;
}

.auth-element__input:active,
.auth-element__input:focus {
  border-color: #29006c;
}

.auth-element__input::placeholder {
  color: rgb(101 92 117 / 70%);
}

.auth-form__delim {
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background: rgb(195 194 201 / 40%);
}

.auth-element__button {
  outline: none;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border-radius: 5px;
}

.auth-element__button-value {
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  position: relative;
  padding-right: 25px;
}

.auth-element__button-value::before {
  content: "";
  position: absolute;
  background: url("../../img/arrow-right.svg") no-repeat;
  background-position: 0 0;
  flex-shrink: 0;
  width: 25px;
  height: 19px;
  top: 2px;
  right: -8px;
}

.auth-element__button:active {
  opacity: 0.9;
}

.auth-element__button_violet {
  background: #29006c;
  border: 1px solid #29006c;
}

.auth-element__button_black {
  background: black;
  border: 1px solid black;
}

.auth-element__button_light-violet {
  background: #29006c;
  border: 1px solid #29006c;
}

.auth-element__button_light-black {
  background: black;
  border: 1px solid black;
  cursor: pointer;
}

.auth-text {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  font-weight: 500;
}

.auth-text_absolute {
  position: absolute;
  width: 100%;
  bottom: 8%;
}

.auth-text_white {
  color: #fff;
}

.auth-text_big {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
}

.auth-link {
  color: #9c9ba6;
  text-decoration: underline;
  transition: color 0.2s linear;
}

.auth-link:hover {
  color: black;
}

.auth-link:hover,
.auth-link:active,
.auth-link:focus {
  opacity: 0.9;
}

.auth-text_white .auth-link {
  color: #fff;
}

.auth-element__password {
  position: relative;
}

.auth-element__eye-open {
  position: absolute;
  top: 16px;
  right: 16px;
  display: inline-block;
  width: 18px;
  height: 18px;
}

.auth-element__eye-open::after {
  content: "";
  width: 100%;
  height: 100%;
  display: inline-block;
  mask-image: url("../../img/eye-open.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
  transition: all 0.2s linear;
}

.auth-element__eye-open:hover::after {
  background-color: gray;
}

.auth-element__eye-closed {
  top: 16px;
  right: 16px;
  display: none;
  position: absolute;
  width: 18px;
  height: 18px;
}

.auth-element__eye-closed::after {
  content: "";
  width: 100%;
  height: 100%;
  display: inline-block;
  mask-image: url("../../img/eye-close.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
  transition: all 0.2s linear;
}

.auth-element__eye-closed:hover::after {
  background-color: gray;
}

.remember__text {
  font-size: 13px;
  line-height: 14px;
  margin-left: 10px;
  color: #7c7b85;
}

.auth-img-phone {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.auth-img-phone::after {
  content: "";
  position: absolute;
  background-image: url("../../img/auth-phone.png");
  background-repeat: no-repeat;
  background-size: 97%;
  background-position: -20px 50px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

input[type="text"]::-ms-clear {
  display: none;
}

/* удаление иконки отображения пароля в IE */
input[type="password"]::-ms-reveal {
  display: none;
}

.remember {
  flex-direction: row;
}

.auth-element__label--remember {
  position: relative;
  min-width: 125px;
  margin-bottom: 20px;
}

.auth-element__label--remember span {
  position: absolute;
  top: -2px;
  right: 0;
  transform: translateY(-7px);
}

@media only screen and (max-width: 1600px) {
  .auth-text_absolute {
    bottom: 6%;
  }
}

@media only screen and (max-width: 1120px) {
  .auth-text_absolute {
    display: none;
  }

  .auth-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    width: 100%;
  }
}

@media only screen and (max-width: 440px) {
  .auth-content__form-wrapper {
    padding: 0 20px;
  }

  .auth-content__main {
    width: 100%;
  }

  .auth-form {
    width: 100%;
    padding: 20px;
  }
}

@media only screen and (max-width: 350px) {
  .auth-form__element_submit {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
