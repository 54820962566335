.action-type {
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.action-reward,
.action-winner,
.action-consider,
.action-managers {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  div {
    display: inline-block;
    min-height: 0;
    height: auto;
  }
}

.action-row > .custom-table__body-col {
  height: 60px;
  display: flex;
  align-items: center;
}

.action-row-header {
  display: grid;
  width: 1215px;
  grid-template-columns: 80px 132px 130px 100px 80px 80px 73px 100px 100px 80px 80px;
  align-content: center;
  justify-content: space-around;
  justify-items: flex-start;
}

.action-row-header > .custom-table__body-col {
  display: flex;
  align-items: center;
}

.action-row {
  display: grid;
  grid-template-columns: 80px 132px 130px 100px 80px 80px 73px 100px 100px 80px 80px;
  width: 1215px;
  align-content: center;
  border-bottom: 0;
  justify-content: space-around;
  align-items: center;
  justify-items: flex-start;

  &:nth-child(2) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.btn_delete-action {
  margin-left: 25px;
}
