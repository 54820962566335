.platform-menu {
  display: flex;
  min-width: 70px;
  max-width: 70px;
  height: 100vh;
  background-color: white;
  position: relative;
  z-index: 3;
  box-shadow: 5px 0 25px rgb(7 2 14 / 5%);
  transition: min-width 0.2s ease-in-out;

  &__main {
    display: flex;
    flex-direction: column;
    width: 70px;
    position: relative;
    z-index: 10;
    box-shadow: 5px 0 25px rgb(7 2 14 / 5%);
  }

  &__sub {
    width: 310px;
    pointer-events: none;
  }

  &.open {
    .burger__row {
      transform: rotate(45deg);

      &::before {
        transform: rotate(-90deg);
      }
    }

    .platform-menu__sub {
      pointer-events: all;
    }
  }
}

.platform-menu__item.funnel::after {
  mask-image: url("../../img/nav-finance.svg");
  mask-size: 22px 22px;
}

.platform-menu__logo {
  display: flex;
  align-items: center;
  white-space: nowrap;
  pointer-events: none;
}

.platform-menu__list {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}

.platform-menu__item {
  color: #bfc0c4;
  white-space: nowrap;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  padding-top: 20px;

  &.settings::after,
  &.marketing::after {
    content: "";
    display: inline-block;
    min-width: 70px;
    width: 70px;
    min-height: 24px;
    height: 24px;
    background-color: #bfc0c4;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 22px 22px;
    position: relative;
    transition: all 0.2s linear;
  }

  &:hover {
    color: black;

    &::after {
      background-color: $black;
    }

    .platform-menu__link::after {
      background-color: black;
    }
  }
}

.platform-menu__link.marketing {
  color: #bfc0c4;
}

.active.platform-menu__link.marketing {
  color: black;
}

.platform-submenu {
  position: relative;
  right: 0;
  min-width: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #7c7b85;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;

  &.open {
    min-width: 310px;
    height: 100vh;
    transition: min-width 0.2s ease-in-out;
  }

  &.open_t0 {
    min-width: 310px;
    transition: min-width 0s linear;
    height: 100vh;
  }

  &.height_100vh {
    height: 100vh;
  }
}

.platform-menu__item.settings::after {
  mask-image: url("../../img/nav-settings.svg");
  mask-size: 22px 22px;
}

.platform-menu__item.marketing::after {
  mask-image: url("../../img/nav-advertising-cabinet.svg");
  mask-size: 22px 22px;
}

.platform-menu__item.active {
  &::after {
    background-color: $black;
  }
}

.platform-menu__link,
.platform-menu_link {
  display: flex;
  transition: all 0.2s linear;
  flex-flow: row-reverse;
  align-items: center;
  justify-content: flex-end;
  color: inherit;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
}

.platform-menu__link:hover {
  color: inherit;
}

.platform-menu__link.dashboard::after,
.platform-menu__link.analytics::after,
.platform-menu__link.transactions::after,
.platform-menu__link.marketing::after,
.platform-menu__link.control::after,
.platform-menu__link.faq::after,
.platform-menu__link.erp::after,
.platform-menu_link.marketing::after,
.platform-menu__link.funnels::after,
.platform-menu__link.clients::after,
.platform-menu__link.homework::after,
.platform-menu__link.salary-item::after,
.platform-menu__link.bills-item::after,
.platform-menu__link.adv-cabinet::after,
.platform-menu__link.products-menu::after,
.platform-menu__link.team::after,
.platform-menu__link.settings::after {
  content: "";
  display: inline-block;
  min-width: 70px;
  width: 70px;
  min-height: 24px;
  height: 24px;
  background-color: #bfc0c4;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 22px 22px;
  position: relative;
  transition: all 0.2s linear;
}

.platform-menu__link.dashboard::after {
  mask-image: url("../../img/nav-dashboard.svg");
}

.platform-menu__link.salary-item::after {
  mask-image: url("../../img/nav-ruble.svg");
  mask-repeat: no-repeat;
}

.platform-menu__link.homework::after {
  mask-image: url("../../img/nav-check.svg");
}

.platform-menu__link.analytics::after {
  mask-image: url("../../img/nav-chart.svg");
  mask-size: 23px 22px;
}

.platform-menu__link.clients::after {
  mask-image: url("../../img/nav-clients.svg");
  mask-size: 22px 22px;
}

.platform-menu__link.funnels::after {
  mask-image: url("../../img/nav-funnel.svg");
  mask-size: 23px 22px;
}

.platform-menu__link.transactions::after {
  mask-image: url("../../img/nav-cart.svg");
  mask-size: 24px 22px;
}

.platform-menu__link.marketing::after {
  mask-image: url("../../img/nav-advertising-cabinet.svg");
  mask-size: 22px 22px;
}

.platform-menu__link.control::after {
  mask-image: url("../../img/nav-estimation-dialogs.svg");
  mask-size: 23px 22px;
}

.platform-menu__link.bills-item::after {
  mask-image: url("../../img/nav-bills-item.svg");
  mask-size: 19px 24px;
}

.platform-menu__link.products-menu::after {
  mask-image: url("../../img/nav-products.svg");
  mask-size: 22px 22px;
}

.platform-menu__link.team::after {
  mask-image: url("../../img/nav-team.svg");
  mask-size: 22px 22px;
}

.platform-menu__link.settings::after {
  mask-image: url("../../img/nav-settings.svg");
  mask-size: 22px 22px;
}

.platform-menu_link {
  color: #bfc0c4;
}

.platform-menu_link.active {
  color: black;
}

.platform-menu__link.active {
  &::after {
    background-color: $black;
  }
}

.platform-menu__logout {
  margin-top: auto;
}

.company__logo {
  min-width: 70px;
  max-width: 70px;
  width: 70px;
  max-height: 70px;
  height: 70px;
  min-height: 70px;
  display: inline-block;
}

.company__caption {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.company-caption__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #161718;
}

.company-caption__subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #7d7c85;
  margin-top: 5px;
}

.platform-submenu__item {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #c4c3c9;
  white-space: nowrap;

  &.title {
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0;
    text-align: left;
    color: #161718;
    height: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    width: 100%;

    span {
      width: 100%;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.platform-submenu__link {
  display: flex;
  padding: 20px;
  color: #c4c3c9;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;

  &::before {
    background-color: #c4c3c9;
  }

  &:hover {
    color: inherit;

    &::before {
      background-color: $black;
    }
  }
}

.platform-submenu__item:hover {
  color: black;
}

.platform-submenu__item.active {
  color: white;
  background-color: #282828 !important;
}

.submenu {
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.platform-logout__info {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
}

.platform-logout__desc {
  padding-bottom: 0;
  margin-top: 0;
  white-space: nowrap;
  margin-left: 15px;
}

.platform-logout__name {
  font-size: 14px;
  line-height: 14px;
}

.platform-logout__rating {
  font-size: 12px;
  line-height: 12px;
}

.platform-logout__item {
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  justify-content: flex-end;
  color: #bfc0c4;
  transition: all 0.2s linear;
}

.platform-logout__item:hover {
  color: black;
}

.platform-logout__item.exit::after {
  content: "";
  display: inline-block;
  min-width: 70px;
  width: 70px;
  min-height: 82px;
  height: 82px;
  mask-image: url("../../img/nav-exit.svg");
  background-color: #bfc0c4;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 22px 22px;
  position: relative;
  transition: all 0.2s linear;
}

.platform-logout__item:hover.exit::after {
  background-color: black;
}

.platform-logout__image {
  min-width: 42px;
  width: 42px;
  min-height: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 100%;
  margin-left: 14px;
}

.platform-logout__image img {
  width: 0;
  height: 0;
  margin-left: 0;
}

.platform-submenu__link.distribution-deals::before,
.platform-submenu__link.distribution-applications::before,
.platform-submenu__link.products-icon::before,
.platform-submenu__link.streams-icon::before,
.platform-submenu__link.users-icon::before,
.platform-submenu__link.calc-salary-icon::before,
.platform-submenu__link.schedule-icon::before,
.platform-submenu__link.plans-icon::before,
.platform-submenu__link.motivation-icon::before,
.platform-submenu__link.actions-icon::before,
.platform-submenu__link.main-icon::before,
.platform-submenu__link.contracts-icon::before,
.platform-submenu__link.payments-icon::before,
.platform-submenu__link.projects-icon::before,
.platform-submenu__link.acquiring-icon::before,
.platform-submenu__link.integrations-icon::before,
.platform-submenu__link.clear-clones-icon::before,
.platform-submenu__link.template-bills-icon::before,
.platform-submenu__link.banners-icon::before {
  content: "";
  display: inline-block;
  min-width: 15px;
  width: 15px;
  min-height: 15px;
  height: 15px;
  margin-right: 10px;
  mask-repeat: no-repeat;
  mask-position: center;
}

.platform-submenu__link.products-icon::before {
  mask-image: url("../../img/nav-products-catalog.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.streams-icon::before {
  mask-image: url("../../img/nav-products-streams.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.users-icon::before {
  mask-image: url("../../img/nav-settings-users.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.calc-salary-icon::before {
  mask-image: url("../../img/nav-team-salary.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.schedule-icon::before {
  mask-image: url("../../img/nav-team-graphic.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.plans-icon::before {
  mask-image: url("../../img/nav-team-plan.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.motivation-icon::before {
  mask-image: url("../../img/nav-team-motivation.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.actions-icon::before {
  mask-image: url("../../img/nav-team-actions.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.main-icon::before {
  mask-image: url("../../img/nav-settings-main.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.contracts-icon::before, {
  mask-image: url("../../img/nav-settings-contracts.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.payment-icon::before {
  mask-image: url("../../img/nav-settings-pm-bills.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.projects-icon::before {
  mask-image: url("../../img/nav-settings-projects.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.acquiring-icon::before {
  mask-image: url("../../img/nav-settings-pf-builder.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.integrations-icon::before {
  mask-image: url("../../img/nav-settings-integrations.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.clear-clones-icon::before {
  mask-image: url("../../img/nav-settings-clear-clones.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.template-bills-icon::before {
  mask-image: url("../../img/nav-settings-template-bills.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.banners-icon::before {
  mask-image: url("../../img/nav-settings-module-banner.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.payments-icon::before {
  mask-image: url("../../img/nav-settings-pm-bills.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.distribution-deals::before {
  mask-image: url("../../img/nav-adv-dist-deals.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.distribution-applications::before {
  mask-image: url("../../img/nav-adv-dist-applications.svg");
  mask-size: 100% 100%;
}

.platform-submenu__link.active {
  color: $black;

  &::before {
    background-color: $black;
  }
}

.tooltip-menu__item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $black;
  color: $white;
  position: fixed;
  z-index: 10000;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 3px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s linear;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -15px;
    display: block;
    border: 10px solid transparent;
    border-top: 10px solid black;
    transform: rotate(90deg) translateX(-50%);
  }
}

.tooltip-menu {
  position: relative;

  &:hover {
    .tooltip-menu__item {
      opacity: 1;
    }
  }
}

.avatar-image_default {
  background-image: url("../../img/default-avatar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: inline-block;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  margin-left: 0;
}

@media (max-width: 1000px) {
  .platform-menu {
    width: 100vw;
    min-height: 70px;
    height: 70px;
    max-width: 100%;

    &.open {
      min-height: 100vh;
      height: 100vh;
      max-width: 100%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding-right: 40px;
      box-shadow: 0 10px 10px 0 rgb(0 0 0 / 2%);
    }

    &__main {
      min-width: 100vw;
    }

    &__burger {
      display: inline-flex;
      width: 25px;
      height: 20px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .burger {
    position: relative;

    &__row {
      background-color: $black;
      width: 100%;
      height: 3px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 3px;
        background-color: $black;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 3px;
        background-color: $black;
      }
    }
  }

  .platform-submenu {
    &.open {
      width: 100%;
      height: max-content;
      box-shadow: inset 5px 5px 5px rgb(0 0 0 / 10%);
    }
  }
}

@media (min-width: 1000px) {
  .platform-menu {
    &.open {
      min-width: 380px;
      width: 380px;
    }
  }
}

@media (min-width: 1260px) {
  .platform__nav {
    width: 100%;
    padding: 0;
    height: auto;
  }

  .platform-form__header--update,
  .platform-form__header--bills {
    height: 70px;
    padding: 0 25px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .platform-form__title--value {
    display: inline-block;
    width: calc(100% - 23px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .platform-form__create-info {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    border-top: 1px solid #f1f1f3;
    padding-top: 20px;
    width: 100%;
  }

  .platform-form__item {
    margin: 20px 25px 0;
    font-size: 12px;
    line-height: 12px;
    position: relative;
    transition: all 0.2s linear;
    border: 1px solid transparent;
  }

  .platform-form__item.client {
    background-color: #e5e5e5;
  }

  .platform-form__item.client:hover {
    border: 1px solid black;
  }

  .platform-form__item.loader {
    font-style: italic;
    font-size: 12px;
    line-height: 12px;
    background-color: transparent;
  }

  .platform-form__item.loader:hover {
    border: 1px solid transparent;
  }

  .platform-form__item--price {
    display: flex;
    background-color: rgb(244 244 246 / 70%);
    border-radius: 5px;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 50px);
  }

  .platform-form__item--price::after {
    content: "₽";
    display: inline-block;
    width: 7px;
    height: 18px;
    position: absolute;
    color: inherit;
    top: 35px;
    right: 35px;
    pointer-events: none;
  }

  .platform-form__item--price.disabled::after {
    color: #c3c2c9;
  }
}
