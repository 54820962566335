.platform__toggle {
  display: inline-block;
  width: 40px;
  height: 20px;
  padding: 3.3px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: #ff284f;
}

.platform__thumb--access {
  width: 17px;
  height: 17px;
  position: relative;
  top: 1px;
  right: 5px;
  left: 1px;
}

.platform__toggle--access {
  display: inline-block;
  padding: 3.3px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: #ff284f;
  width: 50px;
  height: 25px;
}

.platform__toggle.active {
  background: #27ae60;
}

.platform__thumb {
  display: inline-block;
  background: #fff;
  box-shadow: 0 10px 20px rgb(8 15 32 / 20%);
  height: 13.3px;
  width: 13.3px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.platform__toggle.active .platform__thumb {
  transform: translateX(20px);
}

.platform__toggle--access.active .platform__thumb--access {
  transform: translateX(24px);
  pointer-events: none;
}

.platform__toggle--access.active {
  background-color: #27ae60;
}
