@import "../helpers/variables";

.check-payment {
  pointer-events: none;
}

.sortable-placeholder {
  width: 100%;
  height: 2px;
  margin: 10px 0 30;
  background: black;
  box-shadow: 0 0 10px rgb(72 30 140 / 30%);
}

.only-timepicker .datepicker--nav,
.only-timepicker .datepicker--content {
  display: none;
}

.only-timepicker .datepicker--time {
  border-top: none;
}

.datepickers-container {
  z-index: 10000;
}

.datepicker--buttons {
  display: none !important;
}

.content-main {
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
  max-width: 1215px;
}

.content-main__payment {
  flex-direction: column;
  align-items: flex-start;
}

.pagination {
  display: flex;
  flex: 1;
}

.content-main__payment .pagination {
  margin-right: auto;
  margin-left: auto;
}

.content-main__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.content-main__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.content-main_relative {
  position: relative;
  width: max-content;
}

.content-main_xl {
  min-width: 1515px;
  width: 1515px;
}

.container-table {
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 50px;
}

.container-table:last-child {
  margin-bottom: 0;
}

.content-main__panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1215px;

  &.settings {
    width: 970px;
  }
}

.advertising,
.sms-checks {
  max-width: 1215px;
}

.content-main__panel-side {
  display: flex;
  flex-direction: row;
}

.content-main__panel_col {
  flex-direction: column;
}

.common-monitor {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  padding-right: 40px;
}

.common-monitor__today {
  padding-top: 40px;
  width: 285px;
  display: flex;
  justify-content: center;
}

.today__text {
  font-size: 12px;
  line-height: 24px;
  color: #898a91;
}

.today__text_none {
  display: none;
}

.today__text_black {
  color: #161718;
}

.common-monitor__element {
  padding: 40px 40px 55px;
  background: #fff;
  border: 1px solid #f1f1f4;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-radius: 3px;
}

.block_full {
  flex: 1;
}

.monitor-element__header {
  font-size: 18px;
  line-height: normal;
  color: #161718;
}

.fact-block {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fact-block_start {
  justify-content: flex-start;
}

.fact-element {
  display: flex;
  flex-direction: column;
  min-width: 61px;
}

.fact-element__value {
  font-size: 36px;
  line-height: normal;
  margin-bottom: 15px;
  position: relative;

  &:first-child {
    .fact-element__value {
      padding-left: 0;
    }
  }
}

.fact-element__header {
  min-height: 17px;
  font-size: 12px;
  line-height: normal;
  color: #7c7b85;
  margin-bottom: 10px;
}

.fact-element__header_center {
  text-align: center;
}

.fact-element__value_black {
  color: #161718;
}

.fact-element__value_red {
  color: #ff284f;
}

.fact-element__value_green {
  color: lightgray;
}

.fact-element__value_yellow {
  color: #ffc000;
}

.fact-element__value_p20 {
  padding: 0 20px;
  white-space: nowrap;
}

.fact-element__plan-value {
  font-size: 18px;
  line-height: normal;
  color: #161718;
}

.fact-element__value_delim::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 1px;
  background: #e1e3e5;
}

.plan-block {
  display: flex;
  flex-direction: row;
}

.plan-element {
  display: flex;
  flex-direction: column;
}

.plan-element__value {
  font-size: 18px;
  line-height: normal;
  color: #161718;
  padding-left: 31px;
  padding-right: 31px;
}

.plan-element:first-child .plan-element__value {
  padding-left: 0;
}

.plan-element__header {
  min-height: 17px;
  font-size: 12px;
  line-height: normal;
  color: #7c7b85;
  margin-bottom: 10px;
}

.plan-element__value_red {
  color: #ff284f;
}

.plan-element__value_left {
  padding-left: 0;
}

.plan-element__delim {
  color: #e1e3e5;
  padding-right: 15px;
  padding-left: 15px;
}

.plan-element__value_pl0 {
  padding-left: 0;
}

.block_90 {
  min-width: 90px;
  width: 90px;
}

.block_230 {
  min-width: 230px;
  width: 230px;
}

.block_270 {
  min-width: 270px;
  width: 270px;
}

.block_320 {
  min-width: 320px;
  width: 320px;
}

.block_400 {
  min-width: 400px;
  width: 400px;
}

.block_500 {
  min-width: 500px;
  width: 500px;
}

.manager {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-bottom: 1px solid #e4e5e8;
  height: 52px;
}

.manager_values {
  justify-content: space-between;
  border-right: 1px solid #e4e5e8;
  padding: 0 40px;
}

.manager_name {
  border-right: 1px solid #e4e5e8;
}

.manager-id {
  width: 40px;
  text-align: center;
  font-size: 18px;
  line-height: normal;
  color: #bfc0c4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.manager-name {
  font-size: 12px;
  line-height: normal;
  color: #7d7c85;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}

.manager-value {
  width: 72px;
  height: 30px;
  border-radius: 3px;
  font-size: 12px;
  line-height: normal;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.manager-value_mlr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.manager-value_small {
  width: 52px;
}

.manager-value_large {
  width: 90px;
}

.manager-value_xmlarge {
  width: 94px;
}

.manager-value_slarge {
  width: 110px;
}

.manager-value_smlarge {
  width: 120px;
}

.manager-value_mlarge {
  width: 140px;
}

.manager-value_llarge {
  width: 155px;
}

.manager-value_xlarge {
  width: 180px;
}

.manager-value_text {
  color: #7c7b85;
  font-weight: bold;
  font-size: 17px;
}

.manager-value_gray {
  background: #f4f4f6;
  color: #7c7b85;
}

.manager-value_fact {
  font-weight: bold;
  font-size: 12px;
  height: 16px;
}

.manager-value_delim {
  height: 15px;
  margin: 0 2px;
  opacity: 0.3;
}

.manager-value__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.manager-value_plan {
  height: 12px;
}

.manager-value_type0 {
  background: #f4f4f6;
  color: #7c7b85;
}

.manager-value_type1,
.manager-value_type2,
.manager-value_type3 {
  background: #f4f4f6;
  color: #bfc0c4;
}

.manager-value_type4 {
  background: #27ae60;
  box-shadow: 0 2px 5px rgb(36 200 105 / 10%);
}

.manager-value_type5,
.manager-value_type6 {
  background: #07020e;
  box-shadow: 0 2px 5px rgb(36 200 105 / 10%);
}

.manager-value_type1 .manager-value_fact {
  color: #ff284f;
}

.manager-value_type2 .manager-value_fact {
  color: #ffa515;
}

.manager-value_type3 .manager-value_fact {
  color: #27ae60;
}

.manager-value_type4 .manager-value_fact,
.manager-value_type4 .manager-value_plan {
  color: #fff;
}

.manager-value_type5 .manager-value_fact {
  color: #ffa515;
}

.manager-value_type6 .manager-value_fact {
  color: #27ae60;
}

.manager-value_type5 .manager-value_plan,
.manager-value_type6 .manager-value_plan {
  color: #fff;
}

.manager-value_type10 {
  background: #f4f4f6;
  color: #7c7b85;
}

.manager-value_type11 {
  background: rgb(255 206 215 / 60%);
  color: #ff284f;
}

.manager-value_type12 {
  background: #f8da78;
  color: #a66c0f;
}

.manager-value_type13 {
  background: #88d3a7;
  box-shadow: 0 2px 5px rgba(36 200 105 / 10%);
  color: #3f614d;
}

.manager-value_type14 {
  background: #27ae60;
  box-shadow: 0 2px 5px rgb(36 200 105 / 10%);
  color: #134f2c;
}

.manager-value_type15 {
  background: #07020e;
  box-shadow: 0 2px 5px rgb(191 192 196 / 10%);
  color: #30e37c;
}

.manager-value_green {
  background: #27ae60;
  box-shadow: 0 2px 5px rgb(36 200 105 / 10%);
}

.manager-value_yellow {
  background: #ffa515;
  box-shadow: 0 2px 5px rgb(255 165 21 / 10%);
}

.manager-value_red {
  background: #ff284f;
  box-shadow: 0 2px 5px rgb(255 40 79 / 10%);
}

.manager-value_black {
  background: #161718;
  box-shadow: 0 2px 5px rgb(22 23 24 / 10%);
}

.manager-value_dgray {
  background: #f4f4f6;
  color: #7c7b85;
}

.manager-element__title {
  font-size: 18px;
  line-height: normal;
  color: rgb(22 23 24 / 40%);
  margin-bottom: 30px;
  padding-left: 40px;
  min-height: 21px;
}

.manager-element__header {
  display: flex;
  flex-direction: row;
  padding: 0 40px;
  margin-bottom: 10px;
  justify-content: center;
}

.manager-element__header_bg {
  background-color: #f4f4f6;
}

.header-element {
  font-size: 11px;
  line-height: normal;
  color: #b4b3bb;
  width: 72px;
  text-align: center;
  margin: 0 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-element_small {
  width: 52px;
}

.header-element_large {
  width: 90px;
}

.header-element_xmlarge {
  width: 94px;
}

.header-element_slarge {
  width: 110px;
}

.header-element_smlarge {
  width: 120px;
}

.header-element_mlarge {
  width: 140px;
}

.header-element_llarge {
  width: 155px;
}

.header-element_xlarge {
  width: 180px;
}

.lb {
  border-left: 2px solid #e4e5e8;
  z-index: 1;
}

.salary {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
}

.manager_values_p35 {
  padding: 0 35px;
}

.manager_values_p10 {
  padding: 0 10px;
}

.manager-element__header_p10 {
  padding: 0 10px;
}

.manager-value_m10 {
  margin: 0 9px;
}

.header-element_m10 {
  margin: 0 9px;
}

.salary_delim {
  margin-left: 0;
  margin-right: 0;
}

.salary_delim .managers-monitor__element::after {
  content: "";
  height: 1px;
  width: 100%;
  background: rgb(196 196 196 / 50%);
  position: absolute;
  left: 0;
  bottom: -53px;
}

.content-main_max {
  width: max-content;
}

.content-main_min {
  width: min-content;
}

.block-row {
  display: flex;
  flex-direction: row;
}

.styled-select {
  height: 40px;
  overflow: hidden;
  width: 200px;
  background: #fff;
  border: 1px solid #c3c2c9;
  box-sizing: border-box;
  border-radius: 3px;
}

.styled-select select {
  font-size: 15px;
  line-height: 16px;
  height: 40px;
  padding: 5px;
  width: 200px;
}

.column_pmethod-select option {
  background-color: #fff;
  color: #161718;
  padding-left: 15px;
  margin-bottom: 5px;
}

.staff-table__position option {
  display: flex;
  justify-content: center;
  text-align: center;
  text-align-last: center;
}

.styled-select select option {
  font-size: 15px;
  line-height: 16px;
}

.manager_name_center {
  justify-content: center;
}

.errors {
  margin-left: 15px;
  margin-right: 35px;
}

.error-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(5 4 20 / 60%);
  border-bottom: 1px solid #e4e5e8;
  height: 52px;
}

.error-line_max {
  width: max-content;
}

.errors_max {
  width: max-content;
}

.error-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 52px;
  font-size: 16px;
  line-height: normal;
  color: #b4b3bb;
}

.error-header_max {
  width: max-content;
}

.error-header_text {
  padding-left: 35px;
}

.error-header_count {
  padding-left: 20px;
  width: 120px;
}

.error-header_lines {
  padding-left: 20px;
}

.error-line_text {
  font-size: 18px;
  line-height: normal;
  color: #7d7c85;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  border-right: 1px solid #e4e5e8;
}

.error-line_name {
  font-size: 18px;
  line-height: normal;
  color: #7d7c85;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  border-right: 1px solid #e4e5e8;
}

.error-line_line {
  width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid #e4e5e8;
  padding-left: 20px;
  padding-right: 20px;
}

.error-line_count {
  width: 120px;
  min-width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid #e4e5e8;
  padding-left: 20px;
  padding-right: 20px;
}

.line-number {
  width: 40px;
  min-width: 40px;
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 3px;
  font-size: 12px;
  line-height: normal;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.error-count {
  width: 100%;
  height: 26px;
  border-radius: 3px;
  font-size: 12px;
  line-height: normal;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.error-line_lines {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.error-count_red {
  background: rgb(255 206 215 / 60%);
  color: #ff284f;
}

.line-number-value {
  font-weight: bold;
  font-size: 17px;
  height: 16px;
}

.error-col {
  width: 100%;
  height: 26px;
  border-radius: 3px;
  font-size: 12px;
  line-height: normal;
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
}

.error-col_green {
  background: #88d3a7;
  box-shadow: 0 2px 5px rgb(36 200 105 / 10%);
  color: #3f614d;
}

.error-col_red {
  background: rgb(255 206 215 / 60%);
  color: #ff284f;
}

.line-number_gray {
  background: #f4f4f6;
  color: #7c7b85;
}

.error-value {
  font-weight: bold;
  font-size: 17px;
  height: 16px;
}

.manager_name_pl20 {
  padding-left: 20px;
}

.error-header_line {
  padding-left: 20px;
  width: 140px;
}

.errors_delim {
  margin-bottom: 105px;
  position: relative;
}

.errors_delim .error-line::after {
  content: "";
  height: 1px;
  width: 100%;
  background: rgb(196 196 196 / 50%);
  position: absolute;
  left: 0;
  bottom: -53px;
}

.error-delim {
  height: 15px;
  margin: 0 2px;
  opacity: 0.3;
}

.error-l {
  height: 12px;
  overflow: hidden;
  text-overflow: clip;
}

.action-form__row {
  display: flex;
  flex-direction: row;
  margin-left: -18px;
  margin-right: -18px;
  margin-bottom: 30px;
}

.input-element {
  margin-left: 18px;
  margin-right: 18px;
}

.input-element__header {
  font-size: 16px;
  line-height: 18px;
  color: #b4b3bb;
  padding-left: 16px;
  margin-bottom: 9px;
}

.input-element__input {
  background: #fff;
  border: 1px solid #c3c2c9;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
  line-height: 16px;
  color: #161718;
  padding-left: 15px;
  height: 40px;
}

.input-element__input_l30 {
  margin-left: 30px;
}

.input-element__input_xxsmall {
  width: 90px;
}

.input-element__input_small {
  width: 130px;
}

.input-element__input_xsmall {
  width: 160px;
}

.input-element__input_medium {
  width: 100%;
}

.input-element__input_large {
  width: 300px;
}

.input-element__input_violet {
  border: 1px solid #29006c;
}

.input-element__input_black {
  border: 1px solid black;
}

.input-element__input_drop-message {
  padding: 0;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  background: transparent;
}

.input-element__input_row {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.input-element__input_row_xscroll {
  overflow-x: auto;
}

.input-element__radio-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f4f4f6;
  border-radius: 3px;
  padding: 0 20px;
  height: 40px;
}

.input-element__radio-wrapper_dark {
  background: rgb(212 212 219 / 80%);
}

.input-element__radio {
  display: flex;
  flex: 1;
  background: transparent;
}

.input-element__radio_big {
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.colored-circle {
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.colored-circle_dark {
  background-color: #161718 !important;
}

.colored-circle_light {
  background-color: #fff !important;
}

.colored-circle_default {
  background-color: #f4f4f6 !important;
}

.input-element__radio_small {
  width: 112px;
  height: 40px;
  border: 0;
  transform: translateY(-1px);
}

.input-element__radio-delim {
  width: 1px;
  height: 100%;
  background: black;
}

.radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px;
}

.radio + label {
  display: flex;
  flex: 1;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.radio ~ label {
  font-size: 11px;
  line-height: 16px;
}

.radio_circle:checked ~ label.circle::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: black;
}

.radio_circle ~ label,
.radio_circle-colored ~ label {
  position: relative;
  flex: unset;
  margin-top: 10px;
  margin-bottom: 10px;
}

.menu-search__input ~ label {
  content: "";
  display: block;
  position: absolute;
  top: 42px;
  right: 56px;
  width: 16px;
  height: 16px;
  mask: url("../../img/search.svg") no-repeat center;
  mask-size: auto 16px;
  background-color: #c3c2c9;
}

.menu-search__input_small + label {
  top: 13px;
  right: 12px;
}

.menu-search__input_search {
  padding: 14px 32px 9px 15px;
  height: 40px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
}

.menu-search__input_search ~ label {
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  transition: 0.4s;
  cursor: pointer;
}

.input-element__radio_list label {
  font-size: 12px;
  line-height: 16px;
  color: black;
}

.radio:checked ~ label {
  background-color: black;
  color: #fff;
}

.radio_circle:checked ~ label {
  background-color: transparent;
}

.circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #c3c2c9;
  transition: 0.4s;
  background-color: #fff;
  position: relative;
}

.radio_circle:checked ~ label.circle {
  border-color: black;
}

.radio_circle:checked ~ label::before {
  content: "";
  position: absolute;
  width: 36px;
  height: 36px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid black;
}

.radio_circle-colored:checked ~ label::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 2px solid black;
}

.input-element__radio-wrapper_dark .radio_circle-colored:checked ~ label::before {
  border-color: #f4f4f6;
}

.radio-mech:checked + label {
  background: black;
  color: #fff;
}

.radio + .label {
  position: relative;
}

.radio_neutral + .label {
  color: #471f8a;
  transition: 0.4s;
}

.radio_like + .label {
  color: #27ae60;
}

.radio_dislike + .label {
  color: #ff284f;
}

.radio_dislike + .label::after {
  content: "";
  width: 23px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ff284f;
  mask: url("../../img/dislike.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: 23px 20px;
  transition: 0.4s;
}

.radio_icon + .label {
  padding-left: 30px;
}

.radio_icon + .label::after {
  left: 14px;
  transform: translate(0%, -50%);
}

.radio_success:hover + .label,
.radio_success:checked + .label,
.radio_green:hover + .label,
.radio_green:checked + .label {
  background: #27ae60;
  border-color: #27ae60;
  color: #fff;
  transition: 0.4s;
}

.radio_fail + .label::after {
  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: black;
  mask: url("../../img/fail.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: 14px auto;
  transition: 0.4s;
}

.radio_success + .label::after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: black;
  mask: url("../../img/success.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: 16px auto;
  transition: 0.4s;
}

.radio_like + .label::after {
  content: "";
  width: 23px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 30px;
  background-color: #27ae60;
  mask: url("../../img/like.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: 23px 20px;
  transition: 0.4s;
}

.radio_success:hover + .label::after,
.radio_success:checked + .label::after {
  background-color: #fff;
}

.radio_fail:hover + .label,
.radio_fail:checked + .label,
.radio_red:hover + .label,
.radio_red:checked + .label {
  background: #ff284f;
  border-color: #ff284f;
  color: #fff;
  transition: 0.4s;
}

.radio_fail:hover + .label::after,
.radio_fail:checked + .label::after {
  background-color: #fff;
}

.radio_red,
.radio_green {
  color: #481e8c;
}

.label_unchecked {
  background: #29006c;
  color: #fff;
}

.label_success {
  background: #27ae60;
  border-color: #27ae60;
  color: #fff;
  position: relative;
}

.label_success::after {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  top: 11px;
  right: 19px;
  background: url("../../img/success.svg") no-repeat center;
  background-size: 13px 13px;
}

.label_success span {
  transform: translateX(-10px);
}

.label_fail {
  background: #ff284f;
  border-color: #ff284f;
  color: #fff;
  position: relative;
}

.label_fail::after {
  content: "";
  width: 11px;
  height: 11px;
  position: absolute;
  top: 13px;
  right: 20px;
  background: url("../../img/fail.svg") no-repeat center;
  background-size: 11px 11px;
}

.label_fail span {
  transform: translateX(-10px);
}

.input-element__input_transp {
  background-color: transparent;
}

.input-element__input_transparent {
  background-color: transparent;
  border: none;
}

.input-element__img {
  padding-left: 0;
  display: flex;
  height: 50px;
}

.input-element__img_border {
  border: 1px dashed #b4b3bb;
  box-sizing: border-box;
  border-radius: 5px;
  height: 80px;
  justify-content: center;
}

.input-element__submit {
  background: #29006c;
  border-color: #29006c;
  color: #fff;
  padding: 0 25px 0 23px;
}

.js-labelFile {
  display: flex;
  flex: 1;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
}

.js-upload-banner {
  padding-left: 60px;
  position: relative;
}

.js-upload-banner::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 50px;
  width: 50px;
  background-color: #b4b3bb;
  mask: url("../../img/upload-img.svg") no-repeat center;
  transform: translateY(-50%);
  mask-size: auto 50px;
  transition: 0.4s;
}

.js-upload-banner:hover::before,
.js-upload-banner.has-file::before {
  background-color: black;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file + .js-labelFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.js-labelFile_center {
  align-items: center;
}

.js-upload-file {
  display: block;
  width: 30px;
  height: 20px;
  mask: url("../../img/upload-bill.svg") no-repeat center;
  mask-size: auto 20px;
  background-color: #b4b3bb;
  transition: 0.4s;
  cursor: pointer;
}

.js-upload-file_small {
  width: 50px;
  height: 50px;
  mask-size: auto 16px;
}

.js-upload-file_xsmall {
  width: 30px;
  height: 30px;
  mask-size: auto 16px;
}

.js-upload-file:hover {
  background-color: black;
}

.action-form__row_violet {
  display: none;
  background: #e8e8ed;
  box-shadow: inset 0 0 20px rgb(0 0 0 / 30%);
  padding-top: 30px;
  padding-bottom: 25px;
}

.cb {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  padding: 0 13px;
  margin-bottom: 10px;
  background: #f4f4f6;
  border-radius: 5px;
}

.cb__checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.cb__label {
  display: flex;
  align-items: center;
}

.cb__checkbox ~ .cb__label {
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
}

.cb__text {
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: #161718;
  margin: 0 10px;
}

.cb__text_bordered::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  width: 1px;
  height: 18px;
  transform: translateY(-50%);
  background-color: rgb(22 23 24 / 40%);
}

.cb__checkbox ~ .cb__label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #b9babe;
  box-sizing: border-box;
  border-radius: 3px;
  transform: translateY(-50%);
}

.cb__checkbox:checked ~ .cb__label::before {
  background: #481e8c;
  border-color: #481e8c;
  content: "";
}

.cb__checkbox:checked ~ .cb__label::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 2px;
  height: 9px;
  width: 13px;
  background-color: #fff;
  mask: url("../../img/checked_icon.svg") no-repeat center;
  transform: translateY(-50%);
  mask-size: 13px 9px;
  transition: 0.4s;
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}

.checkbox_wo_margin {
  margin: 0;
}

.checkbox ~ .label-pk {
  position: relative;
  padding: 0 0 0 28px;
  cursor: pointer;
}

.checkbox:disabled ~ .label-pk {
  cursor: default;
}

.checkbox ~ .label-pk::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 38px;
  height: 26px;
  transform: translateY(-15%);
  background: #7d7c85;
  mask: url("../../img/rk.svg") no-repeat center;
  mask-size: 38px auto;
}

.checkbox:disabled ~ .label-pk::before {
  background: transparent;
}

.checkbox:checked ~ .label-pk::before {
  background: black;
}

.checkbox ~ .checkbox-label {
  position: relative;
  padding: 0 0 0 28px;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  transform: translateY(7px);
}

.checkbox:disabled ~ .checkbox-label {
  cursor: default;
}

.checkbox ~ .checkbox-label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #b9babe;
  box-sizing: border-box;
  border-radius: 3px;
  transform: translateY(-50%);
}

.checkbox_wo_margin ~ .checkbox-label::before {
  top: -8px;
}

.checkbox:checked ~ .checkbox-label::before {
  background: black;
  border-color: black;
  content: "";
}

.checkbox:checked ~ .checkbox-label::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 2px;
  height: 9px;
  width: 13px;
  background-color: #fff;
  mask: url("../../img/checked_icon.svg") no-repeat center;
  transform: translateY(-50%);
  mask-size: 13px 9px;
  transition: 0.4s;
}

.input-element__checkbox {
  margin-right: 15px;
  min-width: max-content;
  margin-bottom: 12px;
}

.input-element__checkbox_mid {
  height: 18px;
  margin-bottom: 0;
}

.action {
  margin-right: 35px;
  max-width: 1215px;
}

.history {
  max-width: 1215px;
}

.action_delim {
  margin-bottom: 105px;
  position: relative;
}

.list {
  max-width: 1215px;
}

.article-container {
  max-width: 1215px;

  &.settings {
    max-width: 970px;
  }
}

.plans-content {
  max-width: 1260px;
}

.transactions {
  max-width: 1260px;
}

.bills {
  max-width: 1260px;
  background-color: #fff;
  display: none;
}

.action_delim::after {
  content: "";
  height: 1px;
  width: 100%;
  background: rgb(196 196 196 / 50%);
  position: absolute;
  left: 0;
  bottom: -53px;
}

.white-table {
  margin-top: 50px;
}

.white-table_full {
  width: 100%;
}

.white-table__header::after {
  content: "";
  width: 100%;
  height: 17px;
  display: block;
}

.header__text {
  padding: 0 16px;
  font-size: 12px;
  line-height: 12px;
  color: #b4b3bb;
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

.white-table__body {
  background: #fff;
  border: 1px solid #f1f1f4;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-radius: 3px;
  font-size: 12px;
  line-height: 16px;
  color: #161718;
}

.body__row {
  height: 66px;
  border-bottom: 1px solid #e4e5e8;
}

.body__row_small {
  height: 45px;
}

.body__cell {
  padding-left: 16px;
  padding-right: 16px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  text-align: left;
  font-size: 12px;
}

.end-status {
  color: #7c7b85;
}

.action-info__title {
  font-size: 48px;
  line-height: 55px;
  white-space: nowrap;
}

.action-info__rating {
  display: flex;
  flex-direction: row;
  margin: 0 -15px;
}

.action-rating {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}

.action-rating__place {
  font-size: 12px;
  line-height: 16px;
  position: relative;
  margin-bottom: 8px;
  min-height: 16px;
}

.place_first,
.place_second,
.place_third {
  padding-left: 18px;
}

.place_first::before,
.place_second::before,
.place_third::before {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-60%);
}

.place_first::before {
  background: url("../../img/i-first.png") no-repeat center;
  background-size: 16px;
}

.place_second::before {
  background: url("../../img/i-second.png") no-repeat center;
  background-size: 16px;
}

.place_third::before {
  background: url("../../img/i-third.png") no-repeat center;
  background-size: 16px;
}

.action-rating__member {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.action-rating__value {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.action-info__type {
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid white;
  height: auto;
  padding: 0 8px;
  font-size: 12px;
  width: max-content;
  margin-bottom: 30px;
  margin-top: 15px;
}

.action-info__value {
  font-size: 32px;
  line-height: 37px;
  margin-bottom: 10px;
}

.action-info_prize-title {
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 8px;
}

.action-info_prize {
  font-size: 54px;
  line-height: 54px;
  text-align: center;
}

.action-info_prize_type2 {
  font-size: 50px;
  line-height: 50px;
}

.action-info_prize_type3 {
  font-size: 46px;
  line-height: 46px;
}

.action-carousel-caption {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.action-carousel__element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: max-content;
}

.action-carousel__element_r60 {
  margin-right: 60px;
}

.action-carousel__element_l42 {
  margin-left: 42px;
}

.action-element__header {
  font-size: 12px;
  line-height: 14px;
  color: rgb(124 123 133 / 60%);
  margin-bottom: 12px;
}

.action-element__wrapper {
  display: flex;
  flex-direction: row;
}

.action-element__el {
  display: flex;
  flex-direction: column;
}

.action-element__value {
  font-size: 32px;
  line-height: 37px;
  color: #29006c;
}

.action-element__desc {
  font-size: 12px;
  line-height: 14px;
  color: #7c7b85;
}

.action-element__delim {
  position: relative;
  margin-left: 5px;
  margin-right: 10px;
  font-size: 32px;
  line-height: 37px;
  color: #29006c;
}

.action-element__delim_dots::after {
  content: ":";
  position: absolute;
  top: 0;
  left: 0;
}

.action-element__delim_slash {
  margin-right: 20px;
}

.action-element__delim_slash::after {
  content: "/";
  position: absolute;
  top: 0;
  left: 0;
}

.action-form {
  margin-top: 50px;
}

.bg_gray {
  background-color: #f4f4f6;
}

.bg_white {
  background-color: #fff;
}

.white-table_half {
  width: 60%;
}

.manager-medal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20px;
}

.medal-img {
  margin-left: -8px;
  width: 16px;
  margin-top: -4px;
}

.manager-star {
  font-size: 18px;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20px;
  position: relative;
}

.manager-star_delim::before {
  content: "";
  width: 1px;
  height: 10px;
  position: absolute;
  top: 0;
  left: -15px;
  background: #e1e3e5;
}

.star-count {
  font-weight: bold;
  color: #ffa515;
}

.star-img {
  margin-left: -8px;
  width: 15px;
  margin-top: -4px;
}

.action-element__place {
  display: flex;
  font-size: 12px;
  line-height: 16px;
  color: #29006c;
  padding-left: 24px;
  position: relative;
}

.action-element__place_first {
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 15px;
}

.action-element__place_first::after {
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  bottom: -14px;
  left: 0;
  background: rgb(176 163 153 / 20%);
}

.action-element__place-wrapper {
  display: flex;
  flex-direction: row;
  margin-left: -19px;
  margin-right: -19px;
  margin-top: 18px;
}

.action-element__place_second,
.action-element__place_third {
  margin-left: 19px;
  margin-right: 19px;
}

.action-element__place_first::before,
.action-element__place_second::before,
.action-element__place_third::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: -4px;
  left: 0;
  background-size: 20px 20px;
}

.action-element__place_first::before {
  background: url("../../img/first-place.svg") no-repeat center;
}

.action-element__place_second::before {
  background: url("../../img/second-place.svg") no-repeat center;
}

.action-element__place_third::before {
  background: url("../../img/third-place.svg") no-repeat center;
}

.action-element__place-del {
  width: 1px;
  height: 10px;
  background: #b0a399;
  transform: translateY(2px);
}

.input-element__input:active,
.input-element__input:focus {
  border-color: black;
}

.input-element_right {
  margin-left: auto;
}

.action-main {
  background: #fff;
  border: 1px solid #f1f1f4;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-radius: 3px;
  margin-bottom: 40px;
  position: relative;
  min-height: 70px;
  margin-right: 40px;
  margin-left: 15px;
}

.action-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 5;
}

.action-info {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.action-ban {
  display: flex;
  width: 1110px;
  border-left: 1px solid #e4e5e8;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ban-img {
  width: 1044px;
  height: 200px;
  background: #c4c4c4;
  border-radius: 10px;
}

.action-carousel-indicators {
  margin-right: 30px;
  margin-left: 702px;
  margin-bottom: 3rem;
}

.info-timer {
  display: flex;
  justify-content: flex-end;
  height: 140px;
  border-bottom: 1px solid #f4f4f6;
}

.info-desc {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
}

.action-carousel-controls {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 72px;
  left: 42px;
  z-index: 10;
}

.action-carousel-control-prev,
.action-carousel-control-next {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f4f4f6;
  margin-right: 6px;
}

.action-carousel-control-prev-icon {
  background-image: url("../../img/prev.svg");
  display: inline-block;
  width: 14px;
  height: 10px;
  background-color: rgb(0 0 0 / 0%);
  background-position-x: 50%;
  background-position-y: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100% 100%;
  background-origin: padding-box;
  background-clip: border-box;
}

.action-carousel-control-next-icon {
  background-image: url("../../img/next.svg");
  display: inline-block;
  width: 14px;
  height: 10px;
  background-color: rgb(0 0 0 / 0%);
  background-position-x: 50%;
  background-position-y: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100% 100%;
  background-origin: padding-box;
  background-clip: border-box;
}

.action-collapse {
  position: absolute;
  top: 25px;
  left: 42px;
  z-index: 6;
}

.action-collapse__btn {
  font-size: 12px;
  line-height: 16px;
  color: #29006c;
  background: transparent;
  border: none;
  border-bottom: 1px dashed #29006c;
  position: relative;
}

.action-collapse__btn::after {
  content: "";
  background-image: url("../../img/down.svg");
  position: absolute;
  width: 15px;
  height: 8px;
  right: -22px;
  top: 3px;
}

.action-collapse__btn_up::after {
  content: "";
  background-image: url("../../img/up.svg");
  position: absolute;
  width: 15px;
  height: 8px;
  right: -22px;
  top: 3px;
}

.action-carousel-item {
  display: flex;
  flex-direction: row;
  height: 260px;
}

.rank-elements {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rank-element__wrapper {
  padding-left: 42px;
}

.rank-element {
  font-size: 12px;
  line-height: 16px;
  color: #29006c;
  padding-left: 24px;
  position: relative;
  margin-bottom: 14px;
}

.rank-element_first::before,
.rank-element_second::before,
.rank-element_third::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: -4px;
  left: 0;
  background-size: 20px 20px;
}

.rank-element_first::before {
  background: url("../../img/first-place.svg") no-repeat center;
}

.rank-element_second::before {
  background: url("../../img/second-place.svg") no-repeat center;
}

.rank-element_third::before {
  background: url("../../img/third-place.svg") no-repeat center;
}

.datepicker--cell.-selected- {
  border: none;
  background: inherit;
  background-color: black;
  color: white;
}

.datepicker--cell.-selected-.-current- {
  background: black;
  color: white;
}

.datepicker--cell.-selected-.-focus- {
  background: black;
  color: white;
}

.datepicker--cell {
  border-radius: 50%;
}

.datepicker--day-name {
  color: black;
}

.datepicker--cell.-current- {
  color: black;
}

.transaction__wrapper {
  border-top: 1px solid rgb(196 196 196 / 20%);
  margin-top: 20px;
}

.body__cell_full {
  max-width: unset;
}

.input-element__input_status {
  border: 1px solid black;
  box-sizing: border-box;
}

.input-element__input_status div:first-child {
  border-radius: 5px 0 0 5px;
}

.input-element__input_status div:last-child {
  border-radius: 0 5px 5px 0;
}

.input-element__radio_status {
  width: 60px;
  min-width: 60px;
}

.body__cell_small {
  max-width: 150px;
}

.body__cell_5p {
  max-width: 5%;
  min-width: 5%;
}

.body__cell_19p {
  max-width: 19%;
  min-width: 19%;
}

.body__cell_bordered {
  border-right: 1px solid #e4e5e8;
}

.body__cell_imaged {
  position: relative;
  padding-left: 35px;
}

.body__cell_imaged::before {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-56%);
  left: 0;
  border-radius: 50%;
  background: #e4e5e8;
  background-size: 20px 20px;
}

.body__cell_imaged-1::before {
  background: url("../../img/m_yandex.svg") no-repeat center;
}

.body__cell_imaged-2::before {
  background: url("../../img/m_qiwi.svg") no-repeat center;
}

.body__cell_imaged-3::before {
  background: url("../../img/m_sber.svg") no-repeat center;
}

.body__cell_imaged-4::before {
  background: url("../../img/m_webmoney.svg") no-repeat center;
}

.body__cell_imaged-5::before {
  background: url("../../img/m_paypal.svg") no-repeat center;
}

.body__cell_imaged-6::before {
  background: url("../../img/m_westernunion.svg") no-repeat center;
}

.body__cell_imaged-7::before {
  background: url("../../img/m_bill.svg") no-repeat center;
}

.body__cell_imaged-8::before {
  background: url("../../img/m_other.svg") no-repeat center;
}

.body__cell_yandex::before {
  background: url("../../img/m_yandex.svg") no-repeat center;
}

.body__cell_qiwi::before {
  background: url("../../img/m_qiwi.svg") no-repeat center;
}

.body__cell_sber::before {
  background: url("../../img/m_sber.svg") no-repeat center;
}

.body__cell_webmoney::before {
  background: url("../../img/m_webmoney.svg") no-repeat center;
}

.body__cell_paypal::before {
  background: url("../../img/m_paypal.svg") no-repeat center;
}

.body__cell_westernunion::before {
  background: url("../../img/m_westernunion.svg") no-repeat center;
}

.body__cell_bill::before {
  background: url("../../img/m_bill.svg") no-repeat center;
}

.body__cell_other::before {
  background: url("../../img/m_other.svg") no-repeat center;
}

.white-table_half-top {
  margin-top: 25px;
}

.button-filter {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.button-filter__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  color: #b4b3bb;
}

.transaction {
  display: flex;
  flex-direction: row;
}

.transaction__filter {
  margin-left: 15px;
  margin-right: 45px;
  padding-top: 60px;
}

.input-element__input_status-without-border {
  border-color: transparent;
}

.input-element__input_status-without-border .label_fail,
.input-element__input_status-without-border .label_success,
.input-element__input_status-without-border .label_unchecked {
  border-radius: 3px;
}

.btn_green {
  color: #fff;
  background: #27ae60;
}

.btn_green:hover {
  color: #fff;
}

.btn_red {
  color: #fff;
  background: #ff284f;
}

.btn_red:hover {
  color: #fff;
}

.input-element__input_violet-bord {
  border: 1px solid #29006c;
  padding-left: 0;
  font-size: 12px;
  line-height: 16px;
  color: #29006c;
}

.input-element__input_violet-bord:hover {
  background-color: #29006c;
  color: #fff;
}

.input-element__input_right {
  margin-left: auto;
}

.input-element__input_light-violet {
  border: 1px solid #481e8c;
}

.radio-check:checked + label {
  background: #481e8c;
  color: #fff;
}

.menu {
  transform: translate3d(0, 0, 1200);
  transition: 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  padding-left: 70px;
  overflow: auto;
  display: flex;
  background: rgb(4 7 36 / 40%);
  min-height: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
}

.menu.is-open {
  right: 0;
}

.menu__wrapper {
  flex: 1;
  display: flex;
  position: relative;
  background-color: rgb(0 0 0 / 50%);
  overflow: hidden;
  flex-direction: row-reverse;
}

.menu-hidden {
  overflow: hidden;
}

.menu-content__wrapper.is-selected {
  display: flex;
}

.body__row_channel {
  cursor: pointer;
}

.menu__wrapper_overflow-hidden {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.menu__header-wrapper {
  background: #fff;
  box-shadow: 0 5px 25px rgb(7 2 14 / 4%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
  z-index: 3;
  width: 100%;
  padding: 0 25px;
  position: relative;
  font-size: 20px;
}

.menu__header-wrapper_130 {
  min-height: 160px;
}

.menu__header-wrapper_not-bordered {
  box-shadow: unset;
}

.menu__header-wrapper_large {
  height: auto;
  min-height: auto;
  padding: 10px 0;
}

.menu-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu-header_start {
  justify-content: flex-start;
}

.menu-header__delim {
  width: 1px;
  margin: 0 10px;
}

.menu-header__delim span {
  display: block;
  width: 1px;
  height: 15px;
  background: #bfc0c4;
  position: relative;
  top: 2px;
}

.menu-header__title {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #161718;
  position: relative;
  width: 100%;
}

.menu-header__title.salary {
  margin-left: 0;
}

.menu-header__title_big {
  font-weight: 500;
  font-size: 32px;
  line-height: 41px;
}

.menu-header__title_margin {
  margin-top: 38px;
  margin-bottom: 19px;
}

.menu-header__title_edit {
  cursor: pointer;
  position: relative;
  padding-right: 20px;
  width: 100%;
}

.menu-header__title_edit .menu-header__title {
  width: 100%;
}

.menu-header__title_edit .menu-header__title:disabled {
  cursor: pointer;
}

.menu-header__title_edit::after {
  content: "";
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 14px;
  height: 14px;
  background-color: #b4b3bb;
  mask: url("../../img/edit.svg") no-repeat center;
  mask-size: 14px 14px;
  transition: 0.4s;
  display: none;
}

.menu-header__title_edit:hover::after {
  background-color: black;
}

.menu-header__close-back {
  mask: url("../../img/arrow-left.svg") no-repeat center;
  width: 20px;
  height: 15px;
  mask-size: 20px 15px;
}

.menu__content {
  display: flex;
  flex: 1;
  height: 100%;
}

.menu__content.client-list__add {
  flex-flow: row;
}

.client-list__add-form {
  width: 520px;
}

.menu__list {
  padding: 20px 25px;
  overflow-y: auto;
}

.menu__list_grow {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.menu__list_overflow-x {
  overflow-x: hidden;
}

.menu__list_gray {
  background: #f4f4f6;
}

.menu__content-side {
  width: 400px;
  min-height: 100%;
  max-height: 100vh;
  background: #fff;
  box-shadow: 5px 4px 25px rgb(7 2 14 / 5%);
  display: flex;
  flex-direction: column;
}

.menu__content-main {
  display: flex;
  flex: 1;
  padding: 32px;
  background-color: #f4f4f6;
  position: relative;
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.message__img {
  margin-bottom: 20px;
}

.message__title {
  font-size: 16px;
  line-height: 18px;
  color: #c3c2c9;
}

.menu-search {
  height: 100px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  position: relative;
  width: 100%;
}

.menu-search_small {
  height: auto;
  padding: 20px 25px;
  min-height: 100px;
}

.menu-search_bordered::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 1px;
  background-color: #f1f1f3;
}

.menu__footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  min-height: 80px;
  padding: 0 25px;
  background: #fff;
  box-shadow: 0 -5px 25px rgb(7 2 14 / 4%);
  align-items: center;
  z-index: 10;
}

.menu__footer_start {
  justify-content: flex-start;
}

.menu-search__title {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  padding-left: 15px;
  padding-bottom: 5px;
}

.menu-search__input {
  width: 100%;
  background: #fff;
  border: 1px solid #c3c2c9;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  padding: 14px 30px 10px;
  transition: 0.4s;
}

.menu-search__input:focus {
  border-color: black;
}

.menu-search__input_small {
  padding: 14px 30px 9px 14px;
  height: 40px;
}

.menu-search__wrapper {
  position: relative;
}

.menu-search_bottom-border::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
  position: absolute;
  bottom: -28px;
}

.menu-search__input_search.active ~ label {
  position: absolute;
  background-color: black;
}

.menu-search__input ~ label.clear {
  mask: url("../../img/fail.svg") no-repeat center;
  mask-size: auto 16px;
  background-color: #ff284f;
  position: absolute;
}

.menu-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #fff;
  overflow: auto;
  width: 100%;
}

.menu-content__wrapper {
  width: 100%;
  display: none;
  padding: 0 40px;
}

.input-element__input_cursor {
  cursor: pointer;
}

.circle::after {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  background-color: #29006c;
  top: 4px;
  left: 4px;
  border: 1px solid #29006c;
}

.body__row_channel:hover .circle::after,
.body__row_channel.active .circle::after {
  content: "";
}

.unchecked-counter {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: #b4b3bb;
}

.input-element__input_max {
  width: max-content;
}

.input-element__input_bg {
  background: transparent;
}

.input-element__link {
  display: flex;
  flex: 1;
}

.input-element__link.active {
  background-color: black;
}

.input-element__link a {
  padding: 0 33px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: black;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.input-element__link.active a {
  color: #fff;
}

.none {
  display: none !important;
}

.payment {
  width: auto;
}

th.header {
  cursor: pointer;
  position: relative;
  padding-right: 20px;
}

th.headerSortDown {
  position: relative;
}

th.headerSortDown::after {
  content: "";
  position: absolute;
  top: 2px;
  right: 0;
  display: inline-block;
  width: 15px;
  height: 8px;
  mask-image: url("../../img/sort-down.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

th.headerSortUp {
  position: relative;
}

th.headerSortUp::after {
  position: absolute;
  top: 2px;
  right: 0;
  display: inline-block;
  width: 15px;
  height: 8px;
  content: "";
  mask-image: url("../../img/sort-up.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.input-element_b20 {
  margin-bottom: 20px;
}

.input-element_t20 {
  margin-top: 20px;
}

.fact-element:first-child .fact-element__value_pl20 {
  padding-left: 20px;
}

.plan-element:first-child .plan-element__value_pl20 {
  padding-left: 20px;
}

.funnel-block__btn {
  font-size: 16px;
  line-height: 18px;
  color: #161718;
  cursor: pointer;
  position: relative;
}

.funnel-block__btn::after {
  content: "";
  width: 15px;
  height: 8px;
  position: absolute;
  right: 0;
  top: 4px;
  background: url("../../img/up-black.svg") no-repeat center;
  background-size: 100%;
}

.funnel-block__btn.collapsed::after {
  content: "";
  width: 15px;
  height: 8px;
  position: absolute;
  right: 0;
  top: 4px;
  background: url("../../img/down-black.svg") no-repeat center;
  background-size: 100%;
}

.input-element__input_disabled:disabled {
  background-color: white;
  color: #000;
}

.chart_mb60 {
  margin-bottom: 60px;
}

.chart-wrapper {
  height: 338px;
  background: #fff;
  border: 1px solid #f1f1f4;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-radius: 3px;
  padding: 30px 20px 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.chart-header {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #161718;
  margin-bottom: 26px;
}

.chart-legend__wrapper {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
}

.chart-legend__wrapper_negative {
  margin-left: -9px;
  margin-right: -9px;
  flex-wrap: wrap;
}

.chart-legend__input {
  font-size: 15px;
  line-height: 16px;
  height: 40px;
  padding: 5px;
  width: 200px;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #c3c2c9;
  box-sizing: border-box;
  border-radius: 3px;
}

.chart-legend__input_large {
  width: 300px;
}

.chart-legend__description {
  display: flex;
  flex-direction: row;
  margin-left: 9px;
  margin-right: 9px;
  min-height: 30px;
}

.chart-legend__line {
  width: 63px;
  height: 3px;
  margin-right: 24px;
  position: relative;
  display: flex;
  align-self: center;
}

.chart-legend__text {
  font-size: 12px;
  line-height: 16px;
  color: #161718;
  display: flex;
  align-self: center;
}

.chart-legend__delim {
  margin-right: 24px;
  height: 38px;
  width: 1px;
  background: #c4c4c4;
  display: flex;
  align-self: center;
}

.chart-legend__delim_small {
  height: 30px;
  background: rgb(196 196 196 / 60%);
  margin-left: 14px;
  margin-right: 14px;
}

.chart-legend__line::after {
  content: "";
  position: absolute;
  top: -4px;
  right: -6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.chart-legend__line_violet,
.chart-legend__line_violet::after {
  background-color: #854cba;
}

.chart-legend__line_red,
.chart-legend__line_red::after {
  background-color: #ff284f;
}

.chart-legend__line_pink,
.chart-legend__line_pink::after {
  background-color: deeppink;
}

.chart-legend__line_light_blue,
.chart-legend__line_light_blue::after {
  background-color: #1db9f2;
}

.chart-legend__line_yellow,
.chart-legend__line_yellow::after {
  background-color: #ffa515;
}

.chart-legend__line_orange,
.chart-legend__line_orange::after {
  background-color: #ff4d00;
}

.chart-legend__line_blue,
.chart-legend__line_blue::after {
  background-color: #036cb5;
}

.chart-legend__line_dblue,
.chart-legend__line_dblue::after {
  background-color: #014576;
}

.chart-legend__line_lblue,
.chart-legend__line_lblue::after {
  background-color: #6789e5;
}

.chart-legend__line_green,
.chart-legend__line_green::after {
  background-color: #27ae60;
}

.chart-legend__line_dgreen,
.chart-legend__line_dgreen::after {
  background-color: #0d7138;
}

.chart-legend__line_lgreen,
.chart-legend__line_lgreen::after {
  background-color: #5ad790;
}

.chart-legend__line_ggreen,
.chart-legend__line_ggreen::after {
  background-color: #4bc0c0;
}

.chart-legend__line_bblue,
.chart-legend__line_bblue::after {
  background-color: #36a2eb;
}

.chart-pie {
  margin-bottom: 40px;
}

.chart-bar {
  margin-bottom: 40px;
}

.chart-wrapper_scroll {
  overflow-x: auto;
  overflow-y: hidden;
}

.canvas-wrapper {
  display: flex;
  flex-grow: 1;
  margin-top: 15px;
}

.chart-wrapper_scroll canvas {
  margin-right: 20px;
}

.chart-wrapper_scroll .chart-wrapper_m {
  min-width: 563px;
}

.chart-wrapper_scroll .chart-wrapper_w {
  min-width: 1831px;
}

.chart-wrapper_scroll .chart-wrapper_d {
  min-width: 6804px;
}

.custom-scroll {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: #e1e1e1 transparent;
  scrollbar-width: thin;
}

.custom-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
}

.custom-scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

.custom-scroll::-webkit-scrollbar-corner {
  background: 0 0;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: black;
  border: 0 #fff;
  border-radius: 3px;
}

.custom-scroll_white::-webkit-scrollbar-thumb {
  background: #fff;
  border: 0 #fff;
  border-radius: 3px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border: 0 #fff;
  border-radius: 3px;
}

.chart-wrapper_told {
  height: 500px;
}

.custom-tooltip {
  opacity: 1;
  position: absolute;
  z-index: 1001;
  background: rgb(0 0 0 / 70%);
  color: white;
  border-radius: 3px;
  transition: all 0.1s ease;
  pointer-events: none;
  transform: translate(-50%, 0);
}

.custom-tooltip_translate-none {
  transform: translate(0, 0);
}

.custom-tooltip__color {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  transform: translateY(1px);
}

.custom-tooltip__value {
  display: inline-block;
  font-size: 12px;
  font-style: normal;
  color: #fff;
  min-width: 75px;
  width: 75px;
  margin-right: 15px;
}

.custom-tooltip__value_large {
  width: max-content;
}

.custom-tooltip__description {
  display: inline-block;
  font-size: 12px;
  font-style: normal;
  color: #fff;
  white-space: nowrap;
}

.custom-tooltip__title {
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  color: #fff;
  margin-bottom: 6px;
}

.custom-tooltip__wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.btn_delete {
  border: none;
  outline: none;
  width: 20px;
  height: 25px;
  mask: url("../../img/delete.svg") no-repeat center;
  mask-size: auto 20px;
  background-color: #b4b3bb;
}

.btn-information {
  border: none;
  outline: none;
  width: 20px;
  height: 25px;
  mask: url("../../img/nav-err.svg") no-repeat center;
  mask-size: auto 20px;
  background-color: #b4b3bb;
}

.btn-information_l {
  width: 25px;
  height: 30px;
  mask-size: 25px auto;
}

.btn-information:hover,
.btn_delete:hover {
  background-color: black;
}

.body__cell_left-p-0 {
  padding-left: 0;
}

.theme-wrapper {
  margin: 25px 15px 0 0;
  width: 1180px;
}

.about-wrapper {
  margin-left: 0;
}

.theme-wrapper_row {
  display: flex;
  flex-direction: row;
}

.theme-wrapper_first {
  margin-top: 50px;
}

.theme__title-wrapper {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.theme__title-wrapper_b20 {
  margin-bottom: 20px;
}

.theme__lessons {
  position: relative;
  padding-bottom: 19px;
  margin-bottom: 40px;
}

.theme__lessons::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d7d8e3;
}

.theme__img {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.theme-title {
  font-size: 24px;
  line-height: 24px;
  color: #161718;
  position: relative;
  margin-right: 20px;
}

.theme-title_delim::after {
  content: "";
  position: absolute;
  top: 12px;
  right: -20px;
  width: 1px;
  height: 20px;
  background: #b4b4bc;
  transform: translateY(-50%);
}

.theme-title_delim--about::after {
  top: 13px;
}

.theme-setting {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: relative;
  margin-top: 5px;
  margin-left: 9px;
}

.theme-setting::after {
  content: "";
  mask: url("../../img/gear.svg") no-repeat center;
  mask-size: auto 22px;
  background-color: #bfc0c4;
  transition: 0.4s;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.theme-setting:hover::after {
  background-color: black;
}

.mt_0 {
  margin-top: 0;
}

.mt_4 {
  margin-top: 4px;
}

.mt_1 {
  margin-top: 1px;
}

.mt_10 {
  margin-top: 10px;
}

.theme-setting__bookmark {
  transform: translate(8px, -4px);
}

.theme-setting_violet {
  background-color: #29006c;
}

.theme-setting_top {
  margin-top: auto;
  margin-bottom: auto;
}

.theme-setting_big {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.theme-setting_absolute {
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
}

.theme-setting_m {
  margin-left: 0;
}

.theme__articles {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-flow: row wrap;
}

.article {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.article:nth-child(n+5) {
  margin-top: 20px;
}

.article-wrapper {
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.article-preview {
  position: relative;
}

.article__title {
  font-size: 14px;
  line-height: 14px;
  color: rgb(22 23 24 / 40%);
  margin-bottom: 10px;
}

.article__preview {
  position: relative;
  box-shadow: 0 8px 12px rgb(2 3 27 / 10%);
  background: #c4c4c4;
  border-radius: 3px;
  overflow: hidden;
  display: block;
  height: 220px;
}

.article__img {
  width: 100%;
  height: auto;
}

.article__img_modal {
  cursor: pointer;
}

.article__preview-hover {
  content: "";
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  background: rgb(59 59 59 / 50%);
  transition: 0.4s;
}

.article__preview-arrow {
  width: 20px;
  height: 15px;
  position: absolute;
  mask: url("../../img/arrow.svg") no-repeat center;
  mask-size: 20px 15px;
  background-color: #fff;
  opacity: 0;
  top: 20px;
  right: 20px;
}

.article-preview:hover .article__preview-hover,
.article-preview:hover .article__preview-arrow {
  opacity: 1;
}

.article__link-wrapper {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0;
}

.article-preview:hover .article__link-wrapper {
  opacity: 1;
}

.article__link {
  width: 100%;
  background: black;
  border-radius: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  min-height: 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  transition: 0.4s;
}

.article__btn-link {
  width: 100%;
  margin-top: 10px;
  background: rgb(255 255 255 / 10%);
  border: 2px solid #fff;
  border-radius: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  min-height: 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  transition: 0.4s;
}

.article__btn-link:hover,
.article__link:hover {
  background: #fff;
  color: black;
}

.td-cell {
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.td-cell_l {
  max-width: 350px;
}

.ql-editor {
  background-color: #fff;
  color: rgb(22 23 24 / 40%);
  padding: 20px;
  overflow-y: unset;
}

.ql-editor p {
  font-size: 16px;
  line-height: 18px;
}

.editor-form {
  width: 960px;
  height: 480px;
  display: flex;
  flex-direction: column;
}

.editor-input {
  font-size: 30px;
  line-height: 34px;
  color: #161718;
  margin-bottom: 20px;
}

#editor {
  margin-bottom: 20px;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  height: 30px;
}

.btn_editor {
  width: 110px;
  min-height: 30px;
}

.ql-editor h1,
.article__text h1 {
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 15px;
}

.ql-editor h2,
.article__text h2 {
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 15px;
}

.ql-editor h3,
.article__text h3 {
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 15px;
}

.ql-editor p,
.article__text p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 15px;
}

.ql-editor h1.ql-align-center,
.ql-editor h2.ql-align-center,
.ql-editor h3.ql-align-center,
.ql-editor p.ql-align-center,
.article__text h1.ql-align-center,
.article__text h2.ql-align-center,
.article__text h3.ql-align-center,
.article__text p.ql-align-center {
  text-align: center;
}

.ql-editor h1.ql-align-right,
.ql-editor h2.ql-align-right,
.ql-editor h3.ql-align-right,
.ql-editor p.ql-align-right,
.article__text h1.ql-align-right,
.article__text h2.ql-align-right,
.article__text h3.ql-align-right,
.article__text p.ql-align-right {
  text-align: right;
}

.ql-editor strong,
.article__text strong {
  font-weight: bolder;
  color: black;
}

.ql-editor ul,
.article__text ul {
  margin-bottom: 15px;
  list-style-type: none;
}

.ql-editor ul li,
.article__text ul li {
  padding-left: 30px;
  font-size: 16px;
  line-height: 18px;
  position: relative;
}

.ql-editor ul li::before,
.article__text ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #29006c;
  position: absolute;
  left: 0;
  top: 7px;
}

.editor__img-loader {
  margin-bottom: 20px;
}

.theme__text-wrapper {
  width: 900px;
  border-radius: 5px;
  overflow: hidden;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
}

.title-wrapper__text {
  display: flex;
  margin-right: 20px;
}

.close:not(:disabled):not(.disabled):hover {
  color: #29006c;
}

.modal-header {
  padding: 20px;
  font-size: 26px;
  line-height: 30px;
  color: rgb(22 23 24 / 40%);
  border: none;
}

.modal {
  background: rgb(4 7 36 / 40%);
}

.modal-body {
  padding: 20px;
  font-size: 16px;
  line-height: 18px;
  color: rgb(22 23 24 / 40%);
}

.modal-body_l {
  height: 300px;
}

.modal-body_scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

s #editor {
  min-height: 300px;
}

.table_update {
  display: flex;
  flex-direction: row;
}

.drop-message {
  width: 100%;
  height: 90px;
  background-color: rgb(0 0 0 / 80%);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 49;
  justify-content: center;
  align-items: center;
  display: none;
}

.drop-message_index {
  z-index: 99999;
}

.drop-message.active {
  display: flex;
}

.drop-message__title {
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  margin: 0 20px;
}

.reports {
  width: 1260px;
}

.button_not-allowed:disabled {
  cursor: not-allowed;
  background: #f4f4f6;
  color: #b4b3bb;
  border-color: #f4f4f6;
}

.button_icon {
  padding-left: 61px;
}

.button_debate {
  border-color: transparent;
  cursor: default !important;
}

.button_red {
  border: 1px solid #ff284f;
  background: #fff;
  color: #ff284f;
}

.button_green {
  border: 1px solid #27ae60;
  background: #fff;
  color: #27ae60;
}

.button_hide {
  border: none;
  background: #fff;
  color: #b4b3bb;
}

.button_full {
  width: 100%;
  margin-bottom: 10px;
}

.button_full:last-child {
  margin-bottom: 0;
}

.button_circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  padding: 0;
}

.menu-input {
  height: 100px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  position: relative;
}

.menu-input_small {
  height: auto;
  padding: 20px 25px;
}

.menu-input_xsmall {
  height: auto;
  padding: 0 25px;
  margin-top: 20px;
}

.menu-input_lsmall {
  height: auto;
  min-width: 230px;
  width: 230px;
  padding: 20px 40px 0 0;
  background-color: transparent;
}

.menu-input_full {
  flex-basis: 100%;
  height: auto;
  padding: 10px 25px;
}

.menu-input_block-menu {
  flex-basis: 100%;
  padding: 10px 0;
  height: auto;
  margin: 0 9px;
  background: transparent;
}

.menu-input_f50 {
  flex-basis: calc(50% - 18px);
}

.menu-input_f30 {
  flex-basis: calc(30% - 18px);
}

.menu-input_f-auto {
  flex-basis: auto;
}

.menu-input__input_small {
  padding: 14px 30px 9px 14px;
  height: 40px;
  text-overflow: ellipsis;
}

.menu-input_block-menu .menu-input__input_small {
  background: #fff;
}

.menu-input_large {
  min-width: 480px;
  max-width: 480px;
}

.menu-input_xxsmall {
  height: auto;
  width: 210px;
  padding: 20px 20px 0 0;
  background-color: transparent;
}

.menu-input_xxxsmall {
  height: auto;
  width: 200px;
  padding: 20px 20px 0 0;
  background-color: transparent;
}

.menu-input_b0 {
  padding-bottom: 0;
}

.menu-input_t10 {
  padding-top: 10px;
}

.menu-input_t15 {
  padding-top: 15px;
}

.menu-input_t20 {
  padding-top: 20px;
}

.menu-input_t30 {
  padding-top: 30px;
}

.menu-input_bordered::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 1px;
  background-color: #f1f1f3;
}

.menu-input_client-bordered::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 470px;
  height: 1px;
  background-color: #f1f1f3;
}

.menu-input__title {
  font-size: 12px;
  line-height: 12px;
  color: black;
  padding-left: 15px;
  padding-bottom: 5px;
  min-height: 21px;
  white-space: nowrap;
}

.menu-input__title_black {
  font-weight: bold;
  color: #161718;
}

.menu-input__title_big {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #161718;
  position: relative;
  margin-bottom: 25px;
}

.menu-input__delim {
  width: calc(100% - 50px);
  display: block;
  height: 1px;
  background: #f1f1f3;
  margin: 0 25px;
}

.menu-input__delim_p30 {
  padding: 30px 25px;
}

.menu-input__vdelim {
  width: 1px;
  margin: 0 30px;
}

.menu-input__vdelim span {
  display: block;
  width: 1px;
  height: 40px;
  background: #bfc0c4;
}

.menu-input__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.menu-input__wrapper_wrap {
  margin: 0 -9px;
}

.menu-input__wrapper_row {
  flex-direction: row;
}

.course-setting__row {
  margin-left: 0;
  margin-bottom: 10px;
}

.course-setting__row .course-setting__input {
  margin-left: 0;
}

.course-setting__row:last-child {
  margin-bottom: 0;
}

.menu-input__wrapper_right {
  margin-left: auto;
}

.menu-input__wrapper_margin {
  margin: 0 20px;
}

.menu-input__wrapper_medium {
  width: 190px;
}

.menu-input__wrapper_mr {
  margin-right: 14px;
}

.menu-input__input {
  width: 100%;
  background: #fff;
  border: 1px solid #c3c2c9;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
  line-height: 12px;
  position: relative;
  padding: 14px 15px 10px;
  transition: 0.4s;
}

.menu-input__add {
  width: 100%;
  background: #fff;
  border: 1px solid black;
  border-radius: 3px;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.menu-input__add span {
  position: relative;
  color: black;
}

.menu-input__delete {
  width: 100%;
  background: #fff;
  border: 1px solid #ff284f;
  border-radius: 3px;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.menu-input__delete span {
  position: relative;
  color: #ff284f;
  padding-left: 20px;
}

.menu-input__delete span::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #ff284f;
  mask: url("../../img/delete.svg") no-repeat center;
  mask-size: auto 14px;
  transition: 0.4s;
  transform: translateY(-56%);
}

.menu-input__input:focus {
  border-color: black;
}

.menu-input__input_pswd {
  margin: 0 11px;
  width: 55px;
  padding: 0;
  height: 40px;
  z-index: 1;
  text-align: center;
}

.menu-input__input_pswd.active {
  border-color: #29006c;
}

.menu-input__input_color {
  padding: 0 2px;
  cursor: pointer;
}

.menu-comment {
  padding: 20px 25px 10px;
}

.menu-comment__header {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.menu-subtitle {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
}

.menu-subtitle_black {
  color: #161718;
}

.menu-comment__input {
  background: #f4f4f6;
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  padding: 20px 20px 20px 14px;
  border: 1px solid #f4f4f6;
  width: 100%;
}

.menu-comment__input_deal {
  height: 70px;
  max-height: 70px;
  font-size: 12px;
  line-height: 20px;
  color: #7c7b85;
  padding: 14px;
  overflow: hidden;
}

.menu-comment__input_deal-small {
  height: 50px;
  max-height: 50px;
}

.menu-comment__input_t30 {
  margin-top: 30px;
}

.menu-comment__input:-webkit-input-placeholder {
  color: #b4b3bb;
}

.menu__deal-wrapper {
  display: block;
  margin-right: 0;
  margin-left: 0;
  width: 950px;
  position: relative;
}

.deal {
  width: 269px;
  height: 590px;
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.4s;
  border: 1px solid #b4b3bb;
  margin: 0 10px 20px;
}

.deal_told {
  height: 570px;
}

.deal_low {
  height: 440px;
}

.deal_create::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  mask: url("../../img/plus.svg") no-repeat center;
  mask-size: auto 50px;
  background-color: #b4b3bb;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.deal_create {
  border: 1px dashed #b4b3bb;
  position: relative;
  cursor: pointer;
  display: block;
  width: 280px;
  margin: 0;
  background-color: transparent;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 20px;
  height: 450px;
}

.deal:hover {
  border-color: black;
}

.deal_create:hover::after {
  background-color: black;
}

.deal__btm-message {
  font-size: 16px;
  line-height: 18px;
  color: #b4b3bb;
  transition: 0.4s;
  display: flex;
  margin-bottom: 30px;
  margin-top: auto;
}

.deal_create:hover .deal__btm-message {
  color: black;
}

.deal__access-lessons {
  padding: 15px 0 18px;
  border-bottom: 1px solid #f1f1f3;
}

.deal__access {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.deal__access-text {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
}

.deal-status__text {
  width: 208px;
}

.menu__content-side_medium {
  width: 250px;
}

.menu__content-side_large {
  width: 520px;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
}

.menu__content-side_xlarge {
  width: 740px;
}

.menu__content-side_mlarge {
  width: 820px;
}

.menu__content-side_xxlarge {
  width: 1020px;
}

.menu__content-side_xxxlarge {
  width: 1040px;
}

.menu__content-side_full {
  width: 100%;
}

.menu__content-side_gray {
  background-color: #f4f4f6;
}

.menu__content_end {
  justify-content: flex-end;
  height: 100%;
}

.menu-input__input_cal {
  position: relative;
}

.menu-input__wrapper_cal::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 12px;
  height: 17px;
  width: 18px;
  mask: url("../../img/calendar.svg") no-repeat center;
  mask-size: auto 17px;
  background-color: #b4b3bb;
  transition: 0.4s;
  transform: translateY(-50%);
  pointer-events: none;
}

.menu-input__wrapper_close {
  cursor: pointer;
}

.menu-input__wrapper_cal:focus-within::after {
  background-color: black;
}

.menu-input__input_select {
  appearance: none;
  position: relative;
  cursor: pointer;
}

.menu-input__wrapper_select::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 14px;
  height: 8px;
  width: 15px;
  mask: url("../../img/arrow-down.svg") no-repeat center;
  mask-size: auto 8px;
  background-color: #b4b3bb;
  transition: 0.4s;
  transform: translateY(-50%);
  pointer-events: none;
}

.menu-input__wrapper_select.disabled::after {
  display: none;
}

.menu-input__wrapper_select:focus-within::after {
  background-color: black;
}

.menu__list_p0 {
  padding: 0;
  background-color: white;
  height: 100%;
}

.checkbox-label_gray {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
}

.menu-input_msmall {
  padding: 10px 40px 0;
  height: auto;
}

.menu-input_msmall.module-checkbox {
  height: 35px;
  padding: 0 25px;
}

.menu-input_s {
  padding: 10px 0;
}

.menu-input_m {
  padding: 10px 5px;
}

.menu-input_margin {
  margin: 0 20px;
  padding: 10px 0;
}

.menu-input_mt {
  margin-top: 21px;
  padding: 0;
}

.menu-input__wrapper_price {
  border-top: 1px solid #f1f1f3;
  border-bottom: 1px solid #f1f1f3;
  padding: 20px 0;
}

.menu-input__price-wrapper {
  background: #f4f4f6;
  border-radius: 10px;
  border: 1px solid #f4f4f6;
  width: 100%;
  display: flex;
  min-height: 70px;
  padding: 15px;
  justify-content: flex-start;
  align-items: center;
}

.menu-input__title_mr20 {
  margin-right: 20px;
  padding: 0 0 0 5px;
  display: flex;
  align-items: center;
}

.menu-input__input_xsmall {
  width: 120px;
  height: 40px;
  padding: 11px 30px 11px 14px;
}

.menu-input__input_xxsmall {
  width: 120px;
  height: 40px;
  padding: 11px 11px 11px 34px;
  border: none;
}

.menu-input__input_medium {
  width: 140px;
  height: 40px;
  padding: 11px 30px 11px 14px;
}

.menu-input__input_large {
  width: 200px;
  height: 40px;
  padding: 11px 30px 11px 14px;
}

.menu-input__input_textarea {
  min-height: 80px;
  resize: none;
}

.menu-input__input_textarea_disabled {
  background: #f4f4f6;
  border-color: #f4f4f6;
}

.menu-input_close {
  display: none !important;
}

.menu-input_close.is-open {
  display: block !important;
}

.deal_white {
  background: #fff;
  box-shadow: 0 8px 18px rgb(3 6 29 / 8%);
  border-radius: 5px;
  border: none;
}

.deal__wrapper {
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  padding: 0 20px 20px;
  display: flex;
  align-items: center;
}

.deal__wrapper_overflow {
  overflow: unset;
}

.deal__wrapper_column {
  flex-direction: column;
  align-items: flex-start;
}

.deal__wrapper_bordered::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1185px;
  height: 1px;
  background: #f1f1f3;
  bottom: 0;
}

.deal__subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.deal__kind {
  font-size: 12px;
  line-height: 16px;
  color: #29006c;
}

.deal__kind_del {
  padding-right: 12px;
  margin-right: 16px;
  position: relative;
}

.deal__kind_del::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 20px;
  width: 1px;
  background-color: #f1f1f3;
}

.deal__date {
  font-size: 12px;
  line-height: 16px;
  color: rgb(22 23 24 / 40%);
  padding-left: 22px;
  position: relative;
}

.deal__date::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 14px;
  width: 13px;
  background-color: #b4b3bb;
  mask: url("../../img/calendar.svg") no-repeat center;
  transform: translateY(-62%);
  mask-size: cover;
  mask-size: auto 14px;
  transition: 0.4s;
}

.deal__social {
  margin-right: 7px;
}

.deal-social {
  width: 25px;
  height: 25px;
  position: relative;
}

.deal-social::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
}

.deal-social_big {
  width: 30px;
  height: 30px;
  margin-right: auto;
  margin-left: auto;
}

.deal-social_big::after {
  width: 30px;
  height: 30px;
}

.deal-social_vk::after {
  background: url("../../img/vk.png") no-repeat center;
  background-size: cover;
}

.deal-social_te::after {
  background: url("../../img/telegram.png") no-repeat center;
  background-size: cover;
}

.deal-social_wa::after {
  background: url("../../img/wa.png") no-repeat center;
  background-size: cover;
}

.deal-social_fb::after {
  background: url("../../img/fb.png") no-repeat center;
  background-size: cover;
}

.deal-social_in::after {
  background: url("../../img/in.png") no-repeat center;
  background-size: cover;
}

.deal__manager {
  font-size: 12px;
  line-height: 16px;
  color: #161718;
}

.deal__sale-type {
  font-size: 12px;
  line-height: 16px;
  color: rgb(22 23 24 / 50%);
  margin-left: auto;
}

.deal__comment {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 42px;
}

.menu-comment__input_deal-small .deal__comment {
  white-space: nowrap;
}

.deal__sum {
  display: flex;
  flex-direction: column;
}

.deal__sum-title {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
}

.deal__sum-value {
  font-size: 24px;
  line-height: 28px;
  color: #161718;
}

.deal__sum-value_blue {
  color: #29006c;
}

.add-btn {
  width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  transition: border 0.2s linear 0s, background-color 0.2s linear 0s;
}

.add-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 10px;
  width: 10px;
  background-color: black;
  mask: url("../../img/small-plus.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: auto 10px;
}

.add-btn:hover {
  border: 1px solid #fff;
  background-color: black;
}

.add-btn:hover::after {
  background-color: #fff;
}

.deal__wrapper_between {
  justify-content: space-between;
}

.menu-input__wrapper_sum {
  position: relative;
}

.menu-input__checkbox-wrapper {
  padding-top: 14px;
}

.menu-input__wrapper_sum::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 14px;
  height: 17px;
  width: 8px;
  mask: url("../../img/ruble.svg") no-repeat center;
  mask-size: auto 17px;
  background-color: #b4b3bb;
  transition: 0.4s;
  transform: translateY(-50%);
  z-index: 10;
  pointer-events: none;
}

.menu-input__wrapper_sum:focus-within::after {
  background-color: black;
}

.menu-comment__header_bordered {
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
}

.menu-comment__header_bordered::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e8e8ec;
}

.menu-comment_pt30 {
  padding-top: 30px;
}

.bills.is-open {
  display: block;
}

.bills__wrapper {
  padding-right: 25px;
}

.custom-table {
  width: 100%;
  margin-top: 17px;
}

.transaction-manager {
  max-width: 1260px;
}

.custom-table__header {
  width: 1260px;
}

.custom-table__header-row {
  width: 100%;
  display: table-row;
  transform: translateY(-17px);
  background-color: transparent;
}

.custom-table_content {
  width: max-content;
}

.custom-table_inset {
  box-shadow: 0 8px 10px rgb(5 4 20 / 8%) inset, 0 -8px 10px rgb(5 4 20 / 8%) inset;
  margin: 0;
  padding: 0;
}

.custom-table_fixed {
  table-layout: fixed;
}

.custom-table__header-col {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  padding: 0 8px;
  white-space: nowrap;
  text-align: center;
}

.custom-table__header-col_gray {
  color: #b4b3bb;
}

.custom-table__header_bordered {
  border-bottom: 1px solid #e4e5e8;
}

.custom-table__header_bordered::after {
  height: 7px;
}

.custom-table__header-col_big {
  font-size: 12px;
  line-height: 12px;
  color: #b4b3bb;
  text-align: left;
}

.custom-table__header-col_large {
  width: 216px;
  min-width: 216px;
  padding-left: 32px;
}

.custom-table__header-col_emp {
  width: 200px;
  min-width: 200px;
}

.custom-table__header-col_medium {
  width: 116px;
  min-width: 116px;
}

.custom-table__header-col_small {
  width: 90px;
  min-width: 90px;
}

.custom-table__header-col_xsmall {
  width: 76px;
  min-width: 76px;
}

.custom-table__header-col_15per {
  width: 15%;
}

.custom-table__header-col_25per {
  width: 25%;
}

.custom-table__header-col_30per {
  width: 30%;
}

.bills-form {
  background: rgb(244 244 246 / 60%);
  flex-grow: 1;
}

.bills-form .menu-input {
  background: transparent;
}

.bills-form__wrapper {
  padding-bottom: 20px;
}

.estimation-work__list {
  justify-content: center;
}

.custom-table__body_white {
  background: transparent;
  box-sizing: border-box;
  border-radius: 3px;
}

.custom-table__body-row {
  height: 60px;
  position: relative;
  border-bottom: 1px solid #e4e5e8;
  background-color: white;
}

.custom-table__body-row_gray {
  background: rgb(244 244 246 / 50%);
}

.custom-table__body-row_large {
  height: 70px;
}

.custom-table__body-col {
  font-size: 12px;
  line-height: 12px;
  color: #161718;
  padding: 0 8px;
  text-align: left;
}

.check__text {
  display: flex;
  align-items: center;
}

.custom-table__body-col_emp {
  width: 200px;
  min-width: 200px;
}

.custom-table__body-col_full {
  width: 100%;
  min-width: 100%;
}

.custom-table__body-col_5per {
  width: 5%;
  max-width: 5%;
}

.custom-table__body-col_10per {
  width: 10%;
  max-width: 10%;
}

.custom-table__body-col_15per {
  width: 15%;
  max-width: 15%;
}

.custom-table__body-col_20per {
  width: 20%;
  max-width: 20%;
}

.custom-table__body-col_25per {
  width: 25%;
  max-width: 25%;
}

.custom-table__body-col_30per {
  width: 30%;
  max-width: 30%;
}

.custom-table__body-col_35per {
  width: 35%;
  max-width: 35%;
}

.custom-table__body-col_40per {
  width: 40%;
  max-width: 40%;
}

.custom-table__body-col_50per {
  width: 50%;
  max-width: 50%;
}

.custom-table__body-col_60per {
  width: 60%;
  max-width: 60%;
}

.custom-table__body-col_xxxlarge {
  width: 400px;
  min-width: 400px;
}

.custom-table__body-col_xxlarge {
  width: 250px;
  max-width: 250px;
}

.custom-table__body-col_xlarge {
  width: 180px;
  min-width: 180px;
}

.c {
  width: 160px;
  min-width: 160px;
}

.custom-table__body-col_xmedium {
  width: 150px;
  min-width: 150px;
}

.custom-table__body-col_medium {
  width: 125px;
  min-width: 125px;

  &.companies-column {
    min-width: 110px;
    width: 110px;
  }
}

.custom-table__body-col_lsmall {
  width: 104px;
  min-width: 104px;
}

.custom-table__body-col_small {
  width: 90px;
  min-width: 90px;
}

.custom-table__body-col_xsmall {
  width: 62px;
  min-width: 62px;
}

.custom-table__body-col_xxxsmall {
  width: 20px;
  min-width: 20px;
}

.custom-table__body-col_center {
  padding-left: 0;
  padding-right: 0;
}

.custom-table__body-col_r16 {
  padding-right: 16px;
}

.custom-table__body-col_p18 {
  padding: 18px;
}

.custom-table__body-col_r30 {
  padding-right: 30px;
}

.custom-table__body-col_green {
  color: #27ae60;
}

.custom-table__body-col_xlast {
  padding-right: 12px;
  padding-left: 0;
}

.custom-table__body-col_number {
  padding: 0;
}

.column-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.column_mwidth {
  width: 34px;
  height: 34px;
  position: relative;
  cursor: pointer;
}

.column_mwidth-large {
  width: 50px;
  height: 50px;
}

.column_mwidth:hover::after {
  background-color: black;
}

.column_mwidth-copy::before {
  width: 1px;
  height: 23px;
  content: "";
  display: block;
  position: absolute;
  background-color: #e4e5e8;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
}

.column_mwidth_bordered::before {
  width: 1px;
  height: 23px;
  content: "";
  display: block;
  position: absolute;
  background-color: #e4e5e8;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
}

.column_mwidth-accept::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 8px;
  width: 14px;
  background-color: #b4b3bb;
  mask: url("../../img/accept.svg") no-repeat center;
  mask-size: auto 8px;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.column_mwidth-accept::before {
  width: 1px;
  height: 23px;
  content: "";
  display: block;
  position: absolute;
  background-color: #e4e5e8;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
}

.column_mwidth-cancel::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 12px;
  width: 12px;
  background-color: #b4b3bb;
  mask: url("../../img/cancel.svg") no-repeat center;
  mask-size: auto 12px;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.column_mwidth-copy::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 14px;
  width: 14px;
  background-color: #b4b3bb;
  mask: url("../../img/copy.svg") no-repeat center;
  mask-size: auto 14px;
  transition: background-color 0.4s;
  transform: translate(-50%, -50%);
}

.column_mwidth-open::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 14px;
  width: 14px;
  background-color: #b4b3bb;
  mask: url("../../img/foreign.svg") no-repeat center;
  mask-size: auto 14px;
  transition: background-color 0.4s;
  transform: translate(-50%, -50%);
}

.column_mwidth-delete {
  background: transparent;
  border: none;
}

.column_mwidth-delete-check::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 16px;
  width: 30px;
  background-color: #27ae60;
  mask: url("../../img/check-list.svg") no-repeat center;
  mask-size: auto 16px;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.column_mwidth-add-check::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 16px;
  width: 30px;
  background-color: #b4b3bb;
  mask: url("../../img/check-list.svg") no-repeat center;
  mask-size: auto 16px;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.column_mwidth-edit::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  width: 20px;
  background-color: #b4b3bb;
  mask: url("../../img/nav-setting.svg") no-repeat center;
  mask-size: auto 20px;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.column_mwidth-edit::before {
  width: 1px;
  height: 23px;
  content: "";
  display: block;
  position: absolute;
  background-color: #e4e5e8;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
}

.column_mwidth-edit-b {
  width: 68px;
}

.column_mwidth-edit-b::before {
  display: none;
}

.column_mwidth-delete::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 18px;
  width: 16px;
  background-color: #b4b3bb;
  mask: url("../../img/delete.svg") no-repeat center;
  mask-size: auto 17px;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.column_bstatus {
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e2e2e8;
  position: relative;
}

.column_bstatus_checked {
  background-color: #27ae60;
}

.column_bstatus_checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 8px;
  width: 10px;
  background-color: #fff;
  mask: url("../../img/checked_icon.svg") no-repeat center;
  mask-size: 10px 8px;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.column_bstatus.column_bstatus_notfound {
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff284f;
  position: relative;
}

.column_bstatus_notfound::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 7px;
  width: 7px;
  background: white;
  mask: url("../../img/fail.svg") no-repeat center;
  mask-size: 10px 8px;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.column_bstatus_unchecked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 8px;
  width: 10px;
  background: rgb(22 23 24 / 40%);
  mask: url("../../img/checked_icon.svg") no-repeat center;
  mask-size: 10px 8px;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.column_pmethod {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(244 244 246 / 60%);
  background-size: cover;
}

.column_pmethod_yookassa {
  background: url("../../img/yooukassa.svg") no-repeat center;
}

.column_pmethod_qiwi {
  background: url("../../img/m_qiwi.svg") no-repeat center;
}

.column_pmethod_sber {
  background: url("../../img/m_sber.svg") no-repeat center;
}

.column_pmethod_webmoney {
  background: url("../../img/m_webmoney.svg") no-repeat center;
}

.column_pmethod_paypal {
  background: url("../../img/m_paypal.svg") no-repeat center;
}

.column_pmethod_westernunion {
  background: url("../../img/m_westernunion.svg") no-repeat center;
}

.column_pmethod_bill {
  background: url("../../img/m_bill.svg") no-repeat center;
}

.column_pmethod_other {
  background: url("../../img/m_other.svg") no-repeat center;
}

.menu-input__wrapper_small {
  width: 130px;
  margin: 0 auto;
}

.menu-input__wrapper_xsmall {
  width: 100px;
}

span.error-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
}

span.error-wrapper-validate {
  position: fixed;
  z-index: 100000;
}

label.error::after,
.validate-error::after {
  position: absolute;
  top: -5px;
  left: 16px;
  content: "";
  width: 12px;
  height: 6px;
  background: url("../../img/error-arrow.png") no-repeat center;
  background-size: cover;
  z-index: 101;
}

label.error,
.validate-error {
  width: max-content;
  position: absolute;
  left: 16px;
  bottom: -40px;
  height: max-content;
  font-size: 12px;
  line-height: 16px;
  color: #161718;
  padding: 14px 14px 9px;
  z-index: 100;
  background: #fff;
  border: 1px solid #c3c2c9;
  box-sizing: border-box;
  border-radius: 3px;
}

.action__controls {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid #c4c4c4;
}

.action__inner {
  padding-top: 0 !important;
  border-top: 0 !important;
}

.content-main__wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 1215px;

  &.settings {
    width: 970px;
    overflow-x: auto;
    box-shadow: 0 5px 13px -13px rgb(0 0 0 / 20%);
  }
}

.content-main__wrapper_col {
  flex-direction: column;
}

.content-main__wrapper_b50:not(:last-child) {
  margin-bottom: 50px;
}

.content-main__panel_p {
  padding-left: 0;
  padding-top: 20px;
  border-top: 1px solid #e4e5e8;
  width: 100%;
  position: relative;
}

.streams {
  max-width: 1215px;
}

.homework {
  max-width: 1260px;
}

.content-main__panel_streams {
  border-top: 0;
  padding-top: 0;
}

.column-link {
  cursor: pointer;
  transition: 0.4s;
  color: #161718;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
  overflow: hidden;
}

.column-phone {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
  overflow: hidden;
}

.phone-column {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}

.column-link_active {
  color: #9c9ba6;
  transition: color 0.2s linear;
}

.column-link_active:hover {
  color: black;
}

.column-link_imaged:hover {
  color: #29006c;
}

.column_paid {
  color: #161718;
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;
  padding-left: 5px;
}

.column-paid {
  display: flex;
  width: 135px;
  height: 24px;
  margin-top: -6px;
  justify-content: center;
  align-items: center;
  background: #f4f4f6;
  border-radius: 12px;
  padding: 3px 28px 0 8px;
  position: relative;
}

.column-status {
  width: 170px;
}

.paid-form {
  position: absolute;
  top: 0;
  right: 0;
}

.pagination__client-list {
  margin-bottom: 15px;
}

.pagination__wrapper {
  display: flex;
  justify-content: center;
  flex: 1;
}

.pagination__elem {
  font-size: 12px;
  line-height: 16px;
  width: 40px;
  height: 40px;
  background: #fff;
  color: #7d7c85;
  cursor: pointer;
  border-top: 1px solid rgb(195 194 201 / 40%);
  border-bottom: 1px solid rgb(195 194 201 / 40%);
  align-items: center;
  display: flex;
  justify-content: center;
  transition: 0.4s;
  position: relative;
}

.pagination-elem__default {
  background-color: rgb(2 5 14 / 10%);
  font-size: 12px;
  line-height: 16px;
  width: 40px;
  height: 40px;
  color: #7d7c85;
  cursor: pointer;
  border-top: 1px solid rgb(195 194 201 / 40%);
  border-bottom: 1px solid rgb(195 194 201 / 40%);
  align-items: center;
  display: flex;
  justify-content: center;
  transition: 0.4s;
  position: relative;
}

.pagination-elem__default:hover {
  color: #fff;
  background: black;
  border-color: black;
}

.pagination__elem:hover {
  color: #fff;
  background: black;
  border-color: black;
}

.pagination__elem.active {
  color: #fff;
  background: black;
  border-color: black;
  cursor: default;
}

.pagination__elem_prev {
  border: 1px solid #c3c2c9;
  box-sizing: border-box;
  border-radius: 3px 0 0 3px;
}

.pagination__elem_next {
  border: 1px solid #c3c2c9;
  box-sizing: border-box;
  border-radius: 0 3px 3px 0;
}

.pagination__elem_prev::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 8px;
  width: 15px;
  background: #c3c2c9;
  mask: url("../../img/arrow-down.svg") no-repeat center;
  mask-size: auto 8px;
  transition: 0.4s;
  transform: translate(-50%, -50%) rotate(90deg);
}

.pagination__elem_next::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 8px;
  width: 15px;
  background: #c3c2c9;
  mask: url("../../img/arrow-down.svg") no-repeat center;
  mask-size: auto 8px;
  transition: 0.4s;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.pagination__elem.active.pagination__elem_next::after,
.pagination__elem.active.pagination__elem_prev::after,
.pagination__elem:hover.pagination__elem_next::after,
.pagination__elem:hover.pagination__elem_prev::after {
  background-color: #fff;
}

.hiddenOverflow {
  overflow: hidden;
}

.column-img {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  align-self: center;
  overflow: hidden;
  position: relative;
  transition: 0.4s;
}

.column-img_large {
  min-width: 50px;
  width: 50px;
  height: 40px;
}

.column-img_large img {
  width: 30px;
}

.column-img::after {
  transition: 0.4s;
}

.column-img img {
  max-height: 100%;
  text-align: center;
  transition: 0.4s;
}

.column-img:hover::after {
  content: "";
  background: rgb(41 0 108 / 20%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.column-img_mr5 {
  margin-right: 5px;
}

.column-img_trans:hover::after {
  content: "";
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.datepicker-here-cs {
  background: transparent;
  z-index: 1;
}

.column-course {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
  overflow: hidden;
  color: #9c9ba6;
  transition: color 0.2s linear;
}

.column-course:hover {
  color: black;
}

.column_price {
  font-size: 11px;
  line-height: 11px;
  color: #7d7c85;
  padding-right: 5px;
}

.column-msg {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
}

.column-mailing {
  width: 20px;
  height: 12px;
  background: url("../../img/mailing.png") no-repeat center;
  background-size: cover;
}

.button_search {
  width: 180px;
  padding: 10px 29px;
  margin-left: 60px;
}

.menu-search__input_search.active label {
  background-color: #471f8a;
}

.button_create {
  width: 200px;
  margin-left: 15px;
  padding: 13px 30px;
}

.button_add {
  margin-left: 0;
  padding: 13px 30px;
}

.button-add__action {
  margin-left: auto;
}

.col-status {
  min-width: 95px;
}

.column-bill__status {
  background: #b4b3bb;
  box-shadow: 0 2px 5px rgb(180 179 187 / 10%);
  border-radius: 3px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  height: 40px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-bill__status_paid {
  background: #24c869;
  box-shadow: 0 2px 5px rgb(36 200 105 / 10%);
}

.btn_save {
  display: none;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 11px;
  background-color: #b4b3bb;
  mask: url("../../img/save.svg") no-repeat center;
  mask-size: auto 24px;
  transition: 0.4s;
  transform: translateY(-50%);
  z-index: 2;
}

.btn_save.block {
  display: block;
}

.btn_save:hover {
  background-color: #29006c;
}

.add-btn_small {
  width: 24px;
  height: 24px;
}

.client-wrapper {
  margin: 30px 25px;
  background: rgb(244 244 246 / 60%);
  border-radius: 10px;
  display: flex;
  min-height: 60px;
  max-height: 60px;
  flex: 1;
  padding: 0 14px 21px;
  align-items: flex-end;
}

.client-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  margin-right: 10px;
}

.client-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #161718;
  cursor: pointer;
  position: relative;
}

.client-name::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: -18px;
  height: 14px;
  width: 14px;
  background-color: #b4b3bb;
  mask: url("../../img/copy.svg") no-repeat center;
  mask-size: auto 14px;
  transition: 0.4s;
  transform: translateY(-50%);
}

.client-name:hover::after {
  background-color: black;
}

.counter {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #797880;
  padding: 0 24px;
  margin: 0 28px;
  position: relative;
}

.counter_bordered::after {
  content: "";
  width: 1px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  background: #bfc0c4;
}

.counter_right {
  margin-left: auto;
  margin-right: 0;
  padding-right: 0;
}

.button_client-download {
  margin-left: 60px;
  transition: all 0.2s linear;
}

.counter_right--history-estimation {
  margin-right: 0;
  padding-right: 0;
}

.counter_right--clients {
  margin-right: 0;
  padding-right: 0;
}

.counter_violet {
  color: #481e8c;
}

.content-main__panel_align {
  align-items: unset;
}

.menu__content-main_overy {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 150px);
}

.menu-input__input_nonb {
  border: none;
}

.menu-input__input_editable {
  color: black;
}

.menu-input__input_editable:disabled {
  color: #161718;
  background: transparent;
}

.menu-comment__input.menu-input__input_editable:disabled {
  background: #f4f4f6;
}

.column-form {
  display: table-column;
}

.div-table {
  width: 100%;
  position: relative;
}

.div-table__wrapper {
  padding: 0 25px;
}

.div-table__row {
  padding: 14px 5px;
  border-bottom: 1px solid #f1f1f3;
  font-size: 12px;
  line-height: 16px;
  color: #161718;
  transition: 0.4s;
}

.div-table__body-col {
  font-size: 12px;
  line-height: 16px;
  color: #161718;
  padding-left: 16px;
}

.div-table__row_selected .div-table__body-col {
  color: black;
}

.div-table__row:last-child {
  border-bottom: none;
}

.div-table__row-wrapper {
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 70px;
  transition: 0.4s;
}

.div-table__row-wrapper:hover {
  background: #f8f8fa;
}

.div-table__header-row {
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
}

.div-table__header-col {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  padding-left: 16px;
}

.div-table__header-col_edit {
  width: 85px;
}

.div-table__header_bordered {
  border-bottom: 1px solid #e4e5e8;
}

.div-table__header_bordered::after {
  height: 7px;
}

.div-table__header-col_big {
  font-size: 16px;
  line-height: 18px;
  color: #b4b3bb;
}

.div-table__body {
  display: flex;
  flex-direction: column;
}

.div-table__body-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}

.div-table__body-col_fl {
  display: flex;
}

.div-table__body-col_center {
  padding-left: 0;
}

.div-table__body-col_green {
  color: #27ae60;
}

.div-table__header-col_exxsmall {
  width: 20px;
  min-width: 20px;
}

.div-table__body-col_exxsmall {
  width: 20px;
  min-width: 20px;
}

.div-table__header-col_exsmall {
  width: 36px;
  min-width: 36px;
}

.div-table__body-col_exsmall {
  width: 36px;
  min-width: 36px;
}

.div-table__header-col_xxxsmall {
  width: 58px;
  min-width: 58px;
}

.div-table__body-col_xxxsmall {
  width: 58px;
  min-width: 58px;
}

.div-table__header-col_xxsmall {
  width: 68px;
  min-width: 68px;
}

.div-table__body-col_xxsmall {
  width: 68px;
  min-width: 68px;
}

.div-table__header-col_xsmall {
  width: 70px;
  min-width: 70px;
}

.div-table__body-col_xsmall {
  width: 70px;
  min-width: 70px;
}

.div-table__header-col_small {
  width: 90px;
  min-width: 90px;
}

.div-table__body-col_small {
  width: 90px;
  min-width: 90px;
}

.div-table__header-col_medium {
  width: 100px;
  min-width: 100px;
}

.div-table__body-col_medium {
  width: 100px;
  min-width: 100px;
}

.div-table__header-col_large {
  width: 125px;
  min-width: 125px;
}

.div-table__body-col_large {
  width: 125px;
  min-width: 125px;
}

.div-table__header-col_full {
  width: 100%;
}

.div-table__body-col_full {
  width: 100%;
}

.editable {
  padding: 0;
  text-align: left;
  width: 100%;
}

.editable__adv {
  padding-left: 8px;
}

.editable:disabled {
  border: none;
  width: 100%;
}

.edit-form-bill {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.div-table__body-col_r10 {
  margin-right: 10px;
}

.div-table__header-col_r10 {
  margin-right: 10px;
}

.div-table__body-col_lauto {
  margin-left: auto;
}

.pmethod-wrapper {
  width: 100%;
  position: relative;
}

.column_pmethod-select {
  width: 100%;
  height: 30px;
  border: none;
  appearance: none;
  color: transparent;
  background-position-x: left;
  cursor: pointer;
}

.column_pmethod-select:disabled {
  cursor: default;
}

.column_pmethod-tr {
  color: #161718;
  padding-left: 36px;
  padding-right: 14px;
  width: max-content;
}

.column_pmethod-sm {
  width: 52px;
  background-color: unset;
  background-size: unset;
}

.pmethod-wrapper.disabled::after {
  display: none;
}

.pmethod-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 5px;
  height: 6px;
  width: 12px;
  background-color: #c3c2c9;
  mask: url("../../img/arrow-down.svg") no-repeat center;
  mask-size: auto 6px;
  transition: 0.4s;
  transform: translateY(-50%);
}

.menu-input__input_white {
  background-color: #fff;
  text-overflow: ellipsis;
}

.menu-input_w100 {
  width: 100%;
}

.menu-input_wm100 {
  width: 100%;
  min-width: 420px;
}

.input-element__radio_list {
  display: flex;
  flex: 1;
  height: 40px;
  border: 1px solid black;
  box-sizing: border-box;
}

.input-element__radio_list:first-child {
  border-radius: 3px 0 0 3px;
}

.input-element__radio_list:last-child {
  border-radius: 0 3px 3px 0;
}

.input-element__checkbox-wrapper {
  padding: 20px;
}

.input-element__checkbox-wrapper_sm {
  padding: 20px 20px 0;
}

.checkbox ~ .checkbox-label_told {
  line-height: 21px;
}

.menu-input_reverse {
  position: relative;
  background-color: transparent;
  width: 240px;
  height: auto;
  padding: 20px 40px 0 0;
}

.menu-input_reverse::after {
  content: "";
  position: absolute;
  bottom: 14px;
  right: 12px;
  height: 14px;
  width: 17px;
  background-color: black;
  mask: url("../../img/reverse.svg") no-repeat center;
  mask-size: auto 14px;
  transition: 0.4s;
}

.menu-input_reverse-common {
  position: relative;
  background-color: transparent;
  width: 230px;
  height: auto;
  padding: 20px 40px 0 0;
}

.menu-input_pr40 {
  padding-right: 40px;
}

.pr_0 {
  padding-right: 0;
}

.filter-block {
  width: 400px;
  padding-right: 0;
}

.menu-input_reverse-common::after {
  content: "";
  position: absolute;
  bottom: 14px;
  right: 12px;
  height: 14px;
  width: 17px;
  background-color: black;
  mask: url("../../img/reverse.svg") no-repeat center;
  mask-size: auto 14px;
  transition: 0.4s;
}

.homework-testimonials {
  display: flex;
  flex-direction: column;
}

.homework-testimonials__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: black;
}

.homework-testimonials__inner {
  display: flex;
  justify-content: flex-start;
}

.homework-testimonials__status {
  display: flex;
  flex-direction: column;
  margin-right: 35px;
}

.homework-testimonials__subtitle {
  margin-top: 15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b85;
}

.homework-testimonials__statuses {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  position: relative;
}

.homework-testimonials__accept {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
}

.homework-testimonials__buttons {
  display: flex;
}

.homework-testimonials__complete {
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgb(39 174 96 / 50%);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.2s linear;
  cursor: pointer;
  font-size: 12px;
}

.homework-testimonials__not-execute {
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: rgb(0 0 0 / 50%);
  border: 1px solid rgb(0 0 0 / 50%);
  cursor: pointer;
}

.homework-testimonials__radio#succes:checked ~ .homework-testimonials__complete {
  background-color: #27ae60;
}

.homework-testimonials__radio#fail:checked ~ .homework-testimonials__not-execute {
  color: black;
  border: 1px solid black;
}

.homework-testimonials__rate {
  margin-left: 30px;
  margin-top: 15px;
}

.homework-testimonials__wrapper {
  margin-top: 7px;
  position: relative;
}

.homework-testimonials__wrapper::after {
  content: "";
  display: flex;
  width: 1px;
  height: 40px;
  background-color: #f1f1f3;
  position: absolute;
  bottom: 15%;
  left: -30px;
}

.clear-filter {
  display: flex;
  align-items: center;
  margin: 0 20px;
  padding: 0 20px;
  position: relative;
}

.clear-filter_bordered::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 40px;
  background: #c7c9cf;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.button_download {
  width: 180px;
  margin-right: 0;
  padding: 13px 16px;
  color: #fff;
  text-align: center;
  transition: all 0.2s linear;
  margin-left: 60px;
}

.button_download:hover {
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 20%);
  color: black;
}

.button_clear {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #b4b3bb;
  background-color: transparent;
  transition: 0.4s;
  padding: 0 0 0 24px;
  border: none;
  position: relative;
}

.button_clear:hover {
  color: #ff284f;
}

.button_clear:hover::after {
  background-color: #ff284f;
}

.column-text {
  color: #161718;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
  overflow: hidden;
  margin: 0;
  min-height: 50%;
  display: flex;
  align-items: center;
}

.column-text_violet {
  color: #481e8c;
}

.column-text_pl {
  max-width: 120px;
  padding-left: 30px;
}

.column-text_full {
  max-width: 100%;
}

.column-wrapper {
  display: flex;
  flex-direction: row;
}

.column-full-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #161718;
  white-space: nowrap;
  flex-basis: 100%;
}

.button-filter_bordered {
  margin-right: 41px;
  position: relative;
}

.button-filter_bordered::after {
  content: "";
  width: 1px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  background: #bfc0c4;
  pointer-events: none;
}

.search-form {
  width: 340px;
  position: relative;
}

.main-content_pl40 {
  padding-left: 40px;
  position: relative;
}

.main-content_white-top::before {
  content: "";
  width: 100%;
  height: 140px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: -1;
}

.main-content_white-top-small::before {
  content: "";
  width: 100%;
  height: 74px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: -1;
}

.main-content_white-top-medium {
  position: relative;
  min-width: max-content;
}

.main-content_mw-unset {
  min-width: unset;
}

.main-content_s-white-top {
  position: relative;
  min-width: max-content;
}

.main-content_s-white-top::before {
  content: "";
  width: 100%;
  height: 74px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: -1;
}

.setting-said {
  position: absolute;
  top: 0;
  left: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 0 25px rgb(7 2 14 / 5%);
  z-index: 100;
  width: 0;
  height: 100vh;
  overflow: hidden;
  background-color: black;
}

.setting-said__btn {
  min-height: 100%;
  width: 100%;
  background-color: #29006c;
  position: absolute;
}

.setting-said__btn::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 10px;
  width: 12px;
  background-color: #fff;
  mask: url("../../img/settting.svg") no-repeat center;
  mask-size: auto 10px;
  transform: translate(-50%, -50%);
}

.setting-said__wrapper {
  display: flex;
  flex-direction: row;
  position: fixed;
  height: 100vh;
  width: 0;
  background-color: #29006c;
}

.setting-said__list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 200px;
}

.setting-said__item {
  font-size: 14px;
  line-height: 14px;
  color: white;
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  display: flex;
  min-height: 60px;
  padding: 0 24px;
  align-items: center;
  white-space: nowrap;
  transition: background-color, color 0.2s linear;
}

.setting-said__item:hover {
  color: white;
  background-color: rgb(117 117 117 / 50%);
  border-bottom: 0;
}

.setting-said__item_active {
  color: #fff;
}

.setting-said__list_hide {
  width: 100%;
}

.setting-said__wrapper:hover .setting-said__list_hide {
  transition: width 0.6s 0.5s;
  width: 240px;
  opacity: 1;
}

.setting-said__btn:not(:hover) {
  transition: opacity 0.4s;
}

.setting-said__wrapper:hover .setting-said__btn {
  opacity: 0;
  width: 0;
}

.deal__reminder {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.deal__reminder-date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #161718;
}

.deal__reminder-title {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0;
}

.deal__reminder-title_left {
  padding-left: 0;
}

.deal__reminder-title::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -20px;
  height: 25px;
  width: 25px;
  transform: translateY(-50%);
  border-radius: 50%;
  transition: 0.4s;
}

.deal-reminder::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -20px;
  height: 25px;
  width: 25px;
  background-color: #fff;
  mask: url("../../img/reminder.svg") no-repeat center;
  transform: translateY(-50%);
  mask-size: auto 13px;
  transition: 0.4s;
}

.deal-reminder_gray::before {
  background-color: #7d7c85;
}

.deal-reminder_green::before {
  background-color: lightgray;
}

.deal-reminder_done::before {
  display: none;
}

.deal-reminder_done::after {
  content: "✓";
  background-color: #24c869;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  transform: translate(-20px, -12px);
  font-size: 14px;
}

.deal-reminder_red::before {
  background-color: #ff284f;
}

.deal-reminder_done.deal-reminder::after {
  mask-image: none;
}

.deal-reminder_yellow.deal-reminder::after {
  background-color: black;
}

.menu-comment__input_deal-violet {
  background-color: #29006c;
  color: #fff;
}

.menu-comment__input_deal-empty {
  border: 1px dashed rgb(0 0 0 / 20%);
  background-color: transparent;
}

.column-reminder {
  display: flex;
  width: 90px;
  height: 36px;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f6;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  padding: 0 10px;
}

.column-reminder_mr10 {
  margin-right: 10px;
}

.reminder-wrapper {
  min-width: 180px;
  max-width: 180px;
  height: max-content;
  padding: 14px;
  background: rgb(0 0 0 / 97%);
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -5px;
  right: 6px;
  transform: translateY(-100%);
  z-index: 1002;
}

.reminder__selector::after {
  content: "";
  width: 1px;
  height: 10px;
  background-color: #7d7c85;
  display: inline-block;
  position: absolute;
  top: 2px;
  right: -4px;
}

.column-reminder:hover .reminder-wrapper {
  transition: opacity 0.4s;
  opacity: 1;
  z-index: 1002;
  visibility: visible;
}

.reminder_red {
  background-color: #ff284f;
}

.reminder_green {
  background-color: #24c869;
}

.reminder_gray {
  background-color: #7d7c85;
}

.reminder-date {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  color: white;
  text-align: left;
}

.reminder-msg {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  color: #fff;
  overflow: hidden;
  margin-top: 10px;
  text-align: left;
}

.reminder-d {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  transform: translateX(2px);
}

.column-type {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 55px;
  overflow: hidden;
}

.column-paid_green {
  background: #27ae60;
}

.column-paid_green .column_price {
  color: #fff;
}

.column-paid_green .column_price-del {
  color: #fff;
}

.column-paid_green .column_paid {
  color: #fff;
}

.menu-input__wrapper_border {
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f3;
}

.button_medium {
  width: 220px;
}

.button_small {
  width: 140px;
}

.avatar-upload {
  width: 30px;
  height: 30px;
  transition: 0.4s;
}

.avatar-upload__upload {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  border-radius: 50%;
}

.avatar-upload__upload_default-user {
  background-image: url("../../img/default-avatar.svg");
}

.avatar-upload__upload_default-company {
  background-image: url("../../img/company_default.png");
}

.avatar-upload__upload img {
  max-width: 100%;
  width: 100%;
}

.avatar-upload__upload_height img {
  max-height: 100%;
  max-width: unset;
}

.indicated-select {
  position: relative;
}

.indicated-select select {
  padding: 0 0 0 34px;
}

.indicated-select::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #b4b3bb;
  z-index: 5;
}

.indicated-select_center::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.indicated-select_true::before {
  background: #24c869;
}

.indicated-select_false::before {
  background: #ff284f;
}

.max-content {
  width: max-content;
}

.content-top_btn {
  max-width: 1195px;
  min-width: 1195px;
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 30px;
  background-color: $black;
  color: $white;
  cursor: pointer;
  transition: all 0.2s linear;
}

.add-button:hover {
  background-color: transparent;
  color: $black;
}

.add-button_violet,
.button_violet {
  background-color: #29006c;
  color: #fff;
}

.add-button_black,
.button_black {
  background-color: black;
  color: #fff;
}

.add-button_black:hover,
.button_black:hover {
  background-color: transparent;
  color: black;
  border: 1px solid black;
}

.add-button_light-black {
  background-color: black;
  border-color: black;
  color: #fff;
}

.add-button_light-violet {
  background-color: #471f8a;
  border-color: #471f8a;
  color: #fff;
}

.add-button_right,
.button_right {
  margin-left: auto;
}

.add-button_link {
  margin-right: 15px;
}

.add-button_link:hover {
  color: #29006c;
}

.add-button_medium {
  width: 256px;
}

.add-button_auto {
  width: 100%;
}

.article__text {
  max-width: 900px;
  min-width: 900px;
  padding: 0 40px;
}

.lesson {
  background: #fff;
  border: 1px solid #e6e5ee;
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgb(8 15 32 / 4%);
  border-radius: 1px;
  transition: 0.4s;
  position: relative;
  margin-bottom: 20px;
}

.lesson:hover {
  border-color: black;
}

.lesson__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 100px;
  cursor: pointer;
}

.lesson__header::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 50px;
  height: 14px;
  width: 24px;
  background-color: #999ba1;
  mask: url("../../img/arrow.svg") no-repeat center;
  transform: translateY(-50%);
  mask-size: 24px 14px;
  transition: 0.4s;
}

.lesson__header:hover::after {
  background-color: black;
}

.lesson__header_active::after {
  background-color: black;
  transform: translateY(-50%) scale(1, -1);
}

.lesson-header__number {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 34px;
  color: black;
  position: absolute;
  top: 50%;
  left: 46px;
  transform: translateY(-50%);
}

.lesson-header__title {
  padding-left: 168px;
  padding-right: 98px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.lesson__content {
  padding: 44px 46px 52px;
  border-top: 1px solid rgb(224 223 234 / 80%);
  display: none;
}

.lesson__content-wrapper {
  display: flex;
  flex-direction: row;
}

.lesson-content__main {
  display: flex;
  max-width: 590px;
  margin-left: 40px;
  flex-direction: column;
}

.lesson-description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: rgb(105 102 118 / 90%);
  margin-bottom: 40px;
}

.lesson-document__delete-btn {
  width: 50px;
  height: 40px;
  position: relative;
  cursor: pointer;
}

.lesson-document__delete-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 50px;
  background-color: #b4b3bb;
  mask: url("../../img/delete.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: auto 16px;
  transition: 0.4s;
}

.lesson-document__delete-btn:hover::after {
  background-color: black;
}

.lesson__documents {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  flex-wrap: wrap;
}

.lesson-document {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 280px;
  height: 40px;
  margin-bottom: 20px;
  background: #f4f4f6;
  border-radius: 5px;
  color: #fff;
  transition: 0.4s;
}

.lesson-document:hover {
  color: #fff;
  background: rgb(0 0 0 / 50%);
}

.lesson-document_without-hover:hover {
  background: #f4f4f6;
}

.lesson-document__name {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: black;
  padding: 0 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.4s;
}

.lesson-document:hover .lesson-document__name {
  color: #fff;
}

.lesson-document_without-hover:hover .lesson-document__name {
  color: black;
}

.lesson-document__type {
  display: flex;
  min-width: 40px;
  height: 40px;
  border-radius: 5px;
  font-size: 11px;
  line-height: 13px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: $white;
}

.menu-input__input-textarea {
  min-height: 200px;
  resize: none;
}

.input-upload-file {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.input-upload-label {
  display: flex;
  flex: 1;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
}

.input-upload-file + .input-upload-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-upload-label_auto {
  flex: unset;
}

.lesson-document_full {
  flex-grow: 1;
  margin-bottom: 0;
}

.input-element__documents {
  display: flex;
  flex-direction: column;
  padding: 40px 6px 0;
}

.input-element__documents-new {
  display: flex;
  flex-direction: column;
}

.input-element__documents-old {
  display: flex;
  flex-direction: column;
}

.input-element__document-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.display-none {
  display: none;
}

.display-none.is-open {
  display: block;
}

.display-flex-none {
  display: none;
}

.display-flex-none.is-open {
  display: flex;
  align-items: center;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.setting-title {
  color: #bfc0c4;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  transition: 0.4s;
}

.setting-wrapper:hover .setting-title {
  color: black;
}

.setting-wrapper:hover .theme-setting::after {
  background-color: black;
}

.table-title_wrapper {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  min-width: 1195px;
}

.table-title__title {
  font-size: 20px;
  line-height: 20px;
  color: #161718;
  position: relative;
  margin-right: 20px;
}

.hidden-btn {
  position: relative;
  display: inline;
}

.hidden-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 16px;
  width: 16px;
  transform: translate(-50%, -50%);
  background-color: #b4b3bb;
  transition: 0.4s;
}

.button_red .hidden-btn::before {
  background-color: #ff284f;
}

.button_white .hidden-btn::before {
  background-color: #29006c;
}

.button_hide:hover .hidden-btn::before {
  background-color: black;
}

.hidden-btn_left::before {
  left: 0;
  transform: translate(-25px, -60%);
}

.hidden-btn_right::before {
  left: unset;
  right: 0;
  transform: translate(25px, -60%);
}

.hidden-btn_right-medium::before {
  transform: translate(40px, -60%);
}

.hidden-btn_right-large::before {
  transform: translate(55px, -60%);
}

.hidden-btn_hide::before {
  mask: url("../../img/delete.svg") no-repeat center;
  mask-size: 16px auto;
}

.hidden-btn_reveal::before {
  mask: url("../../img/reveal.svg") no-repeat center;
  mask-size: 13px auto;
}

.hidden-btn_debate::before {
  height: 16px;
  width: 21px;
  mask: url("../../img/debate.svg") no-repeat center;
  mask-size: 21px 16px;
}

.c-tooltip {
  position: relative;
  cursor: default;
}

.c-tooltip .avatar-image {
  transform: translateY(0);
  overflow: hidden;
  border-radius: 100%;
}

.c-tooltip__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.c-tooltip .c-tooltip__avatar {
  transform: translateY(0);
}

.c-tooltip-wrapper {
  position: relative;
}

.c-tooltip__wrapper {
  min-width: 180px;
  max-width: 180px;
  height: auto;
  padding: 14px;
  border-radius: 5px;
  background: $black;
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: left;
  visibility: hidden;
  filter: saturate(0.5);
}

.c-tooltip:hover .c-tooltip__wrapper,
.c-tooltip__wrapper.show {
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  transition: opacity 0.4s;
}

.c-tooltip__wrapper_left {
  top: -10px;
  left: 0;
  transform: translate(0%, -100%);
}

.c-tooltip__wrapper_right {
  top: -10px;
  left: unset;
  right: 0%;
  transform: translate(0%, -100%);
}

.c-tooltip__wrapper_medium {
  min-width: 120px;
  max-width: 120px;
}

.c-tooltip__wrapper_maxwidth {
  max-width: unset;
}

.c-tooltip__wrapper_large {
  min-width: 200px;
  max-width: 200px;
}

.c-tooltip__wrapper_xxlarge {
  min-width: 260px;
  max-width: 260px;
}

.c-tooltip__wrapper_avatar {
  min-width: 224px;
  height: 224px;
  border-radius: 50%;
  padding: 0;
}

.c-tooltip:hover .c-tooltip__wrapper_avatar {
  left: 45px;
}

.c-tooltip__wrapper_avatar img {
  width: auto;
  height: auto;
}

.c-tooltip__text {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: white;
  overflow: hidden;
}

.pa-rate_delim {
  margin: 0 4px;
  font-size: 18px;
}

.c-tooltip__text .pa-rate_delim {
  color: white;
  background-color: white;
}

.c-tooltip__text_green {
  color: #27ae60 !important;
}

.c-tooltip__text-icon {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #fff;
  padding-left: 34px;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
  margin-bottom: 8px;
}

.c-tooltip__text-icon:last-child {
  margin-bottom: 0;
}

.c-tooltip__text-icon_first::before {
  background: url("../../img/i-first.png") no-repeat center;
  background-size: 32px 32px;
  content: "";
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.c-tooltip__text-icon_second::before {
  background: url("../../img/i-second.png") no-repeat center;
  background-size: 32px 32px;
  content: "";
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.c-tooltip__text-icon_third::before {
  background: url("../../img/i-third.png") no-repeat center;
  background-size: 32px 32px;
  content: "";
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.c-tooltip__text-icon_common::before {
  background: url("../../img/i-common.png") no-repeat center;
  background-size: 32px 32px;
  content: "";
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.c-tooltip__text_p16 {
  padding-left: 16px;
}

.c-tooltip__text_flex {
  display: flex;
}

.c-tooltip__text_nowrap {
  white-space: nowrap;
}

.c-tooltip__text_title {
  opacity: 0.6;
  text-align: left;
}

.c-tooltip__note {
  opacity: 0.6;
  margin-left: auto;
}

.c-tooltip__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.c-tooltip__row:not(:last-child) {
  margin-bottom: 6px;
}

.content-setting {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.estimation-col .column-text {
  pointer-events: none;
}

.grade {
  display: flex;
  flex-direction: row;
}

.grade_right {
  margin-left: auto;
}

.grade__title {
  font-size: 16px;
  line-height: 28px;
  color: #b4b3bb;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 15px;
}

.grade__title_small {
  font-size: 12px;
  line-height: 16px;
}

.grade__values {
  display: flex;
  flex-direction: row;
}

.grade__values_without-margin span {
  margin-right: 0;
}

.grade__value:last-child {
  margin-right: 0;
}

.grade-value {
  font-weight: 500;
  font-size: 38px;
  line-height: 1;
  color: #161718;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 7px;
}

.grade-value_small {
  font-size: 24px;
}

.grade-value_xsmall {
  font-size: 20px;
}

.grade-value_green {
  color: #27ae60;
}

.grade-value_yellow {
  color: #fbcf09;
}

.grade-value_red {
  color: #ff284f;
}

.grade-delim {
  font-weight: 500;
  font-size: 38px;
  line-height: 1;
  color: #161718;
  opacity: 0.4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 7px;
}

.grade-delim_small {
  font-size: 24px;
}

.content-delim {
  display: flex;
  margin: 20px 0;
}

.content-delim span {
  width: 100%;
  height: 1px;
  background: rgb(196 196 196 / 20%);
}

.element-delim {
  display: flex;
  margin: 0 20px;
}

.element-delim span {
  width: 1px;
  height: 40px;
  background: rgb(196 196 196 / 60%);
}

.content-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -10px;
}

.rating {
  width: 270px;
  height: 330px;
  background: #fff;
  border: 1px solid #f1f1f4;
  box-sizing: border-box;
  box-shadow: 0 8px 18px rgb(5 4 20 / 8%);
  border-radius: 5px;
  margin: 0 10px 20px;
  display: flex;
  flex-direction: column;
}

.rating_told {
  height: 380px;
}

.rating__values {
  display: flex;
  min-height: 120px;
  color: #fff;
  flex-direction: column;
  justify-content: center;
}

.values_green {
  background: #27ae60;
}

.values_yellow {
  background: #fbcf09;
}

.values_red {
  background: #ff284f;
}

.rate-value {
  font-size: 58px;
  line-height: 1;
  text-align: center;
  color: #fff;
}

.rate-date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  color: rgb(255 255 255 / 60%);
}

.rating__fields {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 18px;
}

.rate-field {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  transition: 0.4s;
  min-height: 14px;
  margin-bottom: 15px;
}

.rate-field:last-child {
  margin-bottom: 0;
}

.rate-field:hover {
  color: #481e8c;
  cursor: pointer;
}

.rate-field__title {
  margin-right: 20px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rate-field__weight {
  color: #161718;
}

.rate-field__weight_pr24 {
  padding-right: 24px;
  position: relative;
}

.rate-field__weight_icon::after {
  content: "?";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-60%);
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background: #f4f4f6;
  color: #7e7d84;
  padding-top: 1px;
  transition: 0.4s;
}

.rate-field:hover .rate-field__weight_icon::after {
  background: #471f8a;
  color: #fff;
}

.rating__footer {
  display: flex;
  min-height: 80px;
  border-top: 1px solid rgb(228 229 232 / 60%);
  padding: 18px;
}

.templates {
  margin-top: 10px;
  margin-bottom: 30px;
}

.templates:last-child {
  margin-bottom: 0;
}

.templates__wrapper {
  position: relative;
}

.templates__wrapper::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgb(196 196 196 / 20%);
}

.templates__title {
  font-size: 30px;
  line-height: 34px;
  color: #161718;
  margin-bottom: 25px;
}

.templates__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.template {
  width: 270px;
  height: 380px;
  background: #fff;
  border: 1px solid #f1f1f4;
  box-sizing: border-box;
  box-shadow: 0 8px 18px rgb(5 4 20 / 8%);
  border-radius: 3px;
  margin: 0 10px 20px;
}

.template__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  padding: 20px;
}

.template-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: #161718;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.template_create {
  border: 1px dashed #b4b3bb;
  position: relative;
  cursor: pointer;
  display: flex;
  background: transparent;
  justify-content: center;
  box-shadow: none;
  transition: 0.4s;
}

.template_create:hover {
  border-color: #29006c;
}

.template_create::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  mask: url("../../img/plus.svg") no-repeat center;
  mask-size: auto 50px;
  background-color: #b4b3bb;
  transition: 0.4s;
  transform: translate(-50%, -50%);
}

.template_create:hover::after {
  background-color: #29006c;
}

.template__btm-message {
  font-size: 16px;
  line-height: 18px;
  color: #b4b3bb;
  transition: 0.4s;
  display: flex;
  margin-bottom: 30px;
  margin-top: auto;
}

.template_create:hover .template__btm-message {
  color: #29006c;
}

.menu-input__tabs {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 -8px;
}

.menu-input__tabs_p0 {
  padding-left: 0;
  padding-right: 0;
}

.menu-tab {
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #9c9ba6;
  margin: 0 8px;
  min-height: 21px;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.4s;
}

.menu-tab:hover::after,
.menu-tab.active::after {
  content: "";
  position: absolute;
  background-color: black;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: -10px;
  transition: 0.4s;
}

.menu-tab.active,
.menu-tab:active,
.menu-tab:hover {
  color: black;
}

.menu-tabs {
  position: relative;
}

.menu-tabs_overflow-hidden {
  overflow: hidden;
}

.tab-content:not(.is-open) {
  pointer-events: none;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0s linear;
}

.tab-content:not(.is-open) * {
  pointer-events: none !important;
}

.tab-content__wrapper {
  overflow: hidden;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  height: 100%;
}

.tab-content__wrapper_overflow-unset {
  overflow: unset;
}

.tab-content:not(.is-open) .tab-content__wrapper {
  opacity: 0;
  /* stylelint-disable */
  ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  transition: 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translate(0, 20px) scale(0.98);
}

.tab-content:not(.is-open) .products__list {
  display: none;
}

.rate-fields,
.rate-levels {
  border: 1px solid rgb(164 170 178 / 60%);
  border-left: none;
  border-right: none;
  background: #f4f4f6;
  padding: 13px 0;
  margin-top: 15px;
}

.rate-fields_transparent {
  border: none;
  background: transparent;
}

.rate-fields_transparent input {
  background-color: #fff;
}

.rate-field__delete,
.rate-level__delete {
  position: relative;
  width: 30px;
  min-width: 30px;
  height: 40px;
  cursor: pointer;
}

.rate-field__delete_disabled {
  cursor: default;
}

.rate-field__delete_disabled::after {
  display: none;
}

.rate-field__delete::after,
.rate-level__delete::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 18px;
  width: 16px;
  background-color: #b4b3bb;
  mask: url("../../img/delete.svg") no-repeat center;
  mask-size: 16px 18px;
  transition: 0.4s;
}

.rate-field__delete:hover::after,
.rate-level__delete:hover::after {
  background-color: black;
}

.rate-filed,
.rate-level {
  display: flex;
  flex-direction: row;
  padding: 10px 25px;
  align-items: center;
}

.rate-filed__wrapper {
  display: flex;
  flex-direction: column;
}

.menu-input__input_rate-filed {
  width: 350px;
  margin: 0 5px;
  background: #fff;
}

.menu-input__input_rate-value {
  width: 80px;
  min-width: 80px;
  margin: 0 5px;
  background: #fff;
}

.menu-input__input_community {
  background-color: #fff;
  cursor: pointer;
}

.menu-input__input_level-filed {
  margin: 0 5px;
  background: #fff;
}

.menu-input__input_level {
  color: #fff;
  background: #481e8c;
  border-radius: 50%;
  font-size: 18px;
  line-height: 1;
  width: 30px;
  height: 30px;
  min-width: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  padding-top: 3px;
}

.level-reverse {
  min-width: 13px;
  height: 12px;
  position: relative;
}

.level-reverse::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 12px;
  width: 13px;
  background-color: #481e8c;
  mask: url("../../img/reverse.svg") no-repeat center;
  mask-size: 13px 12px;
  transition: 0.4s;
}

.template__fields {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  border-top: 1px solid rgb(228 229 232 / 60%);
  height: 80px;
}

.template__fields_told {
  height: 156px;
}

.template-field {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  min-height: 14px;
  margin-bottom: 15px;
}

.template-field:last-child {
  margin-bottom: 0;
}

.template-field__title {
  margin-right: 20px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.template-field__value {
  color: #161718;
  white-space: nowrap;
}

.template-field__tooltip {
  color: #161718;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  transition: 0.4s;
  padding-right: 24px;
}

.template-field__tooltip::after {
  content: "?";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background: #f4f4f6;
  color: #7e7d84;
  padding-top: 1px;
  transition: 0.4s;
}

.template-field__tooltip:hover {
  color: #471f8a;
}

.template-field__tooltip:hover::after {
  background: #471f8a;
  color: #fff;
}

.template__footer {
  display: flex;
  height: 80px;
  border-top: 1px solid rgb(228 229 232 / 60%);
  padding: 22px 18px;
}

.rate-totals {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 25px;
}

.rate-titles {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 25px;
}

.rate-title {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  padding-left: 15px;
  white-space: nowrap;
  margin: 0 5px;
}

.rate-title_field {
  width: 342px;
}

.rate-title_field-small {
  width: 332px;
}

.rate-title_value {
  width: 80px;
}

.rate-title_lvl {
  padding-left: 8px;
  width: 30px;
}

.rate-title_from {
  width: 153px;
}

.rate-title_to {
  width: 140px;
}

.template-setting {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.template-setting:last-child {
  margin-bottom: 0;
}

.template-setting::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgb(196 196 196 / 20%);
}

.template-setting__title {
  font-size: 30px;
  line-height: 34px;
  color: #161718;
  margin-bottom: 25px;
}

.employee {
  display: flex;
  flex-direction: row;
}

.employee__avatar {
  display: flex;
  align-items: center;
}

.employee__desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
}

.employee__desc-wrapper {
  display: flex;
  flex-direction: row;
}

.employee-desc {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
}

.employee-score {
  position: relative;
  font-size: 12px;
  line-height: 16px;
  padding-left: 20px;
  color: #7c7b85;
  text-align: left;
}

.employee-score::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-60%);
  height: 13px;
  width: 13px;
  background-color: #ffa515;
  mask: url("../../img/star.svg") no-repeat center;
  mask-size: 13px 13px;
}

.employee-name {
  font-size: 12px;
  line-height: 16px;
  color: #161718;
}

.employee-name_medium {
  font-size: 24px;
  line-height: 28px;
}

.employee-name_big {
  font-size: 28px;
  line-height: 38px;
}

.employee-position {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
}

.employee-delim {
  position: relative;
  margin: 0 10px;
}

.employee-delim::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 10px;
  background: #7c7b85;
}

.custom-table__header-col_number {
  padding: 0;
  text-align: center;
}

.custom-table__body-col_last {
  padding-right: 6px;
  padding-left: 0;
}

.custom-table__body-col_first {
  padding-left: 16px;
}

.rate_disabled {
  cursor: default;
}

.rate_disabled:hover {
  border-color: #f4f4f6;
}

.rate_green {
  background: #27ae60;
  color: #fff;
}

.rate_yellow {
  background: #fbcf09;
  color: #fff;
}

.rate_red {
  background: #ff284f;
  color: #fff;
}

.menu__description-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  width: 100%;
  padding: 0 40px;
  z-index: 1800;
  position: relative;
}

.desc-date {
  display: flex;
  flex-direction: row;
}

.desc-date__description {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  margin-right: 10px;
}

.desc-date__date {
  font-size: 12px;
  line-height: 16px;
  color: #161718;
}

.desc-block__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.desc-block__wrapper_mr30 {
  margin-right: 30px;
}

.desc-block__wrapper_mr10 {
  margin-right: 10px;
}

.desc-block__wrapper_end {
  justify-content: flex-end;
}

.desc-block__wrapper_right {
  margin-left: auto;
}

.desc-block__wrapper_row {
  flex-direction: row;
  justify-content: flex-start;
}

.desc-block__desc {
  font-size: 12px;
  line-height: 16px;
  color: #161718;
}

.menu-rate__fields {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 40px 0;
  margin: 0 -10px;
}

.menu-rate-field {
  display: flex;
  flex-direction: column;
  height: max-content;
  width: calc(50% - 20px);
  margin: 0 10px 20px;
  background: #fff;
  box-shadow: 0 10px 20px rgb(2 5 14 / 10%);
  border-radius: 5px;
  padding: 24px;
}

.menu-rate-field__title {
  font-size: 18px;
  line-height: 21px;
  color: #161718;
  margin-bottom: 20px;
}

.menu-rate-field__rate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.rate-stars {
  display: flex;
  flex-direction: row;
  margin: 0 -5px;
}

.rate-star,
.rate-star-disabled {
  padding-top: 6px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: #481e8c;
  cursor: pointer;
  background-color: #dbdbe1;
  mask: url("../../img/rate-star.svg") no-repeat center;
  mask-size: 45px 45px;
  transition: 0.4s;
  margin: 0 5px;
}

.rate-star-disabled {
  cursor: default;
}

.rate-star:hover,
.rate-star.active,
.rate-star-disabled.active {
  background-color: #fdcc0a;
}

.rate-stars:hover .rate-star {
  background-color: #fdcc0a;
}

.rate-star:hover ~ .rate-star {
  background-color: #dbdbe1;
}

.star-rating__wrap {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 -9px;
  padding: 0 15px;
  width: max-content;
}

.star-rating__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.star-rating__mark {
  width: 57px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;
  margin: 0 9px;
}

.star-rating__mark::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dbdbe1;
  mask: url("../../img/r-star.svg") no-repeat center;
  mask-size: 57px 55px;
  transition: 0.4s;
}

.star-rating__mark:hover::after,
.star-rating__mark:hover ~ .star-rating__mark::after,
.star-rating__input:checked ~ .star-rating__mark::after {
  background-color: #481e8c;
}

.apply-block {
  width: 240px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 5px;
  background: #fff;
  color: #29006c;
  cursor: pointer;
  border: 1px solid #29006c;
  height: 40px;
  display: flex;
}

.apply-block__btn {
  width: 50%;
  cursor: pointer;
  position: relative;
  transition: 0.4s;
}

.apply-block__btn::after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #29006c;
  transition: 0.4s;
}

.apply-block__btn:hover::after {
  background-color: #fff;
}

.apply-block__btn_apply::after {
  mask: url("../../img/success.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: 16px auto;
}

.apply-block__btn_apply:hover {
  background: #24c869;
}

.apply-block__btn_reject:hover {
  background: #ff284f;
}

.apply-block__btn_reject::after {
  width: 14px;
  height: 14px;
  mask: url("../../img/fail.svg") no-repeat center;
  transform: translate(-50%, -50%);
  mask-size: 14px auto;
}

.rating__avatar {
  display: flex;
  min-height: 56px;
  padding: 0 18px;
}

.rate-block__wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 -5px;
}

.rate-block__btn {
  width: 150px;
  height: 50px;
  border: 1px solid #c3c2c9;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: 0.4s;
}

.rate-block__btn_small {
  width: 115px;
  height: 40px;
}

.radio_neutral:hover + .label,
.radio_neutral:checked + .label,
.radio_neutral.active + .label {
  background-color: rgb(195 194 201 / 60%);
  color: #471f8a;
  transition: 0.4s;
}

.radio_like:hover + .label,
.radio_like:checked + .label,
.radio_like.active + .label {
  background: #27ae60;
  border-color: #27ae60;
  color: #fff;
  transition: 0.4s;
}

.radio_like:hover + .label::after,
.radio_like:checked + .label::after,
.radio_like.active + .label::after {
  background-color: #fff;
}

.radio_dislike:hover + .label,
.radio_dislike:checked + .label,
.radio_dislike.active + .label {
  background: #ff284f;
  border-color: #ff284f;
  color: #fff;
  transition: 0.4s;
}

.radio_dislike:hover + .label::after,
.radio_dislike:checked + .label::after,
.radio_dislike.active + .label::after {
  background-color: #fff;
}

.rate-img {
  margin-top: 21px;
}

.rate-img__wrapper {
  display: flex;
  flex-direction: row;
  height: 80px;
  border: 1px dashed #b4b3bb;
  box-sizing: border-box;
  border-radius: 5px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: 0.4s;
}

.rate-img__label {
  flex: unset;
}

.rate-image__title {
  padding: 0;
  min-height: unset;
}

.rate-image {
  transition: 0.4s;
}

.rate-image img {
  height: 100%;
}

.rate-image__banner:hover::before {
  background-color: #b4b3bb;
}

.average {
  padding-left: 0;
}

.average::before {
  width: 0;
  height: 0;
  padding-left: 0;
}

.progress-bar {
  background: transparent;
  margin-top: auto;
}

.progress-bar_large {
  width: 410px;
}

.progress-bar__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 33px;
  background: #f4f4f6;
  position: relative;
  margin-bottom: 11px;
}

.progress-bar__title {
  font-size: 11px;
  line-height: 11px;
  padding-left: 13px;
  color: black;
  position: relative;
}

.progress-bar__title_white {
  color: #fff !important;
  position: relative;
  padding-left: 15px;
}

.progress-bar__progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 33px;
  z-index: 0;
  max-width: 100%;
}

.progress_red {
  background: #e7072d;
  box-shadow: 0 5px 20px rgb(255 40 79 / 40%);
}

.progress_yellow {
  background: #ffa515;
  box-shadow: 0 5px 20px rgb(255 165 21 / 40%);
}

.progress-bar__description {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  line-height: 16px;
  color: black;
}
.progress-bar__description.rating-tile {
  margin-top: auto;
}

.progress-bar__description span {
  padding-left: 4px;
}

.color-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 60px;
  height: 26px;
  background: #f4f4f6;
  border-radius: 3px;
}

.color-block_transparent {
  padding: 0 10px;
  min-width: max-content;
  background: transparent;
}

.color-block_p0 {
  padding: 0;
}

.color-block_small {
  min-width: 74px;
}

.color-block_medium {
  min-width: 90px;
}

.color-block_large {
  min-width: 160px;
}

.color-block__value {
  font-size: 13px;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 15px;
}

.color-block_transparent .color-block__value {
  color: #141515;
}

.color-block__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
}

.color-block__wrapper_align-left {
  justify-content: flex-start;
}

.color-block__value_bold {
  font-size: 12px;
}

.color-block__delim {
  font-size: 12px;
  line-height: 16px;
  margin: 0 2px;
  opacity: 0.3;
}

.color-block_typedefault {
  background: #f4f4f6;
  color: #7c7b85;
}

.color-block_type11 {
  background: rgb(255 206 215 / 60%);
  color: #ff284f;
}

.color-block_type12 {
  background: #f8da78;
  color: #a66c0f;
}

.color-block_type13 {
  background: #88d3a7;
  box-shadow: 0 2px 5px rgb(36 200 105 / 10%);
  color: #3f614d;
}

.color-block_type14 {
  background: #27ae60;
  box-shadow: 0 2px 5px rgb(36 200 105 / 10%);
  color: #fff;
}

.color-block_type15 {
  background: #07020e;
  box-shadow: 0 2px 5px rgb(191 192 196 / 10%);
  color: #30e37c;
}

.transparent-selector {
  position: relative;
  cursor: pointer;
  z-index: 0;
}

.transparent-selector::after {
  content: "";
  display: block;
  position: absolute;
  top: 12px;
  right: -5px;
  height: 5px;
  width: 10px;
  background-color: black;
  mask: url("../../img/arrow-down.svg") no-repeat center;
  mask-size: auto 5px;
  transition: 0.4s;
  transform: translateY(-60%);
  z-index: 2;
}

.transparent-selector__selector {
  appearance: none;
  position: relative;
  font-size: 16px;
  line-height: 21px;
  background: transparent;
  z-index: 1;
  border: none;
  padding: 0 6px;
  color: black;
  min-width: 100px;
  cursor: pointer;
}

.timer {
  display: flex;
  flex-direction: row;
}

.timer__description {
  font-size: 12px;
  line-height: 16px;
  color: rgb(255 255 255 / 60%);
}

.timer__wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 4px;
}

.timer__value {
  display: flex;
  flex-direction: row;
}

.timer-value,
.timer-description,
.timer__dots {
  font-size: 12px;
  line-height: 16px;
}

.timer-description {
  padding-left: 4px;
}

.timer__dots {
  position: relative;
  margin-left: 4px;
  margin-right: 7px;
}

.timer__dots::after {
  content: ":";
  position: absolute;
  top: 0;
  left: 0;
}

.c-tooltip-table {
  margin-bottom: 6px;
}

.c-tooltip-table__head-col:not(:first-child) {
  padding-left: 12px;
}

.c-tooltip-table__body-col:not(:first-child) {
  padding-left: 14px;
}

.menu-block__wrapper {
  display: flex;
  padding: 30px 40px;
}

.menu-block__wrapper_pb10 {
  padding-bottom: 10px;
}

.menu-block__wrapper_large {
  width: 960px;
}

.menu-block__wrapper_xlarge {
  width: 1180px;
}

.menu-block__content {
  display: flex;
  flex-direction: column;
}

.menu-block__title {
  font-size: 18px;
  line-height: 1;
  color: #161718;
  margin-bottom: 10px;
}

.menu-block__side {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.flex-basic_30 {
  flex-basis: 30%;
}

.upload-image__block {
  position: relative;
  cursor: pointer;
  display: flex;
  background: transparent;
  justify-content: center;
  box-shadow: none;
  transition: 0.4s;
}

.upload-image__block::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  mask: url("../../img/upload-img.svg") no-repeat center;
  mask-size: auto 50px;
  background-color: #b4b3bb;
  transition: 0.4s;
  transform: translate(-50%, -70%);
}

.upload-image__block:hover::after {
  background-color: black;
}

.upload-image__block.has-file::after {
  background-color: black;
}

.upload-image__title {
  transition: 0.4s;
  display: flex;
  margin-bottom: 45px;
  margin-top: auto;
  font-size: 11px;
  line-height: 11px;
  text-align: center;
  color: #7c7b85;
  align-self: center;
}

.upload-image {
  display: flex;
  flex-basis: 100%;
  margin: 0 9px;
  padding: 10px 0;
}

.upload-image__wrapper {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  height: 200px;
  border: 1px dashed #b4b3bb;
  box-sizing: border-box;
  border-radius: 5px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: 0.4s;
}

.upload-image__wrapper:hover {
  border-color: black;
}

.accordion-btn {
  cursor: pointer;
  margin: 0 auto;
}

.accordion-btn__wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: black;
  position: relative;
  transition: 0.4s;
}

.accordion-btn__wrapper::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 14px;
  width: 8px;
  mask: url("../../img/a-down.svg") no-repeat center;
  mask-size: auto 14px;
  transform: translate(-50%, -50%) rotate(-90deg);
  background-color: #fff;
  transition: 0.4s;
}

.accordion-btn__wrapper.collapsed {
  background: #fbfbfb;
  box-shadow: 0 2px 8px rgb(19 0 49 / 16%);
}

.accordion-btn__wrapper:hover.collapsed {
  box-shadow: unset;
}

.accordion-btn__wrapper.collapsed::after {
  transform: translate(-50%, -50%) rotate(90deg);
  background-color: black;
}

.hidden-setting {
  position: relative;
}

.hidden-setting__wrapper {
  display: none;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translate(50%, -50%);
  width: 22px;
  height: 22px;
  mask: url("../../img/gear.svg") no-repeat center;
  mask-size: auto 22px;
  background-color: #bfc0c4;
  transition: 0.4s;
  cursor: pointer;
}

.hidden-setting__wrapper:hover {
  background: #29006c;
}

.hidden-setting:hover .hidden-setting__wrapper {
  display: block;
}

.radio-link {
  display: flex;
  flex: 1;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #161718;
  font-size: 11px;
}

.radio-link.active {
  background: black;
  color: #fff;
}

.radio-link:hover {
  color: #161718;
}

.radio-link.active:hover {
  color: #fff;
}

.cell-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 -3px;
  justify-content: flex-start;
}

.cell-number {
  margin: 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 28px;
  background: #b4b3bb;
  border-radius: 3px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}

.cell-number_green {
  background: #27ae60;
  color: #fff;
}

.cell-number_red {
  background: #ff284f;
  color: #fff;
}

.score {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: #161718;
}

.score_green {
  color: #27ae60;
}

.score_red {
  color: #ff284f;
}

.score_gray {
  color: #bfc0c4;
}

.comment-debate {
  padding: 0 25px 25px;
}

.comment-debate:nth-child(4) {
  margin-top: 25px;
}

.comment__create-data--hm {
  margin-top: 10px;
}

.comment:first-child {
  margin-top: 25px;
}

.comment__wrapper {
  background: #f4f4f6;
  border-radius: 5px;
  padding: 25px 20px;
  overflow: hidden;
}

.comment__wrapper_400 {
  max-width: 400px;
  width: 400px;
}

.comment__wrapper_violet {
  background: #471f8a;
}

.comment__text {
  font-size: 16px;
  line-height: 22px;
  color: #161718;
  margin-top: 15px;
  margin-bottom: 0;
}

.comment__wrapper_violet .comment__text {
  color: #fff;
}

.comment__wrapper_right {
  margin-left: auto;
}

.comment__score {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 0;
}

.comment__score span {
  font-size: 16px;
  line-height: 22px;
  color: #161718;
  margin-right: 10px;
}

.comment__document-wrapper {
  display: flex;
  flex-flow: row nowrap;
  margin: 15px -5px 0;
}

.comment__document {
  width: 100px;
  height: 100px;
  margin: 0 5px 10px;
  box-shadow: 0 5px 10px rgb(2 5 14 / 20%);
  border-radius: 5px;
  overflow: hidden;
}

.comment__img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  align-self: center;
  overflow: hidden;
  position: relative;
  transition: 0.4s;
  cursor: pointer;
}

.comment__img:hover::after {
  content: "";
  background: rgb(41 0 108 / 20%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.comment__img img {
  max-height: 100%;
  text-align: center;
  transition: 0.4s;
}

.comment__file {
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  align-self: center;
  position: relative;
}

.comment__file:hover {
  color: #fff;
}

.comment__file-type {
  font-size: 28px;
  line-height: 32px;
}

.comment__file-name {
  position: absolute;
  width: 86px;
  left: 7px;
  bottom: 13px;
  font-size: 13px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
  overflow: hidden;
  text-align: center;
}

.comment__file__pdf {
  background: #ce1126;
}

.comment__file_xls,
.comment__file_xlsx {
  background: #107c41;
}

.comment__file_doc,
.comment__file_docx {
  background: #4466ad;
}

.comment__file_pptx,
.comment__file_ppt {
  background: #f14524;
}

.collapse-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.collapse-block__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px 100px 20px 40px;
  border-top: 1px solid rgb(164 170 178 / 40%);
  border-bottom: 1px solid rgb(164 170 178 / 40%);
  background: #fff;
  position: relative;
  z-index: 90;
}

.collapse-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.collapse-desc__title {
  font-size: 18px;
  line-height: 21px;
  color: #161718;
  margin-bottom: 5px;
}

.collapse-desc__wrapper {
  display: flex;
  flex-direction: row;
}

.collapse-desc__delim {
  width: 1px;
  margin: 0 10px;
}

.collapse-desc__delim span {
  display: block;
  width: 1px;
  height: 14px;
  background: rgb(196 196 196 / 50%);
}

.collapse-desc__text {
  font-size: 12px;
  line-height: 16px;
  color: rgb(124 123 133 / 60%);
}

.collapse-desc__text span {
  color: black;
  margin-left: 5px;
}

.collapse-block__header.collapsed::after {
  content: "";
  width: 21px;
  height: 11px;
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  background: url("../../img/down.svg") no-repeat center;
  background-size: 21px 11px;
  z-index: 100;
}

.collapse-block__header::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 8px;
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  mask-image: url("../../img/up.svg");
  background-color: black;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.collapse-block__header_hidden::after {
  display: none;
}

.collapse-block__body {
  padding: 25px;
  background: #f4f4f6;
  border-bottom: 1px solid rgb(164 170 178 / 40%);
}

.collapse-body {
  background: #fff;
  border: 1px solid rgb(180 179 187 / 40%);
  box-sizing: border-box;
  box-shadow: 0 8px 18px rgb(5 4 20 / 8%);
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 25px;
}

.collapse-delete {
  position: relative;
  width: 42px;
  min-width: 42px;
  height: 42px;
  cursor: pointer;
  margin-left: auto;
}

.collapse-delete::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 18px;
  width: 16px;
  background-color: #b4b3bb;
  mask: url("../../img/delete.svg") no-repeat center;
  mask-size: 16px 18px;
  transition: 0.4s;
}

.collapse-delete:hover::after {
  background-color: black;
}

.pa-rate {
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
}

.pa-rate_rate {
  font-size: 18px;
  line-height: 21px;
  color: #000;
}

.lesson-rate__rate {
  position: relative;
  padding-left: 20px;
  font-size: 12px;
  line-height: 16px;
  color: #161718;
}

.lesson-rate__rate::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  transition: 0.4s;
}

.lesson-rate__rate_like::after {
  background-color: #27ae60;
  mask: url("../../img/like.svg") no-repeat center;
  mask-size: 19px 16px;
}

.lesson-rate__rate_dislike::after {
  background-color: #ff284f;
  mask: url("../../img/dislike.svg") no-repeat center;
  mask-size: 19px 16px;
}

.hr-without-margin {
  margin: 0;
}

.client-card__register-info {
  background: #f4f4f6;
  border-radius: 10px;
  width: 100%;
  padding: 19px 14px 20px;
  position: relative;
}

.client-card__register-info-label {
  font-size: 12px;
  line-height: 16px;
  color: #7c7b85;
  margin-bottom: 5px;
}

.client-card__register-info-value {
  font-size: 12px;
  line-height: 16px;
  color: #000;
  margin-bottom: 19px;
}

.client-card__register-info-value:last-child {
  margin-bottom: 0;
}

.client-card__register-info-button {
  position: absolute;
  right: 14px;
  top: 21px;
  cursor: pointer;
}

.slide-toggle {
  width: 40px;
  height: 20px;
  padding: 3.3px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: #ff284f;
}

.slide-toggle_active {
  background: #27ae60;
}

.slide-toggle__thumb {
  background: #fff;
  box-shadow: 0 10px 20px rgb(8 15 32 / 20%);
  height: 13.3px;
  width: 13.3px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.slide-toggle_active .slide-toggle__thumb {
  transform: translateX(20px);
}

.hide {
  display: none;
}

.checks {
  margin-top: 20px;
}

.checks__panel {
  align-items: flex-start;
  flex-direction: column;
  border-top: 0;
}

.checks__panel .pagination {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}

.clients-list__panel {
  align-items: flex-start;
  flex-direction: column;
}

.clients-list__panel .pagination {
  margin-right: auto;
  margin-left: auto;
}

.bills__panel {
  width: 1272px;
}

.bills__wrapper--content {
  width: 1390px;
  padding-left: 0;
}

.transaction__panel {
  min-width: 1515px;
}

.statistic-payment {
  width: 100%;
}

.statistic-payment__upload {
  margin-left: 0;
}

.statistic-payment__download {
  margin-left: 15px;
}

.statistic-payment__counter {
  padding-right: 24px;
}

.statistic-payment__counter::after {
  display: none;
}

.payment-toggle {
  margin-left: 0;
}

.preloader-table {
  width: 100%;
}

.preloader-table td {
  width: 100%;
  text-align: center;
  transform: translateY(17px);
}

.crm-controls {
  max-width: 1274px;
}

.crm-controls__filter {
  cursor: pointer;
}

.crm-controls__icon {
  border: 0;
  background-color: #9c9ba6;
}

.crm-controls__filter:hover .crm-controls__icon {
  background-color: black;
}

.crm-controls__text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  transition: all 0.2s linear;
  color: #9c9ba6;
}

.crm-controls__filter:hover .crm-controls__text {
  color: black;
}

.content-main__inner {
  flex-direction: column;
}

.menu-content__payment {
  overflow-y: scroll;
  max-height: calc(100vh - 170px);
  padding-bottom: 40px;
}

.menu__content-main.custom-scroll {
  padding: 0 25px 20px;
  margin-top: 70px;
}

.menu__content-main.custom-scroll.menu__content-main_overy {
  height: calc(100vh - 150px);
  margin-top: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  width: calc(100vw - 590px);
}

.menu__content-main.custom-scroll.motivation-modify__content {
  margin-top: 70px;
}

.menu__content-main.custom-scroll.motivation-add__content {
  margin-top: 0;
}

.deal-button {
  visibility: hidden;
}

.product-button {
  visibility: hidden;
}

.rate-list {
  opacity: 0;
  pointer-events: none;
}

.empty-fields {
  margin-top: 20px;
}

.empty-fields__checkbox {
  padding: 20px;
}

.comment-discus {
  margin-left: 25px;
  margin-top: 25px;
}

.comment-discus.comment__wrapper_400.comment__wrapper_right {
  margin-right: 25px;
  margin-top: 25px;
  margin-left: auto;
}

.main-content.main-content_white-top-medium {
  min-width: auto;
  max-width: 100%;
}

.salary-manager {
  margin-right: 40px;
  overflow-x: scroll;
}

.estimation-list {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.estimation-list__item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 5px;
}

.estimation-list__item:first-child {
  margin-top: 0;
}

.history-panel__left {
  display: flex;
  align-items: center;
}

.monitor-panel {
  margin-top: 10px;
  padding-top: 20px;
  border-top: 1px solid rgb(156 155 166 / 40%);
  align-items: flex-end;
  min-width: auto;
  width: 100%;
  max-width: 100%;
}

.monitor-panel--notabs {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
  width: 1216px;
  min-width: 0;
}

.monitor-panel__table {
  margin-top: 20px;
  padding-top: 0;
  border-top: 0;
  min-width: auto;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.monitor-table {
  width: 100%;
}

.monitor-table__row {
  margin: 0;
  padding: 0;
  height: 55px;
  border-top: 1px solid #e4e5e8;
}

.monitor-table__row:first-child {
  border-top: 0;
  font-size: 14px;
}

.monitor-table__row:last-child {
  border-bottom: 1px solid #e4e5e8;
}

.monitor-table__col {
  margin: 0;
  padding: 0;
  text-align: center;
}

.monitor-manager__column {
  text-align: left;
  padding-left: 15px;
}

.monitor-manager__index {
  width: 36px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
}

.monitor-manager__info {
  width: 227px;
  text-align: left;
}

.monitor-manager__awards {
  width: 78px;
  border-right: 1px solid #e4e5e8;
}

.monitor-manager__order {
  border-right: 1px solid #e4e5e8;
}

.monitor-manager__payment {
  border-right: 1px solid #e4e5e8;
}

.monitor-manager__revenue {
  border-right: 1px solid #e4e5e8;
}

.comment-debate__date--white {
  color: white;
}

.row__wrapper {
  padding-left: 0;
}

.content-main__salary-manager {
  max-width: calc(100vw - 150px);
}

.employee-score__control {
  padding-left: 0;
}

.employee-score__control::before {
  width: 0;
  height: 0;
  border: 0;
  opacity: 0;
}

.monitor-manager__checked {
  border-right: 1px solid #e4e5e8;
}

.monitor-manager__estimation {
  border-right: 1px solid #e4e5e8;
}

.monitor-manager__dispute {
  border-right: 1px solid #e4e5e8;
}

.monitor-manager__change {
  border-right: 1px solid #e4e5e8;
}

.no-border {
  border-right: 0;
}

.deal-social__social-icon {
  margin: 0 auto;
}

.adv-panel {
  padding-top: 0;
  border-top: 0;
  margin-bottom: 20px;
}

.ta-center {
  text-align: center;
}

.pa-panel {
  width: 100%;
  max-width: 1730px;
}

.pa-counter {
  margin-right: 0;
  padding-right: 0;
}

.sales-panel {
  border-top: 0;
  padding-top: 0;
  margin-bottom: 20px;
}

.on-center {
  margin: 0 auto;
}

.mb-container-table {
  margin-bottom: 0;
}

.panel-links {
  border-top: 0;
  padding-top: 0;
  margin-bottom: 20px;
}

.audience {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 170px);
  overflow-x: hidden;
  padding-bottom: 40px;
  margin-top: 0;
}

.audience__head {
  display: flex;
  align-items: center;
}

.audience__head::after {
  display: none;
}

.audience-body {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.audience-body__row {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  height: auto;
}

.audience-checkbox {
  overflow: visible;
}

.audience-checkbox__label {
  transform: translateY(7px);
}

.audience-head__row {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.noscroll-block {
  overflow: visible;
}

.noscroll-block__checkbox {
  margin-top: 0 !important;
}

.staff__department {
  max-width: max-content;
}

.staff:first-child .staff__department {
  border-top: 0;
  padding-top: 0;
}

.content-main__product {
  padding-top: 0;
  border-top: 0;
}

.disabled {
  text-align: left;
  width: 100%;
}

.staff {
  width: 1215px;
}

.staff-table__avatar {
  width: 30px;
  min-width: 0;
  padding: 0 0 0 10px;
}

.staff-table__tel,
.staff-table__vk,
.staff-table__card,
.staff-table__birthday,
.staff-table__status,
.staff-table__password {
  width: 90px;
  padding: 0;
  min-width: 0;
}

.staff-table__name {
  width: 160px;
  padding: 0;
  min-width: 0;
}

.staff-table__key,
.staff-table__monitor,
.staff-table__settings,
.staff-table__projects {
  width: 50px;
  padding: 0;
  min-width: 0;
}

.staff-table__key .input-element__checkbox,
.staff-table__monitor .input-element__checkbox,
.staff-table__settings .input-element__checkbox,
.staff-table__projects .input-element__checkbox {
  margin-right: 0;
}

.staff-table__key .checkbox ~ .checkbox-label,
.staff-table__monitor .checkbox ~ .checkbox-label,
.staff-table__settings .checkbox ~ .checkbox-label,
.staff-table__projects .checkbox ~ .checkbox-label {
  left: 4px;
}

.staff-table__position {
  width: 150px;
  padding: 0;
  text-align: left;
  text-align-last: left;
}

.staff-table__employedType {
  width: 145px;
}

.staff-table__login {
  width: 100px;
  padding: 0;
}

.staff-table__header-row {
  display: flex;
  justify-content: space-between;
}

.staff-table__body-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button__estamation--list {
  padding-left: 15px;
  padding-right: 15px;
  width: 150px;
}

.content-main__company {
  width: 970px;

  .button {
    margin-left: auto;
  }
}

.monitor-panel__banner {
  margin-top: 20px;
  min-width: 0;
}

.content-main__transaction-table {
  flex-direction: column;
}

.content-main__bills-manager {
  display: flex;
  flex-direction: column;
}

.salary-employee {
  margin-left: 0;
}

.menu-input__title--small {
  font-size: 12px;
}

.deals-table {
  width: 1620px;
}

.deals__panel {
  width: 1620px;
}

.link-name__left {
  padding-left: 0;
}

.pa__wrapper {
  width: 1730px;
}

.pa-debate__col {
  min-width: 170px;
}

.pa-debate__panel {
  width: 1650px;
}

.projects__table {
  width: 100%;
}

.plans-bottom {
  padding-top: 0;
}

.content-streams {
  margin-top: 20px;
}

.checkbox-companies {
  padding-top: 0;
}

.menu-title {
  font-size: 14px;
  color: black;
}

.pa-column__name {
  text-align: left;
}

.monitor-table__header .monitor-table__row {
  height: 30px;
}

.pal-body__left {
  text-align: left;
}

.pal-header__col {
  text-align: left;
}

.manager-monitor__year-month {
  margin-top: auto;
}

.manager-header__year-month {
  height: 30px;
}

.transaction-content {
  max-width: 1340px;
}

.transaction-controls {
  width: 1340px;
}

.head-bills {
  width: 1340px;
}

.head-transaction,
.pa__content,
.pa-list__content,
.bills-tr {
  width: 1260px;
}

.ml_60 {
  margin-left: 60px;
}

.adv-links__content {
  width: 1260px;
}

.create-deal__funnel {
  padding: 0 25px;
  margin-top: 20px;
}

.button_white:hover {
  color: black;
}

.menu-input__delim--about {
  margin: 20px 25px 0;
}

.main-nav__logout {
  height: auto;
}

.main-nav__logout .logout {
  min-height: 82px;
  max-height: 82px;
  margin-top: 0;
}

.menu-input__title--p0 {
  padding: 0;
}

.bookmarks-delim::after {
  top: 12px;
}

.event-input__name {
  width: 455px;
}

.courses-font__choose {
  margin: 0;
}

.course-fonts__title {
  text-align: center;
}

.m_0 {
  margin: 0;
}

.m_3 {
  margin-top: 3px;
}

.menu-block__wrapper_pt10 {
  padding-top: 10px;
}

.streams__row {
  margin-top: 20px;
}

.stream-date {
  padding-top: 0;
  padding-bottom: 0;
}

.streams-list {
  padding-bottom: 30px;
}

.custom-table_m-0_p-0 {
  margin: 0;
  padding: 0;
}

.payment-method__content {
  max-width: 1215px;
}

.content-main__distribution {
  margin-top: 0;
}

.theme-title__faq::after {
  top: 12px;
}

.bills-table {
  padding-top: 0;
}

.transaction-table {
  padding-top: 0;
}

.deals-status__select--inner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px solid red;
  text-align: -webkit-center;
}

.logout-item {
  min-height: 82px;
  max-height: 82px;
}

.hm-deals__content {
  display: flex;
  flex-direction: column;
}

.transactions__content {
  display: flex;
  flex-direction: column;
}

.crm-deals__status {
  text-align: center;
}

.custom-table__head--text {
  font-size: 14px;
}

.column-text__type {
  margin-top: 5px;
}

.c-tooltip__calc-wage {
  left: -52px;
}

.c-tooltip__calc-wage--fixed {
  right: 0;
  top: 0;
  transform: translateY(calc(-100% - 10px));
  left: auto;
}

.c-tooltip.c-tooltip__wage:hover {
  background-color: inherit;
  color: inherit;
}

.ta-left {
  text-align: left;
}

.pl_0 {
  padding-left: 0 !important;
}

.menu-client__create-warning {
  font-size: 11px;
}

.menu__content--update-deal {
  width: 100%;
  background-color: #f4f4f6;
}

.menu__content-side.custom-scroll.menu__content-update-menu {
  width: 400px;
  padding-bottom: 0;
}

.menu__tasks {
  width: 100%;
  height: 100%;
  background-color: #f4f4f6;
}

.menu__wrapper--update {
  display: flex;
  flex-direction: row;
}

.menu-update__content {
  max-width: 400px;
}

.menu-tasks__inner {
  width: 860px;
}

.menu-tasks__form {
  display: flex;
}

.menu-tasks__add {
  display: flex;
  flex-direction: row;
  padding: 20px 15px 15px 20px;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-radius: 10px;
  height: 100px;
  background-color: white;
  margin-top: 0;
}

.menu-tasks__selector {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #e4e5e8;
  margin-top: 20px;
}

.menu-tasks__title {
  color: #7c7b85;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;
}

.menu-tasks__calendar {
  display: flex;
  flex-direction: column;
  position: relative;
}

.menu-tasks__datepicker {
  width: 150px;
  min-height: 40px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid #c3c2c9;
  margin-top: 10px;
  padding: 0 16px;
}

.menu-tasks__control {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.menu-tasks__text {
  width: 495px;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 17px;
  border-radius: 5px;
  border: 1px solid #c3c2c9;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.menu-tasks__btn {
  width: 140px;
  height: 40px;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  background-color: black;
  border-radius: 5px;
  border: 0;
  transition: all 0.2s linear;
}

.menu-tasks__btn:hover {
  color: black;
  background-color: transparent;
  border: 1px solid black;
}

.menu-tasks__head-title {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-align: left;
}

.menu-tasks__error {
  color: red;
  margin-top: 20px;
}

.task {
  display: flex;
  background-color: white;
  height: 70px;
  padding: 15px 20px;
  box-shadow: 0 4px 8px rgb(5 4 20 / 6%);
  border-radius: 5px;
  border: 1px solid #e4e4e4;
}

.task:not(:first-child) {
  margin-top: 10px;
}

.task__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.task__status {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 100%;
  margin-right: 15px;
  position: relative;
}

.task__status.reminder__off,
.reminder.reminder__off {
  background-color: #ff284f;
}

.task__status.reminder__on,
.reminder.reminder__on {
  background-color: lightgray;
}

.task__status.reminder__now,
.reminder.reminder__now {
  background-color: #ffc000;
}

.task__status.reminder__done,
.reminder.reminder__done {
  background-color: #27ae60;
}

.task__status.reminder__done::after,
.reminder.reminder__done::after {
  content: "✓";
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.task__date {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 35px;
}

.deal-reminder_yellow::before {
  background-color: #ffc000;
}

.task__message {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  width: 100%;
  margin-right: 35px;
  overflow: hidden;
}

.task__through {
  text-decoration: line-through;
}

.task__complete--btn {
  min-width: 100px;
  max-width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  border-radius: 5px;
  border: 1px solid black;
  margin-right: 20px;
  cursor: pointer;
  transition: all 0.2s linear;
}

.task__complete--btn:hover {
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 20%);
}

.task__delete,
.task__remove {
  display: inline-flex;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  mask-image: url("../../img/delete.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
}

.blocks {
  width: 1260px;
  max-width: 1260px;
  padding: 20px 40px;
}

.blocks__wrapper {
  display: flex;
  flex-direction: column;
}

.blocks__content {
  display: flex;
  flex-direction: column;
}

.blocks__btn-wrapper {
  display: flex;
}

.filter-column_row {
  flex-flow: row nowrap;
  margin: 0 -10px;
}

.create-tariff {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed rgb(0 0 0 / 50%);
  transition: all 0.2s linear;
  color: rgb(0 0 0 / 50%);
  border-radius: 10px;
  padding: 0;
  background-color: transparent;
}

.create-tariff__icon {
  width: 50px;
  height: 50px;
  border: 1px solid rgb(0 0 0 / 50%);
  border-radius: 100%;
  position: relative;
  transition: all 0.2s linear;
  margin: 0 auto;
}

.create-tariff__icon::after {
  content: "";
  display: flex;
  width: 20px;
  height: 1px;
  background-color: rgb(0 0 0 / 50%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s linear;
}

.create-tariff:hover {
  border: 1px dashed black;
  color: black;
  cursor: pointer;
}

.create-tariff__icon::before {
  content: "";
  display: flex;
  width: 20px;
  height: 1px;
  background-color: rgb(0 0 0 / 50%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  transition: all 0.2s linear;
}

.create-tariff:hover .create-tariff__icon {
  border: 1px solid black;
}

.create-tariff:hover .create-tariff__icon::after {
  background-color: black;
}

.create-tariff:hover .create-tariff__icon::before {
  background-color: black;
}

.create-tariff__block {
  width: 100px;
  height: 80px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
}

.create-tariff__text {
  margin-top: 10px;
  text-align: center;
  margin-bottom: 0;
}

.create-tariff__checkbox,
.update-tariff__checkbox {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.create-tariff__checkbox:first-child,
.update-tariff__checkbox:first-child {
  margin-top: 10px;
}

.tariff__wrapper {
  display: flex;
  flex-flow: row wrap;
  width: 910px;
}

.menu-tariff__delim {
  margin-top: 20px;
}

.menu-tariff__blocks {
  margin-top: 20px;
  padding-top: 0;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
}

.create-tariff__block-checkbox {
  margin-right: 10px;
}

.update-tariff__block-checkbox {
  margin-right: 10px;
}

.checkbox ~ .create-tariff__name {
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.checkbox ~ .update-tariff__name {
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.tariff-block {
  width: 290px;
  height: 110px;
  padding: 20px 30px 20px 25px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: 0 10px 20px rgb(8 15 32 / 4%);
  border: 1px solid #e6e5ee;
}

.tariff-block:nth-child(3n) {
  margin-right: 0;
}

.tariff-block:nth-child(n+4) {
  margin-top: 20px;
}

.tariff-block:last-child {
  margin-right: 0;
}

.tariff-block__title {
  display: flex;
  justify-content: space-between;
}

.tariff-block__name {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tariff-block__settings {
  display: inline-flex;
  width: 22px;
  height: 22px;
  mask-image: url("../../img/gear.svg");
  background-color: #7c7b85;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  cursor: pointer;
  transition: all 0.2s linear;
}

.tariff-block__settings:hover {
  background-color: black;
}

.tariff-block__info {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.tariff-block__block-count {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #7c7b85;
}

.tariff-block__price {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: right;
}

.tab-content__background {
  background-color: white;
}

.menu__header--courses {
  min-height: 130px;
}

.menu-input_block-menu .menu-input__transparent {
  background-color: transparent;
}

.menu-input__header {
  margin-top: -9px;
}

.menu-block__content--visible {
  overflow: visible;
}

.filter-body.custom-scroll {
  padding-top: 0;
}

td[homework-rate=""] {
  min-height: 60.5px;
  align-items: center;
}

.tariff__name {
  position: relative;
  margin-right: 10px;
  pointer-events: none;
}

.tariff__price {
  pointer-events: none;
}

.tariff__name::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 10px;
  background-color: lightgray;
  position: absolute;
  top: 1px;
  right: -5px;
  pointer-events: none;
}

.no-selector::after {
  display: none;
  margin-right: 0;
}

div[js-update-form-deal-reminder-btn=""] {
  margin-bottom: 20px;
}

.tariffValue {
  width: 0;
  height: 0;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.pt_0 {
  padding-top: 0;
}

.b_0 {
  border: 0;
}

.b_0:hover {
  border: 0;
}

.table-body__row--funnel {
  height: 24px;
}

.reminder-row {
  display: inline-flex;
  font-size: 11px;
  margin-top: 5px;
  width: 100%;
}

.task-tooltip__wrapper {
  min-width: 240px;
  max-width: 240px;
  overflow: hidden;
}

.task-tooltip__table {
  color: white;
  width: 100%;
}

.task-tooltip__date {
  width: 25%;
}

.task-tooltip__message {
  width: 75%;
}

.task-tooltip__status {
  width: 25%;
}

.update-reminder {
  padding-left: 0;
}

div[js-menu-update-deal] {
  z-index: 5;
}

div[js-menu-client-card] {
  z-index: 3;
}

div[js-menu-create-deal] {
  z-index: 4;
}

.menu-wrapper__column {
  flex-direction: column;
}

.payment-wrapper__distributed {
  flex-direction: column;
  width: 100%;
}

.fs_11 {
  font-size: 11px;
  line-height: 11px;
}

.button__create-client {
  padding: 13px;
}

div[js-menu-bills] {
  z-index: 6;
}

div[js-menu-view-deal] {
  z-index: 5;
}

div[js-menu-update-reminder] {
  z-index: 6;
}

.reminder__past {
  color: #ff2e2e;
}

.deal-task__indicator {
  cursor: pointer;
}

.deal-reminder:hover .reminder-wrapper {
  opacity: 1;
  visibility: visible;
}

.deal-task__indicator:hover .reminder-wrapper {
  opacity: 1;
  visibility: visible;
}

.menu-list_deal-create {
  height: calc(100vh - 150px);
  min-height: calc(100vh - 150px);
  max-height: calc(100vh - 150px);
}

input[type="number"] {
  appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
  appearance: number-input;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.button-client__edit {
  transition: all 0.2s linear;
}

.button-client__edit:disabled {
  cursor: default;
  background-color: #f0f0f0;
  color: #b9b9b9;
  border: 1px solid #f0f0f0;
}

div[js-register-info] {
  margin-bottom: 25px;
}

.menu-block__bg--white {
  background-color: white;
}

.update-deal__btn:disabled {
  background-color: #f0f0f0;
  color: #b9b9b9;
  border: 1px solid #f0f0f0;
  cursor: default;
}

.demo-420 {
  width: 420px;
}

div[js-menu-add-comment] {
  z-index: 6;
}

.table-rate__row {
  transform: translateY(0);
}

.table-rate__tbody {
  transform: translateY(0);
}

div[js-menu] {
  z-index: 1250;
}

.content-setting.adv-nav {
  justify-content: space-between;
}

.adv-nav__right {
  display: flex;
  align-items: center;
}

.adv-right__btn {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  mask-image: url("../../img/reprocess.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  cursor: pointer;
  margin-right: 15px;
  border: 0;
}

.adv-right__btn:hover {
  background-color: black;
  color: black;
}

.link-row-header {
  display: grid;
  width: 970px;
  grid-template-columns: 350px 200px 350px 40px;
  align-content: center;
  padding-left: 15px;
}

.link-row {
  display: grid;
  width: 970px;
  grid-template-columns: 350px 200px 350px 40px;
  align-content: center;
  padding-left: 15px;

  &:nth-child(2) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &__link {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.link-row-adv {
  width: 1215px;
  padding-right: 25px;
  display: grid;
  grid-template-columns: 365px 425px 350px 40px;

  &-inside {
    grid-template-columns: 184px 181px 425px 321px 104px;
  }

  &:nth-child(2) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:nth-last-child(2) {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.link-row__remove {
  display: inline-block;
  width: 15px;
  height: 17px;
  cursor: pointer;

  &::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    mask-image: url("../../img/delete-btn.svg");
    background-color: lightgray;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
    transition: background-color 0.2s linear;
  }

  &:hover {
    &::after {
      background-color: black;
    }
  }
}

.custom-table__body.custom-table__body_white.advertising-table {
  width: 100%;
  display: inline-table;
}

.content-setting__right {
  display: flex;
}

.adv-nav__right--check-auth {
  background: transparent;
  border: 1px solid black;
  border-radius: 5px;
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  transition: all 0.2s linear;
  margin-right: 15px;
}

.adv-nav__right--check-auth:hover {
  background-color: black;
  color: white;
}

.adv-nav__right--check-auth::after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  mask-image: url("../../img/lock-solid.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  background-color: black;
  transition: all 0.2s linear;
}

.adv-nav__right--check-auth:hover::after {
  background-color: white;
}

div[distribute-submit-btn] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-outside__content {
  height: 100%;
}

.outside-select {
  font-size: 14px;
  line-height: 14px;
}

.additional-funnel__settings {
  height: 10%;
}

.update-lesson__menu {
  width: 520px;
}

.create-employee__list {
  padding-bottom: 20px;
}

.salary__wrapper {
  min-width: 1215px;
  width: 1215px;
  overflow-y: hidden;
}

.settings-projects-row {
  display: flex;
  width: 970px;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;

  &:nth-child(2) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom: 0;
  }
}

.project-header__row {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
}

.projects-header__name {
  width: 384px;
}

.projects-header__settings {
  width: 68px;
}
