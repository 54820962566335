.platform-form__access-btn.rate-btn {
  min-width: 75px;
  width: 75px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid $black;
  color: $white;
  background-color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
  margin: 0;
}

.platform-form__access-btn.rate-btn:hover {
  color: $black;
  background-color: transparent;
}

.crm {
  margin-top: 20px;
  border-top: 1px solid rgb(191 192 196 / 40%);
  padding-top: 20px;
}

.price-info.green .column_price,
.price-info.green .column_paid {
  color: white;
}

.reminder__add {
  width: 115px;
  height: 36px;
  display: inline-block;
  border: 2px dashed #f3f3f3;
  position: relative;
  transition: all 0.2s linear;
  border-radius: 5px;
}

.reminder__add::after,
.reminder__add::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 13px;
  height: 2px;
  border-radius: 2px;
  background-color: #f3f3f3;
  transition: all 0.2s linear;
}

.reminder__add::before {
  transform: translate(-50%, -50%) rotate(90deg);
}

.reminder__add:hover {
  border: 2px dashed lightgray;
}

.reminder__add:hover::before,
.reminder__add:hover::after {
  background-color: lightgray;
}

.course-crm--icon:hover {
  .tooltip-course__start {
    opacity: 1;
  }
}
