@import "../helpers/variables";

.templates {
  margin-top: 0;
}

.templates__wrapper {
  width: 1260px;
}

.platform-modal__wrapper.transparent-wrapper.template-wrapper {
  width: 520px;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
}

.template-wrapper__form {
  min-width: 100%;
}

.template-form__header {
  width: 100%;
  height: 70px;
  display: flex;
  padding: 0 40px;
  box-shadow: 0 5px 25px rgb(7 2 14 / 4%);
  align-items: flex-start;
  justify-content: center;
}

.template-form__body {
  min-height: calc(100vh - 150px);
  padding: 25px;
}

.template-form__name {
  position: relative;
}

.template-form__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  height: 80px;
}

.template-form__close {
  display: inline-block;
  width: 23px;
  height: 23px;
  position: absolute;
  top: 25px;
  right: 0;
  cursor: pointer;
}

.template-form__close::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  mask-image: url("../../img/cancel.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
}

.template-form__close:hover::after {
  background-color: black;
}

.template-form__input-name {
  padding-left: 15px;
}

.layers {
  position: relative;
  padding: 0;
}

.layers__info {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 100%;
  color: black;
  font-weight: 500;
  top: 0;
  right: 0;
  position: absolute;
  font-size: 13px;
  transition: all 0.2s linear;
  cursor: pointer;
}

.layers__info:hover {
  background-color: black;
  color: white;
}

.layers-info__tooltip {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.2s linear;
  display: inline-flex;
  border-radius: 8px;
  background-color: black;
  color: white;
  position: absolute;
  transform: translateY(calc(-100% + 30px));
  font-size: 11px;
  line-height: 14px;
  padding: 0;
  overflow: hidden;
  right: 30px;
  top: 1000%;
  z-index: 1;
}

.layers__info:hover .layers-info__tooltip {
  opacity: 1;
  min-width: 350px;
  height: auto;
  padding: 10px;
}

.layers__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.layers-item__dates {
  font-size: 12px;
  line-height: 12px;
  display: flex;
  margin-top: 10px;
}

.layers-item__date {
  display: flex;
  align-items: center;
  position: relative;
}

.layers__input {
  border: 1px solid #c3c2c9;
  border-radius: 5px;
  padding-left: 15px;
}

.layers-item {
  width: calc(50% - 20px);
  position: relative;
}

.layers-item__name {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 5px;
}

.layers-item__delete {
  height: 18px;
  margin-top: auto;
  width: 17px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;
  cursor: pointer;
}

.layers-item__delete::after {
  content: "";
  position: relative;
  width: 17px;
  height: 18px;
  mask-image: url("../../img/delete.svg");
  background-color: lightgray;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
}

.layers-item__delete:hover::after {
  background-color: black;
}

.calendar-icon::after {
  content: "";
  display: inline-flex;
  position: absolute;
  top: 49px;
  right: 40px;
  width: 17px;
  height: 18px;
  mask-image: url("../../img/calendar.svg");
  background-color: #c3c2c9;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.templates__bill-button {
  width: 100%;
  height: 40px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: white;
  border: 1px solid black;
  transition: background-color 0.2s linear, color 0.2s linear;
  font-size: 12px;
  line-height: 12px;
}

.templates__bill-button:hover {
  color: white;
  background-color: black;
  border: 1px solid black;
}

.templates-btn__wrapper {
  padding-top: 20px;
  border-top: 1px solid #7c7b851a;
}

.templates-btn__wrapper.mt_20 {
  margin-top: 20px;
}

.templates__nav {
  display: flex;
  justify-content: flex-end;
}

.templates__add-btn {
  display: inline-flex;
  align-items: center;
  width: max-content;
  font-size: 12px;
  height: 40px;
  line-height: 12px;
  padding: 0 25px;
  border: 1px solid black;
  border-radius: 5px;
  color: $white;
  background-color: $black;
  cursor: pointer;
  transition: all 0.2s linear;
}

.templates__content {
  display: flex;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(#c4c4c4, 0.2);
  flex-flow: row wrap;
  max-width: 1215px;
  margin-bottom: 40px;
}

.templates__item {
  width: 310px;
  height: 140px;
  margin-right: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 25px 20px 0;
  cursor: pointer;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s linear;
}

.templates__item:hover {
  box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
}

.templates__item:nth-child(3n) {
  margin-right: 0;
}

.templates__item:nth-child(n+4) {
  margin-top: 20px;
}

.templates__structure {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #c4c4c433;
  font-size: 14px;
  line-height: 14px;
  height: 50px;
  pointer-events: none;
}

.layers-item__wrapper {
  display: flex;
  justify-content: space-between;
}

.templates__add-btn:hover {
  background-color: transparent;
  color: $black;
}

.templates__name {
  pointer-events: none;
}

.percent-icon {
  &::after {
    content: "%";
    position: absolute;
    top: 35px;
    right: 12px;
    font-size: 14px;
  }
}
