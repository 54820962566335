.products {
  margin-top: 10px;
  margin-bottom: 30px;
}

.products__title {
  margin-bottom: 25px;
  display: flex;
}

.products__list {
  display: flex;
  flex-wrap: wrap;
  width: 1215px;
  margin: 0;
}

.product-block {
  width: 288px;
  height: 240px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0 20px 0 0;
  overflow: hidden;
  transition: 0.1s;

  &:nth-child(4n) {
    margin-right: 0;
  }

  &:nth-child(n + 5) {
    margin-top: 20px;
  }

  &.archive {
    filter: grayscale(1);
  }
}

.product-block:hover {
  box-shadow: 0 2px 24px rgb(26 61 107 / 8%), 0 0 2px rgb(38 54 75 / 8%);
}

.product-block_create {
  border: 1px dashed #b4b3bb;
  position: relative;
  cursor: pointer;
  display: flex;
  background: transparent;
  justify-content: center;
  box-shadow: none;
  transition: 0.4s;
}

.product-block_create::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  mask: url("../../img/plus.svg") no-repeat center;
  mask-size: auto 50px;
  background-color: #b4b3bb;
  transition: 0.4s;
  transform: translate(-50%, -70%);
}

.product-block_create:hover {
  border-color: black;
}

.product-block_create:hover::after {
  background-color: black;
}

.product-block__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  cursor: pointer;
  padding: 23px 20px 20px;
}

.product-block__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.product-block__background img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  margin-bottom: 15px;
  margin-right: 15px;
}

.product-block__wrapper.dark {
  color: #161718;
  border-color: rgb(255 255 255 / 40%);
}

.product-block__wrapper.light {
  color: #fff;
  border-color: rgb(255 255 255 / 40%);
}

.product-block__title {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 10px;
}

.product-block__type {
  box-sizing: border-box;
  border-radius: 3px;
  height: auto;
  padding: 6px 10px;
  font-size: 10px;
  line-height: 12px;
  width: max-content;
  border: 1px solid;
  margin-bottom: 12px;
}

.product-block__text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
  z-index: 10;
}

.product-block__text_gray {
  color: #9b9b9e;
}

.product-block__text_desc {
  max-width: 120px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 10;
}

.product-block__price {
  font-size: 20px;
  line-height: 18px;
  margin-top: auto;
}

.product-block__message {
  font-size: 12px;
  line-height: 12px;
  color: #b4b3bb;
  transition: 0.4s;
  display: flex;
  margin-bottom: 30px;
  margin-top: auto;
}

.product-block_create:hover .product-block__message {
  color: black;
}

.menu-block__btn--archive {
  display: inline-block;
  padding: 0 7px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 2em;

  &.active {
    margin-left: 5px;
    background-color: black;
    color: white;
    font-weight: 100;
    border-color: black;
  }
}

.archive {
  font-size: 12px;
  line-height: 12px;
}

.platform-modal__file-name {
  max-width: 239px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card-figuration__file-name {
  max-width: 201px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.course__start {
  display: inline-block;
}
