.preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f4f4f6;
  text-align: center;
}

.transition-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background: rgb(4 7 36 / 40%);
  display: none;
  transition: display 0.4s;
  user-select: none;
}

.transition-loader.show {
  display: block;
}

.transition-loader-inner {
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  width: 100%;
  color: black;
  padding: 0 100px;
  text-align: center;
  user-select: none;
  pointer-events: none;
}

.transition-loader-inner label {
  font-size: 1em;
  padding: 1%;
  opacity: 0;
  display: inline-block;
  user-select: none;
}

.transition-loader-inner label::after {
  font-family: FontAwesome, sans-serif;
  content: "\f111";
  user-select: none;
}

.transition-loader-inner label:nth-child(1) {
  animation: loader 3s 600ms infinite ease-in-out;
  user-select: none;
}

.transition-loader-inner label:nth-child(2) {
  animation: loader 3s 500ms infinite ease-in-out;
  user-select: none;
}

.transition-loader-inner label:nth-child(3) {
  animation: loader 3s 400ms infinite ease-in-out;
  user-select: none;
}

.transition-loader-inner label:nth-child(4) {
  animation: loader 3s 300ms infinite ease-in-out;
  user-select: none;
}

.transition-loader-inner label:nth-child(5) {
  animation: loader 3s 200ms infinite ease-in-out;
  user-select: none;
}

.transition-loader-inner label:nth-child(6) {
  animation: loader 3s 100ms infinite ease-in-out;
  user-select: none;
}

@keyframes loader {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  33% {
    opacity: 1;
    transform: translateX(0);
  }

  66% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}

@keyframes loader {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  33% {
    opacity: 1;
    transform: translateX(0);
  }

  66% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}
