.platform__checkbox {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.platform__checkbox--fake {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #c3c2c9;
  border-radius: 3px;
  transition: all 0.2s linear;
  position: relative;
  background-color: white;
}

.platform__checkbox--fake.radio-fake {
  border-radius: 100%;
}

.platform__checkbox--fake.radio-fake::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.2s linear;
  color: white;
  font-size: 12px;
  line-height: 12px;
  background-color: black;
  border-radius: 100%;
}

.platform__checkbox--fake::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.2s linear;
  color: white;
  font-size: 12px;
  line-height: 12px;
}

.platform-checkbox__label--button {
  width: 33.3333%;
  height: 40px;
  background-color: transparent;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  transition: all 0.2s linear;
  border: 1px solid black;
  cursor: pointer;
}

.platform-checkbox__label--button:nth-child(2) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
}

.platform-checkbox__label--button:nth-child(6) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0;
}

.platform__checkbox:checked ~ .platform-checkbox__label .platform__checkbox--fake {
  background-color: black;
}

.platform__checkbox:checked ~ .platform-checkbox__label .platform__checkbox--fake.radio-fake {
  background-color: transparent;
}

.platform__checkbox:checked ~ .platform-checkbox__label .platform__checkbox--fake::after {
  opacity: 1;
}

.platform__checkbox:checked ~ .platform-checkbox__label .platform__checkbox--fake.radio-fake::after {
  opacity: 1;
}

.platform__checkbox:checked + .platform-checkbox__label--button {
  background-color: black;
  color: white;
}

.platform-checkbox__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.platform-checkbox__label--value {
  margin-left: 10px;
  font-size: 12px;
  line-height: 12px;
}
