.btn {
  cursor: pointer;
  user-select: none;
  transition: none;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  padding: 0;
  border: 0;
  outline: 0;
  background: 0 0;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  border-radius: 0;
}

.btn_default {
  cursor: default;
}

.btn__content {
  transition: 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  padding: 0 26px;
  outline: 0;
  box-shadow: none;
  background-color: #471f8a;
  color: #fff;
  border: 1px solid transparent;
}

.btn__content_small {
  min-width: 100px;
}

.btn__content_medium {
  min-width: 160px;
}

.btn__content_large {
  min-width: 200px;
}

.btn__content_xlarge {
  min-width: 240px;
}

.btn_outline .btn__content {
  background: 0 0;
  border: 1px solid black;
  color: black;
}

.btn_outline .btn__retake {
  background: 0 0;
  border: 0;
  color: black;
  border-radius: 5px;
}

.btn_outline-gray .btn__content {
  background: 0 0;
  border: 1px solid #c3c2c9;
  color: #c3c2c9;
}

.btn_green .btn__content,
.btn_rated .btn__content {
  background: #27ae60;
  border: 1px solid #27ae60;
  color: #fff;
}

.btn_orange .btn__content,
.btn_check .btn__content {
  background: #ffa515;
  border: 1px solid #ffa515;
  color: #fff;
}

.btn_violet .btn__content,
.btn_process .btn__content {
  background: #481e8c;
  border: 1px solid #481e8c;
  color: #fff;
}

.btn_black .btn__content,
.btn_process .btn__content {
  background: black;
  border: 1px solid black;
  color: #fff;
}

.btn_enable .btn__content,
.btn_disable .btn__content {
  background: rgb(191 192 196 / 30%);
  color: #481e8c;
}

.btn:disabled {
  cursor: default;
}

.btn:disabled .btn__content {
  background: 0 0;
  border: 1px solid #efeff0;
  color: #b4b3bb;
}

.btn__title {
  padding: 18px 0;
}

.btn__title_small {
  padding: 12px 0;
}

.btn__title_xsmall {
  padding: 8px 0;
}

.btn__title_medium {
  min-height: 60px;
  padding: 12px 0;
}

.btn_title-text {
  font-size: 16px;
  line-height: 1;
}

.btn_title-text_small {
  font-size: 12px;
}

.btn__icon {
  padding-left: 30px;
  position: relative;
}

.btn__icon_arrow-left::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 15px;
  width: 20px;
  background-color: black;
  mask: url("../../img/arrow-left.svg") no-repeat center;
  mask-size: 20px 15px;
  transition: 0.4s;
  transform: translateY(-50%);
}

.btn__icon_rated::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #1a994f;
  transition: 0.4s;
  transform: translateY(-50%);
}

.btn__icon_rated::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 6px;
  height: 7px;
  width: 9px;
  background-color: #fff;
  mask: url("../../img/arrow-check.svg") no-repeat center;
  mask-size: 9px 7px;
  transition: 0.4s;
  transform: translateY(-50%);
}

.btn__icon_check::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgba(255 255 255 / 60%);
  mask: url("../../img/clock-icon.svg") no-repeat center;
  mask-size: 20px;
  transition: 0.4s;
  transform: translateY(-50%);
}

.btn__icon_process::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 18px;
  width: 13px;
  background-color: rgb(255 255 255 / 60%);
  mask: url("../../img/play-icon.svg") no-repeat center;
  mask-size: 13px 18px;
  transition: 0.4s;
  transform: translateY(-50%);
}

.btn__icon_like::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 23px;
  background-color: #27ae60;
  mask: url("../../img/like.svg") no-repeat center;
  mask-size: 23px 20px;
  transition: 0.4s;
  transform: translateY(-50%);
}

.btn__icon_dislike::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 23px;
  background-color: #ff284f;
  mask: url("../../img/dislike.svg") no-repeat center;
  mask-size: 23px 20px;
  transition: 0.4s;
  transform: translateY(-50%);
}

.upload-btn__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -10px;
  margin-right: -10px;
}

.upload-btn__item {
  width: 100%;
  border: 1px dashed #b4b3bb;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.4s;
}

.upload-btn__item:hover {
  border-color: black;
}

.upload-btn__item-title {
  padding-left: 60px;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.2;
  color: #7c7b85;
  transition: 0.4s;
}

.upload-btn__item:hover .upload-btn__item-title {
  color: black;
}

.upload-btn__item-title::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 50px;
  width: 50px;
  mask: url("../../img/upload-img.svg") no-repeat center;
  mask-size: auto 50px;
  background-color: #b4b3bb;
  transition: 0.4s;
  transform: translateY(-50%);
}

.upload-btn__item:hover .upload-btn__item-title::after {
  background: black;
}

.upload-btn__item-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-btn__item-label {
  display: flex;
  justify-content: center;
  padding: 35px 0;
  cursor: pointer;
}

.upload-btn__item-title_file::after {
  mask: url("../../img/upload-file.svg") no-repeat center;
}
