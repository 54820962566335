.clients__list {
  width: 1215px;
  min-width: 1215px;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 40px;
  border-top: 1px solid #f1f1f3;
  padding-top: 20px;
}

.clients__item {
  width: 288px;
  height: auto;
  background-color: white;
  box-shadow: 0 8px 18px 0 rgb(5 4 20 / 8%);
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s linear;
}

.clients__item:hover {
  cursor: pointer;
  box-shadow: 0 8px 18px 0 rgb(5 4 20 / 20%);
}

.clients__item:first-child {
  margin-top: 0;
}

.clients__item:nth-child(2) {
  margin-top: 0;
}

.clients__item:nth-child(3) {
  margin-top: 0;
}

.clients__item:nth-child(4) {
  margin-top: 0;
}

.clients__item:nth-child(4n) {
  margin-right: 0;
}

.clients__info {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 23px;
}

.clients__name {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  max-width: calc(100% - 49px);
  display: inline-block;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clients-panel {
  width: 1181px;
}

.clients-social {
  display: flex;
  width: 44px;
  justify-content: flex-end;
}

.clients-social__vk {
  display: flex;
  width: 20px;
  height: 20px;
  position: relative;
  padding: 0;
}

.clients-social__vk::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url("../../img/vk.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.clients-social__wa {
  display: flex;
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 4px;
}

.clients-social__wa::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url("../../img/wa.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.clients-contacts {
  padding: 0 20px;
}

.clients-contacts a {
  text-decoration: none;
  color: black;
}

.clients-contacts a:hover {
  text-decoration: underline;
}

.clients-contacts__vk {
  margin-top: 17px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0;
}

.clients-contacts__vk .clients-contacts__icon {
  display: flex;
  width: 16px;
  height: 9px;
  position: relative;
  margin-right: 10px;
}

.clients-contacts__vk .clients-contacts__icon::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/vk.svg");
  background-color: #bfc0c4;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.clients-contacts__phone {
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0;
}

.clients-contacts__phone .clients-contacts__icon {
  display: flex;
  width: 16px;
  height: 16px;
  position: relative;
  margin-right: 10px;
}

.clients-contacts__phone .clients-contacts__icon::before {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  content: "";
  mask-image: url("../../img/phone.svg");
  background-color: #bfc0c4;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transform: translate(-50%, -50%);
}

.clients-contacts__email {
  margin-top: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 12px;
  padding: 0;
}

.clients-contacts__email .clients-contacts__icon {
  display: flex;
  margin-right: 10px;
  width: 16px;
  height: 16px;
  position: relative;
}

.clients-contacts__email .clients-contacts__icon::before {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  content: "";
  mask-image: url("../../img/email.svg");
  background-color: #bfc0c4;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transform: translate(-50%, -50%);
}

.clients-from {
  margin-top: auto;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 23px;
  border-top: 1px solid #f1f1f4;
}

.clients-from__who {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  align-items: center;
  width: 150px;
}

.clients-from__who .clients-from__icon {
  display: flex;
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 10px;
}

.clients-from__who .clients-from__icon::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/manager.svg");
  background-color: #bfc0c4;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.clients-from__when {
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  align-items: center;
}

.clients-from__when .clients-from__icon {
  display: flex;
  width: 15px;
  height: 15px;
  position: relative;
}

.clients-from__when .clients-from__icon::before {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  mask-image: url("../../img/calendar.svg");
  background-color: #bfc0c4;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
}

.button_client-add {
  transition: all 0.2s linear;
  position: relative;
  margin-left: 52px;
}

.button_client-add:hover {
  color: black;
  box-shadow: 0 8px 15px 0 rgb(0 0 0 / 20%);
}

.button_client-add::before {
  display: flex;
  width: 1px;
  height: 40px;
  background-color: gray;
  content: "";
  position: absolute;
  left: -28px;
}

.clients {
  margin-bottom: 0;
}

.clients__wrapper {
  margin-left: 110px;
  width: 1180px;
}

.button_client-download:hover {
  color: black;
}

.clients__sum {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px 10px 23px;
  font-size: 12px;
}

.clients-sum__value {
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
}

.clients-from__when--value {
  margin-right: 10px;
}

.clients-from__value {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
