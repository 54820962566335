.platform__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-left: 0;
  cursor: default;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: auto;

  &.settings {
    width: calc(100vw - 380px);
  }
}

/* Пагинация */
.platform__pagination {
  padding-bottom: 40px;
}

.pagination__wrapper {
  display: flex;
  justify-content: center;
  flex: 1;
}

.pagination__elem {
  font-size: 12px;
  line-height: 16px;
  width: 40px;
  height: 40px;
  background: #fff;
  color: #7d7c85;
  cursor: pointer;
  border-top: 1px solid rgb(195 194 201 / 40%);
  border-bottom: 1px solid rgb(195 194 201 / 40%);
  align-items: center;
  display: flex;
  justify-content: center;
  transition: 0.4s;
  position: relative;
}

.pagination__elem--prev {
  border: 1px solid #c3c2c9;
  border-radius: 3px 0 0 3px;
}

.pagination__elem--prev::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 8px;
  width: 15px;
  background: #c3c2c9;
  mask: url("../../img/arrow-down.svg") no-repeat center;
  mask-size: auto 8px;
  transition: 0.4s;
  transform: translate(-50%, -50%) rotate(90deg);
}

.pagination__elem--next {
  border: 1px solid #c3c2c9;
  border-radius: 0 3px 3px 0;
}

.pagination__elem--next::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 8px;
  width: 15px;
  background: #c3c2c9;
  mask: url("../../img/arrow-down.svg") no-repeat center;
  mask-size: auto 8px;
  transition: 0.4s;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.pagination__elem--disabled {
  cursor: default;
}

.pagination__elem--disabled:hover {
  background-color: white;
  border: 1px solid #c3c2c9;
}

.pagination__elem.active {
  color: #fff;
  background: black;
  border-color: black;
  cursor: default;
}

@media (max-width: 1000px) {
  .platform {
    &__wrapper {
      width: 100%;
    }
  }
}
