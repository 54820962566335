@charset "UTF-8";

.tracker__filter {
  display: flex;
  margin-top: 20px;
  margin-left: 40px;
}

.tracker__year {
  height: 40px;
}

.tracker-select__wrapper {
  background-color: white;
  width: 200px;
  min-width: 180px;
}

.tracker-select__wrapper:nth-child(2) {
  margin-left: 10px;
}

.tracker__content {
  width: 1216px;
  margin-left: 40px;
  margin-bottom: 40px;
}

.tracker__item {
  margin-top: 20px;
  padding-top: 35px;
  border-top: 1px solid #c4c4c433;
}

.tracker__title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

.tracker-table__column {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  padding-left: 15px;
  display: flex;
  align-items: center;
}

.tracker-table__column:first-child {
  color: #161718;
}

.tracker-table__column:last-child {
  text-align: right;
}

.tracker-table__column--head {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #b4b3bb;
  padding-left: 15px;
}

.tracker-table__column--head:first-child {
  padding-left: 0;
}

.tracker-table__settings {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  mask-image: url("../../img/settings.svg");
  background-color: #b4b3bb;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  transition: all 0.2s linear;
  cursor: pointer;
  margin-right: 20px;
}

.tracker-table__settings:hover {
  background-color: black;
}

.tracker-table__settings::after {
  content: "";
  display: block;
  width: 1px;
  height: 20px;
  background-color: #f1f1f4;
  position: absolute;
  top: 0;
  left: -20px;
}

.tracker-table__row {
  background-color: white;
  height: 50px;
  box-shadow: 0 4px 8px rgba(5 4 20 / 6%);
  border-radius: 3px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 203px 414px 222px 272px 101px;
  align-content: center;

  &.with-count {
    grid-template-columns: 203px 314px 222px 272px 100px 101px;
  }
}

.tracker__table {
  width: 100%;
  border-spacing: 0 10px;
  border-collapse: separate;
}

.settings-menu__top {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  box-shadow: 0 5px 25px rgba(7 2 14 / 4%);
  padding: 0 40px;
}

.settings__close {
  right: 0 !important;
}

.settings__form {
  display: flex;
  flex-direction: column;
  min-width: 519px;
  max-width: 520px;
}

.settings__input {
  margin-top: 4px;
}

.settings__holiday {
  margin-top: 20px;
}

.settings__control {
  margin-top: auto;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  box-shadow: 0 -5px 25px rgba(7 2 14 / 4%);
}

.settings__save {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  padding: 13px 50px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s linear;
  background-color: black;
  color: white;
}

.settings__save:hover {
  background-color: transparent;
  color: black;
}

.settings-form__top {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px;
  margin: 20px 25px 13px;
  border-bottom: 1px solid #f1f1f3;
}

.settings-form__personal {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 15px;
}

.settings-form__month {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 15px;
}

.settings-form__element {
  padding: 0 25px;
  position: relative;
}

.settings__title {
  position: relative;
  margin-left: 15px;
}

.settings__title::after {
  content: "Ч";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  position: absolute;
  top: 38px;
  right: 16px;
}

.tracker-filter__button {
  display: inline-flex;
  padding: 5px 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  border: 1px solid black;
  color: black;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}

.tracker-filter__form {
  display: flex;
}

.tracker-table__row--head {
  display: grid;
  grid-template-columns: 203px 414px 222px 272px 101px;
  align-content: center;

  &.with-count {
    grid-template-columns: 203px 314px 222px 272px 100px 101px;
  }
}
