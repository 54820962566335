@import "../helpers/variables";

.platform__nav {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 45px;
  width: 100%;
  max-width: 1215px;

  &.clients-list {
    width: 100%;
  }

  &.crm {
    width: 100%;
    border-top: 0;
    margin-top: 0;
  }

  &.control {
    width: 100%;
  }

  &.bills {
    height: auto;
    background-color: transparent;
  }

  &--right {
    display: flex;
  }

  &--left {
    display: flex;
    align-items: center;
  }
}

.platform {
  &__showed-toggle {
    margin-left: 33px;
  }
}

.showed-toggle {
  &__text {
    font-size: 14px;
    color: $bill-text;
    margin-right: 10px;
  }
}

.platform__nav--delim {
  display: inline-block;
  width: 1px;
  height: 15px;
  background-color: #7c7b85;
  position: absolute;
  right: 35px;
  top: 38px;
}

.top-nav__link {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  text-decoration: none;
  color: #9c9ba6;
  position: relative;
  margin-left: 15px;
  transition: all 0.2s linear;
}

.top-nav {
  height: 35px;
  margin-top: 27px;
}

.top-nav__list {
  display: flex;
  flex-direction: row;
  margin-left: -17px;
  margin-right: -17px;
}

.top-nav__item_small {
  font-size: 22px;
  line-height: 28px;
}

.top-nav__item_xsmall {
  font-size: 18px;
  line-height: 21px;
}

.top-nav__link::after {
  content: "";
  position: absolute;
  background-color: black;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: -14px;
  display: inline-flex;
  opacity: 0;
  transition: all 0.2s linear;
}

.top-nav__item_active .top-nav__link::after {
  display: none;
}

.top-nav__link::after:hover {
  opacity: 1;
}

.top-nav__link:first-child {
  margin-left: 0;
}

.top-nav__link:hover {
  color: black;
}

.top-nav__link.active::after {
  opacity: 1;
}

.top-nav__item_active::after {
  content: "";
  position: absolute;
  background-color: black;
  width: 100%;
  height: 4px;
  left: 0;
  display: block;
  transition: all 0.2s linear;
  opacity: 1;
  bottom: 0;
}

.show-settings {
  display: flex;
  margin-left: 21px;
}

.show-settings.no-selector {
  display: none;
}

.show-settings::after {
  content: "";
  display: flex;
  position: absolute;
  top: 3px;
  right: 28px;
  width: 1px;
  height: 15px;
  background-color: #c4c4c4;
}

.top-nav__item_active .show-settings {
  display: flex;
}
